import { useState } from 'react';
import { truncate } from 'lodash';
const useExpandableDescription = (description = '', maxLength = 250) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpansion = () => {
    setIsExpanded((prevState) => !prevState);
  };

  const renderDescription = () => {
    if (!description) return null;

    const truncatedDescription = truncate(description, {
      length: maxLength,
      separator: ' ',
    });

    return isExpanded ? description : truncatedDescription;
  };

  return { isExpanded, toggleExpansion, renderDescription };
};

export default useExpandableDescription;
