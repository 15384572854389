import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DesktopDateTimePicker } from "@mui/x-date-pickers";
import { DesktopDateTimePickerProps } from "@mui/x-date-pickers/DesktopDateTimePicker/DesktopDateTimePicker";

import './index.scss';

const DateTimePicker = <TInputDate, TDate = TInputDate>(props: DesktopDateTimePickerProps<TInputDate, TDate> & React.RefAttributes<HTMLDivElement>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DesktopDateTimePicker
        className="desktopDateTimePicker"
        {...props}
      />
    </LocalizationProvider>
  )
};

export default DateTimePicker;