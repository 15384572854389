import { FC } from 'react';
import {
  Navigate,
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import {
  ConsumptionRequestsRoutes,
  MainRoutes,
  PaymentsRoutes,
  PaymentsSettingsRoutes,
  TaxModerationRoutes,
} from './enum';
import BannerDetails from 'containers/BannerDetails/BannerDetails';
import CollectionDetails from 'containers/CollectionDetails/CollectionDetails';
import ConsumptionRequests from 'containers/ConsumptionRequests/ConsumptionRequests';
import ContentDisplay from 'containers/ContentDisplay/ContentDisplay';
import ErrorPage from 'containers/ErrorPage/ErrorPage';
import Layout from 'components/Layout/Layout';
import Login from 'containers/Authorization/Login';
import Moderation from 'containers/Moderation/Moderation';
import NotFound from 'containers/NotFound/NotFound';
import Payments from 'containers/Payments/Payments';
import Profile from 'containers/Profile/Profile';
import SetUpPassword from 'containers/Authorization/SetUpPassword';
import Settings from 'containers/PaymentsSettings/PaymentsSettings';
import SnackbarContainer from 'containers/SnackbarContainer';
import TaxModeration from 'containers/TaxModeration/TaxModeration';
import TimMembers from 'containers/TimMembers/TimMembers';
import UserProfile from 'containers/UserProfile/UserProfile';
import Users from 'containers/Users/Users';
import WorldManagement from 'containers/WorldManagement/WorldManagement';
import { Operations } from 'containers/Payments/views';
import { TariffGroups, TariffDetails, PaymentFees, InAppTiers, Taxes } from 'containers/PaymentsSettings/views';
import { TaxModerationCarousel } from 'containers/TaxModeration/views';
import { ConsumptionRequestsCarousel } from '../containers/ConsumptionRequests/views';
import { CustomNoCardsOverlay } from '../containers/TaxModeration/components';

const MainLayout: FC = () => (
  <Layout>
    <Outlet />
  </Layout>
);

const SnackbarProvider = () => {
  return (
    <SnackbarContainer>
      <Outlet />
    </SnackbarContainer>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<SnackbarProvider />}>
      <Route path="/ui/login" element={<Login />} />
      <Route path="/ui/members/password-setup" element={<SetUpPassword />} />
      <Route path="" element={<MainLayout />}>
        <Route path="/ui/users/:tabId?" element={<Users />} />
        <Route path="/ui/users/user/:userId" element={<UserProfile />} />
        <Route path="/ui/members" element={<TimMembers />} />
        <Route path="/ui/content-display/:tabId?" element={<ContentDisplay />} />
        <Route path="/ui/moderation/:tabId?" element={<Moderation />} />
        <Route path="/ui/moderation/:tabId?/:userId?" element={<Moderation />} />
        <Route path={MainRoutes.PAYMENTS} element={<Payments />}>
          <Route path="" element={<Navigate to={PaymentsRoutes.OPERATIONS} replace />} />
          <Route path={PaymentsRoutes.OPERATIONS} element={<Operations />} />
          <Route path={PaymentsRoutes.BULK_PAYOUTS} element={<h1>BULK_PAYOUTS</h1>} />
          <Route path={PaymentsRoutes.FINANCE_DATA} element={<h1>FINANCE_DATA</h1>} />
          <Route path={PaymentsRoutes.USER_FINANCE_DATA} element={<h1>USER_FINANCE_DATA</h1>} />
        </Route>
        <Route path={TaxModerationRoutes.TAX_MODERATION} element={<TaxModeration />}>
          <Route path={TaxModerationRoutes.TAX_MODERATION_DETAILS} element={<TaxModerationCarousel />} />
          <Route path={TaxModerationRoutes.TAX_MODERATION_ALL_RESOLVED} element={<CustomNoCardsOverlay />} />
        </Route>
        <Route path={MainRoutes.PAYMENTS_SETTINGS} element={<Settings />}>
          <Route path="" element={<Navigate to={PaymentsSettingsRoutes.TARIFFS} replace />} />
          <Route path={PaymentsSettingsRoutes.TARIFFS} element={<TariffGroups />} />
          <Route path={PaymentsSettingsRoutes.TARIFFS_DETAILS} element={<TariffDetails />} />
          <Route path={PaymentsSettingsRoutes.PAYMENT_FEES} element={<PaymentFees />} />
          <Route path={PaymentsSettingsRoutes.IN_APP_TIERS} element={<InAppTiers />} />
          <Route path={PaymentsSettingsRoutes.TAXES} element={<Taxes />} />
        </Route>
        <Route path={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS} element={<ConsumptionRequests />}>
          <Route
            path={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS_DETAILS}
            element={<ConsumptionRequestsCarousel />}
          />
        </Route>
        <Route path="/ui/content-display" element={<ContentDisplay />} />
        <Route path="/ui/profile" element={<Profile />} />
        <Route path="/ui/world-management/:tabId?" element={<WorldManagement />} />
        <Route path="/ui/world-management/collections/:collectionId/:userId?" element={<CollectionDetails />} />
        <Route path="/ui/world-management/banners/:bannerId" element={<BannerDetails />} />
        <Route path="/" element={<Navigate to="/ui/users" replace />} />
        <Route path="*" element={<NotFound />} />
      </Route>
    </Route>,
  ),
);

export const AppRoutes: FC = () => {
  return (
    <StyledEngineProvider injectFirst>
      <ErrorBoundary FallbackComponent={ErrorPage}>
        <RouterProvider router={router} />
      </ErrorBoundary>
    </StyledEngineProvider>
  );
};
