import { IMedia, IStatus } from 'types/commonTypes';
import colors from 'theme/constants/colors';
import { IMemberInfo } from 'types/Member';

/* ----------------------------- Payments ------------------------------ */
/**
 * Represents a payment operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the operation.
 * @property {string} userFriendlyOperationId - The unique identifier for the operation (short).
 * @property {string} operationType - The type of operation.
 * @property {string} profileId - The identifier of the user (payer) in the YZZY application.
 * @property {string} profileName - The name of the user (payer) in the YZZY application.
 * @property {string} providerName - The name of the payment service provider used for the transaction.
 * @property {string} currency - The currency used for the payment operation.
 * @property {string} taxJarOrderId - The identifier for the order in TaxJar (Tax Transaction ID).
 * @property {string} recipientProfileId - The identifier of the user (payee) in the YZZY application.
 * @property {string} recipientProfileName - The name of the user (payee) in the YZZY application.
 * @property {string} [entityId] - The identifier of the paid entity (optional).
 * @property {string} [entityTitle] - The name of the paid entity (optional).
 * @property {string} [entityDescription] - The description of the paid entity (optional).
 * @property {string} [entityExecutionId] - The unique identifier for execution entity.
 * @property {string} [externalPayerId] - The external identifier of the payer, if applicable.
 * @property {string} [externalTaxPayerId] - The external tax identifier of the payer, if applicable.
 * @property {string} [externalReceiverId] - The external identifier of the receiver, if applicable.
 * @property {number} entityVersion - The version of the paid entity.
 * @property {number} total - The total amount of the payment operation, including taxes and the cost of the paid entity.
 * @property {number} price - The cost of the paid entity within the operation.
 * @property {number} totalAfterAllFees - The amount after deducting (calculating) all fees.
 * @property {number} platformFeeClient - The platform fee (YZZY) for the buyer.
 * @property {number} platformFeeCreator - The platform fee (YZZY) for the seller.
 * @property {number} serviceFeeClient - The sum of the platform commission and the payment system commission paid by the client.
 * @property {number} serviceFeeCreator - The sum of ${platform_fee_creator} + ${provider_fee_creator}.
 * @property {number} paymentFeeAmount - The payment service fee through which the payment will be made (e.g., Stripe). Paid by the buyer.
 * @property {number} providerFeeCreator - The payment service fee through which the payment will be made (e.g., Stripe). Paid by the seller.
 * @property {number} salesTaxAmount - Taxes paid by the buyer.
 * @property {boolean} isTransferSuspended - Indicates whether the transfer is suspended.
 * @property {OperationStatuses} status - The status of the operation.
 * @property {Date} createDate - The date of operation's creation.
 * @property {string} tierCode - The tier code of operation.
 */
export interface IOperation {
  id: string;
  userFriendlyOperationId: string;
  operationType: string;
  profileId: string;
  profileName: string;
  providerName: string;
  currency: string;
  taxJarOrderId: string;
  recipientProfileId: string;
  recipientProfileName: string;
  entityId?: string;
  entityTitle?: string;
  entityDescription?: string;
  entityExecutionId?: string;
  externalPayerId?: string;
  externalTaxPayerId?: string;
  externalReceiverId?: string;
  entityVersion: number;
  total: number;
  price: number;
  totalAfterAllFees: number;
  platformFeeClient: number;
  platformFeeCreator: number;
  serviceFeeClient: number;
  serviceFeeCreator: number;
  paymentFeeAmount: number;
  providerFeeCreator: number;
  salesTaxAmount: number;
  isTransferSuspended: boolean;
  status: OperationStatuses;
  createDate: Date;
  tierCode: string;
}

/**
 * Represents details of a payment operation.
 *
 * @interface
 * @property {IOperation} operation - Operation itself.
 * @property {ILastAdminOperationHistory} lastAdminOperationHistory - The last administrative history related to the operation.
 * @property {ITransaction[]} transactions - An array of transactions associated with the operation.
 */
export interface IOperationDetails {
  operation: IOperation;
  lastAdminOperationHistory: ILastAdminOperationHistory;
  transactions: ITransaction[];
}

/**
 * Represents the administrative history of the last operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the administrative history.
 * @property {IMemberInfo} memberInfo - Information about the member associated with the administrative action.
 * @property {IMedia} avatarPreview - The member's avatar.
 * @property {OperationActions} action - The type of administrative action performed.
 * @property {Date} createdAt - The date when the administrative action was performed.
 */
export interface ILastAdminOperationHistory {
  id: string;
  memberInfo: IMemberInfo;
  avatarPreview: IMedia;
  action: OperationActions;
  createdAt: Date;
}

/**
 * Enumerates the possible administrative actions for a payment operation.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationActions {
  SUSPEND = 'SUSPEND',
  UNSUSPEND = 'UNSUSPEND',
  REFUND = 'REFUND',
}

/**
 * Represents a transaction associated with a payment operation.
 *
 * @interface
 * @property {string} id - The unique identifier for the transaction.
 * @property {string} type - The type of the transaction.
 * @property {OperationStatuses} status - The status of the transaction.
 * @property {string} amount - The amount of the transaction.
 * @property {string} currency - The currency used for the transaction.
 * @property {string} externalOrderId - The unique identifier for external order.
 * @property {string} paymentMethodName - The name of the payment method used for the transaction.
 * @property {string} psTransactionId - The unique identifier provided by the payment service for the transaction.
 * @property {string} [reason] - The reason for a refund, if applicable.
 * @property {string} [comment] - Additional information or message related to refund.
 * @property {Date} createDate - The date when the transaction was created.
 * @property {boolean} suspended - If true, transaction is suspended.
 */
export interface ITransaction {
  id: string;
  type: string;
  status: OperationStatuses;
  amount: string;
  currency: string;
  externalOrderId: string;
  paymentMethodName: string;
  psTransactionId: string;
  reason?: string;
  comment?: string;
  createDate: Date;
  suspended: boolean;
}

/**
 * Represents the state of operations in the store.
 *
 * @interface
 * @property {IOperation[]} operations - An array of payment operations.
 * @property {IOperationDetails | null} operationDetails - Details of a specific payment operation, or null if not available.
 * @property {number} operationsCount - The total number of payment operations.
 * @property {IStatus} status - The status value indicating the state of the operation's data.
 * @property {IStatus} actionStatus - The status value indicating the state of the action on operation.
 * @property {OperationActions} currentAction - Current operation's action.
 * @property {IStatus} operationDetailsStatus - The status value indicating the state of the specific operation details data.
 * @property {IOperationSearchItem[]} operationsSearch - An array of search results.
 * @property {string[]} operationsSearchAll - An array of "allIds" search results.
 * @property {IStatus} operationsSearchStatus - The status value indicating the state of search loading.
 */
export interface IOperationsState {
  operations: IOperation[];
  operationDetails: IOperationDetails | null;
  operationsCount: number;
  status: IStatus;
  actionStatus: IStatus;
  currentAction: OperationActions | null;
  operationDetailsStatus: IStatus;
  operationsSearch: IOperationSearchItem[];
  operationsSearchAll: string[];
  operationsSearchStatus: IStatus;
}

/**
 * Represents SearchItem for operations.
 */
export interface IOperationSearchItem {
  operationId: string;
  userFriendlyOperationId: string;
}

/**
 * Enumerates the possible status values for operation.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationStatuses {
  START = 'START',
  REQUESTED = 'REQUESTED',
  HOLD = 'HOLD',
  CHARGED = 'CHARGED',
  PENDING = 'PENDING',
  SUCCESS = 'SUCCESS',
  CANCELED = 'CANCELED',
  FAILED = 'FAILED',
  PENDING_REFUND = 'PENDING_REFUND',
  PARTIALLY_REFUNDED = 'PARTIALLY_REFUNDED',
  REFUNDED = 'REFUNDED',
  IN_TRANSIT = 'IN_TRANSIT',
  FROZEN = 'FROZEN',
  COMPLETED = 'COMPLETED',
  REFUNDED_REFUNDED = 'REFUNDED_REFUNDED',
  REFUNDED_HOLD_CHARGE = 'REFUNDED_HOLD_CHARGE',
  HOLD_CHARGE = 'HOLD_CHARGE',
  REFUNDED_PENDING = 'REFUNDED_PENDING',
  REFUNDED_PENDING_CHARGE = 'REFUNDED_PENDING_CHARGE',
  REFUNDED_FROZEN = 'REFUNDED_FROZEN',
  REFUNDED_CHARGED = 'REFUNDED_CHARGED',
  REFUNDED_CANCELED = 'REFUNDED_CANCELED',
}

/**
 * Enumerates the possible types of payment operations.
 *
 * @enum {string}
 * @readonly
 */
export enum OperationTypes {
  IAP_FOLLOW = 'IAP_FOLLOW',
  IAP_LIFESTYLE = 'IAP_LIFESTYLE',
  OFFER = 'OFFER',
  WISH = 'WISH',
  PRIVATE_WISH = 'PRIVATE_WISH',
  PAY_OUT = 'PAY_OUT',
  DONATE = 'DONATE',
  CHARGE = 'CHARGE',
  IAP_DONATE = 'IAP_DONATE',
  RM_GOAL = 'RM_GOAL',
  EVENT = 'EVENT',
  IAP_EVENT = 'IAP_EVENT',
  BOOST = 'BOOST',
  TIPS_EVENT = 'TIPS_EVENT',
  TIPS_OFFER = 'TIPS_OFFER',
}

/**
 * Represents a status object for operations.
 *
 * @interface
 * @property {OperationStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {colors} color - Associated color code.
 */
export interface IOperationStatus {
  status: OperationStatuses;
  title: string;
  color: colors;
}

/**
 * Represents an operation type object for operations.
 *
 * @interface
 * @property {OperationTypes} operationType - Operation type value.
 * @property {string} title - Operation type title.
 * @property {string} subtitle - Operation type subtitle.
 */
export interface IOperationType {
  operationType: OperationTypes;
  title: string;
  subtitle: string;
}

/**
 * Represents a transaction type.
 *
 * @interface
 * @property {TransactionTypes} transactionType - The type of the transaction.
 * @property {string} title - The title or description associated with the transaction type.
 */
export interface ITransactionType {
  transactionType: TransactionTypes;
  title: string;
}

/**
 * Enumerates the possible types of transaction.
 *
 * @enum {string}
 * @readonly
 */
export enum TransactionTypes {
  APPLE_IAP = 'APPLE_IAP',
  CARD_PAYMENTS = 'CARD_PAYMENTS',
  CHARGE = 'CHARGE',
  GOOGLE_IAP = 'GOOGLE_IAP',
  PAY_OUT = 'PAY_OUT',
  REFUND = 'REFUND',
  REVERSAL_TRANSFER = 'REVERSAL_TRANSFER',
  TRANSFER = 'TRANSFER',
}
