import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useAppSelector } from 'store/hooks';
import CloseIcon from 'assets/img/CloseIcon';
import LinkIcon from 'assets/img/LinkIcon';
import Button from 'components/shared/Button/Button';
import IconButton from 'components/shared/IconButton/IconButton';
import Modal from 'components/shared/Modal/Modal';
import Typography from 'components/shared/Typography/Typography';
import UserProfile from 'components/UserProfile/UserProfile';
import './index.scss';

interface IProps {
  open: boolean;
  onClose(): void;
  currentUserId?: string;
}

const UserProfileModal = ({ open, onClose, currentUserId }: IProps) => {
  const { userId } = useParams();
  const usedUserId = currentUserId ?? userId;

  const loadingStatus = useAppSelector((state) => state.userProfile.status);

  useEffect(() => {
    if (loadingStatus === 'failed') {
      onClose();
      enqueueSnackbar('Cannot load user’s card', { variant: 'error' as VariantType });
    }
  }, [loadingStatus]);

  return (
    <Modal open={open} onClose={() => onClose()} customstyle={{ padding: 0 }}>
      <>
        <header className="userModalContent-header">
          <Typography variant="h6" color="text.primary">
            User #{usedUserId}
          </Typography>
          {/* <Link to={`/ui/users/user/${usedUserId}`} target="_blank">
            <Button size="small" startIcon={<LinkIcon />}>
              Show in YZZY users
            </Button>
          </Link> */}
          <IconButton className="userModalContent-header-close" size="medium" onClick={() => onClose()}>
            <CloseIcon />
          </IconButton>
        </header>
        <div className="userModalContent">
          <div className="userProfile--container">
            <UserProfile currentUserId={usedUserId} />
          </div>
        </div>
      </>
    </Modal>
  );
};

export default UserProfileModal;
