import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { TIER_MAX_LIMIT } from 'tim-constants';
import colors from 'theme/constants/colors';
import { ITier } from 'store/slices/Settings/interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchAddTier,
  fetchDropdownOptions,
  fetchTiers,
  selectLinkIdOptions,
  selectOperationTypesOptions,
  selectProviderOptions,
} from 'store/slices/Settings/tiersSlice';
import { getValidationSchema, renderLinkIdOptions } from './utils';
import Autocomplete, { renderOptions } from 'components/shared/Autocomplete/Autocomplete';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogContentText from 'components/shared/Dialog/DialogContentText/DialogContentText';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import NumericFormatCustom from 'components/shared/CurrencyFormatCustom/NumericFormatCustom';
import Radio from 'components/shared/Radio/Radio';
import RadioGroup from 'components/shared/RadioGroup/RadioGroup';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import { ConfirmCancelModal } from './ConfirmCancelModal';
import './index.scss';

interface IAddTierModalProps {
  open: boolean;
  onClose: () => void;
  page: number;
  pageSize: number;
}

export const AddTierModal = ({ open, onClose, page, pageSize }: IAddTierModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(), []);

  const providerOptions = useAppSelector(selectProviderOptions);
  const operationTypeOptions = useAppSelector(selectOperationTypesOptions);
  const linkIdOptions = useAppSelector(selectLinkIdOptions);

  const [selectedProvider, setSelectedProvider] = useState<{ value: string; providerName: string } | null>(null);
  const [selectedOperationType, setSelectedOperationType] = useState<string | null>(null);
  const [selectedLinkId, setSelectedLinkId] = useState<string | null>(null);
  const [isSelectedPriceField, setIsSelectedPriceField] = useState(false);

  const {
    control,
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    setError,
    trigger,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      country: 'US',
      currency: 'USD',
      linkId: '',
      name: '',
      operationType: '',
      price: '',
      productId: '',
      providerId: '',
      radioValue: 'new',
    },
  });

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  const handleClose = () => {
    reset();
    setSelectedProvider(null);
    setSelectedOperationType(null);
    setSelectedLinkId(null);
    onClose();
  };

  const onSubmit = async (data: Omit<ITier, 'status'>) => {
    void trigger();

    const formattedData = {
      country: data.country,
      currency: data.currency,
      linkId: data.linkId,
      operationType: data.operationType,
      price: data.price,
      productId: data.productId.trim(),
      providerId: data.providerId,
      name: data.name.trim(),
    };

    try {
      const result = await dispatch(fetchAddTier(formattedData));

      if (result.meta.requestStatus === 'fulfilled') {
        handleClose();
        enqueueSnackbar('Tier has been added', {
          variant: 'success' as VariantType,
        });
        void dispatch(fetchTiers({ page, pageSize }));
      } else if (result.payload.meta) {
        const { title, meta } = result.payload;
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITier, 'status'>>;

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: title ?? meta[key] }));
        } else {
          enqueueSnackbar('An error occurred while adding the tier', {
            variant: 'error' as VariantType,
          });
        }
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error' as VariantType,
      });
    }
  };

  useEffect(() => {
    dispatch(fetchDropdownOptions({ fieldForDropdown: 'provider', additionalValue: '' }));
    dispatch(fetchDropdownOptions({ fieldForDropdown: 'operation-type', additionalValue: '' }));
  }, [dispatch]);

  useEffect(() => {
    if (selectedProvider) {
      dispatch(fetchDropdownOptions({ fieldForDropdown: 'link-id', additionalValue: selectedProvider?.value ?? '' }));
    }
  }, [dispatch, selectedProvider]);

  return (
    <>
      <Dialog disableScrollLock fullWidth maxWidth="sm" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">Add new tier</DialogTitle>
          <DialogContent>
            <DialogContentText color="text.primary" variant="body1">
              All fields are required
            </DialogContentText>
            <SimpleGrid container flexDirection="column" minHeight="100%" alignContent="stretch" rowSpacing={2}>
              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="providerId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        size="small"
                        label="Provider"
                        clearIcon={null}
                        value={selectedProvider}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(params, option, { inputValue }) =>
                          renderOptions(params, option.providerName, inputValue)
                        }
                        getOptionLabel={({ providerName }) => providerName}
                        options={providerOptions || []}
                        onChange={(_, value) => {
                          setValue('providerId', value ? value.value : null, { shouldDirty: true });
                          setSelectedProvider(value);
                          void trigger('providerId');
                        }}
                        inputprops={{
                          error: !!errors.providerId,
                        }}
                        onBlur={() => trigger('providerId')}
                      />
                      <FormHelperText error={!!errors.providerId}>
                        {errors.providerId?.message as string}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('productId')}
                  size="small"
                  name="productId"
                  label="Product ID"
                  fullWidth
                  error={!!errors.productId}
                  helperText={errors.productId && (errors.productId?.message as string)}
                  onBlur={() => trigger('productId')}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <TextField
                  {...register('name')}
                  size="small"
                  name="name"
                  label="Reference Name"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name && (errors.name?.message as string)}
                  onBlur={() => trigger('name')}
                />
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <Box sx={{ p: 1, border: `1px dashed ${colors.divider}`, borderRadius: 2 }}>
                  <SimpleGrid container flexDirection="column" minHeight="100%" alignContent="stretch" rowSpacing={2}>
                    <SimpleGrid item xs={12}>
                      <TextField
                        {...register('price')}
                        size="small"
                        name="price"
                        label="Price"
                        fullWidth
                        placeholder={isSelectedPriceField ? '0.00' : undefined}
                        error={!!errors.price}
                        helperText={errors.price && (errors.price?.message as string)}
                        onFocus={() => setIsSelectedPriceField(true)}
                        onBlur={() => {
                          setIsSelectedPriceField(false);
                          void trigger('price');
                        }}
                        InputProps={{
                          inputComponent: NumericFormatCustom as any,
                          inputProps: {
                            decimalScale: 2,
                            max: TIER_MAX_LIMIT,
                          },
                          startAdornment: isSelectedPriceField ? (
                            <InputAdornment color="text.primary" position="start">
                              <Typography variant="body1" color="action.disabled">
                                $
                              </Typography>
                            </InputAdornment>
                          ) : undefined,
                        }}
                      />
                    </SimpleGrid>
                    <SimpleGrid item xs={12}>
                      <Controller
                        control={control}
                        name="country"
                        render={() => (
                          <FormControl fullWidth>
                            <Autocomplete
                              size="small"
                              label="Country"
                              clearIcon={null}
                              value="US"
                              // isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                              // getOptionLabel={({ country }) => country}
                              options={[]}
                              disabled
                              inputprops={{
                                error: !!errors.country,
                              }}
                              onBlur={() => trigger('country')}
                            />
                            <FormHelperText error={!!errors.country}>
                              {errors.country?.message as string}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      {errors.country && <FormHelperText error>{errors.country?.message as string}</FormHelperText>}
                    </SimpleGrid>
                    <SimpleGrid item xs={12}>
                      <Controller
                        control={control}
                        name="currency"
                        render={() => (
                          <FormControl fullWidth>
                            <Autocomplete
                              size="small"
                              label="Currency"
                              clearIcon={null}
                              value="USD"
                              // isOptionEqualToValue={(option, value) => option.transactionTypeId === value.transactionTypeId}
                              // getOptionLabel={({ country }) => country}
                              options={[]}
                              disabled
                              inputprops={{
                                error: !!errors.currency,
                              }}
                              onBlur={() => trigger('currency')}
                            />
                            <FormHelperText error={!!errors.currency}>
                              {errors.currency?.message as string}
                            </FormHelperText>
                          </FormControl>
                        )}
                      />
                      {errors.currency && <FormHelperText error>{errors.currency?.message as string}</FormHelperText>}
                    </SimpleGrid>
                  </SimpleGrid>
                </Box>
              </SimpleGrid>
              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="operationType"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        size="small"
                        label="Operation type"
                        clearIcon={null}
                        value={selectedOperationType}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(params, option, { inputValue }) =>
                          renderOptions(params, option.operationTypeDescription, inputValue)
                        }
                        getOptionLabel={({ operationTypeDescription }) => operationTypeDescription}
                        options={operationTypeOptions || []}
                        onChange={(_, value) => {
                          setValue('operationType', value ? value.value : null, { shouldDirty: true });
                          setSelectedOperationType(value);
                          void trigger('operationType');
                        }}
                        inputprops={{
                          error: !!errors.operationType,
                        }}
                        onBlur={() => trigger('operationType')}
                      />
                      <FormHelperText error={!!errors.operationType}>
                        {errors.operationType?.message as string}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>

              <SimpleGrid item xs={12}>
                <FormControl>
                  <Typography variant="subtitle2">Link ID</Typography>
                  <Controller
                    control={control}
                    name="radioValue"
                    render={({ field }) => (
                      <RadioGroup {...field}>
                        <FormControlLabel
                          sx={{ ml: 'unset' }}
                          value="new"
                          control={<Radio />}
                          label="Create a new ID link"
                          onClick={() => {
                            setSelectedLinkId(null);
                            resetField('linkId');
                          }}
                        />
                        <FormControlLabel
                          sx={{ ml: 'unset' }}
                          value="list"
                          control={<Radio />}
                          label="Select an ID link from the existing ones"
                        />
                      </RadioGroup>
                    )}
                  />
                </FormControl>
              </SimpleGrid>

              <SimpleGrid item xs={12}>
                <Controller
                  control={control}
                  name="linkId"
                  render={() => (
                    <FormControl fullWidth>
                      <Autocomplete
                        size="small"
                        label="Link ID"
                        clearIcon={null}
                        value={selectedLinkId}
                        isOptionEqualToValue={(option, value) => option.value === value.value}
                        renderOption={(props, option, { inputValue }) => renderLinkIdOptions(props, option, inputValue)}
                        getOptionLabel={({ value }) => value}
                        options={linkIdOptions || []}
                        onChange={(_, value) => {
                          setValue('linkId', value ? value.value : null, { shouldDirty: true });
                          setSelectedLinkId(value);
                          void trigger('linkId');
                        }}
                        disabled={watch('radioValue') === 'new' || !selectedProvider}
                        inputprops={{
                          error: !!errors.linkId,
                        }}
                        onBlur={() => trigger('linkId')}
                      />
                      <FormHelperText error={!!errors.linkId}>
                        {watch('radioValue') !== 'new' && !errors.linkId && !selectedProvider
                          ? 'Choose a provider first'
                          : (errors.linkId?.message as string)}
                      </FormHelperText>
                    </FormControl>
                  )}
                />
              </SimpleGrid>
            </SimpleGrid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose();
              }}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton disabled={!isValid || !isDirty} variant="contained" color="primary" type="submit">
              Add tier
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleClose}
      />
    </>
  );
};
