import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import { Link, useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { ConsumptionRequestsRoutes } from 'routes/enum';
import { selectUserPermissions } from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchOperationDetails } from 'store/slices/Payments/operationsSlice';
import {
  selectConsumptionRequestCasesIds,
  selectConsumptionRequestCasesStatus,
} from 'store/slices/ConsumptionRequests/consumptionRequestsSlice';
import ArrowBack from 'assets/img/ArrowBack';
import ArrowRight from 'assets/img/ArrowRight';
import ChevronRightIcon from 'assets/img/ChevronRightIcon';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import LinkMUI from 'components/shared/Link/Link';
import IconButton from 'components/shared/IconButton/IconButton';
import Typography from 'components/shared/Typography/Typography';
import { RenderConsumptionRequest } from '../components';
import { useForm } from 'react-hook-form';
import getValidationSchema from './utils/getValidationSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import { ConfirmCancelModal } from '../modals';

export const ConsumptionRequestsCarousel = () => {
  const dispatch = useAppDispatch();
  const { id: requestId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const operationId = searchParams.get('operationId');
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState<boolean>(false);
  const isBackButtonClickedRef = useRef<boolean>(false);

  // const userPermissions = useAppSelector(selectUserPermissions);
  const allIds = useAppSelector(selectConsumptionRequestCasesIds);
  // const status = useAppSelector(selectConsumptionRequestCasesStatus);
  // let isLoading = status === 'loading';

  const direction = useRef(0);
  const paginateCard = (newDirection: number) => {
    direction.current = newDirection;
  };

  const initialFetchData = useCallback(async () => {
    operationId && (await dispatch(fetchOperationDetails(operationId)));
  }, [dispatch, operationId]);

  //TODO сейчас в allIds - массив operationId
  const getNextRequestId = (currentId: string) => {
    const currentIndex = allIds.indexOf(currentId);
    const nextIndex = currentIndex === allIds.length - 1 ? 0 : currentIndex + 1;
    return allIds[nextIndex];
  };

  const handleCloseConfirmModal = () => {
    reset();
    if (requestId) {
      const nextId = getNextRequestId(requestId);
      navigate(`/ui/consumption-requests/details/${nextId}`);
    }

    // additional navigation when pressing the back button.
    if (isBackButtonClickedRef.current) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
      isBackButtonClickedRef.current = false;
    }
  };

  const handleNavigateToNextRequest = async () => {
    if (!requestId) return;

    if (!isValid && !isDirty) {
      const nextId = getNextRequestId(requestId);
      navigate(`/ui/consumption-requests/details/${nextId}`);
      paginateCard(1);
    }

    if (isDirty) {
      // await trigger();
      setIsOpenConfirmCancelModal(true);
    }
  };

  const handleNavigateToBack = () => {
    isBackButtonClickedRef.current = true;

    if (!isDirty) {
      navigate(ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS);
    }

    if (isDirty) {
      // await trigger();
      setIsOpenConfirmCancelModal(true);
    }
  };

  // const isDisabledScrollButton = !(allIds.length > 1);

  const variants = {
    enter: () => {
      return {
        x: direction.current > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: () => {
      return {
        x: direction.current < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  useEffect(() => {
    initialFetchData();
  }, [location.pathname]);

  const schema = useMemo(() => getValidationSchema(), []);

  type FormValues = {
    reason: string;
    comment: string;
    consumptionRequestType: string;
    appleConsumptionRequestData: {
      accountTenure: string;
      consumptionStatus: string;
      deliveryStatus: string;
      lifetimeDollarsPurchased: string;
      lifetimeDollarsRefunded: string;
      playTime: string;
      userStatus: string;
    };
  };

  const {
    getValues,
    setValue,
    setError,
    reset,
    register,
    getFieldState,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      reason: '',
      comment: '',
      consumptionRequestType: 'APPLE',
      appleConsumptionRequestData: {
        accountTenure: '',
        consumptionStatus: '',
        deliveryStatus: '',
        lifetimeDollarsPurchased: '',
        lifetimeDollarsRefunded: '',
        playTime: '',
        userStatus: '',
      },
    },
  });

  return (
    <Stack className="consumption-requests-carousel" mt={2} gap={2}>
      <div className="consumption-requests-carousel__breadcrumbs">
        <div onClick={handleNavigateToBack}>
          <IconButton size="small" color="default">
            <ArrowBack />
          </IconButton>
        </div>
        <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
          <LinkMUI
            component={Link}
            to={ConsumptionRequestsRoutes.CONSUMPTION_REQUESTS}
            variant="h6"
            color="text.secondary"
            underline="hover"
          >
            Table
          </LinkMUI>
          <Typography color="text.primary" variant="h6">
            {`Solving request / Case ID: ${requestId} `}
          </Typography>
        </Breadcrumbs>
      </div>

      <Stack className="consumption-requests-carousel__content" gap={2}>
        <AnimatePresence initial={false} mode="wait">
          <motion.div
            key={location.pathname}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: 'spring', stiffness: 300, damping: 30 },
              opacity: { duration: 0.2 },
            }}
          >
            <RenderConsumptionRequest
              onNavigateToNextRequest={handleNavigateToNextRequest}
              useFormMethods={{
                getValues,
                setValue,
                setError,
                reset,
                register,
                getFieldState,
                trigger,
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                formState: { errors, isValid, isDirty },
              }}
            />
          </motion.div>
        </AnimatePresence>

        <Stack direction="row" alignItems="center" justifyContent="flex-end">
          <Button
            color="primary"
            size="medium"
            endIcon={<ArrowRight />}
            onClick={handleNavigateToNextRequest}
            disabled
            // disabled={isDisabledScrollButton}
          >
            Next request
          </Button>
        </Stack>
      </Stack>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleCloseConfirmModal}
      />
    </Stack>
  );
};
