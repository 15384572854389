import { ChangeEvent, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { GridColDef, GridRenderCellParams, GridSelectionModel } from '@mui/x-data-grid';
import { alpha, Avatar, Link, Menu, MenuItem, SelectChangeEvent, Stack, Tab } from '@mui/material';
import { useNavigate, useParams } from 'react-router';
import { useErrorBoundary } from 'react-error-boundary';
import { VariantType, enqueueSnackbar } from 'notistack';
import { useDocumentTitle } from '@uidotdev/usehooks';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import uniqueId from 'lodash/uniqueId';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import colors from 'theme/constants/colors';
import { getAuraColor } from 'utils/getAuraColor';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearData,
  fetchChangeStatus,
  fetchUsers,
  selectActiveUsers,
  selectBannedUsers,
  selectDeletedUsers,
  selectActiveUsersCount,
  selectBannedUsersCount,
  selectDeletedUsersCount,
  selectUsersRefreshStatus,
  selectUsersStatus,
  setUsersRefreshStatus,
  setUsersStatus,
} from 'store/usersSlice';
import {
  fetchFilters,
  fetchFilterPresets,
  clearFilters,
  reverseFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from 'store/filtersSlice';
import { fetchColumns, fetchColumnsPresets } from 'store/columnsSlice';
import { clearSortingData, fetchSortingColumns, fetchSortingPresets, setSortingData } from 'store/sortingSlice';
import { fetchUpdateComment, selectUpdateCommentStatus } from 'store/userProfileSlice';
import ChangeIcon from 'assets/img/ChangeIcon';
import ColumnsIcon from 'assets/img/ColumnsIcon';
import CopyIcon from 'assets/img/CopyIcon';
import EditIcon from 'assets/img/EditIcon';
import FilterIcon from 'assets/img/FilterIcon';
import RefreshIcon from 'assets/img/RefreshIcon';
import SortIcon from 'assets/img/SortIcon';
import SystemIcon from 'assets/img/SystemIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Alert from 'components/shared/Alert/Alert';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import ColumnsDrawer from 'components/ColumnsDrawer/ColumnsDrawer';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import IconButton from 'components/shared/IconButton/IconButton';
import Input from 'components/shared/Input/Input';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Modal from 'components/shared/Modal/Modal';
import NoUsersData from 'components/Users/NoUsersData';
import Select from 'components/shared/Select/Select';
import SortingDrawer from 'components/SortingDrawer/SortingDrawer';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import withAuth from 'components/Authorization/withAuth';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import './index.scss';

export enum UsersTabs {
  ACTIVE = 'active',
  DELETED = 'deleted',
  BANNED = 'banned',
}

function App() {
  const navigate = useNavigate();
  let { tabId } = useParams();
  const isCorrectPage = useMemo(() => tabId && Object.values(UsersTabs).includes(tabId as UsersTabs), [tabId]);
  const [tabValue, setTabValue] = useState<UsersTabs>(tabId ? (tabId as UsersTabs) : UsersTabs.ACTIVE);
  useDocumentTitle(`YZZY users - ${startCase(tabValue)}`);

  const [changeStatusModal, setChangeStatusModal] = useState(false);
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [columnsDrawer, setColumnsDrawer] = useState<boolean>(false);
  const [sortingDrawer, setSortingDrawer] = useState<boolean>(false);
  const [changeStatusItem, setChangeStatusItem] = useState<any>(null);
  const [term, setTerm] = useState(0);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const changePage = (page: number) => {
    setPage(page);
    void fetchUsersGrid(page, pageSize);
  };

  const changePageSize = async (pageSize: number) => {
    await fetchUsersGrid(0, pageSize);
    setPage(0);
    setPageSize(pageSize);
  };

  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();

  const columns = useAppSelector((state) => state.columns.data);
  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const activeUsers = useAppSelector(selectActiveUsers);
  const bannedUsers = useAppSelector(selectBannedUsers);
  const deletedUsers = useAppSelector(selectDeletedUsers);
  const status = useAppSelector(selectUsersStatus);
  const refreshStatus = useAppSelector(selectUsersRefreshStatus);
  const activeUsersCount = useAppSelector(selectActiveUsersCount);
  const bannedUsersCount = useAppSelector(selectBannedUsersCount);
  const deletedUsersCount = useAppSelector(selectDeletedUsersCount);
  const updateCommentStatus = useAppSelector(selectUpdateCommentStatus);

  dayjs.extend(duration);
  dayjs.extend(relativeTime);

  const [currentUser, setCurrentUser] = useState<any>(null);
  const [comment, setComment] = useState<string>('');
  const [editCommentModal, setEditCommentModal] = useState<boolean>(false);
  const [cancelEditCommentModal, setCancelEditCommentModal] = useState<boolean>(false);
  const [deleteCommentModal, setDeleteCommentModal] = useState<boolean>(false);
  const [dirty, setDirty] = useState<boolean>(false);
  const [commentSaveError, setCommentSaveError] = useState<boolean>(false);
  const [canCommentBeDeleted, setCanCommentBeDeleted] = useState<boolean>(false);
  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const openCommentModal = useCallback((data: any) => {
    setCurrentUser(data.userInfo.user_id);
    setComment(data.comment);
    setCanCommentBeDeleted(data.comment !== '');
    setDirty(false);
    setCommentSaveError(false);
    setEditCommentModal(true);
  }, []);

  const setCommentValue = useCallback((event: any) => {
    setDirty(true);
    setComment(event.target.value);
  }, []);

  const closeEditCommentModal = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setEditCommentModal(false);
  }, []);

  const closeDeleteCommentModal = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setDeleteCommentModal(false);
  }, []);

  const saveEditComment = useCallback(async () => {
    setCommentSaveError(false);
    const result = await dispatch(fetchUpdateComment({ profileId: currentUser!, comment: comment }));
    if (result.meta.requestStatus === 'fulfilled') {
      setEditCommentModal(false);
      enqueueSnackbar(`Comment has been saved`, { variant: 'success' });
      fetchUsersGrid(page, pageSize);
    } else if (result.meta.requestStatus === 'rejected') {
      setCommentSaveError(true);
    }
  }, [comment, currentUser]);

  const copyComment = (e: MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();

    if (!value) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      });
      return;
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      });

      return navigator.clipboard.writeText(value);
    }
  };

  const deleteComment = useCallback(async () => {
    const result = await dispatch(fetchUpdateComment({ profileId: currentUser!, comment: '' }));
    if (result.meta.requestStatus === 'fulfilled') {
      setEditCommentModal(false);
      setComment('');
      setDeleteCommentModal(false);
      enqueueSnackbar(`Comment has been deleted`, { variant: 'success' });
      await fetchUsersGrid(page, pageSize);
    } else {
      enqueueSnackbar(`Deleting error`, { variant: 'error' });
    }
  }, [comment, currentUser]);

  const cancelEditingComment = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setCancelEditCommentModal(false);
    setEditCommentModal(false);
    setComment('');
    setCurrentUser(null);
  }, []);

  const customCellRender: any = {
    userInfo: (props: GridRenderCellParams<any>) => {
      return (
        <Link
          className="usersTable--usernameLink"
          underline="none"
          href={`/ui/users/user/${props.value?.user_id}`}
          onClick={(e: any) => e.stopPropagation()}
        >
          <AvatarItem
            src={props.value?.avatar_url}
            primaryText={props.value?.user_name}
            subText={props.value?.user_id}
            levelAura={props.value?.level_aura}
          />
        </Link>
      );
    },
    status: (props: GridRenderCellParams<any>) => {
      return (
        <Select
          variant="standard"
          options={statusOptions}
          onChange={(event: SelectChangeEvent<unknown>) => callChangeStatusModal([props.row], event.target.value)}
          value={props.value}
          sx={{
            color: statusOptions.find((item: any) => item.value === props.value)?.color,
            '&::before, &::after': { border: 'none!important' },
            '.MuiInput-input:focus': { background: 'none' },
          }}
        />
      );
    },
    email: (props: GridRenderCellParams<any>) => {
      return (
        <div className="deletedUsers--emailContainer">
          <Tooltip followCursor placement="top-end" title={props.value}>
            <div className="deletedUsers--email">{props.value}</div>
          </Tooltip>
        </div>
      );
    },
    level_aura: (props: GridRenderCellParams<string>) => <Chip label={props.value} sx={getAuraColor(props.value)} />,
    passions: (props: GridRenderCellParams<string[]>) =>
      props.value ? (
        <div className="user-passions">
          {props.value.map((item: string) => (
            <Chip key={uniqueId()} label={item} sx={{ backgroundColor: colors.white }} />
          ))}
        </div>
      ) : (
        <></>
      ),
    birth_date: (props: GridRenderCellParams<Date>) => (
      <div>
        {dayjs(props.value).format('MM/DD/YYYY')}
        <span className="age"> ({dayjs().diff(dayjs(props.value), 'year')} y.o.)</span>
      </div>
    ),
    registrationDate: (props: GridRenderCellParams<Date>) => <div>{props.value?.toLocaleDateString('en-US')}</div>,
    latestActivityDate: (props: GridRenderCellParams<Date>) => <div>{props.value?.toLocaleDateString('en-US')}</div>,
    deletedBy: (props: GridRenderCellParams<any>) => {
      if (props.row?.deactivatedByInfo?.memberId) {
        return (
          <AvatarItem
            src={props.value?.memberAvatarUrl}
            primaryText={props.value?.memberFirstName + ' ' + props.value?.memberLastName}
            subText={props.value?.memberRole}
          />
        );
      } else {
        return (
          <Stack alignItems="center" direction="row" spacing={1}>
            <Avatar sx={{ bgcolor: alpha(colors.primary, 0.08), width: 32, height: 32 }}>
              <SystemIcon sx={{ color: colors.textSecondary }} />
            </Avatar>
            <Typography variant="body2" color="text.secondary">
              Automatically
            </Typography>
          </Stack>
        );
      }
    },
    initiatedAt: (props: GridRenderCellParams<Date>) => {
      const date = dayjs(props.value);
      return (
        <div className="usersGrid--initiatedContainer">
          <div>{date.format('MM/DD/YYYY')}</div>
          <div className="usersGrid--initiatedTimeContainer">{date.format('hh:mm A')}</div>
        </div>
      );
    },
    deadlineAt: (props: GridRenderCellParams<Date>) => {
      const now = dayjs();
      const deadline = dayjs(props.value);

      if (now.isAfter(deadline)) {
        return <div>Over</div>;
      } else {
        const duration = dayjs.duration(deadline.diff(now));
        const days = duration.days();
        const hours = duration.hours();
        return (
          <Tooltip title={deadline.format('MM/DD/YYYY, hh:mm A')} followCursor placement="top-end">
            <Typography variant="body2" color="text.primary">
              {(days !== 0 ? days + (days > 1 ? ' days ' : ' day ') : '') +
                (hours !== 0 ? hours + (hours > 1 ? ' hours' : ' hour') : '') +
                (days === 0 && hours === 0 ? 'less than 1 hour' : '')}
            </Typography>
          </Tooltip>
        );
      }
    },
    commentBanned: (props: GridRenderCellParams<any>) => {
      if (props.value === '' || props.value === undefined) {
        return <>—</>;
      }

      return (
        <div className="deletedUsers--commentContainer">
          <Tooltip followCursor placement="top-end" title={props.value}>
            <Typography className="deletedUsers--comment" variant="caption" color="text.secondary">
              {props.value}
            </Typography>
          </Tooltip>
          {props.row?.deactivatedByInfo?.memberId ? (
            <Tooltip
              className="deletedUsers--commentIcon"
              title="Edit comment"
              followCursor
              placement="top-end"
              onClick={() => openCommentModal(props.row)}
            >
              <IconButton sx={{ p: '2px' }} size="small">
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip placement="top-end" followCursor title="Copy">
              <IconButton sx={{ p: '2px' }} size="small" onClick={(e) => copyComment(e, props.value ?? '')}>
                <CopyIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
      );
    },
    commentDeleted: (props: GridRenderCellParams<any>) => {
      let commentElement;
      const emptyComment = props.value === '' || props.value === undefined || props.value === null;

      if (emptyComment) {
        commentElement = (
          <Typography variant="caption" color={colors.outlinedBorder}>
            No comment
          </Typography>
        );
      } else {
        commentElement = (
          <Tooltip title={props.value} followCursor placement="top-end">
            <Typography className="deletedUsers--comment" variant="caption" color="text.secondary">
              {props.value}
            </Typography>
          </Tooltip>
        );
      }
      return (
        <div className="deletedUsers--commentContainer">
          {commentElement}
          <Tooltip
            className="deletedUsers--commentIcon"
            title={emptyComment ? 'Add comment' : 'Edit comment'}
            followCursor
            placement="top-end"
            onClick={() => openCommentModal(props.row)}
          >
            <IconButton sx={{ p: '2px' }} size="small">
              <EditIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      );
    },
  };

  const cols: GridColDef[] = useMemo(() => {
    const res = columns.map((item, index) => {
      const obj: any = {
        field: item.columnName,
        headerName: item.title,
        minWidth: 200,
        flex: 1,
        sortable: false,
      };
      if (item.columnName === 'username') {
        obj.field = 'userInfo';
        obj.minWidth = 350;
        obj.renderCell = customCellRender['userInfo'];
      }
      if (customCellRender[item.columnName]) {
        obj.renderCell = customCellRender[item.columnName];
      }
      return obj;
    });
    // const res = columns.map((item, index) => ({
    //   field: item.columnName,
    //   headerName: item.title,
    //   minWidth: 200,
    //   renderCell: customCellRender[item.columnName] ?? null
    // }));
    return res;
  }, [columns]);

  const bannedUserCols: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'userInfo',
        headerName: 'Username',
        minWidth: 306,
        maxWidth: 306,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['userInfo'],
      },
      {
        field: 'profileEmail',
        headerName: 'Email',
        minWidth: 216,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['email'],
        editable: false,
      },
      {
        field: 'initiatedAt',
        headerName: 'Banned',
        minWidth: 112,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['initiatedAt'],
      },
      {
        field: 'deactivatedByInfo',
        headerName: 'Banned by',
        minWidth: 168,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['deletedBy'],
      },
      {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 272,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['commentBanned'],
      },
    ];
  }, []);

  const deletedUserCols: GridColDef[] = useMemo(() => {
    return [
      {
        field: 'userInfo',
        headerName: 'Username',
        minWidth: 306,
        maxWidth: 306,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['userInfo'],
      },
      {
        field: 'profileEmail',
        headerName: 'Email',
        minWidth: 216,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['email'],
        editable: false,
      },
      {
        field: 'initiatedAt',
        headerName: 'Initiated',
        minWidth: 112,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['initiatedAt'],
      },
      {
        field: 'deactivatedByInfo',
        headerName: 'Deleted by',
        minWidth: 168,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['deletedBy'],
      },
      {
        field: 'deadlineAt',
        headerName: 'Deadline',
        minWidth: 112,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['deadlineAt'],
      },
      {
        field: 'comment',
        headerName: 'Comment',
        minWidth: 272,
        flex: 1,
        sortable: false,
        renderCell: customCellRender['commentDeleted'],
      },
    ];
  }, []);

  const usersCount: number | undefined = useMemo(() => {
    if (tabValue === UsersTabs.ACTIVE) {
      return activeUsersCount;
    }
    if (tabValue === UsersTabs.BANNED) {
      return bannedUsersCount;
    }
    if (tabValue === UsersTabs.DELETED) {
      return deletedUsersCount;
    }

    return 0;
  }, [activeUsersCount, bannedUsersCount, deletedUsersCount, tabValue]);

  const rows: any[] = useMemo(() => {
    if (tabValue === UsersTabs.ACTIVE) {
      const res = activeUsers.map((item, index) => ({
        ...item,
        id: index + 4,
      }));
      return res;
    }
    if (tabValue === UsersTabs.BANNED) {
      const res = bannedUsers.map((item, index) => ({
        ...item,
        id: index + 4,
      }));
      return res;
    }
    if (tabValue === UsersTabs.DELETED) {
      const res = deletedUsers.map((item, index) => ({
        ...item,
        id: index + 4,
      }));
      return res;
    }

    return [];
  }, [activeUsers, bannedUsers, deletedUsers, tabValue]);

  const handleTabChange = async (event: ChangeEvent<{}>, value: string) => {
    setTabValue(value as UsersTabs);
    setPage(0);
    setPageSize(25);
    dispatch(clearData());
    dispatch(clearFilters());
    if (value !== 'active') {
      dispatch(
        setSortingData([{ columnId: 'initiated_at', id: 'initiated_at', title: 'Initiated', sortingType: 'DESC' }]),
      );
    } else {
      dispatch(clearSortingData());
    }
    await fetchUsersGrid(0, 25, value);
  };

  useEffect(() => {
    navigate(`/ui/users/${tabValue}`, { replace: false });
  }, [tabValue]);

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/users/${UsersTabs.ACTIVE}`, { replace: false });
      return;
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'));
    }
  }, []);

  const fetchData = useCallback(async () => {
    Promise.all([
      await dispatch(fetchColumns('users')),
      dispatch(fetchFilters('users')),
      dispatch(fetchFilterPresets('users')),
      dispatch(fetchColumnsPresets('users')),
      dispatch(fetchSortingPresets('users')),
      dispatch(fetchSortingColumns('users')),
    ]).then((result) => {
      result.forEach((value: any) => {
        if (
          value.meta &&
          value.meta.requestStatus === 'rejected' &&
          ErrorBoundaryErrors.includes(value.error.message)
        ) {
          showBoundary(new Error(value.error?.message));
        }
      });
    });
  }, []);

  const fetchUsersInitial = useCallback(
    async (page: number, pageSize: number, type?: string) => {
      dispatch(fetchUsers({ page, pageSize, type: type || tabValue })).then((result: any) => {
        if (
          result.meta &&
          result.meta.requestStatus === 'rejected' &&
          ErrorBoundaryErrors.includes(result.error.message)
        ) {
          showBoundary(new Error(result.error?.message));
        }
      });
    },
    [tabValue],
  );

  const fetchUsersGrid = useCallback(
    async (page: number, pageSize: number, type?: string) => {
      dispatch(fetchUsers({ page, pageSize, type: type || tabValue })).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    },
    [tabValue],
  );

  const initialFetchData = useCallback(async () => {
    dispatch(setUsersStatus('loading'));
    await fetchData();
    if (tabValue !== 'active') {
      dispatch(
        setSortingData([{ columnId: 'initiated_at', id: 'initiated_at', title: 'Initiated', sortingType: 'DESC' }]),
      );
    } else {
      dispatch(clearSortingData());
    }
    await fetchUsersInitial(page, pageSize);
  }, [page, pageSize]);

  const refreshData = useCallback(async () => {
    try {
      dispatch(setUsersRefreshStatus('loading'));
      const result = await dispatch(fetchUsers({ page, pageSize, type: tabValue }));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Data has been refreshed', { variant: 'success' as VariantType });
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
      }
    } catch (e) {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
    }
  }, [dispatch, page, pageSize]);

  const repeatRequestUsers = useCallback(async () => {
    fetchUsersGrid(page, pageSize);
  }, [dispatch, page, pageSize]);

  useEffect(() => {
    dispatch(clearFilters());
    initialFetchData();
  }, [dispatch]);

  const changeTerm = useCallback((value: any) => {
    setTerm(value);
  }, []);

  const callChangeStatusModal = useCallback((data: any, value: any) => {
    setChangeStatusModal(true);
    setChangeStatusItem({ data: data, value: value });
  }, []);

  const handleChangeStatusModalClose = useCallback(() => {
    setChangeStatusModal(false);
    setChangeStatusItem(null);
  }, []);

  const handleChangeStatus = useCallback(async (data: any) => {
    await dispatch(fetchChangeStatus(data));
    handleChangeStatusModalClose();
  }, []);

  const handleMultipleChangeStatus = useCallback(
    (value: number) => {
      handleClose();
      const data = rows.filter((item: any) => selectionModel.includes(item.id));
      callChangeStatusModal(data, value);
    },
    [selectionModel],
  );

  const statusOptions = [
    { value: 0, text: 'Active', color: '#4CA478' },
    { value: 1, text: 'Restricted', color: '#B42929' },
    { value: 2, text: 'Banned', color: '#70778F' },
  ];

  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const clearFiltersFunc = useCallback(() => {
    dispatch(clearFilters());
    void fetchUsersGrid(page, pageSize);
  }, []);

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((value) => value !== null).length;
  }, [filtersData]);

  return (
    <div className="users-container">
      <div className="users-header">
        YZZY users <span className="users-count">{usersCount === 0 ? '' : `(${usersCount})`}</span>
      </div>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} className="users-tabs">
          <Tab label="Actual users" value="active" />
          <Tab label="Banned users" value="banned" />
          <Tab label="Deleted users" value="deleted" />
        </TabList>
        <TabPanel value="active">
          <div className="users-table-container">
            <div className="users-table-buttons">
              <Button
                size="small"
                startIcon={<FilterIcon />}
                className="users-table-button"
                variant="outlined"
                onClick={() => setFilterDrawer(true)}
              >
                Filter
              </Button>
              <Button
                disabled
                size="small"
                startIcon={<SortIcon />}
                className="users-table-button"
                variant="outlined"
                onClick={() => setSortingDrawer(true)}
              >
                Sort
              </Button>
              <Button
                size="small"
                startIcon={<ColumnsIcon />}
                className="users-table-button"
                variant="outlined"
                onClick={() => setColumnsDrawer(true)}
              >
                Columns
              </Button>
              <LoadingButton
                size="small"
                disabled={refreshStatus === 'loading'}
                startIcon={<RefreshIcon />}
                loading={refreshStatus === 'loading'}
                loadingPosition="start"
                className="users-table-button"
                onClick={() => refreshData()}
              >
                Refresh data
              </LoadingButton>
              {selectionModel.length > 0 ? (
                <Button startIcon={<ChangeIcon />} className="users-table-button" onClick={handleClick}>
                  Change status
                </Button>
              ) : (
                <></>
              )}
              <Menu
                id="demo-positioned-menu"
                aria-labelledby="demo-positioned-button"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleMultipleChangeStatus(0)}>Active</MenuItem>
                <MenuItem onClick={() => handleMultipleChangeStatus(1)}>Restricted</MenuItem>
                <MenuItem onClick={() => handleMultipleChangeStatus(2)}>Banned</MenuItem>
              </Menu>
            </div>
            {filtersLength !== 0 && <ActiveFilterList refreshData={() => fetchUsersGrid(page, pageSize)} />}
            <Grid
              columns={cols}
              rows={rows}
              // rowHeight={72}
              getRowHeight={() => 'auto'}
              // checkboxSelection
              loading={status === 'loading'}
              // onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              rowCount={usersCount}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableSelectionOnClick
              onPageChange={(newPage) => changePage(newPage)}
              onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
              sx={{
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  padding: '0',
                  margin: '0',
                },
                '.MuiDataGrid-row': {
                  padding: '10px 0',
                },
              }}
              components={{
                Footer: CustomFooter,
                NoRowsOverlay: () => (
                  <NoUsersData
                    isFilters={!!filtersLength}
                    fetchingError={fetchingError}
                    refreshData={repeatRequestUsers}
                    clearFilters={clearFiltersFunc}
                  />
                ),
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value="banned">
          <div className="users-table-container bannedUsers--container">
            <Grid
              columns={bannedUserCols}
              rows={rows}
              rowHeight={72}
              // checkboxSelection
              loading={status === 'loading'}
              // onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              rowCount={usersCount}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableSelectionOnClick
              onPageChange={(newPage) => changePage(newPage)}
              onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
              sx={{
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  padding: '0',
                  margin: '0',
                },
              }}
              components={{
                Footer: CustomFooter,
                NoRowsOverlay: () => (
                  <NoUsersData
                    isFilters={!!filtersLength}
                    fetchingError={fetchingError}
                    refreshData={repeatRequestUsers}
                    clearFilters={clearFiltersFunc}
                  />
                ),
              }}
            />
          </div>
        </TabPanel>
        <TabPanel value="deleted">
          <div className="users-table-container deletedUsers--container">
            <Grid
              columns={deletedUserCols}
              rows={rows}
              rowHeight={72}
              // checkboxSelection
              loading={status === 'loading'}
              // onSelectionModelChange={(newSelectionModel: GridSelectionModel) => {
              //   setSelectionModel(newSelectionModel);
              // }}
              // selectionModel={selectionModel}
              rowCount={usersCount}
              pagination
              paginationMode="server"
              page={page}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50, 100]}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableSelectionOnClick
              onPageChange={(newPage) => changePage(newPage)}
              onPageSizeChange={(newPageSize) => changePageSize(newPageSize)}
              sx={{
                '.MuiDataGrid-cell': {
                  borderBottom: 'none',
                  padding: '0',
                  margin: '0',
                },
              }}
              components={{
                Footer: CustomFooter,
                NoRowsOverlay: () => (
                  <NoUsersData
                    isFilters={!!filtersLength}
                    fetchingError={fetchingError}
                    refreshData={repeatRequestUsers}
                    clearFilters={clearFiltersFunc}
                  />
                ),
              }}
            />
          </div>
        </TabPanel>

        <Modal open={changeStatusModal} onClose={handleChangeStatusModalClose}>
          <>
            <div className="changeStatusModal-header">
              You are going to change {changeStatusItem?.data.length > 1 ? changeStatusItem?.data.length : ``} user's
              status to
              <div>{statusOptions.find((item) => item.value === changeStatusItem?.value)?.text}:</div>
            </div>
            <div style={{ flex: 1, maxHeight: 175, overflowY: 'auto', marginBottom: 40 }}>
              {changeStatusItem?.data.map((item: any) => (
                <AvatarItem
                  src={item.baseInfo?.avatarUrl}
                  primaryText={item.baseInfo?.userId}
                  subText={item.baseInfo?.nickname}
                  levelAura={'DEFAULT'}
                />
              ))}
            </div>
            {changeStatusItem?.value === 2 ? (
              <div>
                <div className="changeStatusModal-header">Details</div>
                <div>
                  <Select
                    sx={{ width: 250, marginBottom: '16px' }}
                    options={[
                      { value: 0, text: 'Violence' },
                      { value: 1, text: 'Nudity' },
                      { value: 2, text: 'Inappropriate content' },
                    ]}
                    placeholder="Violation category"
                  />
                </div>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    sx={{ width: 150, marginRight: '8px' }}
                    value={term}
                    onChange={(event: SelectChangeEvent<unknown>) => changeTerm(event.target.value)}
                    options={[
                      { value: 0, text: 'period' },
                      { value: 1, text: 'permanently' },
                    ]}
                    placeholder="Term"
                  />
                  {term === 0 ? 'of' : <></>}
                  {term === 0 ? (
                    <Input sx={{ width: 60, marginLeft: '8px' }} placeholder="Days" type="number" />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            ) : (
              <></>
            )}
            <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
              <Button style={{ marginRight: '16px' }} onClick={() => handleChangeStatusModalClose()}>
                Cancel
              </Button>
              <Button variant="contained" onClick={() => handleChangeStatus(changeStatusItem)}>
                Confirm
              </Button>
            </div>
          </>
        </Modal>

        {editCommentModal && (
          <Modal open={editCommentModal} onClose={closeEditCommentModal} customstyle={{ width: 444 }}>
            <div className="defaultModal--content editCommentModal--container">
              <div className="defaultModal--header">Comment to {tabValue} user</div>
              <div className="defaultModal--text">
                <TextField
                  multiline
                  minRows={8}
                  maxRows={8}
                  inputProps={{ maxLength: 640 }}
                  className="editCommentModal--commentField"
                  label={'Comment' + (tabValue === UsersTabs.DELETED ? ' (optional)' : '')}
                  placeholder="No comment"
                  value={comment}
                  onChange={setCommentValue}
                />
              </div>
              {commentSaveError ? (
                <Alert sx={{ marginTop: '24px' }} severity="error">
                  Saving error. Please, Try later
                </Alert>
              ) : (
                <></>
              )}
              <div className="editCommentModal--actions">
                <div>
                  {tabValue === UsersTabs.DELETED && (
                    <Button
                      className="editCommentModal--deleteCommentButton"
                      disabled={!canCommentBeDeleted}
                      onClick={() => setDeleteCommentModal(true)}
                    >
                      Delete comment
                    </Button>
                  )}
                </div>
                <div>
                  <Button style={{ marginRight: '16px' }} onClick={() => setCancelEditCommentModal(true)}>
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={updateCommentStatus === 'loading'}
                    disabled={comment === '' || !dirty}
                    type="submit"
                    variant="contained"
                    onClick={() => saveEditComment()}
                  >
                    Save
                  </LoadingButton>
                </div>
              </div>
            </div>
          </Modal>
        )}
        {cancelEditCommentModal && (
          <Modal open={cancelEditCommentModal} onClose={cancelEditingComment} customstyle={{ minHeight: 160 }}>
            <div className="defaultModal--content">
              <div className="defaultModal--header">Cancel editing comment?</div>
              <div className="defaultModal--text">Changes won't be applied</div>
              <div className="defaultModal--actions">
                <Button style={{ marginRight: '16px' }} onClick={() => setCancelEditCommentModal(false)}>
                  Don't cancel
                </Button>
                <Button type="submit" variant="contained" onClick={cancelEditingComment}>
                  Cancel editing
                </Button>
              </div>
            </div>
          </Modal>
        )}
        {deleteCommentModal && (
          <Modal
            open={deleteCommentModal}
            onClose={closeDeleteCommentModal}
            customstyle={{ minHeight: 160, maxWidth: 444 }}
          >
            <div className="defaultModal--content">
              <div className="defaultModal--header">Delete comment?</div>
              <div className="defaultModal--text">You are going to delete comment to user {currentUser}</div>
              <div className="defaultModal--actions">
                <Button style={{ marginRight: '16px' }} onClick={closeDeleteCommentModal}>
                  Cancel
                </Button>
                <LoadingButton
                  loading={updateCommentStatus === 'loading'}
                  type="submit"
                  variant="contained"
                  className="defaultModal--errorButton"
                  onClick={() => deleteComment()}
                >
                  Delete
                </LoadingButton>
              </div>
            </div>
          </Modal>
        )}
        <CloseFilterDrawerConfirmModal
          open={filterDrawerCloseConfirmModal}
          onClose={() => onFilterSidebarClose(true)}
          closeModal={() => cancelCloseFilterSidebar()}
        />
      </TabContext>
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="users"
          onClose={() => onFilterSidebarClose()}
          onApply={() => {
            onFilterSidebarClose(true);
            fetchUsersGrid(page, pageSize);
          }}
        />
      </Drawer>
      <Drawer anchor="right" open={columnsDrawer} onClose={() => setColumnsDrawer(false)}>
        <ColumnsDrawer
          type="users"
          close={() => setColumnsDrawer(false)}
          apply={() => {
            setColumnsDrawer(false);
            fetchUsersGrid(page, pageSize);
          }}
        />
      </Drawer>
      <Drawer anchor="right" open={sortingDrawer} onClose={() => setSortingDrawer(false)}>
        <SortingDrawer
          type="users"
          close={() => setSortingDrawer(false)}
          apply={() => {
            setSortingDrawer(false);
            fetchUsersGrid(page, pageSize);
          }}
        />
      </Drawer>
    </div>
  );
}

export default withAuth(App);
