import { HTMLAttributes, Key, ReactNode } from 'react';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import Typography from 'components/shared/Typography/Typography';
import Divider from 'components/shared/Divider/Divider';

/**
 * Render linked ID options for a list item.
 *
 * @param {HTMLAttributes<HTMLLIElement>} props - HTML attributes for the list item.
 * @param {{
 *   value: string;
 *   providerName: string;
 *   productId: string;
 *   name: string;
 *   price: string;
 * }} option - The option data to be displayed.
 * @param {string} inputValue - The current input value for highlighting.
 * @returns {ReactNode} React element representing the linked ID option.
 */
export const renderLinkIdOptions = (
  props: HTMLAttributes<HTMLLIElement>,
  option: {
    value: string;
    providerName: string;
    productId: string;
    name: string;
    price: string;
  },
  inputValue: string,
): ReactNode => {
  const matches = match(option.value, inputValue, { insideWords: true });
  const parts = parse(option.value, matches);

  return (
    <>
      <li {...props}>
        <div className="LinkIdOption-content">
          <Typography variant="body2" className="LinkIdOption-content__title">
            {parts.map((part, index: Key) => (
              <span style={{ fontWeight: part.highlight ? 700 : 400 }} key={index}>
                {part.text}
              </span>
            ))}
          </Typography>

          <div className="LinkIdOption-content__info">
            <div className="LinkIdOption-content__info-block">
              <div className="LinkIdOption-content__info-item">
                <Typography variant="inputLabel" color="text.secondary">
                  Provider:
                </Typography>
                <Typography variant="inputLabel">{option.providerName}</Typography>
              </div>
              <div className="LinkIdOption-content__info-item">
                <Typography variant="inputLabel" color="text.secondary">
                  Reference name:
                </Typography>
                <Typography variant="inputLabel">{option.name}</Typography>
              </div>
            </div>
            <div className="LinkIdOption-content__info-block">
              <div className="LinkIdOption-content__info-item">
                <Typography variant="inputLabel" color="text.secondary">
                  Product ID:
                </Typography>
                <Typography variant="inputLabel">{option.productId}</Typography>
              </div>
              <div className="LinkIdOption-content__info-item">
                <Typography variant="inputLabel" color="text.secondary">
                  Price:
                </Typography>
                <Typography variant="inputLabel">{option.price}</Typography>
              </div>
            </div>
          </div>
        </div>
      </li>
      <Divider orientation="horizontal" flexItem />
    </>
  );
};
