import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';
import CardType from 'components/Moderation/types/CardType';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';

export interface ModerationState {
  columns: any[];
  data: any[];
  hiveDataLength: number;
  contentComplaintsDataLength: number;
  profileComplaintsDataLength: number;
  confirmedViolationsDataLength: number;
  declinedRequestsDataLength: number;
  hiveMyDataLength: number;
  contentComplaintsMyDataLength: number;
  profileComplaintsMyDataLength: number;
  status: IStatus;
  assignStatus: IStatus;
  entityCardInfoStatus: IStatus;
  currentTab: ModerationTabs;
  currentGroup: 'All' | 'My';
}

const initialState: ModerationState = {
  columns: [
    {
      columnId: 'moderationCards',
      columnName: 'ModerationCard',
    },
  ],
  data: [],
  hiveDataLength: 0,
  contentComplaintsDataLength: 0,
  profileComplaintsDataLength: 0,
  confirmedViolationsDataLength: 0,
  declinedRequestsDataLength: 0,
  status: 'idle',
  assignStatus: 'idle',
  entityCardInfoStatus: 'idle',

  hiveMyDataLength: 0,
  contentComplaintsMyDataLength: 0,
  profileComplaintsMyDataLength: 0,
  currentTab: ModerationTabs.HIVE,
  currentGroup: 'All',
};

export interface Metrics {
  metricName: string;
  score: number;
}

export const fetchModerationData = createAsyncThunk('moderation/fetchCards', async (params: any, thunkAPI) => {
  if (params.type === ModerationTabs.RULES) {
    return { type: params.type };
  }

  const rootState: RootState = thunkAPI.getState() as RootState;
  const filters = Object.keys(rootState.filters.data)
    ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
    : [];
  const sortingList = Object.keys(rootState.sorting.data)
    ? Object.values(rootState.sorting.data).filter(
        (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
      )
    : [];

  let dataUrl;
  let historyType;
  let subjectType;

  switch (params.type) {
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive';
      break;
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints';
      break;
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints';
      break;
    }
    case ModerationTabs.CONFIRMED_VIOLATIONS: {
      dataUrl = 'moderation/screen/history';
      historyType = 'CONFIRMED';
      subjectType = params.subjectTypeConfirmed;
      break;
    }
    case ModerationTabs.DECLINED_REQUESTS: {
      dataUrl = 'moderation/screen/history';
      historyType = 'DECLINED';
      subjectType = params.subjectTypeDeclined;
      break;
    }
  }

  const response = await axios.post((process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT as string) + dataUrl, {
    historyType: historyType,
    subjectType: subjectType,
    pageInfo: {
      page: 1,
      size: params.pageSize,
    },
    sortingList: sortingList,
    filters: filters,
  });
  return { ...response.data, type: params.type };

  // const response = {
  //   moderationCards: mockHive,
  //   page: 1,
  //   allResultsCount: mockHive.length,
  // }
  // return {...response, type: ModerationTabs.HIVE};
});

export const fetchMyData = createAsyncThunk('moderaion/fetchMyCards', async (params: any) => {
  let dataUrl;

  switch (params.type) {
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive/my-tickets';
      break;
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints/my-tickets';
      break;
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints/my-tickets';
      break;
    }
  }

  const response = await axios.get((process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT as string) + dataUrl);
  return { ...response.data, type: params.type };
});

export const fetchAssignTickets = createAsyncThunk('moderaion/fetchAssignCards', async (params: any) => {
  let dataUrl;

  switch (params.type) {
    case ModerationTabs.HIVE: {
      dataUrl = 'moderation/screen/hive/assign-tickets';
      break;
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      dataUrl = 'moderation/screen/content-complaints/assign-tickets';
      break;
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      dataUrl = 'moderation/screen/profile-complaints/assign-tickets';
      break;
    }
  }

  const response = await axios.get((process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT as string) + dataUrl);
  return { ...response.data, type: params.type };
});

export const fetchDataLength = createAsyncThunk('moderation/fetchDataLength', async () => {
  const response = await axios.get(
    (process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT as string) + 'moderation/screen/counts',
  );
  return response.data;
});

export const fetchChangeStatus = createAsyncThunk('moderation/changeStatus', async (data: any) => {
  let url: string = '';
  let additionalData;
  switch (data.cardType) {
    case CardType.HIVE: {
      url = `${process.env.REACT_APP_MODERATION_CHANGE_STATUS_CONTENT_ENDPOINT as string}moderation/hive-violations/${
        data.entityId
      }/${data.status ? 'confirm' : 'decline'}`;
      additionalData = data.manualMode
        ? {
            historical: data.historical,
            confirmedManualModeViolations: data.confirmedViolations,
          }
        : {
            historical: data.historical,
          };
      break;
    }
    case CardType.CONTENT: {
      url = `${process.env.REACT_APP_MODERATION_CHANGE_STATUS_CONTENT_ENDPOINT as string}moderation/manual-violations/${
        data.entityId
      }/${data.status ? 'confirm' : 'decline'}`;
      additionalData = {
        historical: data.historical,
        confirmedManualViolations: data.confirmedViolations,
      };
      break;
    }
    case CardType.PROFILE: {
      url = `${
        process.env.REACT_APP_MODERATION_CHANGE_STATUS_PROFILE_ENDPOINT as string
      }moderation/profile-violations/${data.entityId}/${data.status ? 'confirm' : 'decline'}`;
      additionalData = {
        historical: data.historical,
        confirmedProfileViolations: data.confirmedViolations,
      };
      break;
    }
  }

  await axios.post(url, {
    moderatorId: data.moderatorId,
    ...additionalData,
  });
  return { entityId: data.entityId, cardType: data.cardType, status: data.status };
});

export const getEntityCardInfo = createAsyncThunk('moderation/getEntityCardInfo', async (entityInfo: any) => {
  const response = await axios.get(
    (process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT as string) + `moderation/screen/${entityInfo.entityId}`,
  );
  return response.data;
});

export const moderationSlice = createSlice({
  name: 'moderation',
  initialState,
  reducers: {
    clearContentModerationData: (state) => {
      state.data = [];
      state.status = 'loading';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchModerationData.pending, (state, action: any) => {
        state.currentTab = action.meta.arg.type as ModerationTabs;
        state.currentGroup = 'All';
        state.status = 'loading';
      })
      .addCase(fetchModerationData.fulfilled, (state, action) => {
        state.status = 'idle';

        if (action.payload.type === ModerationTabs.RULES) {
          return state;
        }
        if (action.meta.arg.type === state.currentTab && state.currentGroup === 'All') {
          state.status = 'idle';
          state.data = action.payload.moderationCards;
        }
        switch (action.payload.type) {
          case ModerationTabs.HIVE: {
            state.hiveDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.CONFIRMED_VIOLATIONS: {
            state.confirmedViolationsDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.DECLINED_REQUESTS: {
            state.declinedRequestsDataLength = action.payload.allResultsCount;
            break;
          }
        }
      })
      .addCase(fetchModerationData.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchMyData.pending, (state) => {
        state.data = [];
        state.currentGroup = 'My';
        state.status = 'loading';
      })
      .addCase(fetchMyData.fulfilled, (state, action) => {
        if (action.meta.arg.type === state.currentTab && state.currentGroup === 'My') {
          state.status = 'idle';
          state.data = action.payload.moderationCards;
        }
        switch (action.payload.type) {
          case ModerationTabs.HIVE: {
            state.hiveMyDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsMyDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsMyDataLength = action.payload.allResultsCount;
            break;
          }
        }
      })
      .addCase(fetchMyData.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAssignTickets.pending, (state) => {
        state.data = [];
        state.assignStatus = 'loading';
      })
      .addCase(fetchAssignTickets.fulfilled, (state, action) => {
        state.assignStatus = 'idle';
        state.data = action.payload.moderationCards;
        switch (action.payload.type) {
          case ModerationTabs.HIVE: {
            state.hiveMyDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.CONTENT_COMPLAINTS: {
            state.contentComplaintsMyDataLength = action.payload.allResultsCount;
            break;
          }
          case ModerationTabs.PROFILE_COMPLAINTS: {
            state.profileComplaintsMyDataLength = action.payload.allResultsCount;
            break;
          }
        }
      })
      .addCase(fetchAssignTickets.rejected, (state) => {
        state.assignStatus = 'failed';
      })
      .addCase(fetchDataLength.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchDataLength.fulfilled, (state, action) => {
        state.status = 'idle';
        state.hiveDataLength = action.payload.hive;
        state.contentComplaintsDataLength = action.payload.content;
        state.profileComplaintsDataLength = action.payload.profile;
      })
      .addCase(fetchDataLength.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchChangeStatus.pending, () => {})
      .addCase(fetchChangeStatus.fulfilled, (state, action) => {
        state.data = state.data.filter(
          (card) =>
            card.profileInfo?.profileId !== action.payload.entityId &&
            card.entityInfo?.entityId !== action.payload.entityId,
        );
        switch (action.payload.cardType) {
          case CardType.HIVE: {
            state.hiveMyDataLength = state.hiveMyDataLength - 1;
            break;
          }
          case CardType.CONTENT: {
            state.contentComplaintsMyDataLength = state.contentComplaintsMyDataLength - 1;
            break;
          }
          case CardType.PROFILE: {
            state.profileComplaintsMyDataLength = state.profileComplaintsMyDataLength - 1;
            break;
          }
        }
      })
      .addCase(fetchChangeStatus.rejected, () => {})
      .addCase(getEntityCardInfo.pending, (state) => {
        state.entityCardInfoStatus = 'loading';
      })
      .addCase(getEntityCardInfo.fulfilled, (state) => {
        state.entityCardInfoStatus = 'idle';
      })
      .addCase(getEntityCardInfo.rejected, (state) => {
        state.entityCardInfoStatus = 'idle';
      });
  },
});

export default moderationSlice.reducer;

export const { clearContentModerationData } = moderationSlice.actions;
export const selectStatus = (state: RootState): IStatus => state.moderation.status;
export const selectModerationData = (state: RootState): any[] => state.moderation.data;
export const selectHiveLength = (state: RootState): number => state.moderation.hiveDataLength;
export const selectContentComplaintsLength = (state: RootState): number => state.moderation.contentComplaintsDataLength;
export const selectProfileComplaintsLength = (state: RootState): number => state.moderation.profileComplaintsDataLength;
export const selectConfirmedViolationsLength = (state: RootState): number =>
  state.moderation.confirmedViolationsDataLength;
export const selectDeclinedRequestsLength = (state: RootState): number => state.moderation.declinedRequestsDataLength;
export const selectColumn = (state: RootState): any[] => state.moderation.columns;
