import * as yup from 'yup';
import { TIER_MIN_LIMIT } from 'tim-constants';

export const getValidationSchema = () => {
  return yup.object().shape({
    linkId: yup.string().when('radioValue', {
      is: (val: string) => val === 'list',
      then: (schema) => schema.required('Required field'),
    }),
    providerId: yup.string().required('Required field'),
    productId: yup.string().required('Required field'),
    name: yup.string().required('Required field').trim().max(150, 'Max limit of 150 characters is exceeded'),
    price: yup
      .string()
      .required('Required field')
      .test('validateMinLimit', `Price must be at least ${TIER_MIN_LIMIT}`, (value) =>
        value ? +value >= TIER_MIN_LIMIT : false,
      ),
    country: yup.string().required('Required field'),
    currency: yup.string().required('Required field'),
    operationType: yup.string().required('Required field'),
  });
};
