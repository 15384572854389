import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker as DatePickerBase } from '@mui/x-date-pickers/DatePicker';
import { DatePickerProps } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';


const DatePicker = (props: DatePickerProps<any, any>) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePickerBase  {...props} />
    </LocalizationProvider>
  );
};

export default DatePicker;
