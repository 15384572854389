import { ChangeEvent, forwardRef, Key, ReactNode, useEffect, useState } from 'react';
import { Fade, Paper, Popper, useAutocomplete } from '@mui/material';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import { useDebounce } from '@uidotdev/usehooks';
import { IOperationSearchItem } from 'store/slices/Payments/interface';
import {
  clearOperationsSearch,
  fetchOperationsSearch,
  selectOperationsSearchResults,
  selectOperationsSearchAllResults,
} from 'store/slices/Payments/operationsSlice';
import { clearFilters, removeFilter, selectFiltersCurrentData, setCustomFilterValue } from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Divider from 'components/shared/Divider/Divider';
import List from 'components/shared/List/List';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemButton from 'components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import SearchField from 'components/shared/SearchField/SearchField';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IOperationsSearchProps {
  refreshData?: () => Promise<void>;
  clearFilter?: () => Promise<void>;
}

export const OperationsSearch = ({ refreshData, clearFilter }: IOperationsSearchProps) => {
  const dispatch = useAppDispatch();

  const SEARCH_FILTER = {
    type: 'IN',
    valueType: 'STRING',
    columnId: 'id',
    title: 'ID',
    elements: [],
  };

  const SEARCH_FILTER_ALL = {
    title: 'Operation search',
    type: 'EQUALS',
    customImplementation: true,
    valueType: 'STRING',
    columnId: 'operation_search',
    element: {
      value: '',
      titles: ['Operation ID'],
    },
  };

  const operationsSearchResults = useAppSelector(selectOperationsSearchResults);
  const operationsSearchAllResults = useAppSelector(selectOperationsSearchAllResults);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue.trim(), 1000);
  const [showLoader, setShowLoader] = useState(false);
  let loaderTimeout: NodeJS.Timeout;

  const { inputValue, getRootProps, getInputProps, getListboxProps, getOptionProps } = useAutocomplete({
    id: 'search-autocomplete',
    options: operationsSearchResults ? operationsSearchResults : [],
    inputValue: searchValue,
    getOptionLabel: (option: IOperationSearchItem) => option.operationId,
    disableCloseOnSelect: true,
  });

  const hasFiltersToClear =
    clearFilter && refreshData && (!isEmpty(filtersCurrentData.id) || !isEmpty(filtersCurrentData.operation_search));

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    if (inputValue.length === 0 && operationsSearchResults.length !== 0) {
      dispatch(clearOperationsSearch());
    }

    if (inputValue.length === 0 && hasFiltersToClear) {
      dispatch(clearOperationsSearch());
      dispatch(removeFilter({ columnId: 'operation_search' }));
      void clearFilter();
      void refreshData();
    }
  };

  const handleClearEvent = () => {
    dispatch(clearOperationsSearch());
    setSearchValue('');

    if (hasFiltersToClear) {
      dispatch(removeFilter({ columnId: 'operation_search' }));
      void clearFilter();
      void refreshData();
    }
  };

  const handleSearchEvent = async () => {
    setShowLoader(false);
    setIsLoadingSearch(true);

    try {
      const result = await dispatch(fetchOperationsSearch({ searchValue: debouncedSearchValue }));

      if (result.meta.requestStatus === 'fulfilled') {
        setIsSearchError(false);
        setIsLoadingSearch(false);
        clearTimeout(loaderTimeout);
        setShowLoader(false);
      } else if (result.meta.requestStatus === 'rejected') {
        setIsSearchError(true);
        setIsLoadingSearch(false);
        clearTimeout(loaderTimeout);
        setShowLoader(false);
      }
    } catch (error) {
      setIsSearchError(true);
      setIsLoadingSearch(false);
      clearTimeout(loaderTimeout);
      setShowLoader(false);
    }
  };

  const handleListItemButtonClick = (id: string, uid: string) => {
    const isUidMatch = uid.toLowerCase().includes(inputValue.toLowerCase());
    if (isUidMatch) {
      setSearchValue(uid);
    } else {
      setSearchValue(id);
    }
    setAnchorEl(null);

    (SEARCH_FILTER.elements as { value: string }[]) = [{ value: id }];
    dispatch(removeFilter({ columnId: 'operation_search' }));
    dispatch(clearFilters());
    dispatch(setCustomFilterValue(SEARCH_FILTER));

    if (refreshData) {
      void refreshData();
    }
  };

  const handleAllResultsClick = () => {
    setSearchValue(searchValue);
    setAnchorEl(null);

    SEARCH_FILTER_ALL.element.value = searchValue;
    dispatch(clearFilters());
    dispatch(setCustomFilterValue(SEARCH_FILTER_ALL));
    if (refreshData) {
      void refreshData();
    }
  };

  const renderOptions = (option: IOperationSearchItem, index: number): ReactNode => {
    const matchesID = match(option.operationId, inputValue, { insideWords: true });
    const partsID = parse(option.operationId, matchesID);

    const matchesUID = match(option.userFriendlyOperationId, inputValue, { insideWords: true });
    const partsUID = parse(option.userFriendlyOperationId, matchesUID);

    return (
      <ListItem {...getOptionProps({ option, index })} key={uniqueId()} disablePadding>
        <ListItemButton onClick={() => handleListItemButtonClick(option.operationId, option.userFriendlyOperationId)}>
          <ListItemText
            disableTypography
            primary={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography width="20px" variant="caption" color="text.secondary">
                  UID
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    wordBreak: 'break-all',
                  }}
                >
                  {partsUID.map((part, index: Key) => (
                    <span style={{ fontWeight: part.highlight ? 700 : 400 }} key={index}>
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Stack>
            }
            secondary={
              <Stack direction="row" alignItems="center" spacing={1}>
                <Typography width="20px" variant="caption" color="text.secondary">
                  ID
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    wordBreak: 'break-all',
                  }}
                >
                  {partsID.map((part, index: Key) => (
                    <span style={{ fontWeight: part.highlight ? 700 : 400 }} key={index}>
                      {part.text}
                    </span>
                  ))}
                </Typography>
              </Stack>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderContent = () => {
    if (isSearchError) {
      return (
        <>
          <ListItem key={uniqueId()} disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <Typography
                    variant="body2Bold"
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {inputValue} {operationsSearchResults.length > 0 ? `(${operationsSearchResults.length})` : ''}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" variant="caption">
                    All results
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />
          <Stack pt={1} alignItems="center" gap={1}>
            <Typography color="error" variant="body2">
              Loading results error
            </Typography>
            <LoadingButton
              loadingPosition="start"
              startIcon={<></>}
              loading={isLoadingSearch}
              onClick={handleSearchEvent}
              variant="text"
              color="primary"
              sx={{ minWidth: 105, p: 0 }}
            >
              Retry
            </LoadingButton>
          </Stack>
        </>
      );
    }

    if (showLoader) {
      return (
        <Stack p="8px 0" alignItems="center">
          <CircularProgress sx={{ margin: 'auto' }} size={24} color="primary" />
        </Stack>
      );
    }

    if (inputValue && operationsSearchResults && operationsSearchResults.length > 0) {
      return (
        <>
          <ListItem key={uniqueId()} disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <Typography
                    variant="body2Bold"
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {`${inputValue} (${operationsSearchAllResults.length})`}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" variant="caption">
                    All results
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />
          {operationsSearchResults.map((result, index) => renderOptions(result, index))}
        </>
      );
    }

    // if (inputValue.length < 3) {
    //   return (
    //     <Typography p="8px 0" textAlign="center" color="text.primary" variant="body2">
    //       Enter at least 3 symbols
    //     </Typography>
    //   );
    // }

    return (
      <Stack p="8px 0" alignItems="center" gap={1}>
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    );
  };

  const RenderList = forwardRef<any, {}>((_, ref) => {
    return (
      <List {...getListboxProps()} ref={ref}>
        {renderContent()}
      </List>
    );
  });

  useEffect(() => {
    if (isEmpty(filtersCurrentData)) {
      setSearchValue('');
      dispatch(clearOperationsSearch());
    }
  }, [filtersCurrentData]);

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      loaderTimeout = setTimeout(() => setShowLoader(true), 1000);
      void handleSearchEvent();
    }
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [debouncedSearchValue]);

  return (
    <div id="operations-search" {...getRootProps()}>
      <SearchField
        customWidth="476px"
        searchValue={inputValue}
        onChange={handleChangeSearch}
        searchEvent={handleSearchEvent}
        clearEvent={() => handleClearEvent()}
        onBlur={() => setAnchorEl(null)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        label="Search by user operation ID or operation ID"
        maxlength={50}
        inputProps={{ ...getInputProps() }}
      />

      {inputValue.length > 2 && (
        <Popper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="bottom-start"
          sx={{ width: anchorEl?.offsetWidth || 476, borderRadius: '4px', zIndex: 1200 }}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={500}>
              <Paper elevation={4} sx={{ backgroundColor: 'background.paper', overflow: 'auto', maxHeight: '393px' }}>
                <RenderList />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};
