import { ChangeEvent, SyntheticEvent, useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { DateValidationError } from '@mui/x-date-pickers/internals';
import { createFilterOptions } from '@mui/material';
import dayjs from 'dayjs';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import isObject from 'lodash/isObject';
import some from 'lodash/some';
import toArray from 'lodash/toArray';
import uniqueId from 'lodash/uniqueId';
import { INT_LIMIT } from 'tim-constants';
import { MULTIPLE_TITLE_SHOW_IDS, PASSIONS_COLUMN_ID, PRICE_IDS, TOOLTIP_MESSAGES } from './constants';
import { IDropdownItem, IFilterItem } from 'types/commonTypes';
import {
  deletePreset,
  fetchFilterPresets,
  fetchPossibleOptions,
  saveFilters,
  savePreset,
  selectActivePresetId,
  selectFiltersCurrentData,
  selectFilters,
  selectPossibleOptions,
  selectPresets,
  selectSortedFilters,
  setActiveFilter,
  setActivePreset,
  setFilterValue,
  clearPossibleOptions,
} from 'store/filtersSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CalendarIcon from 'assets/img/CalendarIcon';
import CloseCircleIcon from 'assets/img/CloseCircleIcon';
import CloseIcon from 'assets/img/CloseIcon';
import Autocomplete from 'components/shared/Autocomplete/Autocomplete';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import DatePicker from 'components/shared/DatePicker/DatePicker';
import Divider from 'components/shared/Divider/Divider';
import IconButton from 'components/shared/IconButton/IconButton';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import Modal from 'components/shared/Modal/Modal';
import NumericFormatCustom from 'components/shared/CurrencyFormatCustom/NumericFormatCustom';
import Stack from 'components/shared/Stack/Stack';
import Switch from 'components/shared/Switch/Switch';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import utc from 'dayjs/plugin/utc';
import './index.scss';

/**
 * Represents the props for a filter drawer component.
 *
 * @interface
 * @property {string} type - The type of the filter drawer.
 * @property {boolean} [isHidePresets] - If true, presets should be hidden.
 * @property {boolean} [hasSorting] - If true, sortedFilters will be returned (sorting based on belongsToKinds).
 * @property {Function} onClose - A function to be called when the filter drawer is closed.
 * @property {Function} onApply - A function to be called when changes are applied in the filter drawer.
 */
interface IFilterDrawerProps {
  type: string;
  isHidePresets?: boolean;
  hasSorting?: boolean;
  onClose: () => void;
  onApply: () => void;
}

dayjs.extend(utc);

const FilterDrawer = ({ type, isHidePresets = false, hasSorting = false, onClose, onApply }: IFilterDrawerProps) => {
  const dispatch = useAppDispatch();

  const data = useAppSelector(selectFiltersCurrentData);
  const possibleOptions = useAppSelector(selectPossibleOptions);
  const filters = useAppSelector(selectFilters);
  const sortedFilters = useAppSelector(selectSortedFilters);
  const presets = useAppSelector(selectPresets);
  const activePresetId = useAppSelector(selectActivePresetId);

  const [savePresetModal, setSavePresetModal] = useState<boolean>(false);
  const [presetName, setPresetName] = useState<string>('');
  const [deletePresetModal, setDeletePresetModal] = useState<boolean>(false);
  const [deletedPreset, setDeletedPreset] = useState<any | null>(null);
  const [errorTo, setErrorTo] = useState<DateValidationError | null>(null);
  const [errorFrom, setErrorFrom] = useState<DateValidationError | null>(null);

  const showDeletePresetModal = useCallback((preset: any) => {
    setDeletedPreset(preset);
    setDeletePresetModal(true);
  }, []);

  const handleChangeSavePresetModalClose = useCallback(() => {
    setSavePresetModal(false);
  }, []);

  const handleChangeDeletePresetModalClose = useCallback(() => {
    setDeletePresetModal(false);
  }, []);

  const handleSavePreset = useCallback(
    async (presetName: string) => {
      await dispatch(savePreset({ type: type, presetName: presetName }));
      handleChangeSavePresetModalClose();
      await dispatch(fetchFilterPresets(type));
    },
    [type],
  );

  const handleDeletePreset = useCallback(
    async (presetId: string) => {
      await dispatch(deletePreset({ type: type, presetId: presetId }));
      handleChangeDeletePresetModalClose();
      await dispatch(fetchFilterPresets(type));
    },
    [type],
  );

  const revisedFilters = useMemo(() => {
    return hasSorting ? sortedFilters : filters;
  }, [filters, hasSorting, sortedFilters]);

  const setPreset = useCallback(
    (presetId: any) => {
      dispatch(setActivePreset({ type: type, presetId: presetId }));
    },
    [dispatch],
  );

  const switchFilter = useCallback(
    async (item: IFilterItem, checked: boolean) => {
      if (checked && item.getValuesImmediately) {
        await dispatch(fetchPossibleOptions({ type: type, field: item.columnId }));
      }
      dispatch(setActiveFilter({ item, checked }));
    },
    [dispatch],
  );

  const setFilterInputValue = useCallback(
    ({
      type,
      columnId,
      value,
      getValuesImmediately,
    }: {
      type: string;
      columnId: string;
      value: string | null;
      getValuesImmediately: boolean;
    }) => {
      if (columnId === PASSIONS_COLUMN_ID) {
        if (value && value.length < 2) {
          dispatch(clearPossibleOptions({ columnId }));
        } else if (value && value.length >= 2 && !getValuesImmediately) {
          dispatch(fetchPossibleOptions({ type, field: columnId, value }));
        }
      } else {
        if (value && value.length < 3 && !getValuesImmediately) {
          dispatch(clearPossibleOptions({ columnId }));
        } else if (!getValuesImmediately && value && value.length > 2) {
          dispatch(fetchPossibleOptions({ type: type, field: columnId, value }));
        } else if (getValuesImmediately && !possibleOptions[columnId]) {
          dispatch(fetchPossibleOptions({ type: type, field: columnId }));
        }
      }
    },
    [dispatch, possibleOptions],
  );

  const isOptionEqualToValue = (option: any, value: any) => {
    return option.titles[0] === value.titles[0];
  };

  const applyFilters = async () => {
    await dispatch(saveFilters());
    onApply();
  };

  const validateFilters = useCallback(() => {
    if (isEmpty(data)) return true;
    if (every(toArray(data), isNull)) return true;

    return some(toArray(data), (filterItem) => {
      if (isNull(filterItem)) return false;

      const hasInvalidDate =
        filterItem.valueType === 'DATE' &&
        (filterItem?.to?.includes('Invalid Date') || filterItem?.from?.includes('Invalid Date'));

      const hasDateError = !!errorTo || !!errorFrom;

      if (hasInvalidDate || hasDateError) return true;

      if (filterItem.type === 'BETWEEN') {
        return isEmpty(filterItem?.from) || isEmpty(filterItem?.to);
      }

      if (filterItem?.element) {
        return isEmpty(filterItem.element) || isEmpty(filterItem.element?.value);
      }

      return isEmpty(filterItem?.elements) || some(filterItem?.elements, isEmpty);
    });
  }, [data, errorTo, errorFrom]);

  const checkFilterAccessibility = useCallback((entityValue: string | null) => {
    if (entityValue === 'PRIVATE_WISH') {
      return { disabled: true, message: TOOLTIP_MESSAGES.privateWish };
    }

    return { disabled: false, message: '' };
  }, []);

  const getNoOptionsHint = (item: IFilterItem, inputValue: string) => {
    if (item.getValuesImmediately) {
      return 'No options';
    }
    if (inputValue.length < (item.columnId === PASSIONS_COLUMN_ID ? 2 : 3)) {
      return `Enter at least ${item.columnId === PASSIONS_COLUMN_ID ? 2 : 3} symbols`;
    }
    return 'No options';
  };

  const filterTypes = {
    EQUALS: function (item: IFilterItem) {
      return (
        <div style={{ marginBottom: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Switch
                checked={data[item.columnId] != undefined}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  void switchFilter(item, event.target.checked);
                }}
              />
            </div>
          </div>
          {data[item.columnId] != undefined ? (
            <Autocomplete
              key={uniqueId()}
              freeSolo
              autoSelect
              size="small"
              value={data[item.columnId].element}
              getOptionLabel={(option: IDropdownItem) =>
                option.titles?.length > 0 ? option.titles[0] : option.value ?? ''
              }
              options={possibleOptions[item.columnId] || []}
              isOptionEqualToValue={(option, value) => option.id === value.value}
              onChange={(_e: SyntheticEvent<Element, Event>, newValue: string | IDropdownItem) => {
                dispatch(
                  setFilterValue({
                    columnId: item.columnId,
                    valueType: 'element',
                    value: isObject(newValue) ? { value: newValue.id, titles: newValue.titles } : { value: newValue },
                  }),
                );
              }}
              onInputChange={(_e: SyntheticEvent<Element, Event>, newValue: string) => {
                setFilterInputValue({
                  type: type,
                  columnId: item.columnId,
                  value: newValue,
                  getValuesImmediately: item.getValuesImmediately,
                });
              }}
            />
          ) : null}
        </div>
      );
    },
    IN: function (item: IFilterItem) {
      const isSalesTaxModerationPassions = item.columnId === PASSIONS_COLUMN_ID;
      const { disabled, message } = checkFilterAccessibility(data.entity_type?.element?.value);
      const [inputValue, setInputValue] = useState('');
      const [value, setValue] = useState(data[item.columnId]?.elements || []);
      const noOptionsHint = useMemo(() => getNoOptionsHint(item, inputValue), [item, inputValue]);

      useEffect(() => {
        if (isSalesTaxModerationPassions && disabled) {
          setValue([]);
        }
      }, [isSalesTaxModerationPassions, disabled]);

      return (
        <div key={item.columnId} style={{ marginBottom: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Tooltip title={isSalesTaxModerationPassions ? message : ''} placement="top-end" followCursor>
                <Switch
                  disabled={isSalesTaxModerationPassions && disabled}
                  checked={data[item.columnId] != undefined}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    void switchFilter(item, event.target.checked);
                    if (!event.target.checked) {
                      setInputValue('');
                      setValue([]);
                    }
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {data[item.columnId] != undefined ? (
            <Autocomplete
              size="small"
              disableCloseOnSelect={item.getValuesImmediately}
              inputValue={inputValue}
              value={value}
              getOptionLabel={(option: any) =>
                MULTIPLE_TITLE_SHOW_IDS.includes(item.columnId) ? `@${option.titles[0]}` : option.titles[0]
              }
              filterOptions={
                MULTIPLE_TITLE_SHOW_IDS.includes(item.columnId)
                  ? createFilterOptions({ stringify: (option: any) => option.titles[0] + ' ' + option.titles[1] })
                  : undefined
              }
              ListboxProps={{
                style: {
                  maxHeight: '352px',
                  boxSizing: 'border-box',
                },
              }}
              renderOption={
                MULTIPLE_TITLE_SHOW_IDS.includes(item.columnId)
                  ? (props: any, option: any) => {
                      return (
                        <div {...props} className={props.className + ' userId--option'}>
                          <div className="userId--optionName">#{option.titles[1]}</div>
                          <div className="userId--optionId">@{option.titles[0]}</div>
                        </div>
                      );
                    }
                  : undefined
              }
              clearOnBlur={false}
              multiple
              isOptionEqualToValue={isOptionEqualToValue}
              filterSelectedOptions={!item.getValuesImmediately}
              options={possibleOptions[item.columnId] || []}
              noOptionsText={noOptionsHint}
              onOpen={() => {
                setFilterInputValue({
                  type: type,
                  columnId: item.columnId,
                  value: null,
                  getValuesImmediately: item.getValuesImmediately,
                });
              }}
              onInputChange={(_e: SyntheticEvent<Element, Event>, newValue: string | null, reason) => {
                setInputValue(newValue as string);
                if (reason === 'reset' && !item.getValuesImmediately) {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }));
                }
                setFilterInputValue({
                  type: type,
                  columnId: item.columnId,
                  value: newValue,
                  getValuesImmediately: item.getValuesImmediately,
                });
              }}
              onChange={(_e: SyntheticEvent<Element, Event>, newValue) => {
                setValue(newValue);
                if (!newValue?.length && !item.getValuesImmediately) {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }));
                }
                dispatch(setFilterValue({ columnId: item.columnId, valueType: 'elements', value: newValue }));
              }}
            />
          ) : null}
        </div>
      );
    },
    BETWEEN: function (item: IFilterItem) {
      const isPriceFilter = PRICE_IDS.includes(item.columnId.toString());
      const inputProps = isPriceFilter
        ? {
            inputComponent: NumericFormatCustom as any,
            inputProps: {
              decimalScale: 2,
              max: INT_LIMIT,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Typography variant="body1" color="action.disabled">
                  $
                </Typography>
              </InputAdornment>
            ),
          }
        : {};

      return (
        <div style={{ marginBottom: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Switch
                checked={data[item.columnId] != undefined}
                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                  void switchFilter(item, event.target.checked);
                }}
              />
            </div>
          </div>
          {data[item.columnId] != undefined ? (
            <Stack justifySelf="space-between">
              {data[item.columnId].displayValueType === 'DATE' || data[item.columnId].valueType === 'DATE' ? (
                <Stack direction="row" spacing={2} justifySelf="space-between" alignItems="center">
                  <DatePicker
                    label="From"
                    value={data[item.columnId].from}
                    renderInput={(params: any) => <TextField {...params} size="small" />}
                    onChange={(newValue) => {
                      dispatch(
                        setFilterValue({
                          columnId: item.columnId,
                          valueType: 'from',
                          value: dayjs(newValue).format('YYYY-MM-DD'),
                        }),
                      );
                      if (dayjs(newValue).isValid() && dayjs(newValue).isAfter('1899-12-31')) {
                        setErrorFrom(null);
                      }
                    }}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                    onError={(newError) => setErrorFrom(newError)}
                    onAccept={() => setErrorFrom(null)}
                  />

                  <DatePicker
                    label="To"
                    value={data[item.columnId].to?.replace('T23:59:59Z', '')}
                    renderInput={(params: any) => <TextField {...params} size="small" />}
                    onChange={(newValue) => {
                      const formattedDate = dayjs(newValue).endOf('day').utc().format('YYYY-MM-DDTHH:mm:ssZ');

                      dispatch(
                        setFilterValue({
                          columnId: item.columnId,
                          valueType: 'to',
                          value: formattedDate,
                        }),
                      );
                      if (dayjs(newValue).isValid() && dayjs(newValue).isBefore('2100-01-01')) {
                        setErrorTo(null);
                      }
                    }}
                    components={{
                      OpenPickerIcon: CalendarIcon,
                    }}
                    onError={(newError) => setErrorTo(newError)}
                    onAccept={() => setErrorTo(null)}
                  />
                </Stack>
              ) : (
                <Stack direction="row" gap={2} justifyContent="space-between" alignItems="center">
                  <TextField
                    size="small"
                    label="From"
                    value={data[item.columnId].from}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      dispatch(setFilterValue({ columnId: item.columnId, valueType: 'from', value: e.target.value }));
                    }}
                    InputProps={inputProps}
                  />
                  <TextField
                    size="small"
                    label="To"
                    value={data[item.columnId].to}
                    onChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                      dispatch(setFilterValue({ columnId: item.columnId, valueType: 'to', value: e.target.value }));
                    }}
                    InputProps={inputProps}
                  />
                </Stack>
              )}
            </Stack>
          ) : null}
        </div>
      );
    },
    OR: function (item: IFilterItem) {
      const isSalesTaxModerationPassions = item.columnId === PASSIONS_COLUMN_ID;
      const { disabled, message } = checkFilterAccessibility(data.entity_type?.element?.value);
      const [inputValue, setInputValue] = useState('');
      const [value, setValue] = useState(data[item.columnId]?.elements || []);
      const noOptionsHint = useMemo(() => getNoOptionsHint(item, inputValue), [item, inputValue]);

      return (
        <div key={item.columnId} style={{ marginBottom: '16px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
            <Typography variant="body2">{item.title}</Typography>
            <div style={{ justifySelf: 'end' }}>
              <Tooltip title={isSalesTaxModerationPassions ? message : ''} placement="top-end" followCursor>
                <Switch
                  disabled={isSalesTaxModerationPassions && disabled}
                  checked={data[item.columnId] != undefined}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => {
                    void switchFilter(item, event.target.checked);
                    if (!event.target.checked) {
                      setInputValue('');
                      setValue([]);
                    }
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {data[item.columnId] != undefined ? (
            <Autocomplete
              size="small"
              disableCloseOnSelect
              inputValue={inputValue}
              value={value}
              getOptionLabel={(option: any) => option.titles[0]}
              options={possibleOptions[item.columnId] || []}
              isOptionEqualToValue={isOptionEqualToValue}
              filterSelectedOptions
              noOptionsText={noOptionsHint}
              clearOnBlur={false}
              multiple
              onInputChange={(_e: SyntheticEvent<Element, Event>, newValue: string | null, reason) => {
                setInputValue(newValue as string);
                if (reason === 'reset') {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }));
                }
                setFilterInputValue({
                  type: type,
                  columnId: item.columnId,
                  value: newValue,
                  getValuesImmediately: item.getValuesImmediately,
                });
              }}
              onChange={(_e: SyntheticEvent<Element, Event>, newValue) => {
                setValue(newValue);
                if (!newValue?.length && !item.getValuesImmediately) {
                  dispatch(clearPossibleOptions({ columnId: item.columnId }));
                }
                dispatch(setFilterValue({ columnId: item.columnId, valueType: 'elements', value: newValue }));
              }}
            />
          ) : null}
        </div>
      );
    },
  };

  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);

  useLayoutEffect(() => {
    const item = document.querySelector('.filterDrawer--content');
    setIsScrollVisible(item?.clientHeight != item?.scrollHeight);
  });

  return (
    <div className="filterDrawer--container">
      <div className="filterDrawer--header">
        <Typography variant="h5">Filter</Typography>
        <IconButton size="small" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <div className={`filterDrawer--content${isScrollVisible ? ' scrollable' : ''}`}>
        {!isHidePresets && presets.length > 0 && (
          <>
            <Stack direction="column" gap={1} className="filterDrawer--group">
              <Typography variant="h6">Presets</Typography>
              <Stack direction="row" flexWrap="wrap" mb={2} gap={1.5}>
                {presets.map((preset: any) => {
                  return (
                    <Chip
                      className="filterDrawer--group__chip"
                      label={preset.presetName}
                      deleteIcon={<CloseCircleIcon />}
                      onDelete={() => showDeletePresetModal(preset)}
                      onClick={() => setPreset(preset.presetId)}
                      active={preset.presetId === activePresetId}
                    />
                  );
                })}
              </Stack>
            </Stack>
            <Divider orientation="horizontal" flexItem />
          </>
        )}

        <div className="filterDrawer--group">
          <Typography variant="h6">Active filters</Typography>
          {revisedFilters.map((item: IFilterItem, idx) => (
            <div key={idx}>{filterTypes[item.type](item)}</div>
          ))}
        </div>
      </div>
      <Box boxShadow={5} className="filterDrawer--footer">
        <Button
          fullWidth
          disabled={validateFilters()}
          style={{ height: '42px' }}
          variant="contained"
          onClick={() => applyFilters()}
        >
          Apply
        </Button>
        {!isHidePresets && (
          <Button
            disabled={type === 'users'}
            fullWidth
            style={{ height: '42px' }}
            variant="outlined"
            onClick={() => {
              setSavePresetModal(true);
            }}
          >
            Save set
          </Button>
        )}
      </Box>
      <Modal customstyle={{ minHeight: 256 }} open={savePresetModal} onClose={handleChangeSavePresetModalClose}>
        <div className="savePresetModal">
          <div>
            <div className="savePresetModal--header">Save new preset</div>
            <div className="savePresetModal--text">Enter name for new filter preset</div>
          </div>
          <TextField
            variant="outlined"
            placeholder="Name"
            onChange={(event: ChangeEvent<HTMLInputElement>) => setPresetName(event.target.value)}
          />
          <div style={{ display: 'flex', justifyContent: 'end', width: '100%' }}>
            <Button style={{ marginRight: '16px' }} onClick={() => handleChangeSavePresetModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleSavePreset(presetName)}>
              Save
            </Button>
          </div>
        </div>
      </Modal>
      <Modal customstyle={{ minHeight: 188 }} open={deletePresetModal} onClose={handleChangeDeletePresetModalClose}>
        <div className="deletePresetModal">
          <div>
            <div className="deletePresetModal--header">Delete preset?</div>
            <div className="deletePresetModal--text">You are going to delete preset {deletedPreset?.presetName}</div>
          </div>
          <div style={{ display: 'flex', justifyContent: 'end', justifySelf: 'end', width: '100%' }}>
            <Button style={{ marginRight: '16px' }} onClick={() => handleChangeDeletePresetModalClose()}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => handleDeletePreset(deletedPreset?.presetId)}>
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default FilterDrawer;
