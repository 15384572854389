import axios, { isAxiosError } from 'axios';
import { createAction, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';
import { ITaxModerationCard, ITaxModerationSearchItem, ITaxModerationState, TaxModerationSolution } from './interface';
import { TaxModerationListType } from 'containers/TaxModeration/constants';

const initialState: ITaxModerationState = {
  taxModerationCards: [],
  allIds: [],
  taxModerationSearch: [],
  taxModerationSearchAll: [],
  taxModerationSearchStatus: 'idle',
  taxModerationCardDetails: null,
  taxModerationCardsCount: 0,
  status: 'idle',
};

export const fetchChangeTaxModerationSolution = createAsyncThunk(
  'taxModeration/changeSolution',
  async (
    {
      salesTaxModerationId,
      newSalesTaxCodeId,
      solution,
    }: {
      salesTaxModerationId: string | null;
      newSalesTaxCodeId: string | null;
      solution: TaxModerationSolution;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/moderation/${salesTaxModerationId}/solution`,
        {
          newSalesTaxCodeId: newSalesTaxCodeId,
          solution: solution,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchTaxModerationCards = createAsyncThunk(
  'taxModeration/cards',
  async (
    {
      page,
      pageSize,
      listType,
    }: {
      page: number;
      pageSize: number;
      listType: TaxModerationListType;
    },
    { rejectWithValue, getState },
  ) => {
    try {
      const rootState: RootState = getState() as RootState;
      const filters = Object.keys(rootState.filters.data)
        ? Object.values(rootState.filters.data).filter((item: any) => item !== null)
        : [];
      const sortingList = Object.keys(rootState.sorting.data)
        ? Object.values(rootState.sorting.data).filter(
            (item: any) => item !== null && item.columnId !== null && item.sortingType !== null,
          )
        : [];
      const response = await axios.post(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/moderation/${listType.toLowerCase()}`,
        {
          pageInfo: {
            page: page + 1,
            size: pageSize,
          },
          sortingList: sortingList,
          filters: filters,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchTaxModerationCard = createAsyncThunk(
  'taxModeration/card',
  async (salesTaxModerationId: string, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/moderation/${salesTaxModerationId}`,
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchTaxModerationSearch = createAsyncThunk(
  'taxModeration/search',
  async ({ searchValue, listType }: { searchValue: string; listType: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/moderation/search/${listType.toLowerCase()}`,
        {
          value: searchValue,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const removeApprovedCardId = createAction<string>('taxModeration/removeApprovedId');

export const TaxModerationSlice = createSlice({
  name: 'taxModeration',
  initialState,
  reducers: {
    setTaxModerationStatus: (state, action: PayloadAction<IStatus>) => {
      state.status = action.payload;
    },
    clearTaxModerationSearch: (state) => {
      state.taxModerationSearch = [];
      state.taxModerationSearchAll = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxModerationCards.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxModerationCards.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.taxModerationCards = payload.content;
        state.allIds = payload.allIds;
        state.taxModerationCardsCount = payload.totalElements;
      })
      .addCase(fetchTaxModerationCards.rejected, (state) => {
        state.status = 'failed';
        state.taxModerationCards = [];
        state.allIds = [];
        state.taxModerationCardsCount = 0;
      })
      .addCase(fetchTaxModerationCard.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxModerationCard.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.taxModerationCardDetails = payload;
      })
      .addCase(fetchTaxModerationCard.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchChangeTaxModerationSolution.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        payload && (state.taxModerationCardDetails = payload);
      })
      .addCase(fetchChangeTaxModerationSolution.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(removeApprovedCardId, (state, action) => {
        state.allIds = state.allIds.filter((id) => id !== action.payload);
      })
      .addCase(fetchTaxModerationSearch.pending, (state) => {
        state.taxModerationSearchStatus = 'loading';
      })
      .addCase(fetchTaxModerationSearch.fulfilled, (state, { payload }) => {
        state.taxModerationSearch = payload.listOfTitleAndIds;
        state.taxModerationSearchAll = payload.allIds;
        state.taxModerationSearchStatus = 'idle';
      })
      .addCase(fetchTaxModerationSearch.rejected, (state) => {
        state.taxModerationSearchStatus = 'failed';
      });
  },
});

export const selectTaxModerationCards = (state: RootState): ITaxModerationCard[] =>
  state.taxModeration.taxModerationCards;
export const selectTaxModerationCardIds = (state: RootState): string[] => state.taxModeration.allIds;
export const selectTaxModerationCardDetails = (state: RootState): ITaxModerationCard | null =>
  state.taxModeration.taxModerationCardDetails;
export const selectTotalCount = (state: RootState): number => state.taxModeration.taxModerationCardsCount;
export const selectTaxModerationSearchResults = (state: RootState): ITaxModerationSearchItem[] =>
  state.taxModeration.taxModerationSearch;
export const selectTaxModerationSearchAllResults = (state: RootState): string[] =>
  state.taxModeration.taxModerationSearchAll;
export const selectTaxModerationStatus = (state: RootState): IStatus => state.taxModeration.status;
export const selectTaxModerationSearchStatus = (state: RootState): IStatus =>
  state.taxModeration.taxModerationSearchStatus;

export const { setTaxModerationStatus, clearTaxModerationSearch } = TaxModerationSlice.actions;

export default TaxModerationSlice.reducer;
