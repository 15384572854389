import { ReactNode } from 'react';
import './index.scss';

interface InfoBlockProps {
  icon: ReactNode;
  header: string;
  text: string | null;
  class?: string;
}

const InfoBlock = (props: InfoBlockProps) => (
  <div className="infoBlock--container">
    <div className="infoBlock--icon">{props.icon}</div>
    <div>
      {props.text && <div className="infoBlock--text">{props.text}</div>}
      <div className={`infoBlock--header ${props.class}`}>{props.header}</div>
    </div>
  </div>
);

export default InfoBlock;
