import dayjs from 'dayjs';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import StatusBlock from 'components/shared/StatusBlock/StatusBlock';
import Carousel from 'react-material-ui-carousel';
import ZoomInIcon from 'assets/img/ZoomInIcon';

const WIDTH_ENTITY = 322;
const HEIGHT_ENTITY = 174;

const EventCard = (props: any) => {
  const { title, orderedMediaList, format, startedAt, locationInfo, priceOption } = props.data;
  const { userInfoDto = {} } = props.data;
  const { avatarUrl, firstName, lastName } = userInfoDto;

  const renderEventCardName = () => {
    if (title.length > 47) {
      return `${title.substring(0, 47)}...`;
    }

    return title;
  };

  return orderedMediaList.length > 1 ? (
    <Carousel
      height="100%"
      animation="slide"
      autoPlay={false}
      swipe={false}
      className="eventCard--Carousel"
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: '#BFC6E2' } }}
      activeIndicatorIconButtonProps={{ style: { color: '#4E79E7' } }}
      indicatorContainerProps={{
        style: { display: 'flex', justifyContent: 'center', gap: '4px', marginBottom: '20px' },
      }}
    >
      {orderedMediaList.map((el: any) => (
        <div className="carouselEntity">
          <div
            className="overlay"
            onClick={() => {
              props.showMedia(
                el.mediaUrl ? el.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                el,
                el.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              );
            }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <div className="eventCard--container carouselEntity">
            <div className="eventCard--data">
              <div className="eventCard--status">
                <StatusBlock status={format} irl={true} />
              </div>
              <div className="eventCard--name">{renderEventCardName()}</div>
              <div className="eventCard--avatar">
                <AvatarItem sizes="small" src={avatarUrl} primaryText={firstName + ' ' + lastName} subText="Creator" />
              </div>
              <div className="eventCard--location">
                {`${dayjs(startedAt).format('DD MMM')} | ${locationInfo ? locationInfo?.locationCity : 'New York'}`}
              </div>
              <div className="eventCard--price">
                Price: <span>{priceOption}</span>
              </div>
            </div>
            <div className="eventCard--image">
              <img
                src={!!el.mediaPreviewUrl ? el.mediaPreviewUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'}
                height={HEIGHT_ENTITY}
                width={WIDTH_ENTITY}
              />
            </div>
          </div>
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        className="overlay"
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
            mediaType: 'Image',
          };

          props.showMedia(
            mediaItem.mediaUrl,
            mediaItem,
            mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image',
            props.data.orderedMediaList,
          );
        }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <div className="eventCard--container">
        <div className="eventCard--data">
          <div className="eventCard--status">
            <StatusBlock status={props.data.format} irl={true} />
          </div>
          <div className="eventCard--name">{renderEventCardName()}</div>
          <div className="eventCard--avatar">
            <AvatarItem sizes="small" src={avatarUrl} primaryText={firstName + ' ' + lastName} subText="Creator" />
          </div>
          <div className="eventCard--location">
            {`${dayjs(startedAt).format('DD MMM')} | ${locationInfo ? locationInfo?.locationCity : 'New York'}`}
          </div>
          <div className="eventCard--price">
            Price: <span>{priceOption}</span>
          </div>
        </div>
        <div className="eventCard--image">
          <img
            src={
              !!orderedMediaList[0]?.mediaPreviewUrl
                ? orderedMediaList[0]?.mediaPreviewUrl
                : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'
            }
          />
        </div>
      </div>
    </div>
  );
};

export default EventCard;
