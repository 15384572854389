import { useMemo } from 'react';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { currencyFormatter } from 'utils/currencyFormatter';
import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import { format } from 'date-fns';
import dayjs from 'dayjs';
import CalendarIcon from 'assets/img/CalendarIcon';
import CameraIcon from 'assets/img/CameraIcon';
import CloseIcon from 'assets/img/CloseIcon';
import LikeIcon from 'assets/img/LikeIcon';
import LocationIcon from 'assets/img/LocationIcon';
import MoneyIcon from 'assets/img/MoneyIcon';
import WorldSendIcon from 'assets/img/WorldSendIcon';
import AvatarItem from 'components/shared/AvatarItem/AvatarItem';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import InfoBlock from 'components/shared/InfoBlock/InfoBlock';
import WishCard from './WishCard';
import './index.scss';
import ExpandButton from 'components/shared/ExpandButton/ExpandButton';
import useExpandableDescription from 'hooks/useExpandableDescription';

const Wish = (props: any) => {
  const data = props.data;
  const publishDate = new Date(data.publishedAt);
  const userInfo = data.userInfoDto ?? {};

  const { isExpanded, toggleExpansion, renderDescription } = useExpandableDescription(data.description);

  const userPermissions = useAppSelector(selectUserPermissions);
  const showTopSearchSelectionButton = checkPermissions(userPermissions, [
    Permissions.WORLD_MANAGEMENT_WORLD_SELECTION,
  ]);

  const priceOption = useMemo(() => {
    if (data.priceOption === 'FREE')
      return {
        priceOption: 'Free',
        price: '$0',
      };
    if (data.priceOption === 'FLEXIBLE')
      return {
        priceOption: 'Flexible price',
        price: `Up to ${currencyFormatter(data.price, 'en-US', 'USD')}`,
      };
    if (data.priceOption === 'FIXED')
      return {
        priceOption: 'Fixed price',
        price: `${currencyFormatter(data.price, 'en-US', 'USD')}`,
      };
    return {
      priceOption: 'Free',
      price: '$0',
    };
  }, [data.priceOption, data.price]);

  const timeOption = useMemo(() => {
    const result = {
      date: 'Open date',
      duration: 'Duration flexible',
    };
    if (data.startedAt && data.finishedAt) {
      result.date = dayjs(data.startedAt).format('MM/DD/YYYY') + ' - ' + dayjs(data.finishedAt).format('MM/DD/YYYY');
    }
    if (data.sessionDuration) {
      const durationHours = Math.floor(data.sessionDuration / 60);
      const durationMinutes = data.sessionDuration % 60;
      result.duration = `Duration:${durationHours > 0 ? ` ${durationHours} hour${durationHours > 1 ? 's' : ''}` : ''}${
        durationMinutes > 0 ? ` ${durationMinutes} minute${durationMinutes > 1 ? 's' : ''}` : ''
      }`;
    }
    return result;
  }, [data]);

  const locationHeader = useMemo(() => {
    if (data.locationInfo?.locationFullAddress) return data.locationInfo.locationFullAddress;
    if (data.locationInfo?.locationCountry && data.locationInfo?.locationCity)
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    if (data.locationInfo?.locationCountry) return `${data.locationInfo.locationCountry}`;
    return 'Not specified';
  }, [data.locationInfo]);

  const locationText = useMemo(() => {
    if (
      data.locationInfo &&
      data.locationInfo?.locationFullAddress &&
      data.locationInfo?.locationCountry &&
      data.locationInfo?.locationCity
    )
      return `${data.locationInfo.locationCity}, ${data.locationInfo.locationCountry}`;
    return null;
  }, [data.locationInfo]);

  return (
    <div className={'wish--container display--cellContainer' + (props.modalClose ? ' closeable' : '')}>
      <div className="wish--containerHeader">
        <div className="wish--eventName">
          <div className="wish--eventId">Wish #{data.entityId}</div>
          <div className="wish--eventPublishDate">
            Published <span>{format(publishDate, 'MM/dd/yyyy, hh:mm a')}</span>
          </div>
        </div>
        {props.modalClose ? (
          <div className="wish--eventActions">
            <div
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                margin: '-4px -12px 0 0',
                color: '#BFC6E2',
              }}
              onClick={() => props.modalClose()}
            >
              <CloseIcon />
            </div>
          </div>
        ) : (
          <div className="wish--eventActions">
            {showTopSearchSelectionButton && (
              <Button
                disabled
                size="small"
                variant="outlined"
                startIcon={<WorldSendIcon />}
                onClick={() => props.linkCollections({ entityId: data.entityId, entityType: data.entityType })}
              >
                Top Search
              </Button>
            )}
          </div>
        )}
      </div>
      <div className="wish--content">
        <div className="wishContent--card">
          <WishCard data={data} />
        </div>
        <div className="wishContent--descriptionContainer">
          <AvatarItem
            src={userInfo.avatarUrl}
            primaryText={userInfo.firstName + ' ' + userInfo.lastName}
            subText={`@${userInfo.username}`}
            onClick={() => props.openUser(userInfo.userId || '1')}
          />
          <div>{data.title}</div>
          <div className="wishContent--description">{renderDescription()}</div>
          {data.description && data.description.length > 250 && (
            <ExpandButton onClick={toggleExpansion} isExpanded={isExpanded}>
              Expand
            </ExpandButton>
          )}
          <div className="wishContent--counters">
            <div>
              <LikeIcon /> {data.likesCounter}
            </div>
          </div>
        </div>
        <div className="wishContent--passions">
          <div className="wishContent--passionsTitle">Passions</div>
          <div className="wishContent--passionsChips">
            {data.passions.length
              ? data.passions.map((el: any) => <Chip variant="filled" label={el} />)
              : 'Not specified'}
          </div>
        </div>
        <div className="wishContent--terms">
          {data.priceOption ? (
            <InfoBlock icon={<MoneyIcon />} header={priceOption.priceOption} text={priceOption.price} />
          ) : (
            <></>
          )}
          {<InfoBlock icon={<CalendarIcon />} header={timeOption.duration} text={timeOption.date} />}
          {data.format === 'OFFLINE' ? (
            <InfoBlock icon={<LocationIcon />} header={locationHeader} text={locationText} />
          ) : (
            <></>
          )}
          {data.format === 'ONLINE' ? <InfoBlock icon={<CameraIcon />} header="Format" text="Online" /> : <></>}
        </div>
      </div>
    </div>
  );
};

export default Wish;
