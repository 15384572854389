import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useErrorBoundary } from 'react-error-boundary';
import { VariantType, enqueueSnackbar } from 'notistack';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { useDocumentTitle } from '@uidotdev/usehooks';
import isEqual from 'lodash/isEqual';
import startCase from 'lodash/startCase';
import { Tab } from '@mui/material';
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { DataGridProProps, GridRowScrollEndParams } from '@mui/x-data-grid-pro';
import ContentDisplayTabs from 'components/ContentDisplay/types/ContentDisplayTabs';
import { IMediaItem, Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  clearContentDisplayData,
  fetchContent,
  selectContentDisplayData,
  selectContentDisplayRefreshStatus,
  selectContentDisplayStatus,
  selectContentDisplayTotalElements,
  setRefreshContentStatus,
} from 'store/contentDisplaySlice';
import {
  clearFilters,
  fetchFilters,
  reverseFilters,
  selectFiltersCurrentData,
  selectFiltersData,
} from 'store/filtersSlice';
import { clearSorting, fetchSortingColumns } from 'store/sortingSlice';
import { selectUserPermissions } from 'store/authSlice';
import FilterIcon from 'assets/img/FilterIcon';
import RefreshIcon from 'assets/img/RefreshIcon';
import SortIcon from 'assets/img/SortIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Button from 'components/shared/Button/Button';
import Drawer from 'components/shared/Drawer/Drawer';
import Event from 'components/ContentDisplay/Event/Event';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import Grid from 'components/shared/Grid/Grid';
import Lifestyle from 'components/ContentDisplay/Lifestyle/Lifestyle';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import ManageLinkedCollectionsModal from 'components/ManageLinkedCollections/ManageLinkedCollectionsModal';
import MediaPreviewDrawer, {
  IMediaProps,
} from 'components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer';
import NoMoreContent from 'components/ContentDisplay/NoMoreContent/NoMoreContent';
import Offer from 'components/ContentDisplay/Offer/Offer';
import SortingDrawer from 'components/SortingDrawer/SortingDrawer';
import UserProfileModal from 'components/Moderation/UserProfileModal/UserProfileModal';
import Wish from 'components/ContentDisplay/Wish/Wish';
import withAuth from 'components/Authorization/withAuth';
import ContentDisplayGridFooter from 'components/ContentDisplay/Footer/ContentDisplayGridFooter';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import './index.scss';

function App() {
  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const mounted = useRef(true);
  const [searchValue, setSearchValue] = useState<string>('');
  const [isShowUserProfileModal, setIsShowUserProfileModal] = useState<string>('');

  const userPermissions = useAppSelector(selectUserPermissions);
  const loadingStatus = useAppSelector(selectContentDisplayStatus);
  const refreshStatus = useAppSelector(selectContentDisplayRefreshStatus);

  const [rowCount, setRowCount] = useState(10);

  const handleChangeSearch = useCallback(
    (event: any) => {
      setSearchValue(event.target.value);
    },
    [setSearchValue],
  );

  let { tabId } = useParams();
  const isCorrectPage = useMemo(
    () => tabId && Object.values(ContentDisplayTabs).includes(tabId as ContentDisplayTabs),
    [tabId],
  );
  const [tabValue, setTabValue] = useState<ContentDisplayTabs>(
    tabId ? (tabId as ContentDisplayTabs) : ContentDisplayTabs.EVENTS,
  );
  useDocumentTitle(`Content display - ${startCase(tabValue)}`);

  const handleTabChange = async (e: SyntheticEvent, tab: string) => {
    await setRowCount(10);
    await setTabValue(tab as ContentDisplayTabs);
    dispatch(clearFilters());
    dispatch(clearSorting());
    await dispatch(clearContentDisplayData());
    dispatch(fetchSortingColumns(tab));
    dispatch(fetchFilters(tab));
    fetchContentFunc(searchValue, tab);
  };

  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [sortingDrawer, setSortingDrawer] = useState<boolean>(false);
  const [mediaPreviewDrawer, setMediaPreviewDrawer] = useState<IMediaProps>();
  const [openLinkedCollections, setOpenLinkedCollections] = useState<any>(null);
  const [scrollEnd, setScrollEnd] = useState(false);

  const filtersData = useAppSelector(selectFiltersData);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const sorting = useAppSelector((state) => state.sorting.data);
  const content = useAppSelector(selectContentDisplayData);
  const totalElements = useAppSelector(selectContentDisplayTotalElements);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const fetchContentFunc = useCallback((searchValue: string, tab: string) => {
    dispatch(fetchContent({ pageSize: rowCount, searchValue: searchValue, tab: tab }));
  }, []);

  const filtersLength = useMemo(() => {
    return Object.values(filtersData).filter((item: any) => item !== null).length;
  }, [filtersData]);

  const initialFetchData = useCallback(() => {
    dispatch(fetchSortingColumns(tabValue));
    dispatch(fetchFilters(tabValue));
    dispatch(fetchContent({ pageSize: rowCount, searchValue: searchValue, tab: tabValue }));
  }, [tabValue]);

  const refreshData = useCallback(async () => {
    try {
      await dispatch(setRefreshContentStatus('loading'));
      const result = await dispatch(fetchContent({ pageSize: rowCount, searchValue: searchValue, tab: tabValue }));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar('Data has been refreshed', { variant: 'success' as VariantType });
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
      }
    } catch (e) {
      enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
    }
  }, [rowCount, searchValue, tabValue]);

  useEffect(() => {
    dispatch(clearFilters());
    initialFetchData();
  }, []);

  useEffect(() => {
    navigate(`/ui/content-display/${tabValue}`, { replace: false });
    return () => {
      mounted.current = true;
    };
  }, [tabValue]);

  useEffect(() => {
    if (!tabId) {
      navigate(`/ui/content-display/${ContentDisplayTabs.EVENTS}`, { replace: false });
      return;
    }

    if (!isCorrectPage) {
      showBoundary(new Error('Request failed with status code 404'));
    }
  }, []);

  const handleOpenUserProfileModal = (userId: string) => {
    if (checkPermissions(userPermissions, [Permissions.USERS_CARD_VIEW_USERS_CARDS])) {
      setIsShowUserProfileModal(userId);
    } else return;
  };

  const handleOnRowsScrollEnd: DataGridProProps['onRowsScrollEnd'] = async (params: GridRowScrollEndParams) => {
    if (rowCount < totalElements && params.viewportPageSize >= 0 && loadingStatus === 'idle') {
      await loadServerRows(params.virtualRowsCount + 10);
    } else if (loadingStatus === 'idle' && rowCount > totalElements) {
      setScrollEnd(true);
    }
  };

  const resetScrollEnd = () => {
    setScrollEnd(false);
  };

  useEffect(() => {
    resetScrollEnd();
  }, [tabValue, filtersData, refreshData]);

  const loadServerRows = useCallback(
    async (newRowLength: number) => {
      await setRowCount(newRowLength);
      await dispatch(fetchContent({ pageSize: newRowLength, searchValue: searchValue, tab: tabValue }));
    },
    [tabValue],
  );

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchContent({ pageSize: rowCount, searchValue: searchValue, tab: tabValue }));
  }, [dispatch, rowCount, searchValue, tabValue]);

  const showMediaDrawer = (
    entityId: string,
    entityType: string,
    mediaUrl: string,
    mainEntity: IMediaItem,
    orderedMediaList: IMediaItem[],
    mediaType?: string,
  ) => {
    setMediaPreviewDrawer({
      entityId: entityId,
      entityType: entityType,
      mediaUrl: mediaUrl,
      mainEntity: mainEntity,
      orderedMediaList: orderedMediaList,
      contentType: mediaType ?? 'Image',
    });
  };

  const customCellRender: any = (props: GridRenderCellParams<any>) => {
    const type = props.row.entityType.toLowerCase();
    switch (type) {
      case ContentDisplayTabs.EVENTS:
        return (
          <Event
            data={props.row}
            openUser={handleOpenUserProfileModal}
            showMedia={showMediaDrawer}
            linkCollections={setOpenLinkedCollections}
          />
        );
      case ContentDisplayTabs.OFFERS:
        return (
          <Offer
            data={props.row}
            openUser={handleOpenUserProfileModal}
            showMedia={showMediaDrawer}
            linkCollections={setOpenLinkedCollections}
          />
        );
      case ContentDisplayTabs.WISHES:
        return (
          <Wish
            data={props.row}
            openUser={handleOpenUserProfileModal}
            showMedia={showMediaDrawer}
            linkCollections={setOpenLinkedCollections}
          />
        );
      case ContentDisplayTabs.LIFESTYLES:
        return <Lifestyle data={props.row} openUser={handleOpenUserProfileModal} showMedia={showMediaDrawer} />;
      default:
        return <div>{'test'}</div>;
    }
  };

  const columns = [{ columnId: '1', columnName: 'Test' }];

  const cols: GridColDef[] = useMemo(() => {
    return columns.map((item) => ({
      field: item.columnId,
      headerName: item.columnName,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isFooter) {
          return <ContentDisplayGridFooter isAllLoaded={scrollEnd} setScrollEnd={setScrollEnd} />;
        }
        return customCellRender && customCellRender(params);
      },
    }));
  }, [columns, customCellRender, scrollEnd]);

  const rows: any[] = useMemo(() => {
    return content;
  }, [content]);

  const rowsWithFooter = useMemo(() => {
    if (!scrollEnd || totalElements === 0) {
      return rows;
    }

    const rowsCopy = [
      ...rows,
      {
        entityId: 'footer',
        isFooter: true,
      },
    ];

    return rowsCopy;
  }, [rows, scrollEnd, totalElements]);

  return (
    <div className="contentDisplay--container">
      <div className="contentDisplay--header">Content display</div>
      <TabContext value={tabValue}>
        <TabList onChange={handleTabChange} className="contentDisplay--tabs">
          <Tab label="Events" value={ContentDisplayTabs.EVENTS} />
          <Tab label="Offers" value={ContentDisplayTabs.OFFERS} />
          <Tab label="Wishes" value={ContentDisplayTabs.WISHES} />
          <Tab label="Lifestyle" value={ContentDisplayTabs.LIFESTYLES} />
        </TabList>
        <div className="contentDisplay--listHeader">
          <div className="contentDisplay--listButtonsContainer">
            <div>
              <Button
                size="small"
                startIcon={<FilterIcon />}
                className="contentDisplay--listButton"
                variant="outlined"
                onClick={() => setFilterDrawer(true)}
              >
                Filter {filtersData.length ? `(${filtersData.length})` : <></>}
              </Button>
              <Button
                size="small"
                startIcon={<SortIcon />}
                disabled
                className="contentDisplay--listButton"
                variant="outlined"
                onClick={() => setSortingDrawer(true)}
              >
                Sort {sorting.length ? `(${sorting.length})` : <></>}
              </Button>
              <LoadingButton
                size="small"
                disabled={refreshStatus === 'loading'}
                startIcon={<RefreshIcon />}
                loading={refreshStatus === 'loading'}
                loadingPosition="start"
                className="contentDisplay--listButton"
                onClick={() => refreshData()}
              >
                Refresh data
              </LoadingButton>
            </div>
          </div>
          {filtersLength !== 0 && <ActiveFilterList refreshData={resetFilters} />}
        </div>
        <TabPanel value={ContentDisplayTabs.EVENTS}>
          <Grid
            columns={cols}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.entityId}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            scrollEndThreshold={1}
            rows={rowsWithFooter}
            headerHeight={0}
            disableSelectionOnClick
            loading={loadingStatus === 'loading'}
            components={{
              NoRowsOverlay: () =>
                NoMoreContent({
                  isFilters: !!filtersLength,
                  clearFilter: resetFilters,
                }),
            }}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            hideFooter
          />
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.OFFERS}>
          <Grid
            columns={cols}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.entityId}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            scrollEndThreshold={1}
            rows={rowsWithFooter}
            headerHeight={0}
            disableSelectionOnClick
            loading={loadingStatus === 'loading'}
            components={{
              NoRowsOverlay: () =>
                NoMoreContent({
                  isFilters: !!filtersLength,
                  clearFilter: resetFilters,
                }),
            }}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            hideFooter
          />
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.WISHES}>
          <Grid
            columns={cols}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.entityId}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            scrollEndThreshold={1}
            rows={rowsWithFooter}
            headerHeight={0}
            disableSelectionOnClick
            loading={loadingStatus === 'loading'}
            components={{
              NoRowsOverlay: () =>
                NoMoreContent({
                  isFilters: !!filtersLength,
                  clearFilter: resetFilters,
                }),
            }}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            hideFooter
          />
        </TabPanel>
        <TabPanel value={ContentDisplayTabs.LIFESTYLES}>
          <Grid
            columns={cols}
            getRowHeight={() => 'auto'}
            getRowId={(row) => row.entityId}
            getRowSpacing={() => ({
              bottom: 16,
            })}
            scrollEndThreshold={1}
            rows={rowsWithFooter}
            headerHeight={0}
            disableSelectionOnClick
            loading={loadingStatus === 'loading'}
            components={{
              NoRowsOverlay: () =>
                NoMoreContent({
                  isFilters: !!filtersLength,
                  clearFilter: resetFilters,
                }),
            }}
            onRowsScrollEnd={handleOnRowsScrollEnd}
            hideFooter
          />
        </TabPanel>
        <CloseFilterDrawerConfirmModal
          open={filterDrawerCloseConfirmModal}
          onClose={() => onFilterSidebarClose(true)}
          closeModal={() => cancelCloseFilterSidebar()}
        />
      </TabContext>
      {isShowUserProfileModal.length > 0 && (
        <UserProfileModal
          open={isShowUserProfileModal.length > 0}
          currentUserId={isShowUserProfileModal}
          onClose={() => {
            setIsShowUserProfileModal('');
            // navigate(`/ui/world-management/collections/${collectionId}`);
          }}
        />
      )}
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="content-display"
          isHidePresets
          onClose={onFilterSidebarClose}
          onApply={() => {
            onFilterSidebarClose(true);
            fetchContentFunc(searchValue, tabValue);
          }}
        />
      </Drawer>
      <Drawer anchor="right" open={sortingDrawer} onClose={() => setSortingDrawer(false)}>
        <SortingDrawer
          type="content-display"
          isHidePresets={true}
          close={() => setSortingDrawer(false)}
          apply={() => {
            setSortingDrawer(false);
            fetchContentFunc(searchValue, tabValue);
          }}
        />
      </Drawer>
      <Drawer
        anchor="right"
        open={!!mediaPreviewDrawer}
        onClose={() => {
          setMediaPreviewDrawer(undefined);
        }}
      >
        {mediaPreviewDrawer && (
          <MediaPreviewDrawer mediaProps={mediaPreviewDrawer} onClose={() => setMediaPreviewDrawer(undefined)} />
        )}
      </Drawer>
      {openLinkedCollections && (
        <ManageLinkedCollectionsModal
          open={!!openLinkedCollections}
          onClose={() => setOpenLinkedCollections(null)}
          entityId={openLinkedCollections?.entityId}
          entityType={openLinkedCollections?.entityType}
        />
      )}
    </div>
  );
}

export default withAuth(App);
