import { ChangeEvent, FC, useState } from 'react';
import { Outlet, NavLink, useNavigate, NavLinkProps } from 'react-router-dom';
import { useDocumentTitle } from '@uidotdev/usehooks';
import find from 'lodash/find';
import Tab from '@mui/material/Tab/Tab';
import TabContext from '@mui/lab/TabContext/TabContext';
import TabList from '@mui/lab/TabList/TabList';
import { Permissions } from 'types/commonTypes';
import { PaymentsRoutes, PaymentsSettingsRoutes } from 'routes/enum';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import useRouteMatch from 'hooks/useRouteMatch';
import Typography from 'components/shared/Typography/Typography';
import withAuth from 'components/Authorization/withAuth';
import './index.scss';

enum PaymentsSettingsTabs {
  TARIFFS = 'Tariffs',
  PAYMENT_FEES = 'Payment Fees',
  IN_APP_TIERS = 'In App Tiers',
  TAXES = 'Taxes',
}

type SettingsTabsCollectionType = {
  label: PaymentsSettingsTabs;
  value: PaymentsSettingsRoutes;
  to: PaymentsSettingsRoutes;
  className: string;
  component: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>;
  disabled: boolean;
};

const PaymentsSettings: FC = () => {
  const navigate = useNavigate();
  const userPermissions = useAppSelector(selectUserPermissions);

  const routeMatch = useRouteMatch(Object.values(PaymentsSettingsRoutes));
  const initialState =
    (routeMatch?.pattern?.path as string) === PaymentsSettingsRoutes.TARIFFS_DETAILS
      ? PaymentsSettingsRoutes.TARIFFS
      : (routeMatch?.pattern?.path as string) || PaymentsSettingsRoutes.TARIFFS;

  const [selectedTab, setSelectedTab] = useState(initialState);

  const handleTabChange = (event: ChangeEvent<{}>, value: PaymentsSettingsRoutes) => {
    navigate(value);
    setSelectedTab(value);
  };

  const settingsTabsCollection: SettingsTabsCollectionType[] = [
    {
      label: PaymentsSettingsTabs.TARIFFS,
      value: PaymentsSettingsRoutes.TARIFFS,
      to: PaymentsSettingsRoutes.TARIFFS,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_PAGE_TARIFF_GROUPS]),
    },
    {
      label: PaymentsSettingsTabs.PAYMENT_FEES,
      value: PaymentsSettingsRoutes.PAYMENT_FEES,
      to: PaymentsSettingsRoutes.PAYMENT_FEES,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_PAYMENT_FEES]),
    },
    {
      label: PaymentsSettingsTabs.IN_APP_TIERS,
      value: PaymentsSettingsRoutes.IN_APP_TIERS,
      to: PaymentsSettingsRoutes.IN_APP_TIERS,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.SETTINGS_VIEW_IN_APP_TIERS]),
    },
    {
      label: PaymentsSettingsTabs.TAXES,
      value: PaymentsSettingsRoutes.TAXES,
      to: PaymentsSettingsRoutes.TAXES,
      className: 'settings-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [
        Permissions.SETTINGS_VIEW_TAXES_CODES,
        Permissions.SETTINGS_VIEW_TAXES_RULES,
      ]),
    },
  ];

  useDocumentTitle(
    `Settings - ${(find(settingsTabsCollection, { to: selectedTab }) as SettingsTabsCollectionType | undefined)
      ?.label}`,
  );

  return (
    <div className="settings-container">
      <Typography variant="h6">Settings</Typography>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange} value={selectedTab} className="settings-tabs">
          {settingsTabsCollection.map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              value={tab.value}
              className={tab.className}
              component={tab.component}
              to={tab.to}
              disabled={tab?.disabled}
            />
          ))}
        </TabList>
      </TabContext>
      <Outlet />
    </div>
  );
};

export default withAuth(PaymentsSettings);
