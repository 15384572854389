import { useEffect, useMemo, useState } from 'react';
import colors from 'theme/constants/colors';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectSortingColumns, selectSortingData, selectSortingStatus, setSortingData } from 'store/sortingSlice';
import ArrowDownIcon from 'assets/img/ArrowDownIcon';
import ArrowUpIcon from 'assets/img/ArrowUpIcon';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Button/Button';
import Chip from 'components/shared/Chip/Chip';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Divider from 'components/shared/Divider/Divider';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import Menu from 'components/shared/Menu/Menu';
import Radio from 'components/shared/Radio/Radio';
import RadioGroup from 'components/shared/RadioGroup/RadioGroup';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface ISortingChipProps {
  disabled?: boolean;
  defaultSortingModel?: any;
  onSortingModelApply: () => void;
}

const SortingChip = ({ disabled, defaultSortingModel, onSortingModelApply }: ISortingChipProps) => {
  const dispatch = useAppDispatch();

  const sortingColumns = useAppSelector(selectSortingColumns);
  const sortingData = useAppSelector(selectSortingData);
  const sortingStatus = useAppSelector(selectSortingStatus);
  let isLoading = sortingStatus === 'loading';

  const [searchValue, setSearchValue] = useState('');
  const [sortingModel, setSortingModel] = useState({
    columnId: (defaultSortingModel?.columnId || sortingData[0]?.columnId) ?? '',
    title: (defaultSortingModel?.title || sortingData[0]?.title) ?? '',
    sortingType: (defaultSortingModel?.sortingType || sortingData[0]?.sortingType) ?? '',
  });
  const [sortingMenuAnchorEl, setSortingMenuAnchorEl] = useState<null | HTMLElement>(null);
  const sortingMenuOpen = Boolean(sortingMenuAnchorEl);

  const isShowSearch = sortingColumns.length > 20;

  const filteredColumns = useMemo(
    () => sortingColumns.filter((column) => column.title.toLowerCase().includes(searchValue.toLowerCase())),
    [sortingColumns, searchValue],
  );

  const handleSortingMenuClose = () => {
    setSortingMenuAnchorEl(null);
  };

  const handleApplySorting = () => {
    handleSortingMenuClose();
    dispatch(setSortingData([sortingModel]));
    handleSortingMenuClose();
    onSortingModelApply();
  };

  useEffect(() => {
    if (sortingData.length !== 0) {
      setSortingModel(sortingData[0]);
    }
  }, [sortingData]);

  const getLabel = (columnId: string, ascending: boolean) => {
    let label = ascending ? 'A-Z' : 'Z-A';
    const lowercaseColumnId = columnId.toLowerCase();

    if (lowercaseColumnId.includes('date') || lowercaseColumnId.includes('created')) {
      label = ascending ? 'Oldest first' : 'Newest first';
    } else if (
      lowercaseColumnId.includes('price') ||
      lowercaseColumnId.includes('amount') ||
      lowercaseColumnId.includes('total') ||
      lowercaseColumnId.includes('fee')
    ) {
      label = ascending ? 'Lowest first' : 'Highest first';
    }

    return <Typography variant="body1">{label}</Typography>;
  };

  return (
    <>
      <Chip
        disabled={disabled}
        variant="outlined"
        size="small"
        className="sorting-chip"
        sx={{
          '& .MuiChip-deleteIcon:hover': {
            color: colors.primary,
          },
          '& .MuiChip-label': {
            padding: '0 6px 0 4px',
          },
        }}
        icon={
          <>
            {sortingModel.sortingType === 'DESC' ? (
              <ArrowDownIcon sx={{ fontSize: 16, paddingLeft: 0.5, color: colors.primary }} />
            ) : (
              <ArrowUpIcon sx={{ fontSize: 16, paddingLeft: 0.5, color: colors.primary }} />
            )}
            <Typography p={'0 4px'} variant="chip" color={colors.textSecondary}>
              Sort by
            </Typography>
            <Typography variant="chip" color={colors.primary}>
              |
            </Typography>
          </>
        }
        label={sortingModel.title}
        deleteIcon={
          sortingMenuOpen ? (
            <ChevronUpIcon
              sx={{
                fontSize: 18,
              }}
            />
          ) : (
            <ChevronDownIcon
              sx={{
                fontSize: 18,
              }}
            />
          )
        }
        onDelete={() => {}}
        onClick={(event) => setSortingMenuAnchorEl(event.currentTarget)}
      />
      <Menu
        id="sorting-menu"
        anchorEl={sortingMenuAnchorEl}
        open={sortingMenuOpen}
        onClose={handleSortingMenuClose}
        PaperProps={{ sx: { mt: '4px' } }}
      >
        {isShowSearch && (
          <>
            <TextField
              size="small"
              sx={{ p: '0 8px 8px 8px' }}
              placeholder="Search"
              value={searchValue}
              helperText={filteredColumns.length === 0 && searchValue !== '' && 'No matches here'}
              onChange={(e) => setSearchValue(e.target.value)}
              error={filteredColumns.length === 0 && searchValue !== ''}
              inputProps={{ maxLength: 50 }}
            />
            <Divider flexItem orientation="horizontal" />
          </>
        )}
        <RadioGroup row={false} sx={{ flexWrap: 'nowrap', p: '8px 12px', maxHeight: '258px', overflowY: 'auto' }}>
          <Typography color="text.secondary" variant="body2">
            Column
          </Typography>
          {isLoading && <CircularProgress size={24} sx={{ m: '8px auto' }} />}
          {filteredColumns.map((item: any) => {
            const parts = item.title.split(new RegExp(`(${searchValue})`, 'gi'));

            return (
              <FormControlLabel
                sx={{ ml: 'unset' }}
                key={item.columnId}
                value={item.columnId}
                control={<Radio key={item.columnId} size="small" checked={sortingModel.columnId === item.columnId} />}
                label={
                  <Typography variant="body2">
                    {parts.map((part: string, index: number) => (
                      <span
                        key={index}
                        style={{ fontWeight: part.toLowerCase() === searchValue.toLowerCase() ? 700 : 400 }}
                      >
                        {part}
                      </span>
                    ))}
                  </Typography>
                }
                onClick={() =>
                  setSortingModel((prevState) => {
                    return {
                      ...prevState,
                      columnId: item.columnId,
                      title: item.title,
                    };
                  })
                }
              />
            );
          })}
        </RadioGroup>
        <Divider flexItem orientation="horizontal" />
        <RadioGroup sx={{ p: '8px 12px' }}>
          <Typography color="text.secondary" variant="body2">
            Direction
          </Typography>
          <FormControlLabel
            sx={{ ml: 'unset' }}
            value="ASC"
            control={<Radio size="small" checked={sortingModel.sortingType === 'ASC'} />}
            label={getLabel(sortingModel.columnId, true)}
            onClick={() =>
              setSortingModel((prevState) => {
                return {
                  ...prevState,
                  sortingType: 'ASC',
                };
              })
            }
          />
          <FormControlLabel
            sx={{ ml: 'unset' }}
            value="DESC"
            control={<Radio size="small" checked={sortingModel.sortingType === 'DESC'} />}
            label={getLabel(sortingModel.columnId, false)}
            onClick={() =>
              setSortingModel((prevState) => {
                return {
                  ...prevState,
                  sortingType: 'DESC',
                };
              })
            }
          />
        </RadioGroup>
        <Divider flexItem orientation="horizontal" />
        <Box p={'12px 12px 4px 12px'}>
          <Button sx={{ width: 1 }} size="small" variant="contained" onClick={handleApplySorting}>
            Apply
          </Button>
        </Box>
      </Menu>
    </>
  );
};

export default SortingChip;
