import {
  GridFooterContainer,
  gridPageCountSelector,
  gridPageSelector,
  gridPageSizeSelector,
  GridPagination,
  useGridSelector,
} from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import MuiPagination from '@mui/material/Pagination';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

const Empty = () => {
  return <></>;
};

const CustomFooter = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowsPerPage = useGridSelector(apiRef, gridPageSizeSelector);

  const setPage = (value: number) => {
    apiRef.current.setPage(value);
  };

  const setPageSize = (value: number) => {
    apiRef.current.setPageSize(value);
  };

  return pageCount !== 0 ? (
    <GridFooterContainer className="customFooter">
      <Typography variant="caption" sx={{ flex: '0 1 30%' }}>
        Page {page + 1} out of {pageCount}
      </Typography>
      <MuiPagination
        page={page + 1}
        count={pageCount}
        defaultPage={1}
        onChange={(event, value) => setPage(value - 1)}
        sx={{ flex: '0 1 auto', display: 'flex', justifyContent: 'center' }}
      />
      <GridPagination
        count={apiRef.current.getRowsCount()}
        page={page}
        rowsPerPage={rowsPerPage}
        SelectProps={{
          MenuProps: {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'center',
            },
            transformOrigin: {
              vertical: 'bottom',
              horizontal: 'center',
            },
          },
        }}
        onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value))}
        onPageChange={() => {}}
        ActionsComponent={Empty}
        sx={{ flex: '0 1 30%' }}
      />
    </GridFooterContainer>
  ) : (
    <></>
  );
};

export default CustomFooter;
