import React, { MouseEvent, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import { enqueueSnackbar, VariantType } from 'notistack';
import { possibleTaxStatuses } from '../constants';
import { ITaxCode, ITaxRule, ITaxStatus, TaxesStatuses } from 'store/slices/Settings/interface';
import { fetchSalesTaxCodes } from 'store/slices/Settings/dictionariesSlice';
import { fetchChangeTaxStatus, fetchChangeTaxCodeStatus, fetchTaxRules } from 'store/slices/Settings/taxesSlice';
import { useAppDispatch } from 'store/hooks';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Button/Button';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IChangeStatusConfirmModalProps {
  data: (ITaxCode & ITaxRule) | null;
  open: boolean;
  onClose: () => void;
  page: number;
  pageSize: number;
}

export const ChangeStatusConfirmModal = ({ data, open, onClose, page, pageSize }: IChangeStatusConfirmModalProps) => {
  const dispatch = useAppDispatch();

  const salesTaxCode: Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'> | undefined =
    data?.salesTaxCode;

  const [statusMenuAnchorEl, setStatusMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<ITaxStatus | null>(null);
  const statusMenuOpen = Boolean(statusMenuAnchorEl);

  const handleStatusMenuClose = () => {
    setStatusMenuAnchorEl(null);
  };

  const handleChangeStatusClick = async (salesTaxCode: ITaxStatus) => {
    setCurrentSelectedStatus(salesTaxCode);
    handleStatusMenuClose();
    const result = await dispatch(
      fetchChangeTaxCodeStatus({
        salesTaxCode: data?.salesTaxCode ? data.salesTaxCode : null,
        currentSelectedStatus: salesTaxCode,
      }),
    );

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Status of sales tax code has been changed to "${salesTaxCode?.title}"`, {
        variant: 'success' as VariantType,
      });
      dispatch(fetchSalesTaxCodes());
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred. Try later`, { variant: 'error' as VariantType });
    }
  };

  const handleStatusMenuClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    setStatusMenuAnchorEl(event.currentTarget);
  };

  const handleConfirm = async () => {
    const result = await dispatch(
      fetchChangeTaxStatus({
        currentSelectedRow: data,
        currentSelectedStatus: currentSelectedStatus,
      }),
    );

    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Status of rule has been changed to "${currentSelectedStatus?.title}"`, {
        variant: 'success' as VariantType,
      });
      dispatch(fetchSalesTaxCodes());
      dispatch(fetchTaxRules({ page, pageSize }));
      onClose();
    } else {
      enqueueSnackbar(result.payload.title ?? `An error occurred. Try later`, { variant: 'error' as VariantType });
    }
  };

  useEffect(() => {
    const currentStatus = possibleTaxStatuses.find(
      (el) => salesTaxCode?.status?.toLowerCase() === el.status.toLowerCase(),
    );

    currentStatus && setCurrentSelectedStatus(currentStatus);
  }, [data, salesTaxCode?.status]);

  return (
    <Dialog fullWidth maxWidth="xs" open={open}>
      <DialogTitle variant="h6">You are going to change the status of the sales tax rule to active</DialogTitle>
      <DialogContent>
        <Typography variant="body1">
          It is impossible to change the status because the status of the tax code is "Inactive" on the "sales tax
          codes" page. Change the status of the tax code directly in this window, and you can change the status of the
          rule.
        </Typography>
        <Stack spacing={2} mt={2} direction="row">
          <Stack width="70%">
            <Typography mb={1} variant="tableHeader">
              Tax code
            </Typography>
            <Typography variant="body1">{salesTaxCode?.category}</Typography>
            <Typography variant="body1">{salesTaxCode?.code}</Typography>
          </Stack>

          <Stack>
            <Typography mb={1} variant="tableHeader">
              Status
            </Typography>
            <Box
              component="div"
              className="confirmation-modal__status-select"
              sx={{ color: currentSelectedStatus?.color }}
              onClick={(e) => handleStatusMenuClick(e)}
            >
              <Typography variant="body2" m="auto 0" color={currentSelectedStatus?.color}>
                {currentSelectedStatus?.title}
              </Typography>
              {currentSelectedStatus?.status === salesTaxCode?.status && statusMenuOpen ? (
                <ChevronUpIcon />
              ) : (
                <ChevronDownIcon />
              )}
            </Box>

            <Menu
              id="status-menu"
              anchorEl={statusMenuAnchorEl}
              open={statusMenuOpen}
              onClose={handleStatusMenuClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              {possibleTaxStatuses.map((status: ITaxStatus) => (
                <MenuItem
                  key={uniqueId()}
                  selected={status.status === currentSelectedStatus?.status}
                  onClick={() => handleChangeStatusClick(status)}
                >
                  {status.title}
                </MenuItem>
              ))}
            </Menu>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleConfirm}
          disabled={currentSelectedStatus?.status === TaxesStatuses.INACTIVE}
          variant="contained"
          color="primary"
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
