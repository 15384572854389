import React, { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useErrorBoundary } from 'react-error-boundary';
import withAuth from "components/Authorization/withAuth";
import { ErrorBoundaryErrors } from "containers/ErrorPage/ErrorPage";
import { useParams, useNavigate } from "react-router-dom";
import BannerDetailsCloseModal
  from "components/WorldManagement/Banners/BannerDetails/BannerDetailsModal/BannerDetailsCloseModal";
import Modal from "components/shared/Modal/Modal";
import { fetchCollection, selectCollectionDetails, selectCollectionStatus } from "store/collectionDetailsSlice";
import { fetchAllCohorts, selectAllCohorts } from "store/worldManagementSlice";
import CollectionDetailsForm from "components/WorldManagement/Collections/CollectionDetails/CollectionDetailsForm";

import './index.scss';

function App() {
  const [isOpenClosingModal, setIsOpenClosingModal] = useState(false);
  const [scrollTop, setScrollTop] = useState(0);

  const collectionDetails = useAppSelector(selectCollectionDetails);
  const status = useAppSelector(selectCollectionStatus);
  const allCohorts = useAppSelector(selectAllCohorts);

  let { collectionId } = useParams();

  const { showBoundary } = useErrorBoundary();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const initialFetchData = useCallback(async () => {
    // Promise.all([
      collectionId && dispatch(fetchCollection(collectionId));
      dispatch(fetchAllCohorts());
    // ]).then(result => {
    //   result.forEach((value: any) => {
    //     if (value.meta && value.meta.requestStatus === 'rejected' && ErrorBoundaryErrors.includes(value.error.message)) {
    //       showBoundary( new Error(value.error.message));
    //     }
    //   })
    // });
  }, [collectionId]);

  const handleScroll = (event: any) => {
    setScrollTop(event.target.scrollTop);
  };

  useEffect(() => {
    initialFetchData();
  }, []);

  return (
    <div className="collectionDetails" onScroll={handleScroll}>
      {collectionDetails && status === 'idle' &&
        <CollectionDetailsForm
          collectionDetails={collectionDetails}
          allCohorts={allCohorts}
          setIsOpenClosingModal={setIsOpenClosingModal}
          scrollTop={scrollTop}
        />
      }
      {/* todo почему то я решила использовать баннерную модалку и туть :)
          вот ее надо открывать и при попытке уйти со страницы, а не просто по клику на кнопку "назад"
       */}
      <Modal customstyle={{ minHeight: 188 }} open={isOpenClosingModal}>
        <BannerDetailsCloseModal
          closeModal={() => setIsOpenClosingModal(false)}
          onClose={() => navigate("/ui/world-management/collections")}
        />
      </Modal>
    </div>
  );
}

export default withAuth(App);
