import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { IStatus } from 'types/commonTypes';
import { RootState } from './store';
import ContentDisplayTabs from 'components/ContentDisplay/types/ContentDisplayTabs';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';

export interface SortingState {
  data: any[];
  presets: any[];
  columns: any[];
  activePresetId: string | null;
  status: IStatus;
}

const initialState: SortingState = {
  data: [],
  presets: [],
  columns: [],
  activePresetId: null,
  status: 'idle',
};

export const fetchSortingColumns = createAsyncThunk('sorting/fetchColumns', async (type: string) => {
  let columnsUrl;
  switch (type) {
    case ModerationTabs.HIVE: {
      columnsUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/hive/sorting`;
      break;
    }
    case ModerationTabs.CONTENT_COMPLAINTS: {
      columnsUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/content/sorting`;
      break;
    }
    case ModerationTabs.PROFILE_COMPLAINTS: {
      columnsUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/profile/sorting`;
      break;
    }
    case ModerationTabs.CONFIRMED_VIOLATIONS:
    case ModerationTabs.DECLINED_REQUESTS: {
      columnsUrl = process.env.REACT_APP_MODERATION_SCREEN_ENDPOINT + `moderation/screen/history/sorting`;
      break;
    }
    case 'members': {
      columnsUrl = process.env.REACT_APP_MEMBERS_ENDPOINT + 'members/screen/sorting';
      break;
    }
    case 'tax-moderation': {
      columnsUrl = process.env.REACT_APP_TAXES_ENDPOINT + 'sales/tax/screen/sorting';
      break;
    }
    case 'tiers': {
      columnsUrl = process.env.REACT_APP_PAYMENTS_ENDPOINT + 'screen/sorting?belongsToKind=tier_find';
      break;
    }
    case 'operations': {
      columnsUrl = process.env.REACT_APP_OPERATIONS_ENDPOINT + 'screen/sorting?belongsToKinds=operation_find';
      break;
    }
    case 'consumption-requests': {
      columnsUrl = process.env.REACT_APP_OPERATIONS_ENDPOINT + 'screen/sorting?kind=consumption_requests_screen';
      break;
    }
    case ContentDisplayTabs.EVENTS:
    case ContentDisplayTabs.OFFERS:
    case ContentDisplayTabs.WISHES:
    case ContentDisplayTabs.LIFESTYLES: {
      columnsUrl = process.env.REACT_APP_CONTENT_DISPLAY_ENDPOINT + `content/screen/${type}/sorting`;
      break;
    }
    case 'users':
    default: {
      columnsUrl = process.env.REACT_APP_USERS_ENDPOINT + 'timusers/presets/columns';
    }
  }
  const response = await axios.get(columnsUrl, {
    // headers: {
    //   'Member-Id': '0'
    // }
  });
  return { type: type, columns: response.data.columns ?? response.data.sortingList };
});

export const fetchSortingPresets = createAsyncThunk('sorting/fetchPresets', async (type: string) => {
  let presetsUrl;
  switch (type) {
    case 'users':
    default: {
      presetsUrl = 'timusers/presets?presetType=SORTING';
    }
  }
  const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + presetsUrl, {
    headers: {
      'Member-Id': '0',
    },
  });

  return { presets: response.data.presets };
});

export const setActivePreset = createAsyncThunk(
  'sorting/setActivePreset',
  async ({ type, presetId }: { type: string; presetId: string }, thunkAPI) => {
    let presetUrl;
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    const columns = rootState.sorting.columns;
    switch (type) {
      case 'users':
      default: {
        presetUrl = `timusers/presets/sorting/${presetId}`;
      }
    }
    const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + presetUrl, {
      headers: {
        'Member-Id': '0',
      },
    });

    return { columns: columns, sortingList: response.data.sortingList, presetId: presetId };
  },
);

export const savePreset = createAsyncThunk(
  'sorting/savePreset',
  async ({ type, presetName }: { type: string; presetName: string }, thunkAPI) => {
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    const sorting = Object.keys(rootState.sorting.data)
      ? Object.values(rootState.sorting.data).filter((item: any) => item !== null)
      : [];
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = 'timusers/presets?presetType=SORTING';
      }
    }
    const response = await axios.post(
      process.env.REACT_APP_USERS_ENDPOINT + presetsUrl,
      {
        presetName: presetName,
        presetType: 'SORTING',
        sortingList: sorting,
      },
      {
        headers: {
          'Member-Id': '0',
        },
      },
    );

    return { presets: response.data.presets };
  },
);

export const deletePreset = createAsyncThunk(
  'sorting/deletePreset',
  async ({ type, presetId }: { type: string; presetId: string }) => {
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = `timusers/presets/${presetId}`;
      }
    }
    const response = await axios.delete(process.env.REACT_APP_USERS_ENDPOINT + presetsUrl, {
      headers: {
        'Member-Id': '0',
      },
    });

    return { presets: response.data.presets };
  },
);

export const sortingSlice = createSlice({
  name: 'sorting',
  initialState,
  reducers: {
    clearSorting: (state) => {
      state.columns = [];
    },
    clearSortingData: (state) => {
      state.data = [];
    },
    setSortingData: (state, action) => {
      state.data = action.payload;
    },
    addLevel: (state) => {
      state.data.push({ sortingType: 'ASC', columnId: null, label: '', id: '' });
    },
    removeLevel: (state, action) => {
      state.data.splice(action.payload.index, 1);
    },
    changeSortingField: (state, action) => {
      state.data[action.payload.index] = {
        sortingType: state.data[action.payload.index].sortingType,
        ...action.payload.newValue,
        columnId: action.payload.newValue.id,
      };
    },
    removeSortingField: (state, action) => {
      state.data.splice(action.payload, 1);
    },
    setNewSortingArray: (state, action) => {
      state.data = action.payload;
    },
    changeSortingType: (state, action) => {
      state.data[action.payload.index].sortingType = action.payload.sortingType;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSortingColumns.pending, (state) => {
        state.status = 'loading';
        state.data = [];
      })
      .addCase(fetchSortingColumns.fulfilled, (state, action) => {
        state.status = 'idle';
        state.columns = action.payload.columns;
        if (action.payload.type === 'members') {
          const nameColumn = action.payload.columns.find((el: any) => el.columnId === 'full_name');
          state.data = [{ columnId: nameColumn.columnId, title: nameColumn.title, sortingType: 'ASC' }];
        }
        if (action.payload.type === ModerationTabs.HIVE) {
          const nameColumn = action.payload.columns.find((el: any) => el.columnId === 'hive_task_created_date');
          state.data = [{ columnId: nameColumn.columnId, title: nameColumn.title, sortingType: 'ASC' }];
        }
        if (action.payload.type === ModerationTabs.PROFILE_COMPLAINTS) {
          const nameColumn = action.payload.columns.find((el: any) => el.columnId === 'complaint_task_created_date');
          state.data = [{ columnId: nameColumn.columnId, title: nameColumn.title, sortingType: 'ASC' }];
        }
        if (action.payload.type === ModerationTabs.CONTENT_COMPLAINTS) {
          const nameColumn = action.payload.columns.find((el: any) => el.columnId === 'complaint_task_created_date');
          state.data = [{ columnId: nameColumn.columnId, title: nameColumn.title, sortingType: 'ASC' }];
        }
      })
      .addCase(fetchSortingColumns.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchSortingPresets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchSortingPresets.fulfilled, (state, action) => {
        state.status = 'idle';
        state.presets = action.payload.presets;
      })
      .addCase(fetchSortingPresets.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: any[] = [];
        action.payload.sortingList.forEach((item: any) => {
          const obj = { ...action.payload.columns.find((col: any) => col.columnName === item.columnId) };
          obj.sortingType = item.sortingType;
          obj.label = obj.title;
          obj.id = obj.columnName;
          newData.push(obj);
          console.log(obj);
        });
        state.data = newData;
        state.activePresetId = action.payload.presetId;
      })
      .addCase(setActivePreset.rejected, (state) => {});
  },
});

export const {
  clearSorting,
  clearSortingData,
  setSortingData,
  addLevel,
  removeLevel,
  changeSortingField,
  setNewSortingArray,
  changeSortingType,
  removeSortingField,
} = sortingSlice.actions;

export const selectSortingColumns = (state: RootState): any[] => state.sorting.columns;
export const selectSortingData = (state: RootState): any[] => state.sorting.data;
export const selectSortingStatus = (state: RootState): IStatus => state.sorting.status;

export default sortingSlice.reducer;
