import { ChangeEvent, forwardRef, Key, ReactNode, useEffect, useState } from 'react';
import { Fade, Paper, Popper, useAutocomplete } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import isEmpty from 'lodash/isEmpty';
import uniqueId from 'lodash/uniqueId';
import { useDebounce } from '@uidotdev/usehooks';
import { TaxModerationRoutes } from 'routes/enum';
import { ITaxModerationSearchItem } from 'store/slices/TaxModeration/interface';
import {
  clearTaxModerationSearch,
  fetchTaxModerationSearch,
  selectTaxModerationSearchAllResults,
  selectTaxModerationSearchResults,
} from 'store/slices/TaxModeration/taxModerationSlice';
import { clearFilters, removeFilter, selectFiltersCurrentData, setCustomFilterValue } from 'store/filtersSlice';
import { clearOperationsSearch } from 'store/slices/Payments/operationsSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import Divider from 'components/shared/Divider/Divider';
import List from 'components/shared/List/List';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemButton from 'components/shared/List/ListItem/ListItemComponents/ListItemButton/ListItemButton';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import SearchField from 'components/shared/SearchField/SearchField';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface ITaxModerationSearchProps {
  refreshData?: () => Promise<void>;
  clearFilter?: () => Promise<void>;
  listType: string;
}

export const TaxModerationSearch = ({ refreshData, clearFilter, listType }: ITaxModerationSearchProps) => {
  const dispatch = useAppDispatch();
  const { id: cardId } = useParams();
  const navigate = useNavigate();

  const SEARCH_FILTER = {
    type: 'EQUALS',
    valueType: 'STRING',
    columnId: 'entity_name',
    title: 'Sales tax moderation search by entity name',
    element: {
      value: '',
      titles: [''],
    },
  };

  const SEARCH_FILTER_ALL = {
    title: 'Sales tax moderation search by part of entity name',
    type: 'EQUALS',
    customImplementation: true,
    valueType: 'STRING',
    columnId: 'sales_tax_moderation_search_by_part_of_entity_name',
    element: {
      value: '',
      titles: [''],
    },
  };

  const taxModerationSearchResults = useAppSelector(selectTaxModerationSearchResults);
  const taxModerationSearchAllResults = useAppSelector(selectTaxModerationSearchAllResults);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [searchValue, setSearchValue] = useState('');
  const [isLoadingSearch, setIsLoadingSearch] = useState(false);
  const [isSearchError, setIsSearchError] = useState(false);
  const debouncedSearchValue = useDebounce(searchValue.trim(), 1000);
  const [showLoader, setShowLoader] = useState(false);
  let loaderTimeout: NodeJS.Timeout;

  const { inputValue, getRootProps, getInputProps, getListboxProps, getOptionProps } = useAutocomplete({
    id: 'search-autocomplete',
    options: taxModerationSearchResults ? taxModerationSearchResults : [],
    inputValue: searchValue,
    getOptionLabel: (option) => option.sameTitle,
    disableCloseOnSelect: true,
  });

  const hasFiltersToClear =
    refreshData &&
    (!isEmpty(filtersCurrentData.sales_tax_moderation_search_by_part_of_entity_name) ||
      !isEmpty(filtersCurrentData.entity_name));

  const handleChangeSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value;
    setSearchValue(inputValue);

    if (event.target.value.length === 0 && taxModerationSearchResults.length !== 0) {
      dispatch(clearTaxModerationSearch());
    }

    if (inputValue.length === 0 && hasFiltersToClear) {
      dispatch(clearOperationsSearch());
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_search_by_part_of_entity_name' }));
      dispatch(removeFilter({ columnId: 'entity_name' }));
      void refreshData();
    }
  };

  const handleClearEvent = () => {
    setSearchValue('');
    dispatch(clearTaxModerationSearch());

    if (hasFiltersToClear) {
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_search_by_part_of_entity_name' }));
      dispatch(removeFilter({ columnId: 'entity_name' }));
      void refreshData();
    }
  };

  const handleSearchEvent = async () => {
    setShowLoader(false);
    setIsLoadingSearch(true);

    try {
      const result = await dispatch(
        fetchTaxModerationSearch({
          searchValue: debouncedSearchValue,
          listType: listType,
        }),
      );

      if (result.meta.requestStatus === 'fulfilled') {
        setIsSearchError(false);
        setIsLoadingSearch(false);
        clearTimeout(loaderTimeout);
        setShowLoader(false);
      } else if (result.meta.requestStatus === 'rejected') {
        setIsSearchError(true);
        setIsLoadingSearch(false);
        clearTimeout(loaderTimeout);
        setShowLoader(false);
      }
    } catch (error) {
      setIsSearchError(true);
      setIsLoadingSearch(false);
      clearTimeout(loaderTimeout);
      setShowLoader(false);
    }
  };

  const handleListItemButtonClick = (option: ITaxModerationSearchItem) => {
    setSearchValue(option.sameTitle);
    setAnchorEl(null);

    SEARCH_FILTER.element.value = option.sameTitle;
    SEARCH_FILTER.element.titles = option.ids.map((id) => id);
    dispatch(clearFilters());
    dispatch(setCustomFilterValue(SEARCH_FILTER));

    if (cardId) {
      navigate(TaxModerationRoutes.TAX_MODERATION);
    }
    if (refreshData) {
      void refreshData();
    }
  };

  const handleAllResultsClick = () => {
    setSearchValue(searchValue);
    setAnchorEl(null);

    if (taxModerationSearchAllResults.length) {
      SEARCH_FILTER_ALL.element.value = searchValue;
      SEARCH_FILTER_ALL.element.titles = taxModerationSearchAllResults;
    }
    dispatch(clearFilters());
    dispatch(setCustomFilterValue(SEARCH_FILTER_ALL));

    if (cardId) {
      navigate(TaxModerationRoutes.TAX_MODERATION);
    }
    if (refreshData) {
      void refreshData();
    }
  };

  const renderOptions = (option: ITaxModerationSearchItem, index: number): ReactNode => {
    const matches = match(option.sameTitle, inputValue, { insideWords: true });
    const parts = parse(option.sameTitle, matches);

    return (
      <ListItem {...getOptionProps({ option, index })} key={uniqueId()} disablePadding>
        <ListItemButton onClick={() => handleListItemButtonClick(option)}>
          <ListItemText
            disableTypography
            primary={
              <Typography
                variant="body2"
                sx={{
                  wordBreak: 'break-word',
                }}
              >
                {parts.map((part, index: Key) => (
                  <span style={{ fontWeight: part.highlight ? 700 : 400 }} key={index}>
                    {part.text}
                  </span>
                ))}
                {option.ids.length > 1 ? (
                  <span style={{ fontWeight: 700 }} key={index}>
                    {` (${option.ids.length})`}
                  </span>
                ) : (
                  ''
                )}
              </Typography>
            }
          />
        </ListItemButton>
      </ListItem>
    );
  };

  const renderContent = () => {
    if (isSearchError) {
      return (
        <>
          <ListItem key={uniqueId()} disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <Typography
                    variant="body2Bold"
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {inputValue}{' '}
                    {taxModerationSearchAllResults.length && taxModerationSearchAllResults.length > 0
                      ? `(${taxModerationSearchAllResults.length})`
                      : ''}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" variant="caption">
                    All results
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />

          <Stack pt={1} alignItems="center" spacing={1}>
            <Typography color="error" variant="body2">
              Loading results error
            </Typography>
            <LoadingButton
              loadingPosition="start"
              startIcon={<></>}
              loading={isLoadingSearch}
              onClick={handleSearchEvent}
              variant="text"
              color="primary"
              sx={{ minWidth: 105, p: 0 }}
            >
              Retry
            </LoadingButton>
          </Stack>
        </>
      );
    }

    if (showLoader) {
      return (
        <Stack p="8px 0" alignItems="center">
          <CircularProgress sx={{ margin: 'auto' }} size={24} color="primary" />
        </Stack>
      );
    }

    if (inputValue && taxModerationSearchResults && taxModerationSearchResults.length > 0) {
      return (
        <>
          <ListItem key={uniqueId()} disablePadding>
            <ListItemButton onClick={handleAllResultsClick}>
              <ListItemText
                sx={{ m: 0 }}
                primary={
                  <Typography
                    variant="body2Bold"
                    sx={{
                      wordBreak: 'break-all',
                    }}
                  >
                    {`${inputValue} (${taxModerationSearchAllResults.length})`}
                  </Typography>
                }
                secondary={
                  <Typography color="text.secondary" variant="caption">
                    All results
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>

          <Divider orientation="horizontal" flexItem />

          {taxModerationSearchResults.map((result, index) => renderOptions(result, index))}
        </>
      );
    }

    // if (inputValue.length < 3) {
    //   return (
    //     <Typography p="8px 0" textAlign="center" color="text.primary" variant="body2">
    //       Enter at least 3 symbols
    //     </Typography>
    //   );
    // }

    return (
      <Stack p="8px 0" alignItems="center" gap={1}>
        <Typography color="text.primary" variant="body2">
          There are no matches.
        </Typography>
        <Typography color="text.secondary" variant="body2">
          Try a different query.
        </Typography>
      </Stack>
    );
  };

  const RenderList = forwardRef<any, {}>((_, ref) => {
    return (
      <List {...getListboxProps()} ref={ref}>
        {renderContent()}
      </List>
    );
  });

  useEffect(() => {
    if (isEmpty(filtersCurrentData)) {
      setSearchValue('');
      dispatch(clearTaxModerationSearch());
    }
  }, [filtersCurrentData]);

  useEffect(() => {
    setSearchValue('');
    dispatch(clearTaxModerationSearch());
  }, [listType]);

  useEffect(() => {
    if (debouncedSearchValue.length > 2) {
      loaderTimeout = setTimeout(() => setShowLoader(true), 1000);
      void handleSearchEvent();
    }
    return () => {
      clearTimeout(loaderTimeout);
    };
  }, [debouncedSearchValue]);

  return (
    <div id="tax-moderation-search" {...getRootProps()}>
      <SearchField
        customWidth="476px"
        searchValue={inputValue}
        onChange={handleChangeSearch}
        searchEvent={handleSearchEvent}
        clearEvent={handleClearEvent}
        onBlur={() => setAnchorEl(null)}
        onClick={(event) => setAnchorEl(event.currentTarget)}
        label="Search by name"
        maxlength={50}
        inputProps={{ ...getInputProps() }}
      />

      {inputValue.length > 2 && (
        <Popper
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          placement="bottom-start"
          sx={{ width: anchorEl?.offsetWidth || 476, borderRadius: '4px', zIndex: 1200 }}
          transition
          disablePortal
        >
          {({ TransitionProps }) => (
            <Fade {...TransitionProps} timeout={300}>
              <Paper elevation={4} sx={{ backgroundColor: 'background.paper', overflow: 'auto', maxHeight: '393px' }}>
                <RenderList />
              </Paper>
            </Fade>
          )}
        </Popper>
      )}
    </div>
  );
};
