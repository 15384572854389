import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { ModalAction } from 'types/commonTypes';
import { ITaxCode } from 'store/slices/Settings/interface';
import getValidationSchema from './utils/getValidationSchema';
import { ITaxModerationCard, TaxModerationSolution } from 'store/slices/TaxModeration/interface';
import { fetchChangeTaxModerationSolution } from 'store/slices/TaxModeration/taxModerationSlice';
import { fetchSalesTaxCodesList, selectSalesTaxCodesList } from 'store/slices/Settings/dictionariesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import Autocomplete, { renderOptions } from 'components/shared/Autocomplete/Autocomplete';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import Typography from 'components/shared/Typography/Typography';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface IChangeTaxCodeModalProps {
  currentSelectedSalesTax?: ITaxModerationCard | null;
  withApproveOption?: boolean;
  shouldKeepTaxCode?: boolean;
  open: boolean;
  onClose: (isSucceed: boolean) => void;
}

export const ChangeTaxCodeModal = ({
  currentSelectedSalesTax,
  withApproveOption,
  shouldKeepTaxCode,
  open,
  onClose,
}: IChangeTaxCodeModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(), []);
  const { id: salesTaxModerationId } = useParams();
  const salesTaxCodesList = useAppSelector(selectSalesTaxCodesList);

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);

  /** Default tax code from backend. Not presented in **salesTaxCodesList** **/
  const URESOLVED_TAX_CODE = {
    id: 'URESOLVED',
    code: 'URESOLVED',
    category: 'URESOLVED',
    description: 'URESOLVED',
  };

  const presetValue = shouldKeepTaxCode
    ? {
        values: {
          salesTaxCode: currentSelectedSalesTax?.salesTaxCode as ITaxCode,
        },
      }
    : {};

  const {
    control,
    setValue,
    reset,
    watch,
    handleSubmit,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    ...presetValue,
    defaultValues: {
      salesTaxCode: {
        id: '',
        code: '',
        category: '',
        description: '',
      } as ITaxCode,
    },
  });

  const handleClose = (isSucceed: boolean) => {
    reset();
    onClose(isSucceed);
  };

  const onSubmit = async (data: { salesTaxCode: ITaxCode }, withApprove: boolean) => {
    void trigger();

    const formattedData = {
      salesTaxModerationId: salesTaxModerationId
        ? salesTaxModerationId
        : currentSelectedSalesTax
        ? currentSelectedSalesTax.id
        : null,
      newSalesTaxCodeId: data.salesTaxCode.id ? data.salesTaxCode.id : null,
      solution: withApprove ? TaxModerationSolution.APPROVED : TaxModerationSolution.MODIFIED,
    };

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData));
      if (result.meta.requestStatus === 'fulfilled') {
        handleClose(true);
        withApprove
          ? enqueueSnackbar(`Approved`, {
              variant: 'success' as VariantType,
            })
          : enqueueSnackbar(`Changes saved`, {
              variant: 'success' as VariantType,
            });
      } else if (result.payload.meta) {
        const { title } = result.payload;
        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error' as VariantType,
      });
    }
  };

  const handleCancelClick = () => {
    if (isDirty) {
      setIsOpenConfirmCancelModal(true);
    } else {
      handleClose(false);
    }
  };

  const noApproveButtonText = withApproveOption ? 'Only change code' : 'Change';
  const isNoApproveDisabled =
    !isValid || !isDirty || watch('salesTaxCode.id') === currentSelectedSalesTax?.salesTaxCode.id;

  const approveButtonText =
    currentSelectedSalesTax?.solution === TaxModerationSolution.MODIFIED ? 'Approve' : 'Change and approve';
  const isApproveDisabled =
    currentSelectedSalesTax?.solution === TaxModerationSolution.MODIFIED
      ? !isValid
      : !isValid || !isDirty || watch('salesTaxCode.id') === currentSelectedSalesTax?.salesTaxCode.id;

  useEffect(() => {
    dispatch(fetchSalesTaxCodesList());
  }, []);

  return (
    <>
      <Dialog fullWidth maxWidth="sm" open={open}>
        <DialogTitle variant="h6">Change to another tax code</DialogTitle>
        <DialogContent>
          <SimpleGrid
            sx={{ paddingTop: 2 }}
            container
            flexDirection="column"
            minHeight="100%"
            alignContent="stretch"
            spacing={2}
          >
            <SimpleGrid item xs={12}>
              <Controller
                control={control}
                name="salesTaxCode.category"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      label="Sales tax category"
                      clearIcon={null}
                      value={
                        field.value
                          ? salesTaxCodesList.find((option) => {
                              return field.value === option.category;
                            }) ?? URESOLVED_TAX_CODE
                          : null
                      }
                      renderOption={(params, option, { inputValue }) =>
                        renderOptions(params, option.category, inputValue)
                      }
                      getOptionLabel={({ category }) => category}
                      options={salesTaxCodesList || []}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true });
                          setValue('salesTaxCode.code', '', { shouldDirty: true });
                          setValue('salesTaxCode.id', '');
                          setValue('salesTaxCode.description', '');
                          return;
                        }
                        setValue('salesTaxCode', value, { shouldDirty: true });
                        void trigger('salesTaxCode');
                      }}
                      inputprops={{
                        error: !!errors.salesTaxCode?.category,
                      }}
                      onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.category}>
                      {errors.salesTaxCode?.category?.message as string}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </SimpleGrid>

            <SimpleGrid item xs={12}>
              <Controller
                control={control}
                name="salesTaxCode.code"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      label="Sales tax code"
                      clearIcon={null}
                      value={
                        field.value
                          ? salesTaxCodesList.find((option) => {
                              return field.value === option.code;
                            }) ?? URESOLVED_TAX_CODE
                          : null
                      }
                      renderOption={(params, option, { inputValue }) => renderOptions(params, option.code, inputValue)}
                      getOptionLabel={({ code }) => code}
                      options={salesTaxCodesList || []}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true });
                          setValue('salesTaxCode.code', '', { shouldDirty: true });
                          setValue('salesTaxCode.id', '');
                          setValue('salesTaxCode.description', '');
                          return;
                        }
                        setValue('salesTaxCode', value, { shouldDirty: true });
                        void trigger('salesTaxCode');
                      }}
                      inputprops={{
                        error: !!errors.salesTaxCode?.code,
                      }}
                      onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.code}>
                      {errors.salesTaxCode?.code?.message as string}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </SimpleGrid>
            {watch('salesTaxCode.description') && (
              <SimpleGrid item xs={12} gap={1}>
                <Typography variant="subtitle1">Description</Typography>
                <Typography variant="body2">{watch('salesTaxCode.description')}</Typography>
              </SimpleGrid>
            )}
          </SimpleGrid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelClick} variant="text" color="primary">
            Cancel
          </Button>
          <LoadingButton
            name="no_approve_btn"
            disabled={isNoApproveDisabled}
            variant={withApproveOption ? 'outlined' : 'contained'}
            color="primary"
            onClick={handleSubmit((data) => onSubmit(data, false))}
          >
            {noApproveButtonText}
          </LoadingButton>
          {withApproveOption && (
            <LoadingButton
              name="approve_btn"
              disabled={isApproveDisabled}
              variant="contained"
              color="primary"
              onClick={handleSubmit((data) => onSubmit(data, true))}
            >
              {approveButtonText}
            </LoadingButton>
          )}
        </DialogActions>
      </Dialog>
      <ConfirmCancelModal
        action={ModalAction.EDIT}
        actionName="tax code"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={() => handleClose(false)}
      />
    </>
  );
};
