import { useCallback } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { OperationActions } from 'store/slices/Payments/interface';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchOperationAction, selectOperationActionStatus } from 'store/slices/Payments/operationsSlice';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Typography from 'components/shared/Typography/Typography';

interface ISuspensionConfirmModalProps {
  open: boolean;
  operationId: string;
  isOperationSuspended: boolean;
  onClose: () => void;
  onDataRefresh: () => void;
}

export const SuspensionConfirmModal = ({
  open,
  operationId,
  isOperationSuspended,
  onClose,
  onDataRefresh,
}: ISuspensionConfirmModalProps) => {
  const dispatch = useAppDispatch();
  const operationActionStatus = useAppSelector(selectOperationActionStatus);
  let isOperationActionLoading = operationActionStatus === 'loading';

  const onSubmit = useCallback(async () => {
    const formattedData = {
      operationId: operationId,
      action: isOperationSuspended ? OperationActions.UNSUSPEND : OperationActions.SUSPEND,
    };

    const result = await dispatch(fetchOperationAction(formattedData));
    if (result.meta.requestStatus === 'fulfilled') {
      enqueueSnackbar(`Transactions on the operation have been ${isOperationSuspended ? 'resumed' : 'suspended'}`, {
        variant: 'success' as VariantType,
      });
      onClose();
      onDataRefresh();
    } else if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Operation error. Try again later', {
        variant: 'error' as VariantType,
      });
    }
  }, [isOperationSuspended]);

  return (
    <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
      <DialogTitle variant="h6">{isOperationSuspended ? 'Unblock operation?' : 'Suspend operation?'}</DialogTitle>
      <DialogContent>
        <Typography mb={3} variant="body1">
          {isOperationSuspended
            ? 'All transactions for the operation will be resumed'
            : 'All transactions for the operation will be suspended. The operation can always be unlocked later and payments will continue'}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="text" color="primary">
          Cancel
        </Button>
        <LoadingButton loading={isOperationActionLoading} onClick={onSubmit} variant="contained" color="primary">
          {isOperationSuspended ? 'Unblock' : 'Suspend'}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};
