import colors from 'theme/constants/colors';
import { GridOverlay } from '@mui/x-data-grid';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IProps {
  isFilters?: boolean;
  clearFilter?: () => void;
}

const NoMoreContent = ({ isFilters, clearFilter }: IProps) => {
  return (
    <GridOverlay>
      {!isFilters && (
        <Stack alignItems="center">
          <Typography variant="h6" color={colors.outlinedBorder}>
            No content to display
          </Typography>
        </Stack>
      )}
      {isFilters && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change its value.
          </Typography>
          <Button onClick={() => clearFilter && clearFilter()} variant="text">
            Clear filters
          </Button>
        </Stack>
      )}
    </GridOverlay>
  );
};

export default NoMoreContent;
