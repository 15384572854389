import { enqueueSnackbar, VariantType } from 'notistack';
import { useHover } from '@uidotdev/usehooks';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from '../shared/IconButton/IconButton';
import ListItem from '../shared/List/ListItem/ListItem';
import ListItemIcon from '../shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from '../shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Tooltip from '../shared/Tooltip/Tooltip';
import Typography from '../shared/Typography/Typography';

interface IUserProfileInfoBlockProps {
  icon?: any;
  primary: any;
  secondary: any;
  withCopy?: boolean;
}

const UserProfileDataBlock = ({ icon, primary, secondary, withCopy }: IUserProfileInfoBlockProps) => {
  const [ref, hovering] = useHover<HTMLDivElement>();

  const copyId = (e: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation();

    if (!secondary) {
      enqueueSnackbar('There is nothing to copy', {
        variant: 'info' as VariantType,
      });
      return;
    }

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', {
        variant: 'success' as VariantType,
      });

      if (navigator.clipboard) {
        return navigator.clipboard.writeText(secondary);
      }
    }
  };

  return (
    <div className="data-block" ref={ref}>
      <ListItem
        disablePadding
        secondaryAction={
          withCopy &&
          hovering && (
            <Tooltip placement="top-end" followCursor title="Copy">
              <IconButton edge="end" color="default" size="small" onClick={(e) => copyId(e)}>
                <CopyIcon />
              </IconButton>
            </Tooltip>
          )
        }
      >
        {icon && <ListItemIcon sx={{ minWidth: 'auto', mr: 2 }}>{icon}</ListItemIcon>}
        <ListItemText
          primary={
            <Typography variant="body2" color="text.secondary">
              {primary}
            </Typography>
          }
          secondary={
            <Typography
              component="span"
              sx={
                withCopy
                  ? {
                      width: 0.75,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }
                  : {}
              }
              variant="subtitle1"
              color="text.primary"
            >
              {secondary}
            </Typography>
          }
        />
      </ListItem>
    </div>
  );
};

export default UserProfileDataBlock;
