import { MouseEvent } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import ContextIcon from 'assets/img/ContextIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Props for the RenderActionsCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {(e: MouseEvent<HTMLButtonElement>, data: any) => void} onClick - Click event handler for the context button.
 */
interface IRenderActionsCellProps {
  props: GridRenderCellParams;
  onClick: (e: MouseEvent<HTMLButtonElement>, data: any) => void;
}

/**
 * Renders actions cell in a data grid.
 */
export const RenderActionsCell = ({ props, onClick }: IRenderActionsCellProps) => {
  const { row } = props;

  if (!row.providerTariffId) {
    return <></>;
  }

  return (
    <Tooltip title="Actions">
      <IconButton color="default" size="small" onClick={(event) => onClick(event, row)}>
        <ContextIcon />
      </IconButton>
    </Tooltip>
  );
};

