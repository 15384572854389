import React, { useEffect, useMemo, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { useSearchParams, useNavigate } from 'react-router-dom';
import InfoIcon from 'assets/img/InfoIcon';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { AuthMessages, CommonErrorMessages } from 'store/types/ErrorMessages';
import {
  fetchSetUpPassword,
  fetchValidateOtp,
  logout,
  selectAuthMemberId,
  selectAuthStatus,
  selectAuthStatusText,
  selectIsAuth,
  selectSnackbarKeys,
  setIsWarningRedirect,
  updateSnackbarKeys,
} from 'store/authSlice';
import EyeClosedIcon from 'assets/img/EyeClosedIcon';
import EyeIcon from 'assets/img/EyeIcon';
import AuthLayout from 'components/AuthLayout/AuthLayout';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import IconButton from 'components/shared/IconButton/IconButton';
import InputAdornment from 'components/shared/InputAdornment/InputAdornment';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Skeleton from 'components/shared/Skeleton/Skeleton';
import TextField from 'components/shared/TextField/TextField';
import getValidationSchema from 'components/Authorization/utils/getValidationShema';
import { action } from 'containers/SnackbarContainer';

import './index.scss';
import Logo from 'assets/img/Logo';

function App() {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const [showError, setShowError] = useState({ isShow: false, message: '' });
  const [isValidOtp, setIsValidOtp] = useState(false);

  const statusText = useAppSelector(selectAuthStatusText);
  const status = useAppSelector(selectAuthStatus);
  const snackbarKeys = useAppSelector(selectSnackbarKeys);
  const isAuth = useAppSelector(selectIsAuth);
  const currentMemberId = useAppSelector(selectAuthMemberId);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const schema = useMemo(() => getValidationSchema('setPassword'), []);
  const {
    register,
    handleSubmit,
    trigger,
    setValue,
    reset,
    formState: { errors, isValid, dirtyFields },
  } = useForm({
    defaultValues: {
      password: '',
      repeatPassword: '',
    },
    resolver: yupResolver(schema),
  });

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');
  const isNewMember = searchParams.get('newMember');
  const memberId = searchParams.get('memberId');

  useEffect(() => {
    if (!code) {
      if (isAuth) {
        navigate('/ui/users'); //TODO поменять на первый доступный раздел после добавления ролей
      } else {
        navigate('/ui/login');
      }
      const key = enqueueSnackbar('Link you have tried to follow is outdated or invalid', {
        action,
        autoHideDuration: null,
        variant: 'warning' as VariantType,
      });
      dispatch(updateSnackbarKeys([...snackbarKeys, key]));

      return;
    }

    const initialValidation = async () => {
      setIsValidOtp(false);
      if (isAuth && memberId !== currentMemberId) {
        dispatch(setIsWarningRedirect(true));
        navigate('/ui/users'); //TODO поменять на первый доступный раздел после добавления ролей

        return;
      }

      await dispatch(fetchValidateOtp({ code: code })).then((result) => {
        if (result.meta.requestStatus === 'fulfilled') {
          setIsValidOtp(true);
        }
        if (result.meta.requestStatus === 'rejected') {
          setIsValidOtp(false);
          navigate('/ui/login');
          const key = enqueueSnackbar('Link you have tried to follow is outdated or invalid', {
            action,
            autoHideDuration: null,
            variant: 'warning' as VariantType,
          });
          dispatch(updateSnackbarKeys([...snackbarKeys, key]));
        }
      });
    };

    void initialValidation();
  }, [code]);

  useEffect(() => {
    switch (statusText) {
      case AuthMessages.MATCH_OLD_PASSWORD:
        setShowError({ isShow: true, message: 'New password has to be different from the old one' });
        break;
      case AuthMessages.BAD_PASSWORD:
        enqueueSnackbar('Password does not match expression', { variant: 'error' as VariantType });
        break;
      case CommonErrorMessages.UNEXPECTED_ERROR:
        enqueueSnackbar('Password setting up error. Try again later', { variant: 'error' as VariantType });
        break;
      default:
        setShowError({ isShow: false, message: '' });
    }
  }, [statusText]);

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const onSubmit = async (data: any) => {
    void trigger();

    try {
      const result = await dispatch(fetchSetUpPassword({ password: data.password, code: code ?? '' }));
      if (result.meta.requestStatus === 'fulfilled') {
        dispatch(logout({ message: '' }));
        navigate('/ui/login');
        isNewMember === 'true'
          ? enqueueSnackbar('Password has been set up', { variant: 'success' as VariantType })
          : enqueueSnackbar('Password has been changed. You need to relogin with new credentials', {
              variant: 'success' as VariantType,
            });
      }
    } catch (e) {
      enqueueSnackbar('Password setting up error. Try again later', { variant: 'error' as VariantType });
    }
  };

  const info = (
    <div className="authForm-info authForm-info--info">
      <div className="authForm-info-title">
        <InfoIcon /> Password must contain at least:
      </div>
      <ul className="authForm-info-list">
        <li>8-20 characters</li>
        <li>1 upper-case Latin letter</li>
        <li>1 lower-case Latin letter</li>
        <li>1 number</li>
        <li>{`1 special symbol &$@[({<>})]!?:;',~^*#+-=/`}</li>
      </ul>
    </div>
  );

  return (
    <AuthLayout>
      {isValidOtp ? (
        <div className="authContainer">
          <div className="authForm-container">
            <Logo isStatic={true} />
            <p className="authForm-text">Enter {isNewMember === 'true' ? 'your' : 'new'} password</p>
            {info}
            <form className="authForm" onSubmit={handleSubmit(onSubmit)}>
              <>
                <TextField
                  {...register('password')}
                  className="authForm-field"
                  error={!!errors.password || showError.isShow}
                  type={showPassword ? 'text' : 'password'}
                  label={isNewMember === 'true' ? 'Password' : 'New password'}
                  name="password"
                  defaultValue=""
                  fullWidth={true}
                  onChange={async ({ target }) => {
                    dirtyFields.repeatPassword && (await trigger('repeatPassword'));
                    setShowError({ isShow: false, message: '' });
                    reset({}, { keepValues: true, keepDirty: true });
                    setValue('password', target.value, { shouldDirty: false });
                  }}
                  onBlur={({ target }) => {
                    setValue('password', target.value, { shouldDirty: true });
                    void trigger('password');
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="authForm-button--minWidth"
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {(errors.password || showError.isShow) && (
                  <FormHelperText error className="authForm-helperText">
                    {showError.isShow ? showError.message : (errors.password?.message as string)}
                  </FormHelperText>
                )}
                {dirtyFields.password && !errors.password && !showError.isShow && isValid && (
                  <FormHelperText className="authForm-helperText authForm-helperText--success">
                    Safe password
                  </FormHelperText>
                )}
              </>
              <>
                <TextField
                  {...register('repeatPassword')}
                  className="authForm-field"
                  error={!!errors.repeatPassword}
                  type={showRepeatPassword ? 'text' : 'password'}
                  label={isNewMember === 'true' ? 'Repeat password' : 'Repeat new password'}
                  name="repeatPassword"
                  defaultValue=""
                  fullWidth={true}
                  onChange={({ target }) => {
                    void trigger('repeatPassword');
                    setValue('repeatPassword', target.value, { shouldDirty: true, shouldValidate: false });
                  }}
                  onFocus={() => {
                    void trigger('repeatPassword');
                  }}
                  onBlur={({ target }) => {
                    setValue('repeatPassword', target.value, { shouldDirty: true });
                    void trigger('repeatPassword');
                  }}
                  InputProps={{
                    autoComplete: 'new-password',
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          className="authForm-button--minWidth"
                          aria-label="toggle password visibility"
                          onClick={() => setShowRepeatPassword((show) => !show)}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showRepeatPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                {errors.repeatPassword && (
                  <FormHelperText error className="authForm-helperText">
                    {errors.repeatPassword?.message as string}
                  </FormHelperText>
                )}
                {((dirtyFields.repeatPassword && !errors.repeatPassword && !showError.isShow) || isValid) && (
                  <FormHelperText className="authForm-helperText authForm-helperText--success">
                    Passwords match
                  </FormHelperText>
                )}
              </>
              <LoadingButton
                disabled={!isValid}
                className="authForm-field"
                type="submit"
                variant="contained"
                color="primary"
                fullWidth={true}
                size="large"
                loadingPosition="start"
                startIcon={<></>}
                loading={status === 'loading'}
              >
                Confirm
              </LoadingButton>
            </form>
          </div>
        </div>
      ) : (
        <Skeleton variant="rectangular" width={444} height={602} sx={{ borderRadius: '8px' }} />
      )}
    </AuthLayout>
  );
}

export default App;
