import { GridRenderCellParams } from '@mui/x-data-grid';
import Tooltip from 'components/shared/Tooltip/Tooltip';

/**
 * Props for the RenderCellWithTooltip component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {string} title - Tooltip title.
 */
interface IRenderCellWithTooltipProps {
  props: GridRenderCellParams;
  title: string;
}

/**
 * Renders a cell with Tooltip in a data grid.
 */
const RenderCellWithTooltip = ({ props, title }: IRenderCellWithTooltipProps) => {
  const { row, value } = props;

  if (row) {
    return (
      <Tooltip placement="top" followCursor title={title}>
        <span>{value}</span>
      </Tooltip>
    );
  } else {
    return <></>;
  }
};

export default RenderCellWithTooltip;
