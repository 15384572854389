import _ from 'lodash';

/**
 * Converts a string to "sentence case" format.
 * @param {string} str - The input string to convert.
 * @returns {string} The string in "sentence case" format.
 */

export const sentenceCase = (str: string) => {
  return _.upperFirst(_.lowerCase(str));
};
