import axios, { isAxiosError } from 'axios';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ITaxCode, ITaxStatus, ITaxesState, ITaxRule } from './interface';
import { RootState } from 'store/store';
import { IStatus } from 'types/commonTypes';

const initialState: ITaxesState = {
  taxCodes: [],
  taxRules: [],
  totalCount: {
    taxCodesCount: 0,
    taxRulesCount: 0,
  },
  status: 'idle',
};

export const fetchTaxCodes = createAsyncThunk(
  'payments/taxCodes',
  async ({ page, pageSize }: { page: number; pageSize: number }, { rejectWithValue }) => {
    try {
      const response = await axios.post((process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/code/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
      });
      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchTaxRules = createAsyncThunk(
  'payments/rulesForSalesTax',
  async (
    {
      page,
      pageSize,
      salesTaxRuleId,
    }: {
      page: number;
      pageSize: number;
      salesTaxRuleId?: string;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post((process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/rule/find', {
        pageInfo: {
          page: page + 1,
          size: pageSize,
        },
        sortingList: [{ columnId: 'created_at', sortingType: 'DESC' }],
        salesTaxRuleId: salesTaxRuleId,
      });
      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchAddTaxCode = createAsyncThunk(
  'payments/addTaxCode',
  async ({ code, category, description }: Pick<ITaxCode, 'code' | 'category' | 'description'>, { rejectWithValue }) => {
    try {
      const response = await axios.post((process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/code', {
        code,
        category,
        description,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchAddTaxRule = createAsyncThunk(
  'payments/addTaxRule',
  async (
    {
      salesTaxCode,
      certainty,
      entityType,
      isDefaultSalesTaxRule,
      format,
      eventOnlineFormatType,
      eventCategories,
      passions,
    }: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.post((process.env.REACT_APP_TAXES_ENDPOINT as string) + 'sales/tax/rule', {
        certainty,
        entityType,
        eventCategories,
        format,
        isDefaultSalesTaxRule,
        eventOnlineFormatType,
        passions,
        salesTaxCodeId: salesTaxCode.salesTaxCodeId,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchEditTaxRule = createAsyncThunk(
  'payments/editTaxRule',
  async (
    {
      salesTaxRuleId,
      salesTaxCode,
      certainty,
      entityType,
      isDefaultSalesTaxRule,
      format,
      eventOnlineFormatType,
      eventCategories,
      passions,
    }: Omit<ITaxRule, 'status'>,
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.put(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/rule/${salesTaxRuleId}`,
        {
          certainty,
          entityType,
          eventCategories,
          format,
          isDefaultSalesTaxRule,
          eventOnlineFormatType,
          passions,
          salesTaxCodeId: salesTaxCode.salesTaxCodeId,
        },
      );

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchChangeTaxStatus = createAsyncThunk(
  'payments/changeStatus',
  async (
    {
      currentSelectedRow,
      currentSelectedStatus,
    }: {
      currentSelectedRow: (ITaxCode & ITaxRule) | null;
      currentSelectedStatus: ITaxStatus | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch(
        (process.env.REACT_APP_TAXES_ENDPOINT as string) +
          `sales/tax/${currentSelectedRow?.salesTaxRuleId ? 'rule' : 'code'}/status`,
        {
          salesTaxCodeId: currentSelectedRow?.id,
          salesTaxRuleId: currentSelectedRow?.salesTaxRuleId,
          status: currentSelectedStatus?.status,
        },
      );
      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchChangeTaxCodeStatus = createAsyncThunk(
  'payments/taxCodes/changeStatus',
  async (
    {
      salesTaxCode,
      currentSelectedStatus,
    }: {
      salesTaxCode: Pick<ITaxCode, 'id' | 'salesTaxCodeId' | 'code' | 'category' | 'status'> | null;
      currentSelectedStatus: ITaxStatus | null;
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await axios.patch((process.env.REACT_APP_TAXES_ENDPOINT as string) + `sales/tax/code/status`, {
        salesTaxCodeId: salesTaxCode?.id,
        status: currentSelectedStatus?.status,
      });
      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const taxesSlice = createSlice({
  name: 'taxes',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaxCodes.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxCodes.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.taxCodes = payload.content;
        state.totalCount.taxCodesCount = payload.totalElements;
      })
      .addCase(fetchTaxCodes.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchTaxRules.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchTaxRules.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.taxRules = payload.content;
        state.totalCount.taxRulesCount = payload.totalElements;
      })
      .addCase(fetchTaxRules.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchAddTaxRule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchAddTaxRule.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(fetchAddTaxRule.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchEditTaxRule.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchEditTaxRule.fulfilled, (state) => {
        state.status = 'idle';
      })
      .addCase(fetchEditTaxRule.rejected, (state) => {
        state.status = 'failed';
      });
  },
});

export const selectTaxCodes = (state: RootState): ITaxCode[] => state.taxes.taxCodes;
export const selectTaxRules = (state: RootState): ITaxRule[] => state.taxes.taxRules;
export const selectTotalCount = (
  state: RootState,
): {
  taxCodesCount: number;
  taxRulesCount: number;
} => state.taxes.totalCount;
export const selectTaxesStatus = (state: RootState): IStatus => state.taxes.status;

export default taxesSlice.reducer;
