import { ChangeEvent, FC, MouseEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import { motion } from 'framer-motion';
import { useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import { useErrorBoundary } from 'react-error-boundary';
import { ErrorBoundaryErrors } from '../ErrorPage/ErrorPage';
import { useDocumentTitle } from '@uidotdev/usehooks';
import startCase from 'lodash/startCase';
import {
  defaultTaxModerationSortingModel,
  SOLVED_FILTER_KIND,
  TaxModerationListType,
  UNRESOLVED_FILTERS_KIND,
} from './constants';
import { TaxModerationRoutes } from 'routes/enum';
import { ITaxModerationCard, TaxModerationSolution } from 'store/slices/TaxModeration/interface';
import { IFilterItem, LocalStorageKeys, Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import {
  fetchChangeTaxModerationSolution,
  fetchTaxModerationCards,
  selectTaxModerationCards,
  selectTaxModerationSearchStatus,
  selectTaxModerationStatus,
  selectTotalCount,
  setTaxModerationStatus,
} from 'store/slices/TaxModeration/taxModerationSlice';
import {
  clearFilters,
  fetchFilters,
  removeFilter,
  reverseFilters,
  selectFilters,
  selectFiltersCurrentData,
  selectFiltersData,
  selectFiltersStatus,
  sortFilters,
} from 'store/filtersSlice';
import { clearSortingData, fetchSortingColumns, selectSortingColumns, setSortingData } from 'store/sortingSlice';
import FilterIcon from 'assets/img/FilterIcon';
import ActiveFilterList from 'components/ActiveFilterList/ActiveFilterList';
import Button from 'components/shared/Button/Button';
import CustomFooter from 'components/shared/DataGrid/CustomFooter/CustomFooter';
import CustomNoRowsOverlay from 'components/shared/DataGrid/CustomNoResultsOverlay/CustomNoRowsOverlay';
import Drawer from 'components/shared/Drawer/Drawer';
import FilterDrawer from 'components/FilterDrawer/FilterDrawer';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import Grid from 'components/shared/Grid/Grid';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Menu from 'components/shared/Menu/Menu';
import MenuItem from 'components/shared/Menu/MenuItem/MenuItem';
import Radio from 'components/shared/Radio/Radio';
import RadioGroup from 'components/shared/RadioGroup/RadioGroup';
import SortingChip from 'components/SortingChip/SortingChip';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import withAuth from 'components/Authorization/withAuth';
import {
  RenderEntityCell,
  RenderReceivedCell,
  RenderSolutionCell,
  RenderTaxCodeCell,
  TaxModerationSearch,
} from './components';
import RenderExpandableCell from 'components/shared/DataGrid/RenderExpandableCell/RenderExpandableCell';
import { CloseFilterDrawerConfirmModal } from 'components/FilterDrawer/CloseFilterDrawerConfirmModal';
import { ApprovalConfirmModal, ChangeTaxCodeModal } from './modals';
import './index.scss';

const TaxModeration: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { showBoundary } = useErrorBoundary();
  const { listType: listTypeParam, id: cardId } = useParams();
  const currentOutlet = useOutlet();

  const taxModerationCards = useAppSelector(selectTaxModerationCards);
  const totalCount = useAppSelector(selectTotalCount);
  const status = useAppSelector(selectTaxModerationStatus);
  const searchStatus = useAppSelector(selectTaxModerationSearchStatus);
  const filters = useAppSelector(selectFilters);
  const filtersCurrentData = useAppSelector(selectFiltersCurrentData);
  const filtersData = useAppSelector(selectFiltersData);
  const filtersStatus = useAppSelector(selectFiltersStatus);
  const userPermissions = useAppSelector(selectUserPermissions);
  const sortingColumns = useAppSelector(selectSortingColumns);

  let isLoading = status === 'loading';
  let isLoadingSearch = searchStatus === 'loading';

  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP);
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false;

  const [isOpenSalesTaxApprovalModal, setIsOpenSalesTaxApprovalModal] = useState<boolean>(false);
  const [isOpenChangeTaxCodeModal, setIsOpenChangeTaxCodeModal] = useState<boolean>(false);

  const [fetchingError, setFetchingError] = useState<boolean>(false);
  const [filterDrawer, setFilterDrawer] = useState<boolean>(false);
  const [filterDrawerCloseConfirmModal, setFilterDrawerCloseConfirmModal] = useState<boolean>(false);
  const [listType, setListType] = useState(
    listTypeParam ? (listTypeParam.toUpperCase() as TaxModerationListType) : TaxModerationListType.UNRESOLVED,
  );
  useDocumentTitle(`Sales tax moderation - ${startCase(listType.toLowerCase())}`);

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(25);

  const [currentSelectedSalesTax, setCurrentSelectedSalesTax] = useState<ITaxModerationCard | null>(null);

  const [solutionMenuAnchorEl, setSolutionMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isSolutionMenuOpen = Boolean(solutionMenuAnchorEl);

  const [highlightedSalesTaxId, setHighlightedSalesTaxId] = useState<string | null>(null);

  const filtersLength = useMemo(() => {
    /** Subtraction due to search using "entity_name" filter. Just UI **/
    if (
      !isEmpty(filtersCurrentData?.entity_name) ||
      !isEmpty(filtersCurrentData?.sales_tax_moderation_search_by_part_of_entity_name)
    ) {
      return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length - 1;
    }

    return Object.values(filtersData).filter((item: IFilterItem) => item !== null).length;
  }, [filtersCurrentData, filtersData]);

  const onFilterSidebarClose = useCallback(
    (apply?: boolean) => {
      if (isEqual(filtersData, filtersCurrentData) || apply === true) {
        setFilterDrawerCloseConfirmModal(false);
        setFilterDrawer(false);
        dispatch(reverseFilters());
      } else {
        setFilterDrawerCloseConfirmModal(true);
      }
    },
    [filtersData, filtersCurrentData],
  );

  const cancelCloseFilterSidebar = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setFilterDrawerCloseConfirmModal(false);
  }, []);

  const handleCardApproval = async () => {
    setIsOpenSalesTaxApprovalModal(false);

    const formattedData = {
      salesTaxModerationId: currentSelectedSalesTax ? currentSelectedSalesTax.id : null,
      newSalesTaxCodeId: null,
      solution: TaxModerationSolution.APPROVED,
    };

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Approved`, {
          variant: 'success' as VariantType,
        });

        setHighlightedSalesTaxId(result.payload.id);
        setTimeout(() => {
          setHighlightedSalesTaxId(null);
          void fetchTaxModerationGrid(page, pageSize, listType);
        }, 2000);
      } else if (result.payload.meta) {
        const { title } = result.payload;
        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error' as VariantType,
      });
    }
  };

  const handleChangePage = (page: number) => {
    setPage(page);
    void fetchTaxModerationGrid(page, pageSize, listType);
  };

  const handleChangePageSize = (pageSize: number) => {
    setPage(0);
    setPageSize(pageSize);
    void fetchTaxModerationGrid(0, pageSize, listType);
  };

  const handleSolutionMenuClick = (event: MouseEvent<HTMLButtonElement>, data: ITaxModerationCard) => {
    event.stopPropagation();
    setSolutionMenuAnchorEl(event.currentTarget);
    setCurrentSelectedSalesTax(data);
  };

  const handleSolutionMenuClose = () => {
    setSolutionMenuAnchorEl(null);
  };
  const handleMenuItemClick = (option: string) => {
    handleSolutionMenuClose();
    if (option.toLowerCase() === TaxModerationSolution.APPROVED.toLowerCase()) {
      if (!salesTaxApprovalConfirmSkip) {
        setIsOpenSalesTaxApprovalModal(true);
      } else void handleCardApproval();
    } else if (option.toLowerCase() === TaxModerationSolution.MODIFIED.toLowerCase()) {
      setIsOpenChangeTaxCodeModal(true);
    }
  };

  const handleCloseTaxCodeModal = (isSucceed: boolean) => {
    setIsOpenChangeTaxCodeModal(false);
    if (isSucceed && currentSelectedSalesTax) {
      setHighlightedSalesTaxId(currentSelectedSalesTax.id);
      setTimeout(() => {
        setHighlightedSalesTaxId(null);
        void fetchTaxModerationGrid(page, pageSize, listType);
      }, 2000);
    }
  };

  const handleChangeListType = async (event: ChangeEvent<HTMLInputElement>) => {
    setPage(0);
    dispatch(clearFilters());
    dispatch(clearSortingData());

    if (cardId || location.pathname.includes('all-resolved')) {
      navigate(TaxModerationRoutes.TAX_MODERATION);
    }
    setListType(event.target.value as TaxModerationListType);

    if (event.target.value?.toUpperCase() === TaxModerationListType.UNRESOLVED) {
      dispatch(sortFilters(UNRESOLVED_FILTERS_KIND));
      dispatch(setSortingData([defaultTaxModerationSortingModel]));
      await fetchTaxModerationGrid(0, pageSize, TaxModerationListType.UNRESOLVED);
    } else {
      dispatch(sortFilters(SOLVED_FILTER_KIND));
      dispatch(setSortingData([defaultTaxModerationSortingModel]));
      await fetchTaxModerationGrid(0, pageSize, TaxModerationListType.SOLVED);
    }
  };

  const handleRowClick = (params: GridRowParams<any>) => {
    const currentRow = params.row;
    navigate(`/ui/tax-moderation/${listType.toLowerCase()}/details/${currentRow.id}`);
  };

  const columns: GridColDef[] = [
    {
      field: 'entityType',
      headerName: 'Entity',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => <RenderEntityCell {...params} />,
    },
    {
      field: 'entityName',
      headerName: 'Name',
      flex: 0.5,
      sortable: false,
    },
    {
      field: 'entityDescription',
      headerName: 'Description',
      flex: 0.6,
      sortable: false,
      renderCell: (params) => <RenderExpandableCell {...params} />,
    },
    {
      field: 'salesTaxCode.description',
      headerName: 'Sales tax description',
      flex: 0.6,
      sortable: false,
      valueGetter: (params) => params.row.salesTaxCode?.description,
      renderCell: (params) => <RenderExpandableCell {...params} />,
    },
    {
      field: 'salesTaxCode',
      headerName: 'Sales tax code',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => <RenderTaxCodeCell props={params} />,
    },
    {
      field: 'createdAt',
      headerName: 'Received',
      flex: 0.3,
      sortable: false,
      renderCell: (params) => <RenderReceivedCell props={params} />,
    },
    {
      field: 'solution',
      headerName: 'Solution',
      type: 'actions',
      flex: 0.5,
      sortable: false,
      renderCell: (params) => (
        <RenderSolutionCell
          props={params}
          isMenuOpen={!!solutionMenuAnchorEl}
          currentSelectedSalesTax={currentSelectedSalesTax}
          onClick={(e, data) => handleSolutionMenuClick(e, data)}
          onEdit={(_, data) => {
            setCurrentSelectedSalesTax(data);
            handleMenuItemClick(TaxModerationSolution.MODIFIED);
          }}
        />
      ),
    },
  ];

  const rows: ITaxModerationCard[] | [] = useMemo(() => {
    if (taxModerationCards) {
      return taxModerationCards;
    } else return [];
  }, [taxModerationCards]);

  const isEmptyData = !rows.length;

  const fetchTaxModerationInitial = useCallback(
    async (page: number, pageSize: number, listType: TaxModerationListType) => {
      dispatch(fetchTaxModerationCards({ page, pageSize, listType })).then((result: any) => {
        if (
          result.meta &&
          result.meta.requestStatus === 'rejected' &&
          ErrorBoundaryErrors.includes(result.error.message)
        ) {
          showBoundary(new Error(result.error?.message));
        }
      });
    },
    [],
  );

  const fetchTaxModerationGrid = useCallback(
    async (page: number, pageSize: number, listType: TaxModerationListType) => {
      dispatch(fetchTaxModerationCards({ page, pageSize, listType })).then((result: any) => {
        if (result.meta && result.meta.requestStatus === 'rejected') {
          enqueueSnackbar('Receiving data error', { variant: 'error' as VariantType });
          setFetchingError(true);
        } else if (result.meta && result.meta.requestStatus === 'fulfilled') {
          setFetchingError(false);
        }
      });
    },
    [dispatch],
  );

  const repeatFetchingRequest = useCallback(async () => {
    fetchTaxModerationGrid(0, pageSize, listType);
  }, [pageSize, listType]);

  const resetFilters = useCallback(async () => {
    dispatch(clearFilters());
    await dispatch(fetchTaxModerationCards({ page, pageSize, listType }));
  }, []);

  const handleSortingModelApply = async () => {
    await fetchTaxModerationGrid(page, pageSize, listType);
  };

  const initialFetchData = useCallback(async () => {
    dispatch(setTaxModerationStatus('loading'));
    await dispatch(fetchFilters('tax-moderation'));
    await dispatch(fetchSortingColumns('tax-moderation'));
    if (filters && sortingColumns) {
      dispatch(sortFilters(UNRESOLVED_FILTERS_KIND));
      dispatch(setSortingData([defaultTaxModerationSortingModel]));
      await fetchTaxModerationInitial(page, pageSize, listType);
    }
  }, [dispatch, page, sortingColumns, filters, fetchTaxModerationGrid]);

  useEffect(() => {
    if (!isEmpty(location.state)) {
      setListType(location.state?.listType);
    }

    return () => {
      location.state = null;
    };
  }, [location.state]);

  useEffect(() => {
    dispatch(clearFilters());
    dispatch(clearSortingData());
    initialFetchData();
  }, []);

  useEffect(() => {
    if (filtersCurrentData?.entity_type?.element?.value === 'PRIVATE_WISH') {
      dispatch(removeFilter({ columnId: 'sales_tax_moderation_passions' }));
    }
  }, [dispatch, filtersCurrentData]);

  return (
    <div className="tax-moderation-container">
      <Typography variant="h6">Sales tax moderation</Typography>
      <div className="tax-moderation-content">
        <div className="tax-moderation-content__actions">
          <Stack pl={2} direction="row" alignItems="center">
            <FormControl>
              <RadioGroup row value={listType} onChange={handleChangeListType}>
                <FormControlLabel value={TaxModerationListType.UNRESOLVED} control={<Radio />} label="Unresolved" />
                <FormControlLabel value={TaxModerationListType.SOLVED} control={<Radio />} label="Solved" />
              </RadioGroup>
            </FormControl>
            <Button
              sx={{ mr: 2 }}
              size="small"
              startIcon={<FilterIcon />}
              variant="outlined"
              onClick={() => setFilterDrawer(true)}
            >
              Filter {filtersLength > 0 && `(${filtersLength})`}
            </Button>
            <SortingChip
              disabled={isEmptyData}
              defaultSortingModel={defaultTaxModerationSortingModel}
              onSortingModelApply={() => handleSortingModelApply()}
            />
          </Stack>
          <TaxModerationSearch refreshData={() => repeatFetchingRequest()} listType={listType} />
        </div>
        {filtersLength !== 0 && <ActiveFilterList refreshData={() => repeatFetchingRequest()} />}
        {currentOutlet}
        {!cardId && !location.pathname.includes('all-resolved') && (
          <motion.div
            className="tax-moderation-table"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Grid
              columns={columns}
              rows={rows}
              getRowId={(row) => row.id}
              getRowClassName={(params) =>
                `${params.row.id === highlightedSalesTaxId ? 'tax-moderation-table__highlighted-row' : ''}`
              }
              loading={isLoading}
              page={page}
              pageSize={pageSize}
              pagination
              paginationMode="server"
              filterMode="server"
              sortingMode="server"
              rowCount={totalCount}
              rowsPerPageOptions={[10, 25, 50, 100]}
              getRowHeight={() => 'auto'}
              hideFooterSelectedRowCount
              disableColumnMenu
              disableColumnReorder
              disableColumnResize
              disableSelectionOnClick
              onRowClick={(params) => handleRowClick(params)}
              onPageChange={(newPage) => handleChangePage(newPage)}
              onPageSizeChange={(newPageSize) => handleChangePageSize(newPageSize)}
              components={{
                NoRowsOverlay: () =>
                  CustomNoRowsOverlay({
                    isFiltersApplied: !!filtersLength,
                    fetchingError: fetchingError,
                    clearFilters: () => resetFilters(),
                    refreshData: () => repeatFetchingRequest(),
                    renderNoDataComponent: () => (
                      <Typography color="text.secondary" variant="body2">
                        {listType === TaxModerationListType.UNRESOLVED
                          ? 'No cards have yet been received for moderation'
                          : 'No cards'}
                      </Typography>
                    ),
                  }),
                Footer: CustomFooter,
              }}
            />
          </motion.div>
        )}
      </div>
      <Menu
        id="solution-menu"
        anchorEl={solutionMenuAnchorEl}
        open={isSolutionMenuOpen}
        onClose={handleSolutionMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{ sx: { width: solutionMenuAnchorEl?.offsetWidth ?? 100 } }}
      >
        {checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_APPROVE]) && (
          <MenuItem onClick={() => handleMenuItemClick(TaxModerationSolution.APPROVED)}>
            <ListItemText>Approve</ListItemText>
          </MenuItem>
        )}
        <MenuItem onClick={() => handleMenuItemClick(TaxModerationSolution.MODIFIED)}>
          <ListItemText>Modify</ListItemText>
        </MenuItem>
      </Menu>
      <CloseFilterDrawerConfirmModal
        open={filterDrawerCloseConfirmModal}
        onClose={() => onFilterSidebarClose(true)}
        closeModal={() => cancelCloseFilterSidebar()}
      />
      <Drawer anchor="right" open={filterDrawer} onClose={() => onFilterSidebarClose()}>
        <FilterDrawer
          type="tax-moderation"
          hasSorting
          isHidePresets
          onClose={onFilterSidebarClose}
          onApply={() => {
            onFilterSidebarClose(true);
            setPage(0);
            void fetchTaxModerationGrid(0, pageSize, listType);
          }}
        />
      </Drawer>
      <ApprovalConfirmModal
        onCardApproval={() => handleCardApproval()}
        open={isOpenSalesTaxApprovalModal}
        onClose={() => setIsOpenSalesTaxApprovalModal(false)}
      />
      <ChangeTaxCodeModal
        shouldKeepTaxCode
        currentSelectedSalesTax={currentSelectedSalesTax}
        onClose={(isSucceed) => handleCloseTaxCodeModal(isSucceed)}
        open={isOpenChangeTaxCodeModal}
        withApproveOption
      />
    </div>
  );
};

export default withAuth(TaxModeration);
