import Breadcrumbs from "components/shared/Breadcrumbs/Breadcrumbs";
import ChevronRightIcon from "assets/img/ChevronRightIcon";
import IconButton from "components/shared/IconButton/IconButton";
import ArrowBack from "assets/img/ArrowBack";
import Button from "components/shared/Button/Button";
import ContextMenu from "components/WorldManagement/ContextMenu/ContextMenu";
import {
  Collection, fetchEditCollection,
  ICollectionDetails,
  OrderedBanner,
  OrderedEntity,
  OrderedEvent,
  OrderedHero,
  OrderedOffer,
  OrderedWish
} from "store/collectionDetailsSlice";
import { Link } from "@mui/material";
import TextField from "components/shared/TextField/TextField";
import { Controller, useForm } from "react-hook-form";
import Autocomplete from "components/shared/Autocomplete/Autocomplete";
import { bannersData } from "__mock__/world/bannersData";
import CheckCircleIcon from "assets/img/CheckCircleIcon";
import CollectionBoard from "components/WorldManagement/Collections/CollectionDetails/CollectionBoard/CollectionBoard";
import WarningIcon from "assets/img/WarningIcon";
import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from "react";
import getValidationSchema from "components/WorldManagement/Collections/CollectionsModal/utils/getValidationSchema";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams, useNavigate } from "react-router-dom";
import { Cohort } from "store/cohortDetailsSlice";
import { useAppDispatch } from "store/hooks";
import PlusIcon from "assets/img/PlusIcon";
import DeleteEntities from "components/WorldManagement/Collections/CollectionDetails/DeleteEntities/DeleteEntities";
import Modal from "components/shared/Modal/Modal";
import { enqueueSnackbar, VariantType } from "notistack";
import FormHelperText from "components/shared/FormHelperText/FormHelperText";
import changeOrderAndStatus from "components/WorldManagement/Collections/CollectionDetails/utils/changeOrderAndStatus";
import ArchiveCollectionsModal
  from "components/WorldManagement/Collections/CollectionsModal/ArchiveCollections/ArchiveCollectionsModal";
import PublishCollectionsModal
  from "components/WorldManagement/Collections/CollectionsModal/PublishCollections/PublishCollectionsModal";
import UnpublishCollectionModal
  from "components/WorldManagement/Collections/CollectionsModal/UnpublishCollection/UnpublishCollectionModal";
import RestoreIcon from "assets/img/RestoreIcon";
import CheckIcon from "assets/img/CheckIcon";
import StopIcon from "assets/img/StopIcon";

import './index.scss';

interface IProps {
  collectionDetails: ICollectionDetails;
  allCohorts: Cohort[];
  setIsOpenClosingModal: Dispatch<SetStateAction<boolean>>;
  scrollTop: number;
}

export interface DeletedEntities {
  entities: string[];
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT';
  prevQuantity: number;
}

const initialEntitiesOrder = (entity: OrderedEntity[]) => {
  const unavailable: OrderedEntity[] | [] = entity.filter(({entityStatus}) => entityStatus === 'UNAVAILABLE');
  const available: OrderedEntity[] | [] = entity.filter(({entityStatus}) => entityStatus !== 'UNAVAILABLE');
  const orderedArray = [...available].sort((item1, item2) => item1.order - item2.order);
  return [...orderedArray, ...unavailable];
}

const CollectionDetailsForm = ({ collectionDetails, allCohorts, setIsOpenClosingModal, scrollTop }: IProps) => {
  const [cohortValue, setCohortValue] = useState(collectionDetails.cohortTitle ?? '');
  const [activeLink, setActiveLink] = useState<string>('banners');
  const [calculatedInfoWidth, setCalculatedInfoWidth] = useState(0);
  const [archiveCollections, setArchiveCollections] = useState<Collection | Collection[] | null>(null);
  const [publishCollections, setPublishCollections] = useState<Collection | Collection[] | null>(null);
  const [unpublishCollections, setUnpublishCollections] = useState<Collection | null>(null);
  const [isDeleteEntities, setIsDeteleEntities] = useState<DeletedEntities | null>(null)

  /* Ширина у блоков энтити одинаковая, поэтому нужно получить только один для расчета ширины */
  const refMaxWidth = useRef<HTMLDivElement | null>(null);

  let { collectionId } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const disableEditing = useMemo(() => collectionId === 'DEFAULT', [collectionId]);
  const disableReordering = useMemo(() => collectionId !== 'DEFAULT' && collectionDetails.status === 'LIVE', [collectionId, collectionDetails]);

  const schema = useMemo(() => getValidationSchema('edit'),[]);
  const { register, handleSubmit, trigger, control, getValues, setValue, reset, formState: { errors, isValid, isDirty } } = useForm<ICollectionDetails>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...collectionDetails,
      cohortId: collectionDetails.cohortId,
      banners: initialEntitiesOrder(collectionDetails.banners),
      heroes: initialEntitiesOrder(collectionDetails.heroes),
      offers: initialEntitiesOrder(collectionDetails.offers),
      wishes: initialEntitiesOrder(collectionDetails.wishes),
      events: initialEntitiesOrder(collectionDetails.events),
    }
  });

  useEffect(() => {
    if (cohortValue) {
      const id = allCohorts.find((cohort) => cohortValue === cohort.title)?.id || '';
      setValue('cohortId', id, { shouldDirty: true });
    }
  }, [collectionDetails, allCohorts, cohortValue]);

  useEffect(() => {
    const init = () => {
      setCalculatedInfoWidth(refMaxWidth.current?.clientWidth as number);
    }
    window.addEventListener('resize', init);
    init();
    return () => window.removeEventListener('resize', init);
  }, [refMaxWidth.current, collectionDetails]);

  useEffect(() => {
    let section = document.querySelectorAll('section');
    section.forEach(sec => {
      let offset = sec.offsetTop;
      let height = sec.offsetHeight;
      let id = sec.querySelector('.collectionDetails-content-info')?.getAttribute('id');

      if (scrollTop >= offset && scrollTop < offset + height) {
        setActiveLink(id!);
      }
    })
  }, [scrollTop]);

  const handleDeleteEntities = (deletedEntities: string[], entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT') => {
    switch (entityType) {
      case "BANNER": {
        const entities = getValues('banners');
        const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId));
        setValue(
          'banners',
          changeOrderAndStatus(restEntities, entityType) as OrderedBanner[],
          { shouldDirty: true }
        )
      }
        break;
      case "HERO": {
        const entities = getValues('heroes');
        const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId));
        setValue(
          'heroes',
          changeOrderAndStatus(restEntities, entityType) as OrderedHero[],
          { shouldDirty: true }
        )
      }
        break;
      case "OFFER": {
        const entities = getValues('offers');
        const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId));
        setValue(
          'offers',
          changeOrderAndStatus(restEntities, entityType) as OrderedOffer[],
          { shouldDirty: true }
        )
      }
        break;
      case "WISH": {
        const entities = getValues('wishes');
        const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId));
        setValue(
          'wishes',
          changeOrderAndStatus(restEntities, entityType) as OrderedWish[],
          { shouldDirty: true }
        )
      }
        break;
      case "EVENT": {
        const entities = getValues('events');
        const restEntities = entities.filter(({ entityId }) => !deletedEntities.includes(entityId));
        setValue(
          'events',
          changeOrderAndStatus(restEntities, entityType) as OrderedEvent[],
          { shouldDirty: true }
        )
      }
        break;
    }
  }

  const onSubmit = async (data: ICollectionDetails) => {
    void trigger();

    try {
      const result = await dispatch(fetchEditCollection(data ));
      if (result.meta.requestStatus === 'fulfilled') {
        reset({...data}, { keepValues: true, keepDirty: false });
        enqueueSnackbar('Changes have been saved', { variant: 'success' as VariantType });
        return;
      }
      result.meta.requestStatus === 'rejected' && enqueueSnackbar('Saving error', { variant: 'error' as VariantType })
    } catch (e) {
      enqueueSnackbar('Saving error', { variant: 'error' as VariantType });
    }
  };

  return (
    <form className="collectionDetails-form" onSubmit={handleSubmit(onSubmit)}>
      <div className="collectionDetails-breadcrumbs">
        <Breadcrumbs separator={<ChevronRightIcon fontSize='small' />}>
          <div className="collectionDetails-header-text">
            <IconButton
              onClick={() => {
                if (isDirty) {
                  setIsOpenClosingModal(true);
                  return;
                }
                navigate("/ui/world-management/collections");
              }}
            >
              <ArrowBack />
            </IconButton>
            Tim World Management
          </div>
          <div className="collectionDetails-header-text">Collections</div>
          <div className="collectionDetails-header-text collectionDetails-header-text_main">
            {collectionDetails?.title}
            {isDirty && <span className="collectionDetails-header-text"> (not saved)</span>}
          </div>
        </Breadcrumbs>
        <div className="collectionDetails-header-actions">
          {(collectionDetails.id === 'DEFAULT' || collectionDetails.status === 'INCOMPLETE' || collectionDetails.status === 'DEACTIVATED') &&
            <Button
              className="collectionDetails-header-actions-button"
              disabled={!isDirty}
              variant="contained"
              type="submit"
              color="primary"
            >
              Save
            </Button>
          }
          {collectionDetails.status === 'ARCHIEVED' &&
            <Button
              className="collectionDetails-header-actions-button"
              variant="contained"
              onClick={() => {
                // todo добавить действие на восстановление из архива
              }}
              color="primary"
              startIcon={<RestoreIcon />}
            >
              Restore
            </Button>
          }
          {collectionDetails.status === 'READY' || collectionDetails.status === 'SCHEDULED' &&
            <Button
              className="collectionDetails-header-actions-button"
              variant="contained"
              onClick={() => setPublishCollections(collectionDetails as Collection)}
              color="primary"
              startIcon={<CheckIcon /> }
            >
              Publish
            </Button>
          }
          {collectionDetails.status === 'LIVE' && collectionDetails.id !== 'DEFAULT' &&
            <Button
              className="collectionDetails-header-actions-button"
              variant="contained"
              onClick={() => setUnpublishCollections(collectionDetails as Collection)}
              color="warning"
              startIcon={<StopIcon /> }
            >
              Unpublish
            </Button>
          }
          {collectionDetails.id !== 'DEFAULT' &&
            <ContextMenu
              item={collectionDetails}
              setPublishCollections={(collection: Collection) => setPublishCollections(collection)}
              setUnpublishCollections={(collection: Collection) => setUnpublishCollections(collection)}
              setArchiveCollections={(collection: Collection) => setArchiveCollections(collection)}
            />
          }
        </div>
      </div>
      <div className="collectionDetails-container">
        <div>
          {/* Пока что скрываем степпер-навигацию по странице, чтоб было больше места для элементов.
              Возможно, в будущем нужно будет его вернуть, поэтому не удаляю. (также раскомментить в стилях) */}

          {/*<nav className="navigation">*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#banners" underline="none" onClick={() => setActiveLink('banners')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'banners' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {bannersData.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Banners*/}
          {/*    </Link>*/}

          {/*    <Divider orientation="vertical" sx={{ height: 20 }} />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#hero" underline="none" onClick={() => setActiveLink('hero')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'hero' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {heroesData?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Heroes*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }}  />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#offers" underline="none" onClick={() => setActiveLink('offers')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'offers' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {collectionDetails.offers?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Offers*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }}  />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#wishes" underline="none" onClick={() => setActiveLink('wishes')}>*/}
          {/*       <span className={`navigation-item-icon ${activeLink === 'wishes' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*         {collectionDetails.wishes?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*       </span>*/}
          {/*      Wishes*/}
          {/*    </Link>*/}
          {/*    <Divider orientation="vertical" sx={{ height: 20 }} />*/}
          {/*  </div>*/}
          {/*  <div className="navigation-item">*/}
          {/*    <Link href="#events" underline="none" onClick={() => setActiveLink('events')}>*/}
          {/*      <span className={`navigation-item-icon ${activeLink === 'events' ? 'navigation-item-icon_active' : ''}`}>*/}
          {/*        {collectionDetails.events?.length < 5 ? <HistoryIcon /> : <CheckIcon_2 />}*/}
          {/*      </span>*/}
          {/*      Events*/}
          {/*    </Link>*/}
          {/*  </div>*/}
          {/*</nav>*/}
        </div>
        <div className="mainSection">
          <div className={disableReordering ? 'collectionDetails-header collectionDetails-header_disabled' : 'collectionDetails-header'}>
            <div className="collectionDetails-header-title">Settings</div>
            <div className="collectionDetails-header-lastModified">Updated: {new Date(collectionDetails.updatedAt).toLocaleDateString()}</div>
            <span style={{ display: 'flex', flexDirection: 'column' }}>
              <TextField
                disabled={disableEditing}
                {...register('title')}
                error={!!errors.title}
                name="title"
                label="Collection name"
                defaultValue={getValues('title')}
                size="small"
                onBlur={() => trigger('title')}
              />
              {/* todo сюда добавится сообщение об ошибке в названии, если такое уже существует
                  см пример в создании коллекции. там работает
               */}
              {errors.title &&
                <FormHelperText error>
                  {errors.title?.message as string}
                </FormHelperText>
              }
            </span>
            <Controller
              control={control}
              name="cohortId"
              render={() => (
                <Autocomplete
                  disabled={disableEditing}
                  label="Cohort"
                  value={cohortValue}
                  inputValue={cohortValue ?? ''}
                  clearIcon={null}
                  inputprops={{
                    error: !!errors.cohortId,
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option || ""}
                  onInputChange={(event, value) => {
                    setCohortValue(value);
                  }}
                  onChange={(event, value) => {
                    setCohortValue(value ? value : null);
                    void trigger('cohortId');
                  }}
                  options={allCohorts.map(({title}) => title) || []}
                  size="small"
                  onBlur={() => trigger('cohortId')}
                  fullWidth={true}
                />)}
            />
          </div>

          {/* BANNERS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Banners
                <span className="collectionDetails-content-titleCount"> ({getValues('banners').length}/5) </span>
                <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                  <CheckCircleIcon />
                </span>
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('banners')?.length ?
                  <Link href="/ui/world-management/banners" target="_blank">
                    <Button variant="outlined" startIcon={<PlusIcon />}>Add Banner</Button>
                  </Link>
                   :
                  <span className="collectionDetails-content-title-actionText">
                    Find more <Link href="/ui/world-management/banners" target="_blank">Banners</Link>
                  </span>
                }
              </div>
            </div>
            <div className="collectionDetails-content-info" id='banners' ref={refMaxWidth}>
              {!getValues('banners')?.length ?
                <span className="collectionDetails-content-info-empty">No banners added yet.</span> :
                <CollectionBoard
                  items={getValues('banners') || []}
                  heightEntity={248}
                  widthEntity={331}
                  entityType={'BANNER'}
                  parentsWidth={calculatedInfoWidth}
                  isCanDrag={!disableReordering}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeteleEntities({ entities, entityType: 'BANNER', prevQuantity: getValues('banners')?.length })
                  }
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  collectionId={collectionDetails.id}
                  onSortEntities={(entities: OrderedEntity[]) => {
                    setValue('banners', entities as OrderedBanner[], { shouldDirty: true })
                  }}
                />
              }
            </div>
          </section>

          {/* HEROES */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Tim Hero
                <span className="collectionDetails-content-titleCount"> ({getValues('heroes')?.length || 0}/15) </span>
                {getValues('heroes')?.length < 15 ?
                  <span className="collectionDetails-content-titleIcon"><WarningIcon /></span> :
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                }
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('heroes')?.length ?
                  <Link href="/ui/users" target="_blank">
                    <Button variant="outlined" startIcon={<PlusIcon />}>Add profile</Button>
                  </Link>
                  :
                  <span className="collectionDetails-content-title-actionText">
                    Find more <Link href="/ui/users" target="_blank">Profiles</Link>
                  </span>
                }
              </div>
            </div>
            <div className="collectionDetails-content-info" id='hero'>
              {!getValues('heroes')?.length ?
                <span className="collectionDetails-content-info-empty">No profiles added yet.</span> :
                <CollectionBoard
                  items={getValues('heroes') || []}
                  heightEntity={280}
                  widthEntity={200}
                  entityType={'HERO'}
                  parentsWidth={calculatedInfoWidth}
                  isCanDrag={!disableReordering}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeteleEntities({ entities, entityType: 'HERO', prevQuantity: getValues('heroes')?.length })
                  }
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  collectionId={collectionDetails.id}
                  onSortEntities={(entities: OrderedEntity[]) => {
                    setValue('heroes', entities as OrderedHero[], { shouldDirty: true })
                  }}
                />
              }
            </div>
          </section>

          {/* OFFERS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Offers
                <span className="collectionDetails-content-titleCount"> ({getValues('offers')?.length || 0}/15) </span>
                {getValues('offers')?.length < 15 ?
                  <span className="collectionDetails-content-titleIcon"><WarningIcon /></span> :
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                }
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('offers')?.length ?
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button variant="outlined" startIcon={<PlusIcon />}>Add offer</Button>
                  </Link>
                  :
                  <span className="collectionDetails-content-title-actionText">
                    Find more <Link href="/ui/content-display" target="_blank">Offers</Link>
                  </span>
                }
              </div>
            </div>
            <div className="collectionDetails-content-info" id='offers'>
              {!getValues('offers')?.length ?
                <span className="collectionDetails-content-info-empty">No offers added yet.</span> :
                <CollectionBoard
                  items={getValues('offers') || []}
                  heightEntity={300}
                  widthEntity={200}
                  entityType={'OFFER'}
                  parentsWidth={calculatedInfoWidth}
                  isCanDrag={!disableReordering}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeteleEntities({ entities, entityType: 'OFFER', prevQuantity: getValues('offers')?.length })
                  }
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  collectionId={collectionDetails.id}
                  onSortEntities={(entities: OrderedEntity[]) => {
                    setValue('offers', entities as OrderedOffer[], { shouldDirty: true })
                  }}
                />
              }
            </div>
          </section>

          {/* WISHES */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Wishes
                <span className="collectionDetails-content-titleCount"> ({getValues('wishes')?.length || 0}/15) </span>
                {getValues('wishes')?.length < 15 ?
                  <span className="collectionDetails-content-titleIcon"><WarningIcon /></span> :
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                }
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('wishes')?.length ?
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button variant="outlined" startIcon={<PlusIcon />}>Add wish</Button>
                  </Link>
                  :
                  <span className="collectionDetails-content-title-actionText">
                    Find more <Link href="/ui/content-display" target="_blank">Wishes</Link>
                  </span>
                }
              </div>
            </div>
            <div className="collectionDetails-content-info" id='wishes'>
              {!getValues('wishes')?.length ?
                <span className="collectionDetails-content-info-empty">No wishes added yet.</span> :
                <CollectionBoard
                  items={getValues('wishes') || []}
                  heightEntity={280}
                  widthEntity={200}
                  entityType={'WISH'}
                  parentsWidth={calculatedInfoWidth}
                  isCanDrag={!disableReordering}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeteleEntities({ entities, entityType: 'WISH', prevQuantity: getValues('wishes')?.length })
                  }
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  collectionId={collectionDetails.id}
                  onSortEntities={(entities: OrderedEntity[]) => {
                    setValue('wishes', entities as OrderedWish[], { shouldDirty: true })
                  }}
                />
              }
            </div>
          </section>

          {/* EVENTS */}
          <section className="collectionDetails-content">
            <div className="collectionDetails-content-title">
              <div>
                Top Events
                <span className="collectionDetails-content-titleCount"> ({getValues('events')?.length || 0}/15) </span>
                {getValues('events')?.length < 15 ?
                  <span className="collectionDetails-content-titleIcon"><WarningIcon /></span> :
                  <span className="collectionDetails-content-titleIcon collectionDetails-content-titleIcon_full">
                    <CheckCircleIcon />
                  </span>
                }
              </div>
              <div className="collectionDetails-content-title-action">
                {!getValues('events')?.length ?
                  // todo когда в контент дисплее будет навигация к конкретной сущности, поменять на конкретную.
                  <Link href="/ui/content-display" target="_blank">
                    <Button variant="outlined" startIcon={<PlusIcon />}>Add Event</Button>
                  </Link>
                  :
                  <span className="collectionDetails-content-title-actionText">
                    Find more <Link href="/ui/content-display" target="_blank">Events</Link>
                  </span>
                }
              </div>
            </div>
            <div className="collectionDetails-content-info" id='events'>
              {!getValues('events')?.length ?
                <span className="collectionDetails-content-info-empty">No events added yet.</span> :
                <CollectionBoard
                  items={getValues('events') || []}
                  heightEntity={176}
                  widthEntity={322}
                  entityType={'EVENT'}
                  parentsWidth={calculatedInfoWidth}
                  isCanDrag={!disableReordering}
                  setIsDeleteEntities={(entities: string[]) =>
                    setIsDeteleEntities({ entities, entityType: 'EVENT', prevQuantity: getValues('events')?.length })
                  }
                  isDefaultCollection={collectionDetails.id === 'DEFAULT'}
                  collectionId={collectionDetails.id}
                  onSortEntities={(entities: OrderedEntity[]) => {
                    setValue('events', entities as OrderedEvent[], { shouldDirty: true })
                  }}
                />
              }
            </div>
          </section>
        </div>
      </div>
      {isDeleteEntities &&
        <Modal customstyle={{ minHeight: 180 }} open={!!isDeleteEntities}>
          <DeleteEntities
            willUnpublishWarning={isDeleteEntities.entityType !== 'BANNER' && isDeleteEntities.prevQuantity - isDeleteEntities.entities.length < 15}
            items={isDeleteEntities.entities}
            entityType={isDeleteEntities.entityType}
            onClose={() => {
              setIsDeteleEntities(null);
            }}
            deleteEntities={handleDeleteEntities}
          />
        </Modal>
      }
      {!!archiveCollections &&
        <Modal customstyle={{ minHeight: 208 }} open={!!archiveCollections}>
          <ArchiveCollectionsModal status={'idle'} onClose={() => setArchiveCollections(null)} items={archiveCollections!} />
        </Modal>
      }
      {!!publishCollections &&
        <Modal customstyle={{ minHeight: 212 }} open={!!publishCollections}>
          <PublishCollectionsModal
            status={'idle'}
            onClose={() => setPublishCollections(null)}
            items={publishCollections!}
          />
        </Modal>
      }
      {!!unpublishCollections &&
        <Modal customstyle={{ minHeight: 180 }} open={!!unpublishCollections}>
          <UnpublishCollectionModal onClose={() => setUnpublishCollections(null)} item={unpublishCollections!} />
        </Modal>
      }
    </form>
  )
};

export default CollectionDetailsForm;
