import './index.scss';
import { AvatarProps } from '@mui/material/Avatar/Avatar';
import Avatar from 'components/shared/Avatar/Avatar';
import { getAuraColor } from 'utils/getAuraColor';
import Tooltip from '../Tooltip/Tooltip';

interface IAvatarItemProps extends AvatarProps {
  primary?: boolean;
  label?: string;
  primaryText?: string;
  subText?: string;
  levelAura?: string;
  sizes?: string;
  textColor?: string;
}

const AvatarItem = (props: IAvatarItemProps) => {
  return (
    <div
      className={`avatar-item-container${props.sizes ? ` ${props.sizes}` : ''}${
        props.onClick ? ' avatar-item-container--clickable' : ''
      }`}
      onClick={props.onClick && props.onClick}
    >
      <div>
        <Avatar
          variant={props.sizes === 'small' ? 'rounded' : undefined}
          alt={props.primaryText}
          src={props.src}
          sx={{
            border: getAuraColor(props.levelAura) && `0.5px solid ${getAuraColor(props.levelAura).border}`,
          }}
        />
      </div>
      <div className="avatar-item-text">
        <Tooltip title={props.primaryText} followCursor placement="top-end">
          <div className={`avatar-item-primary${props.textColor ? ` ${props.textColor}` : ''}`}>
            <div className="avatar-item-primary-description">{props.primaryText || ' '}</div>
          </div>
        </Tooltip>
        <div className={`avatar-item-subtext${props.textColor ? ` ${props.textColor}` : ''}`}>
          <Tooltip
            title={props?.subText && props?.subText?.length > 20 && props?.subText}
            followCursor
            placement="top-end"
          >
            <div>{props?.subText}</div>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};

export default AvatarItem;
