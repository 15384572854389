import LocationIcon from "assets/img/LocationIcon";
import WiFiIcon from "assets/img/WiFiIcon";

import './index.scss'
import AttentionIcon from "assets/img/AttentionIcon";

const StatusBlock = ({status, irl}: any) => {
  return (
    <div className="statusBlock--outerContainer">
      <div className={`statusBlock--container ${status.toLowerCase()}`}>
        {status === 'ONLINE' ? <WiFiIcon /> : <></>}
        {status === 'OFFLINE' ? <LocationIcon /> : <></>}
        {status === 'CANCELED' ? <AttentionIcon /> : <></>}
        {(status === 'OFFLINE' && irl) ? 'irl' : status}
      </div>
    </div>
  )
}

export default StatusBlock;