import { ReactNode } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import Typography from 'components/shared/Typography/Typography';
import withAuth from "components/Authorization/withAuth";
import './index.scss';

interface PageData {
  status: number;
  subtitle: string;
  img: string;
  descriptionTitle: string;
  descriptionInfo: ReactNode;
  descriptionContactString: string;
}

const adminEmail = process.env.REACT_APP_ADMIN_EMAIL as string;

export const ErrorBoundaryErrors = [
  'Request failed with status code 400',
  'Request failed with status code 403',
  'Request failed with status code 404',
  'Request failed with status code 500',
  'Request failed with status code 502',
  'Request failed with status code 503',
  'Request failed with status code 504',
];

function ErrorPage({ error, resetErrorBoundary }: { error: Error; resetErrorBoundary(): void }) {
  let pageData: PageData = {
    status: 400,
    subtitle: 'Bad request',
    img: '',
    descriptionTitle: 'Your client has issued a malformed or illegal request',
    descriptionInfo: <></>,
    descriptionContactString: 'If the problem persists contact to admin:',
  };

  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address was copied to clipboard', { variant: 'success' as VariantType });
      return navigator.clipboard.writeText(adminEmail);
    }
  };

  switch (error?.message) {
    case 'Request failed with status code 400': {
      pageData = {
        status: 400,
        subtitle: 'Bad request',
        descriptionTitle: 'Your client has issued a malformed or illegal request',
        descriptionInfo: (
          <>Try to double-check the address or refresh the page. Also you can choose other module from menu bar.</>
        ),
        descriptionContactString: 'If the problem persists contact to admin: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 403': {
      pageData = {
        status: 403,
        subtitle: 'Forbidden',
        descriptionTitle: 'You have insufficient right to access this page',
        descriptionInfo: <>Please, choose other module from menu bar.</>,
        descriptionContactString: 'If you think it’s a mistake, contact to admin: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 404': {
      pageData = {
        status: 404,
        subtitle: 'Not found',
        descriptionTitle: 'Page you have requested does not exist',
        descriptionInfo: (
          <>
            The error may be caused by typo in the link or page was deleted. <br />
            Try to double-check the address or choose other module from menu bar.
          </>
        ),
        descriptionContactString: 'If you think it’s a mistake, contact to admin: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 500': {
      pageData = {
        status: 500,
        subtitle: 'Internal server error',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who have detected this error.</>,
        descriptionContactString: 'So let admin know about the issue: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 502': {
      pageData = {
        status: 502,
        subtitle: 'Bad gateway',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who have detected this error.</>,
        descriptionContactString: 'So let admin know about the issue: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 503': {
      pageData = {
        status: 503,
        subtitle: 'Service is unavailable',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who have detected this error.</>,
        descriptionContactString: 'So let admin know about the issue: ',
      } as PageData;
      break;
    }
    case 'Request failed with status code 504': {
      pageData = {
        status: 504,
        subtitle: 'Gateway timeout',
        descriptionTitle: 'We are currently trying to fix the issue',
        descriptionInfo: <>Perhaps you are the first who have detected this error.</>,
        descriptionContactString: 'So let admin know about the issue: ',
      } as PageData;
      break;
    }
  }

  return (
    <div className="ErrorPage">
      <header className="ErrorPage-header">
        <Typography variant="errorTitle">Error {pageData.status}</Typography>
        <Typography variant="errorSubtitle" color="text.secondary">
          {pageData.subtitle}
        </Typography>
      </header>
      <div className="ErrorPage-content">
        <div className={`ErrorPage-content-image ErrorPage-content-image-${pageData.status}`} />
        <div className="ErrorPage-content-description">
          <Typography variant="h5" className="ErrorPage-content-descriptionTitle">
            {pageData.descriptionTitle}
          </Typography>
          <Typography variant="body1" color="text.secondary" className="ErrorPage-content-descriptionInfo">
            {pageData.descriptionInfo}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {pageData.descriptionContactString}
            <Typography variant="alertTitle">{adminEmail} </Typography>
            <IconButton
              className="ErrorPage-content-descriptionInfo-button"
              size="small"
              onClick={() => void copyEmail()}
            >
              <CopyIcon color="disabled" fontSize="small" />
            </IconButton>
          </Typography>
        </div>
      </div>
    </div>
  );
}

export default withAuth(ErrorPage);
