import { Button as ButtonBase } from '@mui/material';
import { ButtonProps } from '@mui/material/Button/Button';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';

import './index.scss';

interface ExpandButtonProps extends ButtonProps {
  isExpanded: boolean;
  onClick: () => void;
}

const ExpandButton: React.FC<ExpandButtonProps> = ({ isExpanded, onClick }) => {
  return (
    <ButtonBase
      className="expandedDescription--button"
      onClick={onClick}
      endIcon={isExpanded ? <ChevronUpIcon /> : <ChevronDownIcon />}
    >
      {isExpanded ? 'Collapse' : 'Expand'}
    </ButtonBase>
  );
};

export default ExpandButton;
