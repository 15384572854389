import { useCallback, useEffect, useRef, useState } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { AnimatePresence, motion } from 'framer-motion';
import { TaxModerationRoutes } from 'routes/enum';
import { LocalStorageKeys, Permissions } from 'types/commonTypes';
import { TaxModerationSolution } from 'store/slices/TaxModeration/interface';
import { checkPermissions } from 'utils/checkPermissions';
import { selectUserPermissions } from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchChangeTaxModerationSolution,
  fetchTaxModerationCard,
  fetchTaxModerationCards,
  removeApprovedCardId,
  selectTaxModerationCardDetails,
  selectTaxModerationCardIds,
  selectTaxModerationStatus,
  setTaxModerationStatus,
} from 'store/slices/TaxModeration/taxModerationSlice';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowLeft from 'assets/img/ArrowLeft';
import ArrowRight from 'assets/img/ArrowRight';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import { taxModerationCardSolutions, taxModerationCardStatuses, TaxModerationListType } from '../constants';
import { ChangeTaxCodeModal, ApprovalConfirmModal } from '../modals';
import { RenderTaxModerationCard } from '../components';

export const TaxModerationCarousel = () => {
  const dispatch = useAppDispatch();
  const { listType, id: cardId } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const userPermissions = useAppSelector(selectUserPermissions);
  const taxModerationCardDetails = useAppSelector(selectTaxModerationCardDetails);
  const allIds = useAppSelector(selectTaxModerationCardIds);
  const status = useAppSelector(selectTaxModerationStatus);
  let isLoading = status === 'loading';

  const localData = localStorage.getItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP);
  const salesTaxApprovalConfirmSkip = localData !== null ? !!JSON.parse(localData) : false;

  const [isOpenSalesTaxApprovalModal, setIsOpenSalesTaxApprovalModal] = useState<boolean>(false);
  const [isOpenChangeTaxCodeModal, setIsOpenChangeTaxCodeModal] = useState<boolean>(false);
  const [isNewTaxCode, setIsNewTaxCode] = useState<boolean>(false);
  const direction = useRef(0);
  const paginateCard = (newDirection: number) => {
    direction.current = newDirection;
  };

  const initialFetchData = useCallback(async () => {
    dispatch(setTaxModerationStatus('loading'));
    cardId && (await dispatch(fetchTaxModerationCard(cardId)));
  }, [dispatch, cardId]);

  const handleCloseTaxCodeModal = (isSucceed: boolean) => {
    setIsOpenChangeTaxCodeModal(false);

    setIsNewTaxCode(isSucceed);
    if (listType) {
      dispatch(
        fetchTaxModerationCards({
          page: 0,
          pageSize: 25,
          listType: listType.toUpperCase() as TaxModerationListType,
        }),
      );
    }
    setTimeout(() => {
      setIsNewTaxCode(false);
    }, 2000);
  };

  const handleApproveButtonClick = () => {
    if (!salesTaxApprovalConfirmSkip) {
      setIsOpenSalesTaxApprovalModal(true);
    } else void handleCardApproval();
  };

  const handleNavigateToNextCard = (withApprove?: boolean) => {
    if (cardId) {
      const currentIndex = allIds.indexOf(cardId);
      const nextIndex = currentIndex === allIds.length - 1 ? 0 : currentIndex + 1;
      const nextId = allIds[nextIndex];
      if (withApprove) {
        dispatch(removeApprovedCardId(cardId));
        dispatch(
          fetchTaxModerationCards({
            page: 0,
            pageSize: 25,
            listType: listType as TaxModerationListType,
          }),
        ).then(({ payload }) => {
          if (payload?.allIds?.length === 0 && listType?.toUpperCase() === TaxModerationListType.UNRESOLVED) {
            navigate(TaxModerationRoutes.TAX_MODERATION_ALL_RESOLVED);
            return;
          }
        });
      }
      navigate(`/ui/tax-moderation/${listType}/details/${nextId}`);
      paginateCard(1);
    }
  };

  const handleNavigateToPreviousCard = () => {
    if (cardId && allIds.length) {
      const currentIndex = allIds.indexOf(cardId);
      const previousIndex = currentIndex === 0 ? allIds.length - 1 : currentIndex - 1;
      const previousId = allIds[previousIndex];
      navigate(`/ui/tax-moderation/${listType}/details/${previousId}`);
      paginateCard(-1);
    }
  };

  const handleCardApproval = async () => {
    setIsOpenSalesTaxApprovalModal(false);

    const formattedData = {
      salesTaxModerationId: cardId ? cardId : null,
      newSalesTaxCodeId: null,
      solution: TaxModerationSolution.APPROVED,
    };

    try {
      const result = await dispatch(fetchChangeTaxModerationSolution(formattedData));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(`Approved`, {
          autoHideDuration: 1000,
          variant: 'success' as VariantType,
        });

        if (cardId) {
          setTimeout(() => {
            handleNavigateToNextCard(true);
          }, 1000);
        }
      } else if (result.payload.meta) {
        const { title } = result.payload;
        enqueueSnackbar(`An error occurred during card resolution: ${title}`, {
          variant: 'error' as VariantType,
        });
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error' as VariantType,
      });
    }
  };

  const currentSolution = taxModerationCardSolutions.find(
    (el) => taxModerationCardDetails?.solution?.toLowerCase() === el.solution?.toLowerCase(),
  );
  const currentStatus = taxModerationCardStatuses.find(
    (el) => taxModerationCardDetails?.status?.toLowerCase() === el.status?.toLowerCase(),
  );

  const isDisabledChangeButton =
    taxModerationCardDetails?.solution === TaxModerationSolution.APPROVED
      ? !checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_EDIT_SOLVED_DECISION])
      : !checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_TAX_CHANGE_CODE]);
  const isDisabledApproveButton =
    taxModerationCardDetails?.solution !== TaxModerationSolution.APPROVED &&
    checkPermissions(userPermissions, [Permissions.SALES_TAX_MODERATION_UNRESOLVED_APPROVE]);
  const isDisabledScrollButton = !(allIds.length > 1);

  const variants = {
    enter: () => {
      return {
        x: direction.current > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: () => {
      return {
        x: direction.current < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  useEffect(() => {
    initialFetchData();
  }, [location.pathname]);

  return (
    <>
      <AnimatePresence initial={false} mode="wait">
        <Stack className="tax-moderation-carousel" height={1} mt={2} gap={2}>
          <Stack gap={2} className="tax-moderation-carousel__content">
            <AnimatePresence initial={false} mode="wait">
              <motion.div
                key={location.pathname}
                variants={variants}
                initial="enter"
                animate="center"
                exit="exit"
                transition={{
                  x: { type: 'spring', stiffness: 300, damping: 30 },
                  opacity: { duration: 0.2 },
                }}
              >
                <RenderTaxModerationCard
                  currentSolution={currentSolution}
                  currentStatus={currentStatus}
                  handleApproveButtonClick={handleApproveButtonClick}
                  isDisabledApproveButton={isDisabledApproveButton}
                  isDisabledChangeButton={isDisabledChangeButton}
                  isLoading={isLoading}
                  isNewTaxCode={isNewTaxCode}
                  setIsOpenChangeTaxCodeModal={setIsOpenChangeTaxCodeModal}
                  taxModerationCardDetails={taxModerationCardDetails}
                />
              </motion.div>
            </AnimatePresence>

            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Button
                color="primary"
                size="medium"
                startIcon={<ArrowLeft />}
                onClick={handleNavigateToPreviousCard}
                disabled={isDisabledScrollButton}
              >
                Previous card
              </Button>
              <Button
                color="primary"
                size="medium"
                endIcon={<ArrowRight />}
                onClick={() => handleNavigateToNextCard(false)}
                disabled={isDisabledScrollButton}
              >
                Next card
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </AnimatePresence>
      <ApprovalConfirmModal
        onCardApproval={() => handleCardApproval()}
        open={isOpenSalesTaxApprovalModal}
        onClose={() => setIsOpenSalesTaxApprovalModal(false)}
      />
      <ChangeTaxCodeModal
        currentSelectedSalesTax={taxModerationCardDetails}
        onClose={(isSucceed) => handleCloseTaxCodeModal(isSucceed)}
        open={isOpenChangeTaxCodeModal}
        shouldKeepTaxCode
      />
    </>
  );
};
