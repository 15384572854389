import { Box, Modal as ModalBase } from '@mui/material';
import { ModalProps } from '@mui/material/Modal/Modal';
import './index.scss';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: 444,
  minHeight: 220,
  boxSizing: 'border-box',
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: 1,
  padding: '16px 24px 24px',
  display: 'flex',
  flexDirection: 'column',
  outline: 'none',
};

interface IModalProps extends ModalProps {
  primary?: boolean;
  label?: string;
  customstyle?: any;
}

const Modal = (props: IModalProps) => {
  return (
    <ModalBase {...props} className="modal">
      <Box sx={{ ...style, ...props.customstyle }}>{props.children || <div></div>}</Box>
    </ModalBase>
  );
};

export default Modal;
