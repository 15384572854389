import React, { useCallback, useEffect, useMemo, useState } from "react";
import Button from "components/shared/Button/Button";
import {
  fetchAddEntityToTimWorld,
  fetchLinkedCollections,
  selectLinkedCollections,
  selectLinkedCollectionsColumns,
  selectWorldStatus,
} from "store/worldManagementSlice";
import Grid from "components/shared/Grid/Grid";
import { GridColDef, GridRenderCellParams, GridRowParams, GridSelectionModel } from "@mui/x-data-grid";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { getEntityDescription } from "components/ManageLinkedCollections/utils/getEntityDescription";
import Modal from "components/shared/Modal/Modal";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import { enqueueSnackbar, VariantType } from "notistack";
import Alert from "components/shared/Alert/Alert";

import './index.scss';

interface IProps {
  entityType: 'BANNER' | 'HERO' | 'OFFER' | 'WISH' | 'EVENT';
  entityId: string;
  /* Тайтл баннера для хедера. Остальные сущности отображаются c id */
  entityTitle?: string;
  onClose(): void;
  open: boolean;
  shouldDisabledCollection?: string;
}

// todo тут будет добавлена иконка с тултипом в хедер. сейчас как раз дизайн доделывается.
const ManageLinkedCollectionsModal = ({ entityType, entityId, entityTitle, onClose, open, shouldDisabledCollection }: IProps) => {
  const [initialSelection, setInitialSelection] = useState<string[]>([]);
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [errorMessage, setErrorMessage] = useState('');

  const entityDescription = useMemo(() => getEntityDescription(entityType), [entityType]);

  const linkedCollections = useAppSelector(selectLinkedCollections);
  const linkedCollectionsColumns = useAppSelector(selectLinkedCollectionsColumns);
  const status = useAppSelector(selectWorldStatus);

  const dispatch = useAppDispatch();
  const title = `${entityDescription.title} ${entityTitle ?? '#' + entityId}`

  const customCellRender: any = useMemo(() => {
    return {
    'name': (props: GridRenderCellParams<any>) => (
      <div className='item-content'>
        <div className='item-primary'>{props.row?.title}</div>
        {props.row?.id !== 'DEFAULT' &&
          <div className='item-secondary'>{props.row?.creatorName} {props.row?.creatorLastName}</div>
        }
      </div>
    ),
    'cohort': (props: GridRenderCellParams<string>) => (
      <div className="cohort-cell">{props.row?.cohortTitle}</div>
    ),
    'entities': (props: GridRenderCellParams<string[]>) => {
      let length = props.row?.[entityDescription.entityCountProp];

      if (!props.row?.entityIncluded && selectionModel.includes(props.row?.id)) {
        length += 1;
      }

      if (props.row?.entityIncluded && !selectionModel.includes(props.row?.id)) {
        length -= 1;
      }

      return (
        <div className={
          `entitiesCount-cell ${length >= entityDescription.minAllowedNumberOfEntities ? 'entitiesCount-cell_success' : ''}`
        }>
          {length} {length === 1 ? entityDescription.singularEntityName : entityDescription.pluralEntityName}
        </div>
      )
    },
  }}, [entityType, entityDescription, selectionModel]);

  const rows: any = useMemo(() => {
    return linkedCollections?.map((item: any) => ({ ...item, id: item.id })) ?? [];
  }, [linkedCollections]);

  const cols: GridColDef[] = useMemo(() => {
    return linkedCollectionsColumns.map((item: any) => ({
      field: item.columnId,
      flex: item.flex,
      renderCell: customCellRender[item.columnId] ?? null,
    }));
  }, [linkedCollectionsColumns, selectionModel]);

  useEffect(() => {
    if (linkedCollections) {
      const newArr: string[] = [];
      linkedCollections?.forEach((currentValue) => {
        if (currentValue.entityIncluded) {
          newArr.push(currentValue.id);
        }
      }, [])
      setSelectionModel(newArr);
      setInitialSelection(newArr);
    }
  }, [linkedCollections]);

  useEffect(() => {
    dispatch(fetchLinkedCollections({ entityId, entityType }));
  }, [entityId, entityType]);

  const handleSubmit = useCallback(async () => {
    try {
      const result = await dispatch(fetchAddEntityToTimWorld( {
        entityId,
        entityType,
        compilationIds: selectionModel as string[],
      }));
      if (result.meta.requestStatus === 'fulfilled') {
        onClose();
        enqueueSnackbar(`${title} collections list has been changed`, { variant: 'success' as VariantType });
      }
      if (result.meta.requestStatus === 'rejected') {
        setErrorMessage('Saving linked collections list error. Try later');
      }
    } catch (e) {
      setErrorMessage('Saving linked collections list error. Try later');
      return;
    }
  }, [selectionModel, title]);

  return (
    <Modal customstyle={{ minHeight: 200, padding: 0 }} open={open}>
      <div className='linkedCollectionsModal'>
        <div className="linkedCollectionsModal-header">
          {title} linked collections
        </div>
        <div className={`linkedCollectionsModal-content ${!rows.length ? 'linkedCollectionsModal-content_empty' : ''}`}>
          <Grid
            pagination={false}
            checkboxSelection={true}
            columns={cols}
            rowHeight={54}
            disableColumnResize={true}
            disableColumnMenu={true}
            isRowSelectable={(params: GridRowParams) => !(params.row.status === 'LIVE' && (params.row.id !== 'DEFAULT') || (params.row.id === shouldDisabledCollection))}
            rows={rows}
            headerHeight={0}
            onSelectionModelChange={(newSelectionModel) => {
              setSelectionModel(newSelectionModel);
            }}
            selectionModel={selectionModel}
            components={{
              NoRowsOverlay: () => <span className="empty-cell">No data to display.</span>
            }}
            hideFooter
            loading={false}
          />
        </div>
        <div className="linkedCollectionsModal-footer">
          {!errorMessage && <Alert severity="warning">
            This {entityDescription.alertEntityName} is linked to {initialSelection.length} collections yet
          </Alert>
          }
          <div className="linkedCollectionsModal-footer-buttons">
            {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
            <Button
              onClick={onClose}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              loading={status === 'loading'}
              disabled={initialSelection == selectionModel}
              variant="contained"
              color="primary"
              onClick={handleSubmit}
            >
              Confirm
            </LoadingButton>
          </div>
        </div>
      </div>
    </Modal>
  )
};

export default ManageLinkedCollectionsModal;
