import React, { useCallback, useEffect, useState } from 'react';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useDocumentTitle } from '@uidotdev/usehooks';
import { useErrorBoundary } from 'react-error-boundary';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAvatar, fetchGlobalSignOut, selectAvatar, selectAvatarPreview, selectProfile } from 'store/profileSlice';
import { logout, selectSnackbarKeys, updateSnackbarKeys } from 'store/authSlice';
import InfoIcon from 'assets/img/InfoIcon';
import CopyIcon from 'assets/img/CopyIcon';
import ChangePassword from 'components/Profile/ProfileModal/ChangePassword';
import GlobalLogout from 'components/Profile/ProfileModal/GlobalLogout';
import IconButton from 'components/shared/IconButton/IconButton';
import MemberProfile from 'components/Profile/MemberProfile/MemberProfile';
import Modal from 'components/shared/Modal/Modal';
import SetAvatar from 'components/Profile/ProfileModal/SetAvatar';
import withAuth from 'components/Authorization/withAuth';
import { action } from 'containers/SnackbarContainer';
import { ErrorBoundaryErrors } from 'containers/ErrorPage/ErrorPage';
import './index.scss';

const adminEmail = process.env.REACT_APP_ADMIN_EMAIL as string;

export type AvatarAction = 'add' | 'edit' | 'replace';

function App() {
  useDocumentTitle('Profile');

  const [showGlobalLogoutModal, setShowGlobalLogoutModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showSetAvatar, setShowSetAvatar] = useState<{ show: boolean; action: AvatarAction }>({
    show: false,
    action: 'add',
  });

  const memberAvatar = useAppSelector(selectAvatar);
  const memberAvatarPreview = useAppSelector(selectAvatarPreview);
  const memberProfile = useAppSelector(selectProfile);
  const snackbarKeys = useAppSelector(selectSnackbarKeys);

  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();

  const copyEmail = async () => {
    if (navigator.clipboard) {
      enqueueSnackbar('Email address was copied to clipboard', { variant: 'success' as VariantType });
      return navigator.clipboard.writeText(adminEmail);
    }
  };

  const handleGlobalLogout = useCallback(async () => {
    try {
      const result = await dispatch(fetchGlobalSignOut());
      if (result.meta.requestStatus === 'fulfilled') {
        dispatch(logout({ message: '' }));
        setShowGlobalLogoutModal(false);
        const key = enqueueSnackbar('Session has been ended. You have to relogin', {
          action,
          autoHideDuration: null,
          variant: 'warning' as VariantType,
        });
        dispatch(updateSnackbarKeys([...snackbarKeys, key]));
      }
      if (result.meta.requestStatus === 'rejected') {
        const key = enqueueSnackbar('Logging out error. Try later', { variant: 'error' as VariantType });
        dispatch(updateSnackbarKeys([...snackbarKeys, key]));
      }
    } catch (e) {
      const key = enqueueSnackbar('Logging out error. Try later', { variant: 'error' as VariantType });
      dispatch(updateSnackbarKeys([...snackbarKeys, key]));
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAvatar());
  }, []);

  return (
    <div className="profile-container">
      <div className="profile-header">
        Profile
        <div className="profile-header-info">
          <InfoIcon />
          If your credentials were changed, please contact your manager
          {/*<span>*/}
          {/*  {adminEmail}*/}
          {/*  <IconButton onClick={copyEmail}>*/}
          {/*    <CopyIcon />*/}
          {/*  </IconButton>*/}
          {/*</span>*/}
        </div>
      </div>
      <MemberProfile
        memberProfile={memberProfile}
        avatarPreview={memberAvatarPreview}
        setShowChangePasswordModal={() => setShowChangePasswordModal(true)}
        setShowGlobalLogoutModal={() => setShowGlobalLogoutModal(true)}
        setShowSetAvatar={(action: AvatarAction) => setShowSetAvatar({ show: true, action })}
      />
      <Modal customstyle={{ minHeight: 212 }} open={showGlobalLogoutModal}>
        <GlobalLogout onClose={() => setShowGlobalLogoutModal(false)} logout={() => handleGlobalLogout()} />
      </Modal>
      <Modal open={showChangePasswordModal}>
        <ChangePassword onClose={() => setShowChangePasswordModal(false)} />
      </Modal>
      <Modal open={showSetAvatar.show}>
        <SetAvatar
          onClose={() => setShowSetAvatar({ show: false, action: 'add' })}
          avatar={showSetAvatar.action === 'edit' ? memberAvatar : null}
          avatarAction={showSetAvatar.action}
        />
      </Modal>
    </div>
  );
}

export default withAuth(App);
