import { useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { IUserStatus, UserStatuses } from '../interface';
import getValidationSchema from '../utils/getValidationSchema';
import { useAppDispatch } from 'store/hooks';
import { fetchBanUser, fetchRestoreUser } from 'store/userProfileSlice';
import Alert from 'components/shared/Alert/Alert';
import Button from 'components/shared/Button/Button';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import TextField from 'components/shared/TextField/TextField';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface IChangeStatusModalProps {
  open: boolean;
  currentSelectedStatus: IUserStatus | null;
  profileId: string;
  onClose: () => void;
  onDataRefresh: () => void;
}

export const ChangeUserStatusModal = ({
  open,
  currentSelectedStatus,
  profileId,
  onClose,
  onDataRefresh,
}: IChangeStatusModalProps) => {
  const dispatch = useAppDispatch();
  const schema = useMemo(() => getValidationSchema(), []);

  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);
  const [isShowError, setIsShowError] = useState(false);

  const {
    reset,
    handleSubmit,
    register,
    trigger,
    formState: { errors, isValid, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      comment: '',
    },
  });

  const confirmButtonColor = currentSelectedStatus?.status === UserStatuses.BANNED ? 'error' : 'primary';
  const isConfirmButtonDisabled = currentSelectedStatus?.status === UserStatuses.ACTIVE ? false : !isValid || !isDirty;

  const handleClose = () => {
    setIsShowError(false);
    reset();
    onClose();
  };

  const onSubmit = async (data: { comment: string }) => {
    void trigger();

    let isResultReceived = false;
    const timer = setTimeout(() => {
      if (!isResultReceived) {
        enqueueSnackbar(`User #${profileId} changing status in progress`, {
          variant: 'info' as VariantType,
        });
      }
    }, 10000);

    const formattedData = {
      profileId: profileId,
      comment: data.comment.trim(),
    };

    try {
      let result;
      if (currentSelectedStatus?.status === UserStatuses.BANNED) {
        result = await dispatch(fetchBanUser(formattedData));
      } else if (currentSelectedStatus?.status === UserStatuses.ACTIVE) {
        result = await dispatch(fetchRestoreUser({ profileId: profileId }));
      }

      if (result) {
        if (result.meta.requestStatus === 'fulfilled') {
          isResultReceived = true;
          clearTimeout(timer);
          enqueueSnackbar(`User #${profileId} status has been changed to ${currentSelectedStatus?.status}`, {
            variant: 'success' as VariantType,
          });
          handleClose();
          onDataRefresh();
        } else if (result.meta.requestStatus === 'rejected') {
          isResultReceived = true;
          clearTimeout(timer);
          if (currentSelectedStatus?.status === UserStatuses.BANNED) {
            setIsShowError(true);
          } else if (currentSelectedStatus?.status === UserStatuses.ACTIVE) {
            enqueueSnackbar('Changing status error, try again later', {
              variant: 'error' as VariantType,
            });
          }
        }
      }
    } catch (error) {
      clearTimeout(timer);
      enqueueSnackbar('Changing status error, try again later', {
        variant: 'error' as VariantType,
      });
    }
  };

  return (
    <>
      <Dialog fullWidth maxWidth="xs" open={open}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <DialogTitle variant="h6">
            You are going to change user’s status to {currentSelectedStatus?.status}
          </DialogTitle>
          <DialogContent>
            {currentSelectedStatus?.status === UserStatuses.BANNED && (
              <TextField
                {...register('comment')}
                sx={{ m: '8px 0' }}
                size="small"
                name="comment"
                label="Comment"
                placeholder="Reason of ban or another info"
                type="text"
                fullWidth
                multiline
                rows={5}
                error={!!errors.comment}
                helperText={errors.comment && (errors.comment?.message as string)}
                onBlur={() => trigger('comment')}
              />
            )}
            {isShowError && (
              <Alert sx={{ mt: 2 }} severity="error">
                Changing status error, try again later
              </Alert>
            )}
          </DialogContent>

          <DialogActions>
            <Button
              onClick={() => {
                isDirty ? setIsOpenConfirmCancelModal(true) : handleClose();
              }}
              variant="text"
              color="primary"
            >
              Cancel
            </Button>
            <LoadingButton
              disabled={isConfirmButtonDisabled}
              variant="contained"
              color={confirmButtonColor}
              type="submit"
            >
              Confirm
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <ConfirmCancelModal
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={handleClose}
      />
    </>
  );
};
