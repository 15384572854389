import { GridOverlay } from '@mui/x-data-grid';
import colors from 'theme/constants/colors';
import CheckCircleIcon from 'assets/img/CheckCircleIcon';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';

interface IProps {
  isFilters?: boolean;
  ticketsGroup?: string;
  clearFilter?: () => void;
}

const NoMoreTickets = ({ isFilters, ticketsGroup, clearFilter }: IProps) => {
  return (
    <GridOverlay>
      {isFilters && (
        <Stack alignItems="center">
          <Typography color="text.secondary" variant="body2">
            No data to display. Try to remove any of filters or change its value.
          </Typography>
          <Button onClick={() => clearFilter && clearFilter()} variant="text">
            Clear filters
          </Button>
        </Stack>
      )}
      {!isFilters &&
        (ticketsGroup === 'My' ? (
          <Stack gap={2} alignItems="center">
            <CheckCircleIcon sx={{ fontSize: 32, color: colors.outlinedBorder }} />
            <Typography variant="h6" color={colors.outlinedBorder}>
              There are no tickets assigned to you.
            </Typography>
            <Typography variant="h6" color={colors.outlinedBorder}>
              Press GET TICKETS button if you want to request a batch of tickets
            </Typography>
          </Stack>
        ) : (
          <Stack alignItems="center">
            <Typography variant="h6" color={colors.outlinedBorder}>
              No tickets to display
            </Typography>
          </Stack>
        ))}
    </GridOverlay>
  );
};

export default NoMoreTickets;
