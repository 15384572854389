import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, SelectChangeEvent } from '@mui/material';
import { enqueueSnackbar, VariantType } from 'notistack';
import dayjs from 'dayjs';
import startCase from 'lodash/startCase';
import colors from 'theme/constants/colors';
import SubjectType from '../types/SubjectType';
import { EntityType } from 'types/commonTypes';
import ModerationTabs from 'components/Moderation/types/ModerationTabs';
import { IMediaProps } from 'components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer';
import { IStatusModal } from 'containers/Moderation/Moderation';
import CardType from 'components/Moderation/types/CardType';
import getRenderCardProps from 'components/Moderation/utils/getRenderCardProps';
import { fetchChangeStatus } from 'store/moderationSlice';
import { stringCapitalize } from 'utils/stringCapitalize';
import { useAppDispatch } from 'store/hooks';
import InfoIcon from 'assets/img/InfoIcon';
import Button from 'components/shared/Button/Button';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import Chip from 'components/shared/Chip/Chip';
import ModerationCardContent from 'components/Moderation/ModerationCard/ModerationCardContent/ModerationCardContent';
import Select, { ISelectOptions } from 'components/shared/Select/Select';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import ChangeTicketStatusModal from '../ChangeTicketStatusModal/ChangeTicketStatusModal';
import './index.scss';

interface IModerationCardProps {
  subjectTypeConfirmed: SubjectType;
  subjectTypeDeclined: SubjectType;
  showPreview: (newValue: IMediaProps) => void;
  onChangeStatus: () => void;
  showDetails: (entityInfo: any) => void;
  showUsersProfile: () => void;
  renderCardProps: any;
  ticketsGroup: string;
  tabValue: ModerationTabs;
  allMetrics: {
    content: string[];
    profile: string[];
  };
  dangerousMetrics: {
    hive: [];
    content: [];
    profile: [];
  };
}

const ModerationCard = ({
  subjectTypeConfirmed,
  subjectTypeDeclined,
  showPreview,
  renderCardProps,
  showDetails,
  onChangeStatus,
  tabValue,
  showUsersProfile,
  allMetrics,
  dangerousMetrics,
  ticketsGroup,
}: IModerationCardProps) => {
  const dispatch = useAppDispatch();

  const cardProps = useMemo(() => getRenderCardProps(renderCardProps, tabValue), [renderCardProps, tabValue]);
  const createdDate = useMemo(() => dayjs(cardProps?.createdDate).format('MM/DD/YYYY, h:mm A') || null, [cardProps]);
  const cardType: CardType = cardProps.cardType;
  const isManualMode = cardProps.manualMode;
  const moderatorId = 'moderatorId';

  const complaintsCriteria = useMemo(() => {
    if ((allMetrics && cardType === CardType.CONTENT) || isManualMode) {
      return allMetrics.content;
    }
    if (allMetrics && cardType === CardType.PROFILE) {
      return allMetrics.profile;
    }
    return [];
  }, [cardType, allMetrics, isManualMode]);

  const mostDangerousMetrics = useMemo(() => {
    if (dangerousMetrics && cardType === CardType.HIVE) {
      return dangerousMetrics.hive;
    }
    if (dangerousMetrics && cardType === CardType.CONTENT) {
      return dangerousMetrics.content;
    }
    if (dangerousMetrics && cardType === CardType.PROFILE) {
      return dangerousMetrics.profile;
    }
    return [];
  }, [cardType, dangerousMetrics]);

  const initialStatusViolation =
    tabValue === ModerationTabs.CONFIRMED_VIOLATIONS
      ? true
      : tabValue === ModerationTabs.DECLINED_REQUESTS
      ? false
      : undefined;

  const initialViolations = cardProps.confirmedComplaintsViolations || cardProps.confirmedHiveViolations || [];

  const [isShowChangeStatusModal, setIsShowChangeStatusModal] = useState<IStatusModal>({
    openModal: false,
    status: false,
    moderator: '',
    violations: [],
    onChangeStatus: () => {},
  });

  const [statusViolations, setStatusViolations] = useState<boolean | undefined>(initialStatusViolation);
  const [selectedViolations, setSelectedViolations] = useState<string[]>([]);
  const [isFullHeightCard, setIsFullHeightCard] = useState(false);

  const moderatorName = useMemo(() => {
    if (cardProps.hiveModerator) {
      return cardProps.hiveModerator.firstName + ' ' + cardProps.hiveModerator.lastName;
    }
    if (cardProps.complaintsModerator) {
      return cardProps.complaintsModerator.firstName + ' ' + cardProps.complaintsModerator.lastName;
    }
    return null;
  }, [cardProps.complaintsModerator, cardProps.hiveModerator]);

  const handleChangeStatus = useCallback(async (data: any) => {
    try {
      const result = await dispatch(fetchChangeStatus(data));
      if (result.meta.requestStatus === 'fulfilled') {
        enqueueSnackbar(
          `${stringCapitalize(data.entityType)} #${data.entityId} violation has been ${
            !data.status ? 'declined' : 'confirmed'
          } `,
          { variant: 'success' as VariantType },
        );
      }
      if (result.meta.requestStatus === 'rejected') {
        enqueueSnackbar('Decision has not been applied. Please, try again later.', { variant: 'error' as VariantType });
      }
    } catch (e) {
      enqueueSnackbar('Decision has not been applied. Please, try again later.', { variant: 'error' as VariantType });
    }
  }, []);

  const handleConfirmViolation = () => {
    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        openModal: true,
        status: true,
        moderator: moderatorName,
        violations: selectedViolations,
        onChangeStatus: () =>
          handleChangeStatus({
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            cardType,
            status: true,
            moderatorId,
            historical: true,
            manualMode: isManualMode,
            confirmedViolations:
              cardType === CardType.HIVE ? (isManualMode ? selectedViolations : null) : selectedViolations,
          }).then(() => {
            setSelectedViolations([]);
            onChangeStatus();
          }),
      });

      return;
    }
    setSelectedViolations([]);
    handleChangeStatus({
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      cardType,
      status: true,
      moderatorId,
      historical: false,
      manualMode: isManualMode,
      confirmedViolations: cardType === CardType.HIVE ? (isManualMode ? selectedViolations : null) : selectedViolations,
    }).then(() => {
      onChangeStatus();
    });
  };

  const handleNoViolation = () => {
    if (tabValue === ModerationTabs.CONFIRMED_VIOLATIONS || tabValue === ModerationTabs.DECLINED_REQUESTS) {
      setIsShowChangeStatusModal({
        openModal: true,
        status: false,
        moderator: moderatorName,
        violations: [],
        onChangeStatus: () =>
          handleChangeStatus({
            entityId: cardProps.entityId,
            entityType: cardProps.entityType,
            cardType,
            status: false,
            moderatorId,
            historical: true,
          }).then(() => {
            setSelectedViolations([]);
            onChangeStatus();
          }),
      });

      return;
    }
    setSelectedViolations([]);
    handleChangeStatus({
      entityId: cardProps.entityId,
      entityType: cardProps.entityType,
      cardType,
      status: false,
      moderatorId,
      historical: false,
    }).then(() => {
      onChangeStatus();
    });
  };

  const handleChangeViolations = (event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    setSelectedViolations(typeof value === 'string' ? value.split(',') : (value as string[]));
  };

  const handleDeleteViolation = (event: MouseEvent, deleted: string) => {
    event.preventDefault();
    setSelectedViolations(selectedViolations.filter((selected) => selected !== deleted));
  };

  const isAssignedToModerator = useMemo(() => {
    return cardProps.complaintsModerator !== null || cardProps.hiveModerator !== null;
  }, [cardProps]);

  const getCardType = (entityType: string) => {
    if (entityType?.toUpperCase() === EntityType.AVATAR) {
      return 'Profile pic of user';
    }
    if (entityType?.toUpperCase() === EntityType.ABOUT_ME) {
      return 'What im about of user';
    } else return startCase(entityType?.toLowerCase());
  };

  const getCardId = (entityType: string) => {
    if (entityType?.toUpperCase() === EntityType.ABOUT_ME || entityType?.toUpperCase() === EntityType.AVATAR) {
      return `#${cardProps?.entityOwnerInfo.entityOwnerId}`;
    }
    return `#${cardProps?.entityId}`;
  };

  useEffect(() => {
    setSelectedViolations([]);
  }, [subjectTypeConfirmed, subjectTypeDeclined]);

  return (
    <div className="card" style={{ maxHeight: isFullHeightCard ? '100%' : '400px', minHeight: '400px' }}>
      <div className="card-header">
        <div>
          <Typography variant="body1" className="card-header-name">
            {getCardType(cardProps?.entityType)} {getCardId(cardProps?.entityType)}
          </Typography>
          <Stack direction="row" alignItems="center" gap={0.5}>
            <Typography component="span" color="text.secondary" variant="caption">
              Ticket from {createdDate}
            </Typography>
            {isManualMode && (
              <Tooltip placement="top" followCursor title="Manual mode ticket. It wasn't checked by Hive">
                <InfoIcon sx={{ fontSize: 20, color: colors.primary }} />
              </Tooltip>
            )}
          </Stack>
        </div>
        {ticketsGroup === 'My' ||
        tabValue === ModerationTabs.CONFIRMED_VIOLATIONS ||
        tabValue === ModerationTabs.DECLINED_REQUESTS ? (
          <div className="card-buttons">
            {statusViolations !== undefined && (
              <div className="card-buttons-moderator">
                <span>{moderatorName} </span>
                {statusViolations ? 'confirmed violation' : "didn't confirm a violation"}
                {statusViolations && cardType !== CardType.HIVE && (
                  <>
                    &nbsp;for <span>{initialViolations[0]}</span>
                    {initialViolations.length > 1 && (
                      <Tooltip
                        arrow
                        placement="top"
                        title={initialViolations.map((violation: string) => (
                          <div key={violation}>{violation}</div>
                        ))}
                      >
                        <span>and {initialViolations.length - 1} others categories</span>
                      </Tooltip>
                    )}
                  </>
                )}
              </div>
            )}
            {(isManualMode || cardType !== CardType.HIVE) && !statusViolations && (
              <Select
                variant="outlined"
                placeholder="Violation category"
                value={selectedViolations}
                renderValue={(selected) => {
                  const numTags = (selected as string[]).length;

                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: 1,
                        overflow: 'hidden',
                      }}
                    >
                      {(selected as string[]).slice(0, 1).map((value) => (
                        <Chip
                          key={value}
                          size="small"
                          label={value}
                          clickable={true}
                          onMouseDown={(event) => event.stopPropagation()}
                          onDelete={(event) => handleDeleteViolation(event, value)}
                        />
                      ))}
                      {numTags > 1 && ` +${numTags - 1}`}
                    </Box>
                  );
                }}
                onChange={handleChangeViolations}
                multiple={true}
                size="small"
                sx={{ minWidth: '245px' }}
                options={complaintsCriteria.map((criteria) => ({ value: criteria, text: criteria }) as ISelectOptions)}
              />
            )}
            {!statusViolations ? (
              <Button
                sx={{ minHeight: '40px' }}
                variant={
                  statusViolations === undefined || (cardType !== CardType.HIVE && !selectedViolations.length)
                    ? 'contained'
                    : 'outlined'
                }
                color="error"
                disabled={
                  !isManualMode ? cardType !== CardType.HIVE && !selectedViolations.length : !selectedViolations.length
                }
                onClick={handleConfirmViolation}
              >
                Confirm violation
              </Button>
            ) : null}
            {statusViolations === undefined || statusViolations ? (
              <Button
                sx={{ minHeight: '40px' }}
                variant={statusViolations === undefined ? 'contained' : 'outlined'}
                color="success"
                onClick={handleNoViolation}
              >
                No violation
              </Button>
            ) : null}
          </div>
        ) : (
          <></>
        )}
        {ticketsGroup === 'All' &&
        tabValue !== ModerationTabs.CONFIRMED_VIOLATIONS &&
        tabValue !== ModerationTabs.DECLINED_REQUESTS &&
        moderatorName ? (
          <div className="card-assign-info">
            {isAssignedToModerator ? (
              <>
                Assigned to <span>{moderatorName}</span>
              </>
            ) : (
              <></>
            )}
          </div>
        ) : (
          <></>
        )}
      </div>
      <ModerationCardContent
        mostDangerousMetrics={mostDangerousMetrics}
        violationsFrom={cardProps.violationsFrom}
        amountViolations={cardProps.amountViolations}
        entityInfo={cardProps.entityInfo}
        profileInfo={cardProps.profileInfo}
        moderationResults={cardProps.moderationJobResults}
        complaints={cardProps.complaints}
        entityOwnerInfo={cardProps.entityOwnerInfo}
        showPreview={showPreview}
        showDetails={showDetails}
        showUsersProfile={showUsersProfile}
        isManualMode={cardProps.manualMode}
        cardType={cardProps.cardType}
        isFullHeightCard={isFullHeightCard}
        tabValue={tabValue}
        setIsFullHeightCard={setIsFullHeightCard}
      />
      <ChangeTicketStatusModal
        open={isShowChangeStatusModal.openModal}
        onClose={() =>
          setIsShowChangeStatusModal({
            openModal: false,
            status: false,
            moderator: '',
            violations: [],
          })
        }
        status={isShowChangeStatusModal.status}
        moderator={isShowChangeStatusModal.moderator}
        violations={isShowChangeStatusModal.violations}
        onChangeStatus={isShowChangeStatusModal.onChangeStatus}
      />
    </div>
  );
};

export default ModerationCard;
