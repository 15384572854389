import { MouseEvent, useState } from 'react';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { enqueueSnackbar, VariantType } from 'notistack';
import { useHover } from '@uidotdev/usehooks';
import { IOperation, TransactionTypes } from 'store/slices/Payments/interface';
import { fetchUserProfile, selectUserProfile } from 'store/userProfileSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import CopyIcon from 'assets/img/CopyIcon';
import IconButton from 'components/shared/IconButton/IconButton';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import { ProfilePopover } from '../modals';

/**
 * Props for the RenderPayerCell component.
 *
 * @interface
 * @property {GridRenderCellParams} props - Parameters for rendering the cell.
 * @property {IOperation} rowData - Operation data.
 */
interface IRenderPayerCellProps {
  props: GridRenderCellParams;
  rowData: IOperation;
}

/**
 * Renders a "Payer profile ID" cell in a data grid.
 */
export const RenderPayerCell = ({ props, rowData }: IRenderPayerCellProps) => {
  const { row } = props;
  const dispatch = useAppDispatch();
  const profileInfo = useAppSelector(selectUserProfile);

  const [ref, hovering] = useHover<HTMLDivElement>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [buttonLoadingState, setButtonLoadingState] = useState<{ [profileId: string]: boolean }>({});

  const handleLoadProfileInfo = async (profileId: string) => {
    setButtonLoadingState((prevLoadingStates) => ({
      ...prevLoadingStates,
      [profileId]: true,
    }));

    const result = await dispatch(fetchUserProfile(profileId));

    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Failed to load the profile. Try again later', {
        variant: 'error' as VariantType,
      });
    }

    setButtonLoadingState((prevLoadingStates) => ({
      ...prevLoadingStates,
      [profileId]: false,
    }));
  };

  if (!row.id) {
    return <></>;
  }

  const isSystemPayer = row.type !== TransactionTypes.CARD_PAYMENTS;

  const copyId = (e: MouseEvent<HTMLButtonElement>, value: string) => {
    e.stopPropagation();

    if (navigator.clipboard) {
      enqueueSnackbar('Copied to clipboard', { variant: 'success' as VariantType });

      return navigator.clipboard.writeText(value);
    }
  };

  return (
    <div className="MuiDataGrid-cellContent" ref={ref}>
      <Stack>
        <Stack direction="row" alignItems="center" gap="10px" sx={{ minHeight: 28 }}>
          <Tooltip placement="top" title={rowData.profileId}>
            <div style={{ display: 'inline-grid' }}>
              <Typography
                component="span"
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                }}
                variant="body2"
              >
                {(isSystemPayer ? 'YZZY' : rowData.profileId) || '—'}
              </Typography>
            </div>
          </Tooltip>

          {hovering && !isSystemPayer && rowData.profileId && (
            <Tooltip placement="top-end" title="Copy">
              <IconButton color="default" size="small" onClick={(e) => copyId(e, rowData.profileId)}>
                <CopyIcon sx={{ fontSize: 18 }} />
              </IconButton>
            </Tooltip>
          )}
        </Stack>

        {!isSystemPayer && (
          <>
            <ProfilePopover
              profileInfo={profileInfo}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              coordinates={coordinates}
              copyId={copyId}
              profileId={rowData.profileId}
            />
            <LoadingButton
              onClick={(event) => {
                void handleLoadProfileInfo(rowData.profileId);
                setAnchorEl(event.currentTarget);
                setCoordinates({ x: event.clientX, y: event.clientY });
              }}
              sx={{ mr: 'auto', minWidth: 'unset', p: '4px 0' }}
              variant="text"
              size="small"
              loading={buttonLoadingState[rowData.profileId] || false}
            >
              More
            </LoadingButton>
          </>
        )}
      </Stack>
    </div>
  );
};
