import { ChangeEvent, FC, useState } from 'react';
import { NavLink, NavLinkProps, Outlet, useNavigate } from 'react-router-dom';
import { useDocumentTitle } from '@uidotdev/usehooks';
import find from 'lodash/find';
import { Tab } from '@mui/material';
import { TabContext, TabList } from '@mui/lab';
import { Permissions } from 'types/commonTypes';
import { PaymentsRoutes } from 'routes/enum';
import { checkPermissions } from 'utils/checkPermissions';
import { useAppSelector } from 'store/hooks';
import { selectUserPermissions } from 'store/authSlice';
import useRouteMatch from 'hooks/useRouteMatch';
import Divider from 'components/shared/Divider/Divider';
import Typography from 'components/shared/Typography/Typography';
import withAuth from 'components/Authorization/withAuth';
import './index.scss';

enum PaymentsTabs {
  OPERATIONS = 'Operations',
  BULK_PAYOUTS = 'Bulk Payouts',
  FINANCE_DATA = 'Finance Data',
  USER_FINANCE_DATA = 'User Finance Data',
}

type paymentsTabsCollectionType = {
  label: PaymentsTabs;
  value: PaymentsRoutes;
  to: PaymentsRoutes;
  className: string;
  component: React.ForwardRefExoticComponent<NavLinkProps & React.RefAttributes<HTMLAnchorElement>>;
  disabled: boolean;
};

const Payments: FC = () => {
  const navigate = useNavigate();

  const userPermissions = useAppSelector(selectUserPermissions);

  const routeMatch = useRouteMatch(Object.values(PaymentsRoutes));
  const initialState = (routeMatch?.pattern?.path as string) || PaymentsRoutes.OPERATIONS;
  const [selectedTab, setSelectedTab] = useState(initialState);

  const handleTabChange = (event: ChangeEvent<{}>, value: PaymentsRoutes) => {
    navigate(value);
    setSelectedTab(value);
  };

  const paymentsTabsCollection: paymentsTabsCollectionType[] = [
    {
      label: PaymentsTabs.OPERATIONS,
      value: PaymentsRoutes.OPERATIONS,
      to: PaymentsRoutes.OPERATIONS,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: !checkPermissions(userPermissions, [Permissions.PAYMENTS_VIEW_OPERATIONS]),
    },
    {
      label: PaymentsTabs.BULK_PAYOUTS,
      value: PaymentsRoutes.BULK_PAYOUTS,
      to: PaymentsRoutes.BULK_PAYOUTS,
      className: 'payments-tabs__tab',
      component: NavLink,
      // disabled: !checkPermissions(userPermissions, [Permissions.PAYMENTS_VIEW_PAGE_BULK_PAYOUTS]),
      disabled: true,
    },
    {
      label: PaymentsTabs.FINANCE_DATA,
      value: PaymentsRoutes.FINANCE_DATA,
      to: PaymentsRoutes.FINANCE_DATA,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: true,
    },
    {
      label: PaymentsTabs.USER_FINANCE_DATA,
      value: PaymentsRoutes.USER_FINANCE_DATA,
      to: PaymentsRoutes.USER_FINANCE_DATA,
      className: 'payments-tabs__tab',
      component: NavLink,
      disabled: true,
    },
  ];

  useDocumentTitle(
    `Payments - ${(find(paymentsTabsCollection, { to: selectedTab }) as paymentsTabsCollectionType | undefined)
      ?.label}`,
  );

  return (
    <div className="payments-container">
      <Typography variant="h6">Payments</Typography>
      <TabContext value={selectedTab}>
        <TabList onChange={handleTabChange} className="payments-tabs">
          {paymentsTabsCollection.slice(0, 2).map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              value={tab.value}
              className={tab.className}
              component={tab.component}
              to={tab.to}
              disabled={tab.disabled}
            />
          ))}
          <Divider orientation="vertical" flexItem className="payments-tabs__divider" />
          {paymentsTabsCollection.slice(2).map((tab, index) => (
            <Tab
              key={index}
              label={tab.label}
              value={tab.value}
              className={tab.className}
              component={tab.component}
              to={tab.to}
              disabled={tab.disabled}
            />
          ))}
        </TabList>
      </TabContext>
      <Outlet />
    </div>
  );
};

export default withAuth(Payments);
