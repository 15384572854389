import Button from 'components/shared/Button/Button';
import './index.scss';

interface IProps {
  isFilters: boolean;
  fetchingError: boolean;
  clearFilters: () => void;
  refreshData: () => void;
}

const NoUsersData = ({ isFilters, fetchingError, clearFilters, refreshData }: IProps) => {
  return (
    <div className="noUsersData--container">
      {isFilters && !fetchingError ? (
        <>
          <div className="noUsersData--text">
            No data to display. Try to remove any of filters or change their value.
          </div>
          <Button variant="text" sx={{ zIndex: 1 }} onClick={() => clearFilters()}>
            Clear filters
          </Button>
        </>
      ) : (
        <></>
      )}
      {fetchingError ? (
        <>
          <div className="noUsersData--text">
            There are some troubles with data receiving. Repeat request in a while.
            <div className="noUsersData--warningText">
              WARNING! In case of page reload you could lose your requested data
            </div>
          </div>
          <Button variant="text" sx={{ zIndex: 1 }} onClick={() => refreshData()}>
            Repeat request
          </Button>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default NoUsersData;
