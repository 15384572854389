import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios, { isAxiosError } from 'axios';
import { SnackbarKey } from 'notistack';
import { IPermission, IStatus, LocalStorageKeys } from 'types/commonTypes';
import { RootState } from 'store/store';
import { ErrorMessages, OtherMessages } from 'store/types/ErrorMessages';

export interface AuthState {
  status: IStatus;
  errorStatusText: ErrorMessages | '';
  triesResendLeft: number | undefined;
  token: string;
  refreshToken: string;
  memberId: string;
  cooldownTimer: Date | undefined;
  snackbarKeys: SnackbarKey[] | [];
  isAuth: string | undefined;
  isWarningRedirect: boolean;
  permissions: IPermission[] | [];
}

const initialState: AuthState = {
  status: 'idle',
  errorStatusText: '',
  triesResendLeft: undefined,
  token: localStorage.getItem(LocalStorageKeys.ACCESS_TOKEN) ?? '',
  refreshToken: localStorage.getItem(LocalStorageKeys.REFRESH_TOKEN) ?? '',
  memberId: localStorage.getItem(LocalStorageKeys.MEMBER_ID) ?? '',
  isAuth: localStorage.getItem(LocalStorageKeys.IS_AUTH) ?? undefined,
  cooldownTimer: undefined,
  snackbarKeys: [],
  isWarningRedirect: false,
  permissions: [],
};

interface ResponseError {
  title: string;
  meta: {
    unlockDate: Date;
  };
}

export const fetchSetUpPassword = createAsyncThunk(
  'auth/setUpPassword',
  async ({ password, code }: { password: string; code: string }) => {
    await axios
      .post((process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/password-setup', {
        password,
        code,
      })
      .catch((error: unknown) => {
        if (axios.isAxiosError(error) && error.response?.status === 400) {
          throw Error(error.response.data.title);
        } else {
          throw Error('An unexpected error occurred');
        }
      });
  },
);

export const fetchSignIn = createAsyncThunk(
  'auth/signIn',
  async ({ password, email }: { password: string; email: string }, { rejectWithValue }) => {
    try {
      const response = await axios.post((process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/sign-in', {
        password,
        email,
      });

      return response.data;
    } catch (error: unknown) {
      return isAxiosError(error) && rejectWithValue(error.response?.data);
    }
  },
);

export const fetchVerifyOtp = createAsyncThunk(
  'auth/verifyOtp',
  async ({ otp, email }: { otp: string; email: string }) => {
    const response = await axios
      .post((process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/verify-otp', {
        password: otp,
        email,
      })
      .catch((error: unknown) => {
        if (axios.isAxiosError(error) && (error.response?.status === 400 || error.response?.status === 403)) {
          throw Error(error.response.data.techInfo.title);
        } else {
          throw Error('An unexpected error occurred');
        }
      });

    return response.data;
  },
);

export const fetchSignOut = createAsyncThunk('auth/signOut', async (_, thunkAPI) => {
  const rootState: RootState = (await thunkAPI.getState()) as RootState;

  const result = await axios.post((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + 'members/sign-out', {
    refreshToken: rootState.auth.refreshToken,
  });
  if (result.status === 401) {
    return thunkAPI.rejectWithValue(401);
  }
});

export const fetchValidateOtp = createAsyncThunk('auth/validateOtp', async ({ code }: { code: string }) => {
  const response = await axios
    .post(
      (process.env.REACT_APP_AUTH_MEMBERS_ENDPOINT as string) + 'members/exists-otp',
      {},
      { params: { code: code } },
    )
    .catch((error: unknown) => {
      if (axios.isAxiosError(error) && error.response?.status === 400) {
        throw Error('Unvalidated otp');
      } else {
        throw Error('An unexpected error occurred');
      }
    });

  return response.data;
});

export const fetchPermissions = createAsyncThunk('auth/permissions', async () => {
  try {
    const response = await axios.get((process.env.REACT_APP_MEMBERS_ENDPOINT as string) + `permissions/my`);
    return response.data;
  } catch (error: unknown) {
    return isAxiosError(error);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    updateSnackbarKeys: (state, { payload }) => {
      state.snackbarKeys = payload;
    },
    refresh: (state, { payload }) => {
      state.token = payload.accessToken;
      state.memberId = payload.memberId;
    },
    checkoutErrorMessage: (state, { payload }) => {
      if (payload.showInfoLoginMessage) {
        state.errorStatusText = OtherMessages.SHOULD_ENTER_CREDENTIALS_AGAIN;
        return;
      }
      state.errorStatusText = '';
    },
    logout: (state, action: PayloadAction<any>) => {
      if (action) {
        state.errorStatusText = action.payload.message as ErrorMessages;
      }

      state.token = '';
      state.isAuth = undefined;
      state.permissions = [];
      localStorage.removeItem(LocalStorageKeys.ACCESS_TOKEN);
      localStorage.removeItem(LocalStorageKeys.IS_AUTH);
      localStorage.removeItem(LocalStorageKeys.MEMBER_ID);
      localStorage.removeItem(LocalStorageKeys.MEMBER);
      localStorage.removeItem(LocalStorageKeys.AVATAR);
      localStorage.removeItem(LocalStorageKeys.AVATAR_PREVIEW);
      localStorage.removeItem(LocalStorageKeys.SPEED);
      localStorage.removeItem(LocalStorageKeys.VOLUME);
      localStorage.removeItem(LocalStorageKeys.SALES_TAX_APPROVAL_CONFIRM_SKIP);
    },
    setIsWarningRedirect: (state, { payload }) => {
      state.isWarningRedirect = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSetUpPassword.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchSetUpPassword.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.errorStatusText = '';
      })
      .addCase(fetchSetUpPassword.rejected, (state, { error }) => {
        state.status = 'failed';
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchSignIn.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchSignIn.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.errorStatusText = '';
        state.triesResendLeft = payload.triesResendLeft;
        state.cooldownTimer = undefined;
      })
      .addCase(fetchSignIn.rejected, (state, { payload, error }) => {
        state.status = 'failed';
        state.triesResendLeft = undefined;
        // debugger;
        if (payload) {
          const { title, meta } = payload as ResponseError;
          state.errorStatusText = (title ? title : '') as ErrorMessages;
          if (meta) {
            state.cooldownTimer = meta.unlockDate;
          }
        } else {
          state.errorStatusText = (error.message ?? '') as ErrorMessages;
        }
      })
      .addCase(fetchVerifyOtp.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchVerifyOtp.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.errorStatusText = '';
        state.token = payload.accessToken;
        state.refreshToken = payload.refreshToken;
        state.memberId = payload.memberId;
        state.isAuth = 'true';
        localStorage.setItem(LocalStorageKeys.ACCESS_TOKEN, payload.accessToken);
        localStorage.setItem(LocalStorageKeys.REFRESH_TOKEN, payload.refreshToken);
        localStorage.setItem(LocalStorageKeys.MEMBER_ID, payload.memberId);
        localStorage.setItem(LocalStorageKeys.IS_AUTH, 'true');
      })
      .addCase(fetchVerifyOtp.rejected, (state, { error }) => {
        state.status = 'failed';
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchSignOut.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchSignOut.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.errorStatusText = '';
      })
      .addCase(fetchSignOut.rejected, (state, { error }) => {
        state.status = 'failed';
      })
      .addCase(fetchValidateOtp.pending, (state) => {
        state.status = 'loading';
        state.errorStatusText = '';
      })
      .addCase(fetchValidateOtp.fulfilled, (state, { payload }) => {
        state.status = 'idle';
        state.errorStatusText = '';
      })
      .addCase(fetchValidateOtp.rejected, (state, { error }) => {
        state.status = 'failed';
        state.errorStatusText = (error.message ?? '') as ErrorMessages;
      })
      .addCase(fetchPermissions.fulfilled, (state, { payload }) => {
        state.permissions = payload.permissions;
      });
  },
});

export const selectAuthStatus = (state: RootState): IStatus => state.auth.status;
export const selectAuthMemberId = (state: RootState): string => state.auth.memberId;
export const selectIsAuth = (state: RootState): string | undefined => state.auth.isAuth;
export const selectUserPermissions = (state: RootState): IPermission[] | [] => state.auth.permissions;
export const selectAuthStatusText = (state: RootState): string => state.auth.errorStatusText;
export const selectAuthTriesResendLeft = (state: RootState): number | undefined => state.auth.triesResendLeft;
export const selectAuthCooldownTimer = (state: RootState): Date | undefined => state.auth.cooldownTimer;
export const selectSnackbarKeys = (state: RootState): SnackbarKey[] | [] => state.auth.snackbarKeys;
export const selectIsWarningRedirect = (state: RootState): boolean => state.auth.isWarningRedirect;

export const { checkoutErrorMessage, logout, refresh, updateSnackbarKeys, setIsWarningRedirect } = authSlice.actions;

export default authSlice.reducer;
