import MediaThumbnail from './MediaThumbnail/MediaThumbnail';
import { IMediaItem } from 'types/commonTypes';
import './index.scss';

interface IMediaThumbNails {
  error: boolean;
  orderedMediaList: IMediaItem[];
  currentMainEntity: IMediaItem;
  onThumbnailClick: (maniEntity: IMediaItem) => void;
}

const MediaThumbnails: React.FC<IMediaThumbNails> = ({
  error,
  orderedMediaList,
  currentMainEntity,
  onThumbnailClick,
}) => {

  return (
    <div className="multimedia-preview">
      {orderedMediaList.map((mediaItem: IMediaItem) => {
        const isActive = currentMainEntity.mediaUrl.startsWith(mediaItem.mediaUrl);

        return (
          <MediaThumbnail
            key={mediaItem.mediaId}
            error={error}
            currentMainEntity={currentMainEntity}
            mediaItem={mediaItem}
            onThumbnailClick={onThumbnailClick}
            isActive={isActive}
          />
        );
      })}
    </div>
  );
};

export default MediaThumbnails;
