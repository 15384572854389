import { NavLink, useLocation } from 'react-router-dom';
import { enqueueSnackbar, VariantType } from 'notistack';
import { ReactNode, useCallback, useEffect, useState } from 'react';
import { Collapse } from '@mui/material';
import { ErrorBoundary } from 'react-error-boundary';
import { Permissions } from 'types/commonTypes';
import { checkPermissions } from 'utils/checkPermissions';
import { fetchAvatar, selectAvatarPreview } from 'store/profileSlice';
import {
  fetchSignOut,
  logout,
  selectIsWarningRedirect,
  selectUserPermissions,
  setIsWarningRedirect,
} from 'store/authSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
// import DataIcon from 'assets/img/DataIcon';
import ContentIcon from 'assets/img/ContentIcon';
import DisputeIcon from 'assets/img/DisputeIcon';
import Logo from 'assets/img/Logo';
import LogoutIcon from 'assets/img/LogoutIcon';
import PaymentsSettingsIcon from 'assets/img/PaymentsSettingsIcon';
import ShieldIcon from 'assets/img/ShieldIcon';
import TaxModerationIcon from 'assets/img/TaxModerationIcon';
import Users2Icon from 'assets/img/Users2Icon';
import Users3Icon from 'assets/img/Users3Icon';
import WalletIcon from 'assets/img/WalletIcon';
// import WorldIcon from 'assets/img/WorldIcon';
import Avatar from 'components/shared/Avatar/Avatar';
import Divider from 'components/shared/Divider/Divider';
import Typography from 'components/shared/Typography/Typography';
import LogoutModal from 'components/LogoutModal/LogoutModal';
import { LogoutWarningModal } from 'components/Layout/modals';
import ErrorPage from 'containers/ErrorPage/ErrorPage';
import './index.scss';
import WorldIcon from 'assets/img/WorldIcon';

const NavLinkWithIcon = ({
  to,
  icon,
  text,
  isDisabled,
}: {
  to: string;
  icon: ReactNode;
  text: string;
  isDisabled?: boolean;
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      `layout-menu-link${isActive ? ' layout-menu-link--active' : ''}${isDisabled ? ' layout-menu-link--disabled' : ''}`
    }
  >
    {icon}
    <Typography variant="inputText">{text}</Typography>
  </NavLink>
);

const Layout = (props: any) => {
  const location = useLocation();
  const dispatch = useAppDispatch();
  const memberAvatarPreview = useAppSelector(selectAvatarPreview);
  const isWarningRedirect = useAppSelector(selectIsWarningRedirect);
  const userPermissions = useAppSelector(selectUserPermissions);

  const [isMenuOpen, setOpen] = useState<boolean>(false);
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const [showWarningModal, setShowWarningModal] = useState<boolean>(false);

  const hasPaymentsPermissions = checkPermissions(userPermissions, [
    Permissions.PAYMENTS_VIEW_OPERATIONS,
    Permissions.PAYMENTS_VIEW_PAGE_BULK_PAYOUTS,
  ]);

  const hasSalesTaxModerationPermissions = checkPermissions(userPermissions, [
    Permissions.SALES_TAX_MODERATION_VIEW_SOLVED_PAGE,
    Permissions.SALES_TAX_MODERATION_VIEW_UNRESOLVED_PAGE,
  ]);

  const hasSettingsPermissions = checkPermissions(userPermissions, [
    Permissions.SETTINGS_VIEW_PAGE_TARIFF_GROUPS,
    Permissions.SETTINGS_VIEW_IN_APP_TIERS,
    Permissions.SETTINGS_VIEW_TAXES_CODES,
    Permissions.SETTINGS_VIEW_TAXES_RULES,
    Permissions.SETTINGS_VIEW_PAYMENT_FEES,
    Permissions.SETTINGS_VIEW_TARIFFS_IN_GROUP,
    Permissions.SETTINGS_VIEW_PRICES_RESTRICTIONS_PAGE,
    Permissions.SETTINGS_VIEW_WITHDRAWAL_RESTRICTIONS_PAGE,
  ]);

  const hasConsumptionRequestPermissions = checkPermissions(userPermissions, [
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_OPEN_PAGE,
    Permissions.REQUESTS_REFUNDS_DISPUTES_VIEW_CLOSED_PAGE,
  ]);

  const hasContentDisplayPermissions = checkPermissions(userPermissions, [
    Permissions.CONTENT_DISPLAY_VIEW_CONTENT_DISPLAY,
  ]);

  const shouldShowDivider = hasPaymentsPermissions || hasSalesTaxModerationPermissions || hasSettingsPermissions;

  const handleWarningModalClose = () => {
    dispatch(setIsWarningRedirect(false));
    setShowWarningModal(false);
  };

  const handleLogoutModalClose = () => {
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false));
    }
    setShowLogoutModal(false);
  };

  const handleWarningModalLogout = () => {
    setShowWarningModal(false);
    setShowLogoutModal(true);
  };

  const handleOpen = useCallback((value: boolean) => setOpen(value), []);

  const handleLogout = useCallback(async () => {
    setShowLogoutModal(false);
    if (isWarningRedirect) {
      dispatch(setIsWarningRedirect(false));
    }
    const result = await dispatch(fetchSignOut());
    if (result.meta.requestStatus === 'fulfilled') {
      dispatch(logout({ message: '' }));
    }
    if (result.meta.requestStatus === 'rejected') {
      enqueueSnackbar('Logging out error. Try again later', {
        variant: 'error' as VariantType,
      });
    }
  }, []);

  useEffect(() => {
    dispatch(fetchAvatar());
  }, []);

  useEffect(() => {
    if (isWarningRedirect) {
      setShowWarningModal(true);
    }
  }, [isWarningRedirect]);

  return (
    <>
      <div className={`layout-container${isMenuOpen ? ' layout-container--blurred' : ''}`}>
        <div className="layout-menu-container">
          <Collapse in={isMenuOpen} orientation={'horizontal'} collapsedSize={64} style={{ height: '100%' }}>
            <div
              className={`layout-menu${isMenuOpen ? ' layout-menu--open' : ''}`}
              onMouseEnter={() => handleOpen(true)}
              onMouseLeave={() => handleOpen(false)}
            >
              <div className="layout-menu--navbar">
                <Logo isMenuOpen={isMenuOpen} />
                <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

                <NavLinkWithIcon to="/ui/users" icon={<Users2Icon />} text="YZZY users" />
                {hasContentDisplayPermissions && (
                  <NavLinkWithIcon to="/ui/content-display" icon={<ContentIcon />} text="Content display" />
                )}
                {/* <NavLinkWithIcon
                to="/ui/world-management/collections"
                icon={<WorldIcon />}
                text="World management"
                /> */}
                <NavLinkWithIcon to="/ui/moderation" icon={<ShieldIcon />} text="Content moderation" />

                {shouldShowDivider && <Divider className="layout-menu__divider" orientation="horizontal" flexItem />}

                {hasPaymentsPermissions && <NavLinkWithIcon to="/ui/payments" icon={<WalletIcon />} text="Payments" />}
                {hasSalesTaxModerationPermissions && (
                  <NavLinkWithIcon to="/ui/tax-moderation" icon={<TaxModerationIcon />} text="Sales tax moderation" />
                )}
                {hasConsumptionRequestPermissions && (
                  <NavLinkWithIcon
                    to="/ui/consumption-requests"
                    icon={<DisputeIcon />}
                    text="Requests refunds & disputes"
                  />
                )}
                {hasSettingsPermissions && (
                  <NavLinkWithIcon
                    to="/ui/payments-settings"
                    icon={<PaymentsSettingsIcon />}
                    text="Payments settings"
                  />
                )}

                <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

                <NavLinkWithIcon to="/ui/members" icon={<Users3Icon />} text="Team" />
                {/*<NavLinkWithIcon
                  to="/ui/content-administration"
                  icon={<DataIcon />}
                  text="App content administration"
                />*/}
              </div>

              <div className="layout-menu__user-container">
                <div className="layout-menu-link" onClick={() => setShowLogoutModal(true)}>
                  <LogoutIcon />
                  <Typography variant="inputText">Log out</Typography>
                </div>

                <Divider className="layout-menu__divider" orientation="horizontal" flexItem />

                <NavLink
                  to="/ui/profile"
                  className={({ isActive }) => `layout-menu-link${isActive ? ' layout-menu-link--active' : ''} avatar-icon`}
                >
                  <Avatar src={memberAvatarPreview.originalLink ?? ''} alt="avatar" />
                  <Typography variant="inputText">Profile</Typography>
                </NavLink>
              </div>
            </div>
          </Collapse>
        </div>
        <div style={{ width: '64px' }} />
        <ErrorBoundary FallbackComponent={ErrorPage} resetKeys={[location.pathname]}>
          {props.children}
        </ErrorBoundary>
      </div>
      <LogoutModal
        open={showLogoutModal}
        isWarningRedirect={isWarningRedirect}
        onClose={handleLogoutModalClose}
        logout={handleLogout}
      />
      <LogoutWarningModal
        open={showWarningModal}
        onClose={handleWarningModalClose}
        onLogout={handleWarningModalLogout}
      />
    </>
  );
};

export default Layout;
