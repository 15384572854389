import React, { Dispatch, SetStateAction, useEffect, useMemo, useRef, useState } from 'react';
import dayjs from 'dayjs';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import some from 'lodash/some';
import startCase from 'lodash/startCase';
import uniqueId from 'lodash/uniqueId';
import { Link, useNavigate } from 'react-router-dom';
import { ContentType, EntityType } from 'types/commonTypes';
import { IMediaProps } from 'components/Moderation/MediaPreviewDrawer/MediaPreviewDrawer';
import { Metrics } from 'store/moderationSlice';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronUp from 'assets/img/ChevronUpIcon';
import ImageIcon from 'assets/img/ImageIcon';
import LinkIcon from 'assets/img/LinkIcon';
import PlayIcon from 'assets/img/PlayIcon';
import TextIcon from 'assets/img/TextIcon';
import UserNoAvatar from 'assets/img/UserNoAvatar.svg';
import UserNoAvatarPreview from 'assets/img/UserNoAvatarPreview.svg';
import VideoIcon from 'assets/img/VideoIcon';
import ZoomInIcon from 'assets/img/ZoomInIcon';
import Button from 'components/shared/Button/Button';
import CardType from 'components/Moderation/types/CardType';
import Chip from 'components/shared/Chip/Chip';
import List from 'components/shared/List/List';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemIcon from 'components/shared/List/ListItem/ListItemComponents/ListItemIcon/ListItemIcon';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Stack from 'components/shared/Stack/Stack';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface IProps {
  entityInfo: any;
  profileInfo: any;
  moderationResults: any;
  complaints: any[];
  entityOwnerInfo: any;
  tabValue: any;
  showPreview: (newValue: IMediaProps) => void;
  showDetails: (entityInfo: any) => void;
  showUsersProfile: () => void;
  amountViolations: number;
  violationsFrom: string;
  mostDangerousMetrics: string[];
  isManualMode: boolean;
  cardType: CardType;
  isFullHeightCard: boolean;
  setIsFullHeightCard: Dispatch<SetStateAction<boolean>>;
}

const ModerationCardContent = ({
  entityInfo,
  profileInfo,
  moderationResults,
  entityOwnerInfo,
  showPreview,
  showDetails,
  showUsersProfile,
  amountViolations,
  violationsFrom,
  tabValue,
  complaints,
  mostDangerousMetrics,
  isManualMode,
  cardType,
  isFullHeightCard,
  setIsFullHeightCard,
}: IProps) => {
  const navigate = useNavigate();
  const [isShowButton, setIsShowButton] = useState(false);
  const contentParentRef = useRef<HTMLDivElement>(null);
  const contentLeftRef = useRef<HTMLDivElement>(null);
  const contentRightRef = useRef<HTMLDivElement>(null);

  // const hasVideoModerationJob = some(moderationResults, (result) =>
  //   isEqual(result.contentType?.toUpperCase(), ContentType.VIDEO),
  // );

  const profilePicPreviewJob = find(moderationResults, { mediaId: entityInfo?.entityId });

  const isShowEntityDetails =
    !profileInfo &&
    entityInfo &&
    !(
      entityInfo.entityType?.toUpperCase() === EntityType.AVATAR ||
      entityInfo.entityType?.toUpperCase() === EntityType.ABOUT_ME ||
      entityInfo.entityType?.toUpperCase() === EntityType.COMMENT ||
      entityInfo.entityType?.toUpperCase() === EntityType.MOOD
    );

  const isShowEntityInfo =
    entityInfo &&
    entityInfo.entityType?.toUpperCase() !== EntityType.COMMENT &&
    entityInfo.entityType?.toUpperCase() !== EntityType.AVATAR &&
    entityInfo.entityType?.toUpperCase() !== EntityType.ABOUT_ME;

  const isShowEntityOwnerInfo =
    entityOwnerInfo &&
    (entityOwnerInfo.userName || entityOwnerInfo.firstName) &&
    (entityInfo.entityType === EntityType.AVATAR || entityInfo.entityType === EntityType.ABOUT_ME);

  const isMoodEntityType = entityInfo?.entityType?.toUpperCase() === EntityType.MOOD;

  const publicationDate = useMemo(
    () => dayjs(entityInfo?.publicationDate).format('MM/DD/YYYY, h:mm A') || null,
    [entityInfo?.publicationDate],
  );

  const location = useMemo(() => {
    if (profileInfo && profileInfo?.locationCity && profileInfo?.locationCountry) {
      return `${profileInfo?.locationCity}, ${profileInfo.locationCountry}`;
    }
    if (profileInfo && !profileInfo?.locationCity && profileInfo?.locationCountry) {
      return profileInfo.locationCountry;
    }
    if (entityInfo && entityInfo.entityFormat === 'ONLINE') {
      return 'Online';
    }
    if (entityInfo && entityInfo?.locationCity && entityInfo?.locationCountry) {
      return `${entityInfo.locationCity}, ${entityInfo.locationCountry}`;
    }
    if (entityInfo && !entityInfo?.locationCity && entityInfo?.locationCountry) {
      return entityInfo.locationCountry;
    }

    return 'Not specified';
  }, [entityInfo, profileInfo]);

  const getContentType = (contentType: string, mediaId: string) => {
    if (contentType?.toUpperCase() === ContentType.AVATAR && profilePicPreviewJob?.mediaId !== mediaId) {
      return 'Profile pic';
    }
    if (contentType?.toUpperCase() === ContentType.ABOUT_ME) {
      return 'What im about';
    }
    // if (contentType?.toUpperCase() === ContentType.IMAGE && hasVideoModerationJob) {
    //   return 'Preview';
    // }
    if (profilePicPreviewJob?.mediaId === mediaId) {
      return 'Image';
    } else return startCase(contentType?.toLowerCase());
  };

  useEffect(() => {
    const observerCallback = () => {
      if (contentParentRef.current && contentLeftRef.current && contentRightRef.current) {
        const parentContentHeight = contentParentRef.current.getBoundingClientRect().height;
        const contentLeftHeight = contentLeftRef.current.getBoundingClientRect().height;
        const contentRightHeight = contentRightRef.current.getBoundingClientRect().height;

        if (contentLeftHeight >= parentContentHeight || contentRightHeight >= parentContentHeight) {
          setIsShowButton(true);
        }
      }
    };

    const resizeObserver = new ResizeObserver(observerCallback);

    if (contentParentRef.current) {
      resizeObserver.observe(contentParentRef.current);
    }
    if (contentLeftRef.current) {
      resizeObserver.observe(contentLeftRef.current);
    }
    if (contentRightRef.current) {
      resizeObserver.observe(contentRightRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [contentParentRef, contentLeftRef, contentRightRef]);

  const handleToggleHeight = () => {
    setIsFullHeightCard(!isFullHeightCard);
  };

  return (
    <>
      <div
        ref={contentParentRef}
        className="content"
        style={{
          maxHeight: isFullHeightCard ? 'fit-content' : '320px',
          overflow: isFullHeightCard ? 'visible' : 'hidden',
        }}
      >
        <div className="content-violationBlock" ref={contentLeftRef}>
          <Typography variant="h6">
            {isManualMode ? 'Entity content' : `${amountViolations} ${violationsFrom}`}
          </Typography>

          {moderationResults &&
            moderationResults.map((result: any) => {
              const text = result.text ?? `Text is NULL, mediaId ${result.mediaId}`;

              const isTextContentType =
                result.contentType?.toUpperCase() === ContentType.TITLE ||
                result.contentType?.toUpperCase() === ContentType.DESCRIPTION ||
                result.contentType?.toUpperCase() === ContentType.ABOUT_ME ||
                result.contentType?.toUpperCase() === ContentType.BENEFITS;
              const isVideoContentType = result.contentType?.toUpperCase() === ContentType.VIDEO;
              const isImageContentType = result.contentType?.toUpperCase() === ContentType.IMAGE;
              const isAvatarContentType = result.contentType?.toUpperCase() === ContentType.AVATAR;

              const getMetricsIcon = () => {
                if (isTextContentType) {
                  return <TextIcon />;
                }

                if (isVideoContentType) {
                  return <VideoIcon />;
                }

                return <ImageIcon />;
              };

              return (
                <div key={uniqueId()}>
                  <Typography variant="body2" color="text.secondary" className="content-type">
                    {getContentType(result.contentType, result.mediaId)}
                  </Typography>
                  <div className="content-violationSubject">
                    {!isManualMode && (
                      <List className="violations-list">
                        {result.metrics.map(({ metricName, score }: Metrics) => (
                          <ListItem key={metricName} className="violations-list__description">
                            <ListItemIcon className="violations-list__icon">{getMetricsIcon()}</ListItemIcon>
                            <ListItemText
                              className={
                                mostDangerousMetrics.some((element) => element === metricName) ? 'mostDangerous' : ''
                              }
                              primary={metricName}
                              secondary={`Accuracy ${score}`}
                            />
                          </ListItem>
                        ))}
                      </List>
                    )}
                    <div className={`content-item ${isManualMode ? 'content-item--wide' : ''} `}>
                      {isTextContentType ? (
                        <Typography className="content-item-text" variant="body2" color="text.secondary">
                          {result.contentType?.toUpperCase() === ContentType.BENEFITS ? (
                            <ul>
                              {text.split('\n').map((item: string, index: number) => (
                                <li key={index}>{item.trim()}</li>
                              ))}
                            </ul>
                          ) : (
                            text
                          )}
                        </Typography>
                      ) : (
                        <>
                          {(isImageContentType || isAvatarContentType) && (
                            <img className="content-item-preview" src={result.mediaUrl} alt="media" />
                          )}
                          {(isImageContentType || isAvatarContentType) && (
                            <Button
                              className="content-item-button"
                              variant="outlined"
                              startIcon={<ZoomInIcon />}
                              onClick={() =>
                                showPreview({
                                  entityOwnerId: entityOwnerInfo?.entityOwnerId,
                                  entityType: entityInfo.entityType,
                                  entityId: entityInfo.entityId,
                                  contentType: result.contentType,
                                  mediaUrl: result.mediaUrl,
                                  mediaId: result.mediaId,
                                  // isVideoPreview: hasVideoModerationJob,
                                } as IMediaProps)
                              }
                            >
                              Show
                            </Button>
                          )}
                          {result.contentType?.toUpperCase() === ContentType.VIDEO && (
                            <Button
                              className="content-item-button"
                              variant="outlined"
                              startIcon={<PlayIcon />}
                              onClick={() =>
                                showPreview({
                                  entityType: entityInfo.entityType,
                                  entityId: entityInfo.entityId,
                                  contentType: result.contentType,
                                  mediaUrl: result.mediaUrl,
                                } as IMediaProps)
                              }
                            >
                              Play
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          {complaints &&
            complaints?.map(({ value, key }: { value: any[]; key: string }) => {
              return (
                <div className="content-complaints" key={uniqueId()}>
                  <div className="content-type">
                    <span className={mostDangerousMetrics.some((element) => element === key) ? 'mostDangerous' : ''}>
                      {key} ({value.length})
                    </span>
                  </div>
                  <List className="violations-list">
                    {value.map((author: any) => (
                      <div key={uniqueId()}>
                        <ListItem className="content-violationSubject-item">
                          <ListItemText
                            primary={
                              <>
                                @{author.userName} <span>(#{author.authorId})</span>
                              </>
                            }
                            secondary={
                              <span className="trust-block">
                                <span className="trust-block-validComplaints">
                                  {author.validComplaintsSent}/{author.allComplaintsSent}
                                </span>
                                <span className="trust-block-text"> valid complaints sent</span>
                              </span>
                            }
                          />
                        </ListItem>
                        {author.comment && <div className="content-item-comment">{author.comment}</div>}
                      </div>
                    ))}
                  </List>
                </div>
              );
            })}
        </div>
        <div className="content-violationBlock" ref={contentRightRef}>
          {isShowEntityOwnerInfo && (
            <div className="content-profile">
              <div className="">
                <div className="content-profileInfo-userName">
                  <Typography variant="avatarInitials">
                    {entityOwnerInfo?.firstName}&nbsp;{entityOwnerInfo?.lastName}
                  </Typography>
                  {/*<Link to={`/ui/users/user/${entityOwnerInfo?.entityOwnerId}`} target="_blank">*/}
                  <LinkIcon color="disabled" fontSize="small" />
                  {/*</Link>*/}
                </div>
                <span className="trust-block">
                  <span className="trust-block-text">@{entityOwnerInfo?.userName}</span>
                </span>
              </div>
              <div className="content-profileSettings">
                <div className="content-profile-imageContainer">
                  <div
                    className="overlay"
                    style={{ width: '100%', height: '100%' }}
                    onClick={() =>
                      showPreview({
                        entityType: "User's",
                        entityId: entityOwnerInfo.entityOwnerId,
                        contentType: 'Avatar',
                        mediaUrl: entityOwnerInfo?.avatarUrl || UserNoAvatar,
                      } as IMediaProps)
                    }
                  >
                    <ZoomInIcon sx={{ fontSize: 56 }} />
                  </div>
                  <img src={entityOwnerInfo?.avatarUrl || UserNoAvatarPreview} alt="Avatar of user" />
                  {/*<div className="content-profile-levelAura"></div>*/}
                  {/*<div className="content-profile-levelAuraPlate">{entityOwnerInfo?.levelAura}</div>*/}
                </div>
              </div>
              <div className="content-buttonContainer">
                <Button
                  className="content-violationButton-button"
                  variant="outlined"
                  onClick={() => {
                    showUsersProfile();
                    navigate(`/ui/moderation/${tabValue}/${entityOwnerInfo?.entityOwnerId}`);
                  }}
                >
                  User’s card
                </Button>
              </div>
            </div>
          )}

          {isShowEntityInfo && (
            <List className="content-violationInfo">
              <ListItem className="content-violationInfo-item">
                <ListItemText
                  className="content-violationInfo-itemDescription_bold overflow-hide"
                  primary="Author"
                  secondary={
                    <>
                      <div className="content-violationInfo-user">
                        {entityOwnerInfo?.userName && <b>{`@${entityOwnerInfo?.userName} `}</b>}
                        <Tooltip
                          title={entityOwnerInfo?.entityOwnerId}
                          placement="bottom-start"
                          className="content-violationInfo-tooltipId"
                        >
                          <span>(#{entityOwnerInfo?.entityOwnerId})</span>
                        </Tooltip>
                      </div>
                      {entityInfo.entityType !== EntityType.AVATAR && cardType !== CardType.HIVE && (
                        <span className="trust-block">
                          <span className="trust-block-validComplaints">
                            {entityOwnerInfo?.validComplaintsReceived}/{entityOwnerInfo?.allComplaintsReceived}
                          </span>

                          <span className="trust-block-text"> valid complaints received</span>
                        </span>
                      )}
                    </>
                  }
                />
                {!isMoodEntityType && (
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold"
                    primary="Entity created"
                    secondary={publicationDate}
                  />
                )}
              </ListItem>
              {!isMoodEntityType && (
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold"
                    primary="Location"
                    secondary={location}
                  />
                  <ListItemText
                    className="content-violationInfo-itemDescription"
                    primary="Passions"
                    secondary={
                      <span className="content-chipsContainer">
                        {entityInfo?.passions.length > 0
                          ? entityInfo?.passions?.map((passion: string, index: number) => (
                              <Chip size="small" variant="filled" key={passion + index} label={passion} />
                            ))
                          : 'Not specified'}
                      </span>
                    }
                  />
                </ListItem>
              )}
            </List>
          )}
          {entityInfo && entityInfo.entityType === EntityType.COMMENT && (
            <div className="contentViolation--commentContainer">
              <List className="content-violationInfo">
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold overflow-hide"
                    primary="Comment"
                    secondary={
                      <div className="content-violationInfo-commentContainer">
                        {entityInfo.textData || 'Comment not found'}
                      </div>
                    }
                  />
                </ListItem>
              </List>
              <List className="content-violationInfo">
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold overflow-hide"
                    primary="Author"
                    secondary={
                      <>
                        <div className="content-violationInfo-user">
                          <b>@{entityOwnerInfo?.userName}</b>
                          <Tooltip
                            title={entityOwnerInfo?.entityOwnerId}
                            placement="bottom-start"
                            className="content-violationInfo-tooltipId"
                          >
                            <span>(#{entityOwnerInfo?.entityOwnerId})</span>
                          </Tooltip>
                        </div>
                        {cardType !== CardType.HIVE && (
                          <span className="trust-block">
                            <span className="trust-block-validComplaints">
                              {entityOwnerInfo?.validComplaintsReceived}/{entityOwnerInfo?.allComplaintsReceived}
                            </span>
                            <span className="trust-block-text"> valid complaints received</span>
                          </span>
                        )}
                      </>
                    }
                  />
                </ListItem>
                <ListItem className="content-violationInfo-item">
                  <ListItemText
                    className="content-violationInfo-itemDescription_bold"
                    primary="Entity created"
                    secondary={publicationDate}
                  />
                </ListItem>
              </List>
            </div>
          )}
          {profileInfo && (
            <div className="content-profile">
              <div className="">
                <div className="content-profileInfo-userName">
                  <Typography variant="avatarInitials">
                    {profileInfo?.firstName}&nbsp;{profileInfo?.lastName}
                  </Typography>
                  {/*<Link to={`/ui/users/user/${profileInfo?.profileId}`} target="_blank">*/}
                  <LinkIcon color="disabled" fontSize="small" />
                  {/*</Link>*/}
                </div>
                <span className="trust-block">
                  <span className="trust-block-text">@{profileInfo?.username}</span>
                  <>
                    <span className="trust-block-separator">|</span>
                    <span className="trust-block-validComplaints">
                      {profileInfo?.validComplaintsReceived}/{profileInfo?.allComplaintsReceived}
                    </span>
                    <span className="trust-block-text"> valid complaints received</span>
                  </>
                </span>
              </div>
              <div className="content-profileSettings">
                <div className="content-profile-imageContainer">
                  {/* todo сделать аватар дефолтный, если урл пустой. и "сломанный" если ошибка при загрузке */}
                  <div
                    className="overlay"
                    style={{ width: '100%', height: '100%' }}
                    onClick={() =>
                      showPreview({
                        entityType: "User's",
                        entityId: profileInfo.profileId,
                        contentType: 'Avatar',
                        mediaUrl: profileInfo?.avatarUrl || UserNoAvatar,
                      } as IMediaProps)
                    }
                  >
                    <ZoomInIcon sx={{ fontSize: 56 }} />
                  </div>
                  <img src={profileInfo?.avatarUrl || UserNoAvatarPreview} alt="usersAvatar" />
                  {/*<div className="content-profile-levelAura"></div>*/}
                  {/*<div className="content-profile-levelAuraPlate">{profileInfo?.levelAura}</div>*/}
                </div>
                {isManualMode && (
                  <div className="content-profile-info">
                    <div className="content-profileLocation">
                      <div className="content-profileTitle">Location</div>
                      <div>{location}</div>
                    </div>
                    <div className="content-profilePassions">
                      <div className="content-profileTitle">Passions</div>
                      <div className="content-chipsContainer">
                        {profileInfo?.passions?.map((el: any) => <Chip variant="filled" label={el} />)}
                        {!profileInfo?.passions || profileInfo?.passions.length < 1 ? 'Not specified' : <></>}
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="content-buttonContainer">
                <Button
                  className="content-violationButton-button"
                  variant="outlined"
                  onClick={() => {
                    showUsersProfile();
                    // todo тут урл поменяется после сделанной навигации по табам.
                    //  будет c tabValue (т.к. профиль можно открыть в трех вкладках. tabValue передать параметром)
                    //  `/ui/moderation/${tabValue}/${profileInfo?.profileId}`
                    navigate(`/ui/moderation/${tabValue}/${profileInfo?.profileId}`);
                  }}
                >
                  User’s card
                </Button>
              </div>
            </div>
          )}
          {isShowEntityDetails && (
            <div className="content-violationButtonsGroup">
              <Button
                variant="outlined"
                className="content-violationButton-button"
                onClick={() => showDetails(entityInfo)}
              >
                Entity details
              </Button>
            </div>
          )}
        </div>
      </div>
      {isShowButton && (
        <div className="content-violationButtonsGroup">
          <div
            className={isFullHeightCard ? 'content-violationButton' : 'content-violationButton shadow'}
            style={{ width: entityInfo?.entityType === 'COMMENT' ? '100%' : '45%' }}
          >
            <Stack
              className={'content-violationButton__custom-button'}
              direction="row"
              alignItems="center"
              gap={0.5}
              pt={0.5}
              onClick={handleToggleHeight}
            >
              <Typography variant="bodyLarge">{isFullHeightCard ? 'Show less' : 'Show more'}</Typography>
              {isFullHeightCard ? <ChevronUp /> : <ChevronDownIcon />}
            </Stack>
          </div>
        </div>
      )}
    </>
  );
};

export default ModerationCardContent;
