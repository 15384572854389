import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import { string } from 'prop-types';
import { RootState } from './store';
import { IStatus } from "types/commonTypes";

export interface FiltersState {
  data: any[];
  presets: any[];
  columns: any[];
  activePresetId: string | null;
  status: IStatus;
}

const initialState: FiltersState = {
  data: [],
  presets: [],
  columns: [],
  activePresetId: null,
  status: 'idle',
};

export const fetchColumns = createAsyncThunk(
  'columns/fetchColumns',
  async (type: string) => {
    let columnsUrl;
    switch (type) {
      case 'users':
      default: {
        columnsUrl = 'timusers/presets/columns'
      };
    }
    const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + columnsUrl, {
      // headers: {
      //   'Member-Id': '0'
      // }
    });
    // console.log(response);
    return { columns: response.data.columns };
  }
);

export const fetchColumnsPresets = createAsyncThunk(
  'columns/fetchPresets',
  async (type: string) => {
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = 'timusers/presets?presetType=COLUMNS'
      };
    }
    const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + presetsUrl, {
      headers: {
        'Member-Id': '0'
      }
    });
    // console.log(response);
    return { presets: response.data.presets };
  }
)

export const setActivePreset = createAsyncThunk(
  'columns/setActivePreset',
  async ({ type, presetId }: { type: string, presetId: string }) => {
    let presetUrl;
    switch (type) {
      case 'users':
      default: {
        presetUrl = `timusers/presets/columns/${presetId}`;
      };
    }
    const response = await axios.get(process.env.REACT_APP_USERS_ENDPOINT + presetUrl, {
      headers: {
        'Member-Id': '0'
      }
    });
    // console.log(response);
    return { columns: response.data.columns, presetId: presetId };
  }
)

export const savePreset = createAsyncThunk(
  'columns/savePreset',
  async ({ type, presetName }: { type: string, presetName: string }, thunkAPI) => {
    const rootState: RootState = (await thunkAPI.getState()) as RootState;
    // console.log(Object.keys(rootState.filters.data), Object.values(rootState.filters.data));
    const columns = Object.keys(rootState.columns.data) ? Object.values(rootState.columns.data).filter((item: any) => item !== null) : [];
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = 'timusers/presets?presetType=COLUMNS'
      };
    }
    const response = await axios.post(process.env.REACT_APP_USERS_ENDPOINT + presetsUrl, {
      presetName: presetName,
      presetType: "COLUMNS",
      columns: columns
    }, {
      headers: {
        'Member-Id': '0'
      }
    });
    // console.log(response);
    return { presets: response.data.presets };
  }
)

export const deletePreset = createAsyncThunk(
  'columns/deletePreset',
  async ({type, presetId}: {type: string, presetId: string}) => {
    let presetsUrl;
    switch (type) {
      case 'users':
      default: {
        presetsUrl = `timusers/presets/${presetId}`
      };
    }
    const response = await axios.delete(process.env.REACT_APP_USERS_ENDPOINT + presetsUrl, {
      headers: {
        'Member-Id': '0'
      }
    });
    // console.log(response);
    return { presets: response.data.presets };
  }
)

export const columnsSlice = createSlice({
  name: 'columns',
  initialState,
  reducers: {
    clearColumns: (state) => {
      state.columns = [];
    },
    removeColumn: (state, action) => {
      state.data.splice(action.payload.index, 1);
    },
    addColumn: (state, action) => {
      state.data.push(state.columns.find((item: any) => item.columnName === action.payload.columnName));
    },
    setNewColumnsArray: (state, action) => {
      state.data = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchColumns.pending, (state) => {
        // console.log('getting columns');
        state.status = 'loading';
      })
      .addCase(fetchColumns.fulfilled, (state, action) => {
        // console.log('columns updated')
        state.status = 'idle';
        state.columns = action.payload.columns;
        state.data = action.payload.columns.filter((item: any) => item.hideByDefault !== true);
      })
      .addCase(fetchColumns.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(fetchColumnsPresets.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(fetchColumnsPresets.fulfilled, (state, action) => {
        state.status = 'idle';
        state.presets = action.payload.presets;
      })
      .addCase(fetchColumnsPresets.rejected, (state) => {
        state.status = 'failed';
      })
      .addCase(setActivePreset.fulfilled, (state, action) => {
        const newData: any = {};
        action.payload.columns.forEach((item: any) => {
          newData[item.columnId] = item;
        });
        state.data = newData;
        state.activePresetId = action.payload.presetId;
      })
      .addCase(setActivePreset.rejected, (state) => {

      })
  },
});

export const { clearColumns, removeColumn, addColumn, setNewColumnsArray } = columnsSlice.actions;

export default columnsSlice.reducer;
