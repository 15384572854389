import { yupResolver } from "@hookform/resolvers/yup";
import getValidationSchema from 'containers/TimMembers/utils/getValidationSchema';
import Modal from "components/shared/Modal/Modal";
import { useForm } from "react-hook-form";
import TextField from "components/shared/TextField/TextField";
import Button from "components/shared/Button/Button";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { enqueueSnackbar, VariantType } from "notistack";
import Select from "components/shared/Select/Select";
import { IMemberInfo } from "types/Member";
import { useCallback, useEffect, useMemo, useState } from "react";
import Alert from "components/shared/Alert/Alert";
import { IStatus } from "types/commonTypes";
import { fetchEditMember } from "store/membersSlice";

const EditMemberModal = (props: {refresh: any, open: boolean, onClose: any, possibleRoles: any[], editMember: IMemberInfo}) => {
  const currentMember = useAppSelector(state => state.profile.member);
  const dispatch = useAppDispatch();
  // Костыль для TM-8462
  const [forceUpdate, setForceUpdate] = useState<boolean>(false);

  const { register, handleSubmit, trigger, setValue, getValues, formState: { errors, isValid, isDirty } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(getValidationSchema()),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      roleId: '',
    },
  })

  const [editMemberCreationStatus, setEditMemberCreationStatus] = useState<IStatus>('idle');
  const [isCanceling, setIsCanceling] = useState<boolean>(false);

  const handleCancel = useCallback(() => {
    console.log(isDirty, getValues());
    // Костыль, оставшийся от TM-8574, изменение роли не меняет isDirty
    if(isDirty || getValues().roleId !== props.editMember.role.id) {
      setIsCanceling(true);
    } else {
      props.onClose();
    }
  }, [props.onClose, isDirty]);

  const handleCloseConfirmCancel = useCallback((confirm: boolean) => {
    if(confirm) {
      setIsCanceling(false);
      props.onClose();
    } else {
      setIsCanceling(false);
    }
  }, []);

  const onSubmit = async (data: any) => {
    setEditMemberCreationStatus('idle');
    void trigger();
    const inputData = {
      ...data,
      id: props.editMember.id
    }
    const result = await dispatch(fetchEditMember(inputData));
    if (result.meta.requestStatus === 'fulfilled') {
      props.refresh();
      props.onClose();
      enqueueSnackbar(`${data?.firstName + ' ' + data?.lastName}'s changes were saved`, { variant: 'success' as VariantType });
    } else if(result.meta.requestStatus === 'rejected') {
      setEditMemberCreationStatus('failed');
    }
  }

  useEffect(() => {
    setValue('roleId', props.editMember.role.id);
    setValue('email', props.editMember.email);
    setValue('firstName', props.editMember.firstName);
    setValue('lastName', props.editMember.lastName);
  }, [props.editMember, setValue])

  const currentUserSelected = useMemo(() => {
    return props.editMember.email === currentMember.email;
  }, [props.editMember, currentMember]);

  const isReallyDirty = useMemo(() => {
    console.log(isDirty, getValues().roleId, props.editMember.role.id);
    return isDirty || getValues().roleId !== props.editMember.role.id
  }, [isDirty, getValues().roleId, props.editMember])

  console.log(isReallyDirty);

  return (
    <>
    <Modal customstyle={{ minHeight: 380, maxWidth: 444 }} open={props.open} onClose={props.onClose} >
      <div className="saveNewMemberModal">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <div className="saveNewMemberModal--header">Edit member</div>
            </div>
            <div className="saveNewMemberModal--content">
              <TextField
                {...register('firstName')}
                className="saveNewMemberModal--textfield"
                variant="outlined" size="small"
                label="First name"
                error={!!errors.firstName}
                name="firstName"
                inputProps={{ maxLength: 58 }}
                defaultValue=''
                helperText={errors.firstName && errors.firstName?.message as string}
              />
              <TextField
                {...register('lastName')}
                className="saveNewMemberModal--textfield"
                variant="outlined"
                size="small"
                label="Last name"
                error={!!errors.lastName}
                name="lastName"
                inputProps={{ maxLength: 58 }}
                defaultValue=''
                helperText={errors.lastName && errors.lastName?.message as string}
              />

              <TextField
                {...register('email')}
                disabled={true}
                className="saveNewMemberModal--textfield"
                variant="outlined"
                size="small"
                label="Email"
                error={!!errors.email}
                name="email"
                inputProps={{ maxLength: 255 }}
                defaultValue=''
                helperText={errors.email && errors.email?.message as string}
              />
              {props.possibleRoles.length > 0 && props.editMember && <Select
                {...register('roleId')}
                error={!!errors.roleId}
                name="roleId"
                placeholder="Role"
                className="saveNewMemberModal--textfield"
                size="small"
                options={props.possibleRoles}
                disabled={currentUserSelected}
                onChange={(event) => {
                  setValue('roleId', (event.target.value) as string);
                  setForceUpdate((prevState: boolean) => !prevState);
                }}
                defaultValue={props.editMember.role.id}
              />}
            </div>
            {editMemberCreationStatus === 'failed' ? <Alert severity="error">Saving error. Please, Try later</Alert> : <></>}
            <div style={{ display: 'flex', justifyContent: 'end', width: '100%', marginTop: '28px' }}>
              <Button style={{ marginRight: '16px' }} onClick={handleCancel}>Cancel</Button>
              <Button type="submit" variant="contained" disabled={!isValid || !isReallyDirty}>Save</Button>
            </div>
          </form>
        </div>
    </Modal>
    <Modal customstyle={{ minHeight: '188px' }} open={isCanceling} onClose={() => handleCloseConfirmCancel(false)}>
      <div className="defaultModal--content">
        <div className="defaultModal--header">
          Cancel editing member
        </div>
        <div className="defaultModal--text">
          Changes won't be applied
        </div>
        <div className="defaultModal--actions">
          <Button style={{ marginRight: '16px' }} onClick={() => handleCloseConfirmCancel(false)}>Don't cancel</Button>
          <Button type="submit" variant="contained" onClick={() => handleCloseConfirmCancel(true)}>Cancel editing</Button>
        </div>
      </div>
    </Modal>
    </>
  )
}

export default EditMemberModal;
