import React, { SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import Button from "components/shared/Button/Button";
import LoadingButton from "components/shared/LoadingButton/LoadingButton";
import {
  fetchAddCohort,
  fetchAllCohorts,
  selectErrorText,
  fetchLocations,
  selectLocations,
} from "store/worldManagementSlice";
import Alert from "components/shared/Alert/Alert";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { enqueueSnackbar, VariantType } from "notistack";
import getValidationSchema from "components/WorldManagement/Cohorts/CohortsModal/utils/getValidationSchema";
import TextField from "components/shared/TextField/TextField";
import FormHelperText from "components/shared/FormHelperText/FormHelperText";
import Select, { ISelectOptions } from "components/shared/Select/Select";
import Autocomplete from "components/shared/Autocomplete/Autocomplete";
import { Box, SelectChangeEvent } from "@mui/material";
import Chip from "components/shared/Chip/Chip";
import { IGender, ILocation } from "types/commonTypes";
import { useAppDispatch, useAppSelector } from "store/hooks";
import { Cohort, CohortDetails, fetchEditCohort } from "store/cohortDetailsSlice";
import { CommonErrorMessages, WorldText } from "store/types/ErrorMessages";

import './index.scss';

interface IProps {
  action: 'add' | 'edit';
  item: CohortDetails | null;
  onClose(): void;
  genders: IGender[];
}

const CohortsModal = ({ action, item, onClose, genders }: IProps) => {
  const [title, setTitle] = useState('Add new cohort');
  const [showMessage, setShowMessage] = useState<{ show: boolean, type?: 'info' | 'error' }>({
    show: false,
  });
  const [minAgeValue, setMinAgeValue] = useState<string | null>(item?.minAge?.toString() ?? '');
  const [maxAgeValue, setMaxAgeValue] = useState<string | null>(item?.maxAge?.toString() ?? '');
  const [selectedGenders, setSelectedGenders] = useState<string[]>(item?.genders?.map(({name}) => name) ?? []);
  const [selectedLocations, setSelectedLocations] = useState<ILocation[]>(item?.locations ?? []);
  const [alertMessage, setAlertMessage] = useState({ isError: false, errorMessage: 'Fill at least one attribute field' });
  const [showFieldError, setShowFieldError] = useState({ isShow: false, message: '' });
  const schema = useMemo(() => getValidationSchema(),[]);

  const { register, handleSubmit, trigger, setValue, formState: { errors, isValid, isDirty }, control } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      id: item?.id ?? '',
      title: item?.title ?? '',
      minAge: item?.minAge ?? null,
      maxAge: item?.maxAge ?? null,
      genders: item?.genders ?? null,
      locations: item?.locations ?? null,
    }
  });

  const locations = useAppSelector(selectLocations);
  const statusText = useAppSelector(selectErrorText);

  const dispatch = useAppDispatch();

  const arrayOptions = useMemo(() => {
    const arr = [];
    for (let i = 13; i < 151; i++) {
      arr.push({ id: i, label: i.toString() });
    }
    return arr;
  }, []);

  const handleChangeGender = useCallback((event: SelectChangeEvent<unknown>) => {
    const {
      target: { value },
    } = event;
    const newValue = typeof value === 'string' ? value.split(',') : value as string[];
    setSelectedGenders(newValue);
  }, []);

  const handleDeleteGender = useCallback((event: MouseEvent, deleted: string) => {
    const newValue = selectedGenders.filter((selected) => selected !== deleted);
    setSelectedGenders(newValue);
  }, [selectedGenders]);

  const handleChangeLocation = async (event: SyntheticEvent, value: string) => {
    value.length > 2 && await dispatch(fetchLocations({ text: value }));
  }

  const triggerAttributeFields = useCallback((checkErrors = false): void => {
    if (checkErrors) {
      Promise.all([
        trigger('genders'),
        trigger('locations'),
        trigger('minAge'),
        trigger('maxAge'),
      ]).then((results) => {
        const isValidResult = results.some((result) => !!result);
        setAlertMessage({ errorMessage: 'Fill at least one attribute field', isError: !isValidResult });
        setShowMessage({ type: isValidResult ? 'info' : 'error', show: !isValidResult || action === 'add' })
      })
    } else {
      setAlertMessage({ isError: false, errorMessage: 'Fill at least one attribute field' });
      setShowMessage({ type: 'info', show: action === 'add' })
      void trigger('genders');
      void trigger('locations');
      void trigger('minAge');
      void trigger('maxAge');
    }
  }, []);

  const onSubmit = async (data: Omit<Cohort, 'status' | 'creatorName' | 'creatorLastName' | 'createdAt'>) => {
    void trigger();

    try {
      const result = action === 'add'
        ? await dispatch(fetchAddCohort(data))
        : await dispatch(fetchEditCohort(data));

      if (result.meta.requestStatus === 'fulfilled') {
        onClose();
        enqueueSnackbar(`“${data.title}” cohort ${action === 'add' ? 'has been created' : 'was changed'}`,
          { variant: 'success' as VariantType });
        action === 'add' && void dispatch(fetchAllCohorts());
      }
    } catch (e) {
      setAlertMessage({
        isError: true,
        errorMessage: `Cohort ${action === 'add' ? 'adding' : 'saving'} error. Try again later`,
      });
      setShowMessage({type: 'error', show: true});
    }
  };

  useEffect(() => {
    if (action === 'edit' && item) {
      setTitle(`Edit “${item.title}” cohort`);
      setShowMessage(alertMessage.isError ? { show: true, type: 'error' } : { show: false });
    } else {
      setTitle('Add new cohort');
      setShowMessage({ show: true, type: alertMessage.isError ? 'error' : 'info' });
    }
  }, [item, action]);

  useEffect(() => {
    const newGendersArray: IGender[] | null = selectedGenders.length
      ? selectedGenders.map((value) => genders.filter((gender) => gender.name === value)[0])
      : null;
    setValue('genders', newGendersArray, { shouldDirty: true });
    triggerAttributeFields();
  }, [selectedGenders]);

  useEffect(() => {
    setValue('locations', selectedLocations, { shouldDirty: true });
    triggerAttributeFields();
  }, [selectedLocations]);

  useEffect(() => {
    switch (statusText) {
      case WorldText.DUPLICATE_TITLE:
        setShowFieldError({ isShow: true, message: 'Cohort with the same name already exists '});
        break;
      case CommonErrorMessages.UNEXPECTED_ERROR:
        setAlertMessage({
          isError: true,
          errorMessage: `Cohort ${action === 'add' ? 'adding' : 'saving'} error. Try again later`,
        });
        setShowMessage({type: 'error', show: true});
        break;
      default:
        setShowFieldError({ isShow: false, message: ''});
    }
  }, [statusText]);

  return (
    <form className='cohortsModal' onSubmit={handleSubmit(onSubmit)}>
      <div className="cohortsModal-header">
        {title}
      </div>
      <div className='cohortsModal-content'>
        <div className="cohortsModal-content-group">
          <TextField
            {...register('title')}
            error={!!errors.title || showFieldError.isShow}
            label="Name*"
            name="title"
            defaultValue={item?.title ?? ''}
            onBlur={() => trigger('title')}
            size="small"
            fullWidth={true}
          />
          {(errors.title || showFieldError.isShow) &&
            <FormHelperText error>
              {showFieldError.isShow ? showFieldError.message : errors.title?.message as string}
            </FormHelperText>
          }
        </div>
        <div className="cohortsModal-content-group">
          <div>
            <div className="cohortsModal-content-name">Age</div>
            <Controller
              control={control}
              name="minAge"
              render={() => (
              <Autocomplete
                label="From"
                disabled
                value={minAgeValue ?? ''}
                inputValue={minAgeValue ?? ''}
                isOptionEqualToValue={(option, value) => option === value}
                getOptionLabel={(option) => option || ""}
                clearIcon={null}
                inputprops={{
                  error: (!!errors.minAge && errors.minAge.type !== 'oneOrMore')
                    || (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError),
                }}
                onInputChange={(event, value: string | null) => {
                  setValue('minAge',value ? parseInt(value) : null, { shouldDirty: true });
                  setMinAgeValue(value);
                }}
                onChange={(event, value) => {
                  setValue('minAge',value ? parseInt(value) : null, { shouldDirty: true });
                  setMinAgeValue(value);
                  triggerAttributeFields();
                }}
                options={arrayOptions.map(({ label }) => label)}
                size="small"
                onBlur={() => triggerAttributeFields()}
                sx={{ width: '190px', display: 'inline-block' }}
              />)}
            />
            <Controller
              control={control}
              name="maxAge"
              render={() => (
                <Autocomplete
                  label="to"
                  value={maxAgeValue ?? ''}
                  inputValue={maxAgeValue ?? ''}
                  clearIcon={null}
                  inputprops={{
                    error: (!!errors.maxAge && errors.maxAge.type !== 'oneOrMore')
                      || (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError),
                  }}
                  isOptionEqualToValue={(option, value) => option === value}
                  getOptionLabel={(option) => option || ""}
                  onInputChange={(event, value: string | null) => {
                    setValue('maxAge',value ? parseInt(value) : null, { shouldDirty: true });
                    setMaxAgeValue(value);
                  }}
                  onChange={(event, value) => {
                    setValue('maxAge',value ? parseInt(value) : null, { shouldDirty: true });
                    setMaxAgeValue(value)
                    triggerAttributeFields();
                  }}
                  options={arrayOptions.map(({ label }) => label)}
                  size="small"
                  onBlur={() => triggerAttributeFields()}
                  sx={{ width: '190px', display: 'inline-block' }}
                />)}
            />
          </div>
          {errors.minAge &&
            <FormHelperText error>
              {errors.minAge?.message as string}
            </FormHelperText>
          }
        </div>
        <div className="cohortsModal-content-group">
          <div className="cohortsModal-content-name">Gender</div>
            <Select
              error={(!!errors.genders && errors.genders.type !== 'oneOrMore')
                || (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError)
              }
              name="genders"
              variant="outlined"
              displayEmpty
              size="small"
              options={genders.map(({ name }) => ({ value: name, text: name } as ISelectOptions))}
              value={selectedGenders}
              onChange={handleChangeGender}
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, overflow: 'hidden' }}>
                  {(selected as string[]).length === 0
                    ? <span className="placeholder">Choose gender</span>
                    :
                    (selected as string[]).map((value) => (
                    <Chip
                      key={value}
                      label={value}
                      clickable={true}
                      variant="filled"
                      onMouseDown={(event) => event.stopPropagation()}
                      onDelete={(event) => handleDeleteGender(event, value)}
                    />
                  ))}
                </Box>
              )}
              multiple={true}
              fullWidth
            />
          {errors.genders && <FormHelperText error>
            {errors.genders.message as string}
          </FormHelperText>
          }
        </div>
        <div className="cohortsModal-content-group">
          <div className="cohortsModal-content-name">Location</div>
          <Controller
            control={control}
            name="locations"
            render={() => (
              <Autocomplete
                className="autocompleteLocations"
                value={selectedLocations}
                clearIcon={null}
                inputprops={{
                  placeholder: selectedLocations.length === 0 ? 'Choose location' : '',
                  error: (!!errors.locations && errors.locations.type !== 'oneOrMore')
                  || (alertMessage?.errorMessage === 'Fill at least one attribute field' && alertMessage?.isError)
                }}
                isOptionEqualToValue={(option, value) => option.fullAddress === value.fullAddress}
                getOptionLabel={(option) => option.fullAddress || ""}
                onInputChange={handleChangeLocation}
                onChange={(event, value) => {
                  triggerAttributeFields();
                  setSelectedLocations(value);
                }}
                options={locations || []}
                size="small"
                onBlur={() => triggerAttributeFields()}
                fullWidth={true}
                multiple={true}
              />)}
          />
        </div>
      </div>
      <div className="cohortsModal-footer">
        {showMessage.show && <Alert severity={showMessage.type}>{alertMessage?.errorMessage}</Alert>}
        <div className="cohortsModal-footer-buttons">
          <Button
            onClick={onClose}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            variant="contained"
            disabled={!isValid || !isDirty && action === 'edit'}
            color="primary"
            loading={false}
            type="submit"
            onClick={() => triggerAttributeFields(true)}
          >
            {action === 'add' ? 'Add' : 'Save'}
          </LoadingButton>
        </div>
      </div>
    </form>
  )
};

export default CohortsModal;
