/**
 * Enumerates the possible status values for user.
 *
 * @enum {string}
 * @readonly
 * @property {string} ACTIVE - The active status.
 * @property {string} BANNED - The banned status.
 * @property {string} DELETED - The deleted status.
 */
export enum UserStatuses {
  ACTIVE = 'ACTIVE',
  BANNED = 'BANNED',
  DELETED = 'DELETED',
}

/**
 * Represents status object for users.
 *
 * @interface
 * @property {TierStatuses} status - Status value.
 * @property {string} title - Status title.
 * @property {'error' | 'success' | 'warning' | 'info' | 'inherit' | 'primary' | 'secondary' | undefined} color - Associated color code.
 */
export interface IUserStatus {
  status: UserStatuses;
  title: string;
  color: 'error' | 'success' | 'warning' | 'info' | 'inherit' | 'primary' | 'secondary' | undefined;
}
