import React, { useCallback, useEffect, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { enqueueSnackbar } from 'notistack';
import { Link, useParams } from 'react-router-dom';
import uniqueId from 'lodash/uniqueId';
import find from 'lodash/find';
import { IUserStatus } from './interface';
import { possibleUserStatuses } from './constant';
import { stringCapitalize } from 'utils/stringCapitalize';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import {
  fetchDeleteUser,
  fetchRestoreUser,
  fetchUserProfile,
  selectUserProfile,
  selectUserProfileStatus,
} from 'store/userProfileSlice';
import ArrowBack from 'assets/img/ArrowBack';
import CheckCircleFilledIcon from 'assets/img/CheckCircleFilledIcon';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import ChevronRightIcon from 'assets/img/ChevronRightIcon';
import ChevronUp from 'assets/img/ChevronUpIcon';
import DeleteIcon from 'assets/img/DeleteIcon';
import InfoIcon from 'assets/img/InfoIcon';
import RestoreIcon from 'assets/img/RestoreIcon';
import WorldSendIcon from 'assets/img/WorldSendIcon';
import Alert from 'components/shared/Alert/Alert';
import Breadcrumbs from 'components/shared/Breadcrumbs/Breadcrumbs';
import Button from 'components/shared/Button/Button';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import IconButton from 'components/shared/IconButton/IconButton';
import LinkMUI from 'components/shared/Link/Link';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import ManageLinkedCollectionsModal from 'components/ManageLinkedCollections/ManageLinkedCollectionsModal';
import Modal from 'components/shared/Modal/Modal';
import Stack from 'components/shared/Stack/Stack';
import TextField from 'components/shared/TextField/TextField';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import UserProfile from 'components/UserProfile/UserProfile';
import withAuth from 'components/Authorization/withAuth';
import { ChangeUserStatusModal } from './modals';
import './index.scss';

function UserProfilePage() {
  let { userId } = useParams();

  const dispatch = useAppDispatch();
  const loadingStatus = useAppSelector((state) => state.userProfile.status);
  const deleteStatus = useAppSelector((state) => state.userProfile.deleteStatus);
  const restoreStatus = useAppSelector((state) => state.userProfile.restoreStatus);
  const data = useAppSelector(selectUserProfile);
  const status = useAppSelector(selectUserProfileStatus);
  let isLoadingUserProfile = status === 'loading';

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openLinkedCollections, setOpenLinkedCollections] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    setCurrentSelectedStatus(find(possibleUserStatuses, { status: data.main.status }) as IUserStatus);
  }, [data]);
  const handleStatusButtonClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleStatusMenuClose = () => {
    setAnchorEl(null);
  };

  const [deleteUserModal, setDeleteUserModal] = useState<boolean>(false);
  const [cancelDeleteUserModal, setCancelDeleteUserModal] = useState<boolean>(false);
  const [restoreUserModal, setRestoreUserModal] = useState<boolean>(false);
  const [deletingError, setDeletingError] = useState<boolean>(false);
  const [comment, setComment] = useState<string>('');
  const [currentSelectedStatus, setCurrentSelectedStatus] = useState<IUserStatus | null>(null);
  const [showChangeStatusModal, setShowChangeStatusModal] = useState<boolean>(false);

  const deleteUser = useCallback(async () => {
    const result = await dispatch(fetchDeleteUser({ profileId: userId!, comment: comment }));
    if (result.meta.requestStatus === 'fulfilled') {
      setDeletingError(false);
      setDeleteUserModal(false);
      setComment('');
      enqueueSnackbar(`User #${userId} has been deleted`, { variant: 'success' });
      dispatch(fetchUserProfile(userId!));
    } else {
      await setDeletingError(true);
    }
  }, [userId, comment, dispatch]);

  const restoreUser = useCallback(async () => {
    const result = await dispatch(fetchRestoreUser({ profileId: userId! }));
    if (result.meta.requestStatus === 'fulfilled') {
      setRestoreUserModal(false);
      enqueueSnackbar(`User #${userId} has been restored`, { variant: 'success' });
      dispatch(fetchUserProfile(userId!));
    } else {
      setRestoreUserModal(false);
      enqueueSnackbar(`Restoring error`, { variant: 'error' });
    }
  }, [userId, dispatch]);

  const closeDeleteModal = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setDeleteUserModal(false);
  }, []);

  const closeRestoreModal = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setRestoreUserModal(false);
  }, []);

  const closeCancelDeleteModal = useCallback((event?: any, reason?: any) => {
    if (reason && reason === 'backdropClick') return;
    setCancelDeleteUserModal(false);
    setDeleteUserModal(false);
    setComment('');
  }, []);

  const handleChangeStatusClick = async (selectedStatus: IUserStatus) => {
    if (selectedStatus.status.toLowerCase() === data.main.status?.toLowerCase()) {
      handleStatusMenuClose();
    } else {
      setCurrentSelectedStatus(selectedStatus);
      setShowChangeStatusModal(true);
      handleStatusMenuClose();
    }
  };

  return (
    <div className="userProfile--container">
      {isLoadingUserProfile && <CircularProgress sx={{ m: 'auto' }} />}
      <div className="userProfile--header" style={{ display: loadingStatus === 'loading' ? 'none' : 'flex' }}>
        <div className="userProfile--header__breadcrumbs">
          <Link color="default" to={`/ui/users/${((data.main.status || '') as string).toLowerCase()}`}>
            <IconButton size="small" color="default">
              <ArrowBack />
            </IconButton>
          </Link>
          <Breadcrumbs separator={<ChevronRightIcon fontSize="small" />}>
            <LinkMUI component={Link} underline="hover" to="/ui/users" color="text.secondary" variant="h6">
              YZZY users
            </LinkMUI>
            <LinkMUI
              component={Link}
              to={`/ui/users/${((data.main.status || '') as string).toLowerCase()}`}
              color="text.secondary"
              variant="h6"
              underline="hover"
            >
              {loadingStatus !== 'loading' && data.main.status && `${stringCapitalize(data.main.status)} users`}
            </LinkMUI>
            <Typography color="text.primary" variant="h6">
              {`TiM user ${userId} `}
            </Typography>
          </Breadcrumbs>
        </div>
        <Stack height={36} gap={2} direction="row">
          {/*<Link href="/ui/users" underline="none" sx={{ marginRight: '16px' }}>*/}
          <Button
            disabled
            startIcon={<WorldSendIcon />}
            variant="outlined"
            // disabled={data.main.status !== 'ACTIVE'}
            onClick={() => setOpenLinkedCollections(true)}
          >
            Top Search
          </Button>
          {/*</Link>*/}
          <Button
            endIcon={!!anchorEl ? <ChevronUp /> : <ChevronDownIcon />}
            variant="contained"
            disabled={find(possibleUserStatuses, { status: data.main.status } as IUserStatus) == undefined}
            onClick={handleStatusButtonClick}
            color={find(possibleUserStatuses, { status: data.main.status } as IUserStatus)?.color || 'primary'}
          >
            {find(possibleUserStatuses, { status: data.main.status } as IUserStatus) == undefined
              ? 'Active'
              : data.main.status}
          </Button>
          <Menu
            id="user-status-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleStatusMenuClose}
            PaperProps={{ sx: { width: anchorEl?.offsetWidth || 106 } }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            {possibleUserStatuses.map((item) => (
              <MenuItem
                key={uniqueId()}
                selected={item.status?.toLowerCase() === data.main.status?.toLowerCase()}
                onClick={() => handleChangeStatusClick(item)}
              >
                {item.title}
              </MenuItem>
            ))}
          </Menu>
        </Stack>
      </div>
      <div className="userProfile--content">
        <div style={{ display: loadingStatus === 'loading' ? 'none' : 'block' }}>
          <UserProfile />
        </div>
        <div
          className="userProfile--deleteUser"
          style={{ visibility: loadingStatus === 'loading' ? 'hidden' : 'visible' }}
        >
          {data.main.status && data.main.status !== 'DELETED' && (
            <Button
              className="userProfile--deleteUserButton"
              startIcon={<DeleteIcon />}
              onClick={() => setDeleteUserModal(true)}
            >
              Delete user
            </Button>
          )}
          {data.main.status && data.main.status === 'DELETED' && (
            <Button
              className="userProfile--restoreUserButton"
              startIcon={<RestoreIcon />}
              onClick={() => setRestoreUserModal(true)}
            >
              Restore user
            </Button>
          )}
        </div>
      </div>

      {userId && openLinkedCollections && (
        <ManageLinkedCollectionsModal
          open={openLinkedCollections}
          onClose={() => setOpenLinkedCollections(false)}
          entityId={userId}
          entityType={'HERO'}
        />
      )}
      {deleteUserModal && (
        <Modal open={deleteUserModal} onClose={closeDeleteModal} customstyle={{ width: 600 }}>
          <div className="defaultModal--content deleteUserModal">
            <div className="defaultModal--header">You are going to delete user #{data.main.userId}</div>
            <div className="deleteUserModal--textContainer">
              <div className="deleteUserModal--grayText">Be sure if you checked following conditions:</div>
              <div className="deleteUserModal--conditionText">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                User has no active paid content
              </div>
              <div className="deleteUserModal--conditionText">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                User either had no wallet or the wallet has been blocked
                <Tooltip title="You can block it on the Stripe panel if user fits following:">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                All user's balances are zero
                <Tooltip
                  title={
                    <div>
                      All balances must be zero, including:
                      <ul>
                        <li>Balance</li>
                        <li>Available to withdrawal</li>
                        <li>Pending (Withdrawn)</li>
                        <li>Pending (Earned)</li>
                      </ul>
                    </div>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                Yearly user's payouts sum is less than $599.99
                <Tooltip
                  sx={{ maxWidth: 300 }}
                  title={
                    <>
                      <div>
                        If yearly payouts sum is more or equal $599.99 we still can delete user but should keep their
                        data for tax authorities.
                      </div>
                      <div>You can check it on the Stripe panel</div>
                    </>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
              <div className="deleteUserModal--conditionText margin-left">
                <CheckCircleFilledIcon className="deleteUserModal--checkIcon" />
                There are more than 7 days passed after last user's transaction
                <Tooltip
                  title={
                    <>
                      <div>It’s obligatory to wait 7 days due to refund policy.</div>
                      <div>You can check it on the Payments module</div>
                    </>
                  }
                >
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
            <div>
              <TextField
                multiline
                minRows={5}
                maxRows={5}
                inputProps={{ maxLength: 640 }}
                className="deleteUserModal--commentField"
                label="Comment (optional)"
                value={comment}
                onChange={(v) => setComment(v.target.value)}
              />
            </div>
            {deletingError ? (
              <Alert severity="error">Deleting error</Alert>
            ) : (
              <Alert severity="info">
                <div>
                  User will be moved to Deleted users tab for 30 days and can be restored in that term if it will be
                  necessary.
                </div>
                <div>After 30 days user will be deleted permanently.</div>
              </Alert>
            )}
            <div className="defaultModal--actions">
              <Button className="deleteUserModal--cancelButton" onClick={() => setCancelDeleteUserModal(true)}>
                Cancel
              </Button>
              <LoadingButton
                loading={deleteStatus === 'loading'}
                variant="contained"
                className="deleteUserModal--deleteButton defaultModal--errorButton"
                onClick={() => deleteUser()}
              >
                Delete user
              </LoadingButton>
            </div>
          </div>
        </Modal>
      )}
      {cancelDeleteUserModal && (
        <Modal open={cancelDeleteUserModal} onClose={closeCancelDeleteModal} customstyle={{ minHeight: 160 }}>
          <div className="defaultModal--content">
            <div className="defaultModal--header">Cancel deleting user?</div>
            <div className="defaultModal--text">Changes won't be applied</div>
            <div className="defaultModal--actions">
              <Button style={{ marginRight: '16px' }} onClick={() => setCancelDeleteUserModal(false)}>
                Don't cancel
              </Button>
              <Button type="submit" variant="contained" onClick={() => closeCancelDeleteModal()}>
                Cancel deleting
              </Button>
            </div>
          </div>
        </Modal>
      )}
      {restoreUserModal && (
        <Modal customstyle={{ maxWidth: 600, minHeight: 100 }} open={restoreUserModal} onClose={closeRestoreModal}>
          <div className="defaultModal--content">
            <div className="defaultModal--header">You are going to restore user #{data.main.userId}</div>
            <div className="defaultModal--text">User will be moved to Actual users tab</div>
            <div className="defaultModal--actions">
              <Button style={{ marginRight: '16px' }} onClick={() => closeRestoreModal()}>
                Cancel
              </Button>
              <LoadingButton
                loading={restoreStatus === 'loading'}
                type="submit"
                variant="contained"
                onClick={() => restoreUser()}
              >
                Restore user
              </LoadingButton>
            </div>
          </div>
        </Modal>
      )}

      <ChangeUserStatusModal
        open={showChangeStatusModal}
        currentSelectedStatus={currentSelectedStatus}
        profileId={userId as string}
        onClose={() => setShowChangeStatusModal(false)}
        onDataRefresh={() => dispatch(fetchUserProfile(userId!))}
      />
    </div>
  );
}

export default withAuth(UserProfilePage);
