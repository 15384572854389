import { Fragment, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { enqueueSnackbar, VariantType } from 'notistack';
import { AutocompleteRenderGroupParams, createFilterOptions } from '@mui/material';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import isObject from 'lodash/isObject';
import colors from 'theme/constants/colors';
import { SalesTaxGroup } from '../constants';
import { IDropdownItem, IGroupedDropdownItem, ModalAction } from 'types/commonTypes';
import getValidationSchema from './utils/getValidationSchema';
import {
  EventFormat,
  EventOnlineFormatType,
  ITaxCode,
  ITaxRule,
  TaxesStatuses,
  TaxRuleCertainty,
  EntityType,
  TDataGridRowItem,
} from 'store/slices/Settings/interface';
import {
  fetchCertainties,
  fetchEntityTypes,
  fetchEventCategories,
  fetchFormats,
  fetchOnlineFormatTypes,
  fetchPassions,
  fetchSalesTaxCodes,
  selectCertainties,
  selectEntityTypes,
  selectEventCategories,
  selectFormats,
  selectOnlineFormatTypes,
  selectPassions,
  selectSalesTaxCodes,
} from 'store/slices/Settings/dictionariesSlice';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchAddTaxRule, fetchEditTaxRule, fetchTaxRules, selectTaxesStatus } from 'store/slices/Settings/taxesSlice';
import getTransformedFormData from './utils/getTransformedFormData';
import Autocomplete, { CollapseGroup, renderOptions } from 'components/shared/Autocomplete/Autocomplete';
import Box from 'components/shared/Box/Box';
import Button from 'components/shared/Button/Button';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Chip from 'components/shared/Chip/Chip';
import DeleteIcon from 'assets/img/DeleteIcon';
import Dialog from 'components/shared/Dialog/Dialog';
import DialogActions from 'components/shared/Dialog/DialogActions/DialogActions';
import DialogContent from 'components/shared/Dialog/DialogContent/DialogContent';
import DialogContentText from 'components/shared/Dialog/DialogContentText/DialogContentText';
import DialogTitle from 'components/shared/Dialog/DialogTitle/DialogTitle';
import FormControl from 'components/shared/FormControl/FormControl';
import FormControlLabel from 'components/shared/FormControlLabel/FormControlLabel';
import FormHelperText from 'components/shared/FormHelperText/FormHelperText';
import IconButton from 'components/shared/IconButton/IconButton';
import ListItem from 'components/shared/List/ListItem/ListItem';
import ListItemText from 'components/shared/List/ListItem/ListItemComponents/ListItemText/ListItemText';
import LoadingButton from 'components/shared/LoadingButton/LoadingButton';
import PlusIcon from 'assets/img/PlusIcon';
import SimpleGrid from 'components/shared/SimpleGrid/SimpleGrid';
import Stack from 'components/shared/Stack/Stack';
import Tooltip from 'components/shared/Tooltip/Tooltip';
import Typography from 'components/shared/Typography/Typography';
import { ConfirmCancelModal } from './ConfirmCancelModal';

interface ITaxRuleModalProps {
  action: ModalAction;
  item: TDataGridRowItem | null;
  open: boolean;
  onClose: (result?: ITaxRule) => void;
  page: number;
  pageSize: number;
}

export const TaxRuleModal = ({ action, item, open, onClose, page, pageSize }: ITaxRuleModalProps) => {
  const dispatch = useAppDispatch();
  const isDefaultRule = item?.status === TaxesStatuses.DEFAULT;
  const salesTaxRuleId = item?.salesTaxRuleId;

  const schema = useMemo(
    () => getValidationSchema(action, SalesTaxGroup.RULES, isDefaultRule),
    [action, isDefaultRule],
  );

  const entityTypes = useAppSelector(selectEntityTypes);
  const formats = useAppSelector(selectFormats);
  const onlineFormatTypes = useAppSelector(selectOnlineFormatTypes);
  const eventCategories = useAppSelector(selectEventCategories);
  const passions = useAppSelector(selectPassions);
  const salesTaxCodes = useAppSelector(selectSalesTaxCodes);
  const certainties = useAppSelector(selectCertainties);
  const status = useAppSelector(selectTaxesStatus);
  let isLoading = status === 'loading';

  const [isScrollVisible, setIsScrollVisible] = useState<boolean>(false);
  const [isOpenConfirmCancelModal, setIsOpenConfirmCancelModal] = useState(false);
  const [inputValuePassions, setInputValuePassions] = useState('');

  const {
    control,
    reset,
    resetField,
    setError,
    watch,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      entityType: '' as EntityType,
      format: '' as EventFormat,
      isDefaultSalesTaxRule: false,
      eventOnlineFormatType: '' as EventOnlineFormatType,
      eventCategories: [],
      passions: [[]] as (string[] | void)[],
      certainty: '' as TaxRuleCertainty,
      salesTaxCode: {
        salesTaxCodeId: '',
        code: '',
        category: '',
      } as ITaxCode,
    },
  });

  const {
    fields: passionFields,
    append: passionAppend,
    remove: passionRemove,
  } = useFieldArray({
    control,
    name: 'passions',
  });

  const sortedPassions = useMemo(() => sortBy(passions, 'group'), [passions]);
  const filter = createFilterOptions();

  const watchAllFields = watch();

  const isDisabledFormat = watchAllFields.entityType === EntityType.DONATION;
  const isDisabledOnlineFormatType =
    (watchAllFields.entityType === EntityType.EVENT && watchAllFields.format === EventFormat.OFFLINE) ||
    watchAllFields.entityType === EntityType.OFFER ||
    watchAllFields.entityType === EntityType.WISH ||
    watchAllFields.entityType === EntityType.PRIVATE_WISH ||
    watchAllFields.entityType === EntityType.DONATION ||
    watchAllFields.isDefaultSalesTaxRule;
  const isDisabledEventCategories =
    watchAllFields.entityType === EntityType.OFFER ||
    watchAllFields.entityType === EntityType.WISH ||
    watchAllFields.entityType === EntityType.PRIVATE_WISH ||
    watchAllFields.entityType === EntityType.DONATION ||
    watchAllFields.isDefaultSalesTaxRule;
  const isDisabledPassions =
    watchAllFields.entityType === EntityType.PRIVATE_WISH ||
    watchAllFields.entityType === EntityType.DONATION ||
    watchAllFields.isDefaultSalesTaxRule;
  const isAllCategoriesSelected = watchAllFields.eventCategories.length === eventCategories.length;

  const handleCloseModal = (result?: ITaxRule) => {
    reset({
      entityType: '' as EntityType,
      format: '' as EventFormat,
      isDefaultSalesTaxRule: false,
      eventOnlineFormatType: '' as EventOnlineFormatType,
      eventCategories: [],
      passions: [[]],
      certainty: '' as TaxRuleCertainty,
      salesTaxCode: {
        salesTaxCodeId: '',
        code: '',
        category: '',
      } as ITaxCode,
    });
    onClose(result);
  };
  const onSubmit = async (data: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>) => {
    void trigger();

    const formattedData: Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'> = {
      certainty: data.certainty || null,
      entityType: data.entityType || null,
      isDefaultSalesTaxRule: data.isDefaultSalesTaxRule ?? isDefaultRule,
      format: data.format || undefined,
      eventOnlineFormatType: data.eventOnlineFormatType || undefined,
      eventCategories: data.eventCategories?.map((item) => (isObject(item) ? item.value : item)) || undefined,
      salesTaxCode: data.salesTaxCode,
      passions:
        data.passions?.map((innerArray) => innerArray?.map((item) => (isObject(item) ? item.value : item))) ||
        undefined,
    };

    try {
      const result =
        action === ModalAction.ADD
          ? await dispatch(fetchAddTaxRule(formattedData))
          : await dispatch(
              fetchEditTaxRule({
                ...formattedData,
                salesTaxRuleId: salesTaxRuleId ? salesTaxRuleId : '',
              }),
            );

      if (result.meta.requestStatus === 'fulfilled') {
        handleCloseModal(result.payload);
        enqueueSnackbar(
          `${watchAllFields.isDefaultSalesTaxRule ? 'Default rule fee' : 'Rule'} has been  ${
            action === ModalAction.ADD ? 'added' : 'edited'
          }`,
          {
            variant: 'success' as VariantType,
          },
        );
        void dispatch(fetchTaxRules({ page, pageSize }));
      } else if (result.payload.meta) {
        const { title, meta } = result.payload;
        const metaKeys = Object.keys(meta) as Array<keyof Omit<ITaxRule, 'id' | 'salesTaxRuleId' | 'status'>>;

        if (metaKeys.length) {
          metaKeys.forEach((key) => setError(key, { message: meta[key] }));
        } else {
          enqueueSnackbar(
            `An error occurred while ${action === ModalAction.ADD ? 'adding' : 'saving'} the rule: ${title}`,
            {
              variant: 'error' as VariantType,
            },
          );
        }
      }
    } catch (error) {
      enqueueSnackbar('An error occurred. Please try again later.', {
        variant: 'error' as VariantType,
      });
    }
  };

  useEffect(() => {
    if (isDisabledOnlineFormatType) {
      resetField('eventOnlineFormatType');
    }
    if (isDisabledEventCategories) {
      resetField('eventCategories');
    }
    if (isDisabledPassions) {
      resetField('passions');
    }
    if (isDisabledFormat) {
      resetField('format');
    }
  }, [isDisabledFormat, isDisabledEventCategories, isDisabledOnlineFormatType, isDisabledPassions]);

  useEffect(() => {
    if (action === ModalAction.EDIT && open && item) {
      const transformedObject = getTransformedFormData(item);

      reset({
        entityType: transformedObject.entityType,
        format: transformedObject.format,
        salesTaxCode: transformedObject.salesTaxCode,
        certainty: transformedObject.certainty,
        isDefaultSalesTaxRule: isDefaultRule,
        eventOnlineFormatType: transformedObject.eventOnlineFormatType,
        eventCategories: transformedObject.eventCategories as [],
        passions: transformedObject.passions as [],
      });
    } else {
      return;
    }
  }, [open, action, item]);

  useEffect(() => {
    dispatch(fetchEntityTypes());
    dispatch(fetchFormats());
    dispatch(fetchOnlineFormatTypes());
    dispatch(fetchEventCategories());
    dispatch(fetchPassions());
    dispatch(fetchSalesTaxCodes());
    dispatch(fetchCertainties());
  }, [dispatch]);

  useLayoutEffect(() => {
    const item = document.querySelector('.MuiDialogContent-root');
    setIsScrollVisible(item?.clientHeight !== item?.scrollHeight);
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Dialog fullWidth maxWidth="sm" open={open} disablePortal>
        <DialogTitle variant="h6">
          {action === ModalAction.ADD ? 'Add new' : 'Edit'} {isDefaultRule && 'default'} rule for sales tax
        </DialogTitle>
        <DialogContentText ml={3} mr={3} color="text.primary" variant="body1">
          All fields are required except for the fields: Type, YZZY category, Passions. But filling in optional fields
          is desirable
        </DialogContentText>
        <DialogContent className={isScrollVisible ? 'scrollable' : ''}>
          <SimpleGrid
            tabIndex={-1}
            container
            flexDirection="column"
            minHeight="100%"
            alignContent="stretch"
            spacing={2}
          >
            {isDefaultRule && action === ModalAction.EDIT ? (
              <SimpleGrid item xs={12}>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Entity
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="subtitle1">
                        {startCase(watchAllFields.entityType?.toLowerCase())}
                      </Typography>
                    }
                  />
                </ListItem>
                <ListItem>
                  <ListItemText
                    primary={
                      <Typography color="text.secondary" variant="body2">
                        Format
                      </Typography>
                    }
                    secondary={
                      <Typography color="text.primary" variant="subtitle1">
                        {startCase(watchAllFields.format?.toLowerCase()) || '—'}
                      </Typography>
                    }
                  />
                </ListItem>
              </SimpleGrid>
            ) : (
              <>
                <SimpleGrid item xs={12}>
                  <Controller
                    control={control}
                    name="entityType"
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <Autocomplete
                          size="small"
                          label="Entity"
                          fullWidth
                          clearIcon={null}
                          renderOption={(params, option, { inputValue }) =>
                            renderOptions(params, option.titles[0], inputValue)
                          }
                          getOptionLabel={({ titles }) => titles[0]}
                          options={entityTypes || []}
                          value={
                            field.value
                              ? entityTypes.find((option) => {
                                  return field.value === option.value;
                                }) ?? null
                              : null
                          }
                          onChange={(_, value, reason) => {
                            if (reason === 'clear') {
                              field.onChange('');
                              return;
                            }
                            field.onChange(value.value);
                            void trigger('entityType');
                          }}
                          inputprops={{
                            error: !!errors.entityType,
                          }}
                          // onBlur={() => trigger('entityType')}
                        />
                        <FormHelperText error={!!errors.entityType}>
                          {errors.entityType?.message as string}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>
                <SimpleGrid item xs={12}>
                  <Tooltip
                    placement="top"
                    followCursor
                    title={isDisabledFormat ? 'Not needed for the selected entity' : ''}
                  >
                    <Controller
                      control={control}
                      name="format"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            size="small"
                            label="Format"
                            disabled={isDisabledFormat}
                            clearIcon={null}
                            value={
                              field.value
                                ? formats.find((option) => {
                                    return field.value === option.value;
                                  }) ?? null
                                : null
                            }
                            renderOption={(params, option, { inputValue }) =>
                              renderOptions(params, option.titles[0], inputValue)
                            }
                            getOptionLabel={({ titles }) => titles[0]}
                            options={formats || []}
                            onChange={(_, value, reason) => {
                              if (reason === 'clear') {
                                field.onChange('');
                                return;
                              }
                              field.onChange(value.value);
                              void trigger('format');
                            }}
                            inputprops={{
                              error: !!errors.format,
                            }}
                            // onBlur={() => trigger('format')}
                          />
                          <FormHelperText error={!!errors.format}>{errors.format?.message as string}</FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid item xs={12}>
                  <Controller
                    name="isDefaultSalesTaxRule"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth>
                        <FormControlLabel
                          sx={{ pl: 1 }}
                          control={
                            <Checkbox
                              inputRef={field.ref}
                              disableRipple
                              checked={field.value}
                              onChange={(e) => field.onChange(e.target.checked)}
                            />
                          }
                          label="Default rule"
                        />
                        <FormHelperText error={!!errors.isDefaultSalesTaxRule}>
                          {errors.isDefaultSalesTaxRule?.message as string}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </SimpleGrid>

                <SimpleGrid item xs={12}>
                  <Tooltip
                    placement="top"
                    followCursor
                    title={
                      isDisabledOnlineFormatType
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : `Not needed for selected ${
                              watchAllFields.entityType === EntityType.EVENT &&
                              watchAllFields.format === EventFormat.OFFLINE
                                ? 'format'
                                : 'entity'
                            }`
                        : ''
                    }
                  >
                    <Controller
                      control={control}
                      name="eventOnlineFormatType"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            size="small"
                            label="Type"
                            fullWidth
                            clearIcon={null}
                            disabled={isDisabledOnlineFormatType}
                            isOptionEqualToValue={(option, value) => option.value === value.value}
                            value={
                              field.value
                                ? onlineFormatTypes.find((option) => {
                                    return field.value === option.value;
                                  }) ?? null
                                : null
                            }
                            renderOption={(params, option, { inputValue }) =>
                              renderOptions(params, option.titles[0], inputValue)
                            }
                            getOptionLabel={({ titles }) => titles[0]}
                            options={onlineFormatTypes || []}
                            onChange={(_, value, reason) => {
                              if (reason === 'clear') {
                                field.onChange('');
                                return;
                              }
                              field.onChange(value.value);
                              void trigger('eventOnlineFormatType');
                            }}
                            inputprops={{
                              error: !!errors.eventOnlineFormatType,
                            }}
                            // onBlur={() => trigger('eventOnlineFormatType')}
                          />
                          <FormHelperText error={!!errors.eventOnlineFormatType}>
                            {errors.eventOnlineFormatType?.message as string}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Tooltip>
                </SimpleGrid>
                <SimpleGrid item xs={12}>
                  <Tooltip
                    placement="top"
                    followCursor
                    title={
                      isDisabledEventCategories
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : 'Not needed for the selected entity'
                        : ''
                    }
                  >
                    <Controller
                      control={control}
                      name="eventCategories"
                      render={({ field }) => (
                        <FormControl fullWidth>
                          <Autocomplete
                            size="small"
                            label="YZZY category"
                            disabled={isDisabledEventCategories}
                            clearIcon={null}
                            multiple
                            disableCloseOnSelect
                            value={field.value}
                            filterOptions={(options, params) => {
                              const filtered = filter(options, params);
                              return options.length
                                ? [
                                    {
                                      value: 'all',
                                      titles: ['All category'],
                                    },
                                    ...filtered,
                                  ]
                                : [];
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.value === value.value && option.titles[0] === value.titles[0]
                            }
                            renderOption={(params, option, { inputValue, selected }) =>
                              renderOptions(
                                params,
                                option.titles[0],
                                inputValue,
                                selected,
                                true,
                                isAllCategoriesSelected,
                              )
                            }
                            getOptionLabel={({ titles }) => titles[0]}
                            options={eventCategories || []}
                            onChange={(_, value) => {
                              if (value.find((item: IDropdownItem) => item.value === 'all')) {
                                setValue(
                                  'eventCategories',
                                  value.length - 1 === eventCategories.length ? [] : (eventCategories as []),
                                  { shouldDirty: true },
                                );
                              } else {
                                setValue('eventCategories', value, { shouldDirty: true });
                              }
                              void trigger('eventCategories');
                            }}
                            renderTags={(tagValue, getTagProps) =>
                              tagValue.map((option, index) => (
                                <Fragment key={index}>
                                  {tagValue.length && index !== 0 && (
                                    <Typography sx={{ padding: '0 8px' }} variant="inputText">
                                      OR
                                    </Typography>
                                  )}
                                  <Chip
                                    size="small"
                                    variant="filled"
                                    label={option.titles[0]}
                                    {...getTagProps({ index })}
                                  />
                                </Fragment>
                              ))
                            }
                            inputprops={{
                              error: !!errors.eventCategories,
                            }}
                            // onBlur={() => trigger('eventCategories')}
                          />
                          <FormHelperText disabled={isDisabledEventCategories} error={!!errors.eventCategories}>
                            {!!errors.eventCategories
                              ? (errors.eventCategories?.message as string)
                              : 'All options will be with the OR operator'}
                          </FormHelperText>
                        </FormControl>
                      )}
                    />
                  </Tooltip>
                </SimpleGrid>

                <SimpleGrid item xs={12}>
                  <Tooltip
                    placement="top"
                    followCursor
                    title={
                      isDisabledPassions
                        ? watchAllFields.isDefaultSalesTaxRule
                          ? 'Not needed for default rule'
                          : 'Not needed for the selected entity'
                        : ''
                    }
                  >
                    <Box
                      sx={{ p: 1, border: `1px solid ${colors.divider}`, borderRadius: 2 }}
                      display="flex"
                      flexDirection="column"
                      alignItems="flex-start"
                      alignSelf="stretch"
                      gap={1}
                    >
                      {passionFields.map((item, index) => {
                        return (
                          <Fragment key={item.id}>
                            {passionFields.length && index !== 0 && <Typography variant="body1">OR</Typography>}
                            <Stack alignItems="flex-start" alignSelf="stretch" direction="row" spacing={1}>
                              <Controller
                                control={control}
                                name={`passions.${index}` as const}
                                render={({ field }) => {
                                  return (
                                    <FormControl fullWidth>
                                      <Autocomplete
                                        size="small"
                                        label="Passions"
                                        clearIcon={null}
                                        multiple
                                        disabled={isDisabledPassions}
                                        disableCloseOnSelect
                                        value={field.value}
                                        isOptionEqualToValue={(option, value) => option.value === value.value}
                                        getOptionDisabled={() => (field.value ? field.value?.length >= 3 : false)}
                                        onInputChange={(_, newInputValue) => {
                                          setInputValuePassions(newInputValue);
                                        }}
                                        renderOption={(params, option, { inputValue, selected }) =>
                                          renderOptions(params, option.titles[0], inputValue, selected, true)
                                        }
                                        renderGroup={(paramsGroup: AutocompleteRenderGroupParams) => {
                                          return (
                                            <CollapseGroup
                                              key={paramsGroup.key}
                                              params={paramsGroup}
                                              inputValue={inputValuePassions}
                                            />
                                          );
                                        }}
                                        groupBy={(option) => option.group}
                                        getOptionLabel={({ titles }) => titles[0]}
                                        options={sortedPassions || []}
                                        onChange={(_, value) => {
                                          field.onChange(value.map((item: IGroupedDropdownItem) => item));
                                        }}
                                        renderTags={(tagValue, getTagProps) => {
                                          return tagValue.map((option, index) => {
                                            return (
                                              <Chip
                                                size="small"
                                                variant="filled"
                                                label={option.titles[0]}
                                                {...getTagProps({ index })}
                                              />
                                            );
                                          });
                                        }}
                                        // onBlur={() => trigger('passions')}
                                      />
                                      <FormHelperText disabled={isDisabledPassions}>{'Max 3 passions'}</FormHelperText>
                                    </FormControl>
                                  );
                                }}
                              />
                              {!isDisabledPassions && (
                                <IconButton
                                  disabled={watchAllFields.passions.length === 1}
                                  onClick={() => passionRemove(index)}
                                >
                                  <DeleteIcon />
                                </IconButton>
                              )}
                            </Stack>
                          </Fragment>
                        );
                      })}
                      <Button
                        size="small"
                        variant="text"
                        color="primary"
                        startIcon={<PlusIcon sx={{ fontSize: 16 }} />}
                        disabled={isDisabledPassions}
                        onClick={() => passionAppend([[]])}
                      >
                        or other passions
                      </Button>
                    </Box>
                  </Tooltip>
                </SimpleGrid>
              </>
            )}

            <SimpleGrid item xs={12}>
              <Controller
                control={control}
                name="salesTaxCode.category"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      label="Sales tax category"
                      clearIcon={null}
                      value={
                        field.value
                          ? salesTaxCodes.find((option) => {
                              return field.value === option.group;
                            }) ?? null
                          : null
                      }
                      renderOption={(params, option, { inputValue }) => renderOptions(params, option.group, inputValue)}
                      getOptionLabel={({ group }) => group}
                      options={salesTaxCodes || []}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true });
                          setValue('salesTaxCode.code', '', { shouldDirty: true });
                          setValue('salesTaxCode.salesTaxCodeId', '', { shouldDirty: true });
                          return;
                        }
                        setValue('salesTaxCode.category', value?.group as string, { shouldDirty: true });
                        setValue('salesTaxCode.code', value?.titles[0] as string, { shouldDirty: true });
                        setValue('salesTaxCode.salesTaxCodeId', value?.value as string, { shouldDirty: true });
                        void trigger('salesTaxCode');
                      }}
                      inputprops={{
                        error: !!errors.salesTaxCode?.category,
                      }}
                      // onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.category}>
                      {errors.salesTaxCode?.category?.message as string}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </SimpleGrid>

            <SimpleGrid item xs={12}>
              <Controller
                control={control}
                name="salesTaxCode.code"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      label="Sales tax code"
                      clearIcon={null}
                      value={
                        field.value
                          ? salesTaxCodes.find((option) => {
                              return field.value === option.titles[0];
                            }) ?? null
                          : null
                      }
                      renderOption={(params, option, { inputValue }) =>
                        renderOptions(params, option.titles[0], inputValue)
                      }
                      getOptionLabel={({ titles }) => titles[0]}
                      options={salesTaxCodes || []}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          setValue('salesTaxCode.category', '', { shouldDirty: true });
                          setValue('salesTaxCode.code', '', { shouldDirty: true });
                          setValue('salesTaxCode.salesTaxCodeId', '', { shouldDirty: true });
                          return;
                        }
                        setValue('salesTaxCode.category', value?.group as string, { shouldDirty: true });
                        setValue('salesTaxCode.code', value?.titles[0] as string, { shouldDirty: true });
                        setValue('salesTaxCode.salesTaxCodeId', value?.value as string, { shouldDirty: true });
                        void trigger('salesTaxCode');
                      }}
                      inputprops={{
                        error: !!errors.salesTaxCode?.code,
                      }}
                      // onBlur={() => trigger('salesTaxCode')}
                    />
                    <FormHelperText error={!!errors.salesTaxCode?.code}>
                      {errors.salesTaxCode?.code?.message as string}
                    </FormHelperText>
                  </FormControl>
                )}
              />
            </SimpleGrid>

            <SimpleGrid item xs={12}>
              <Controller
                control={control}
                name="certainty"
                render={({ field }) => (
                  <FormControl fullWidth>
                    <Autocomplete
                      size="small"
                      label="Certainty"
                      fullWidth
                      clearIcon={null}
                      isOptionEqualToValue={(option, value) => option.value === value.value}
                      value={
                        field.value
                          ? certainties.find((option) => {
                              return field.value === option.value;
                            }) ?? null
                          : null
                      }
                      renderOption={(params, option, { inputValue }) =>
                        renderOptions(params, option.titles[0], inputValue)
                      }
                      getOptionLabel={({ titles }) => titles[0]}
                      options={certainties || []}
                      onChange={(_, value, reason) => {
                        if (reason === 'clear') {
                          field.onChange('');
                          return;
                        }
                        field.onChange(value.value);
                        void trigger('certainty');
                      }}
                      inputprops={{
                        error: !!errors.certainty,
                      }}
                      // onBlur={() => trigger('certainty')}
                    />
                    <FormHelperText error={!!errors.certainty}>{errors.certainty?.message as string}</FormHelperText>
                  </FormControl>
                )}
              />
            </SimpleGrid>
          </SimpleGrid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              isDirty ? setIsOpenConfirmCancelModal(true) : handleCloseModal(undefined);
            }}
            variant="text"
            color="primary"
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            // disabled={!isValid || !isDirty}
            variant="contained"
            color="primary"
            type="submit"
          >
            {action === ModalAction.ADD ? 'Add rule' : 'Edit rule'}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmCancelModal
        action={action}
        actionName="rule"
        closeModal={() => setIsOpenConfirmCancelModal(false)}
        open={isOpenConfirmCancelModal}
        onClose={() => handleCloseModal(undefined)}
      />
    </form>
  );
};
