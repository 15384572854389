import { useMemo, useState } from 'react';
import { useGridApiRef } from '@mui/x-data-grid-pro';
import { GridColDef, GridColumnVisibilityModel } from '@mui/x-data-grid';
import { IOperation, OperationTypes, TransactionTypes } from 'store/slices/Payments/interface';
import { useAppSelector } from 'store/hooks';
import { selectOperationDetails, selectOperationDetailsStatus } from 'store/slices/Payments/operationsSlice';
import Button from 'components/shared/Button/Button';
import Grid from 'components/shared/Grid/Grid';
import RenderCellWithCopy from 'components/shared/DataGrid/RenderCellWithCopy/RenderCellWithCopy';
import Typography from 'components/shared/Typography/Typography';
import {
  RenderInfoCell,
  RenderPayerCell,
  RenderProviderCell,
  RenderRecipientCell,
  RenderStatusCell,
  RenderTransactionFeeCell,
  RenderTransactionIDCell,
  RenderTransactionTotalCell,
  RenderSalesTaxAmountCell,
  RenderAmountCell,
} from '../../components';
import './index.scss';

const TransactionsTable = ({ row: rowData }: { row: IOperation }) => {
  const apiRef = useGridApiRef();

  const data = useAppSelector(selectOperationDetails);
  const status = useAppSelector(selectOperationDetailsStatus);

  let isLoading = status === 'loading';
  let isFailed = status === 'failed';

  const isInAppOperation =
    rowData.operationType === OperationTypes.IAP_FOLLOW ||
    rowData.operationType === OperationTypes.IAP_DONATE ||
    rowData.operationType === OperationTypes.IAP_EVENT ||
    rowData.operationType === OperationTypes.IAP_LIFESTYLE;

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<GridColumnVisibilityModel>({
    id: false,
    externalOrderId: false,
    tierCode: false,
    taxJarOrderId: false,
    currency: false,
    salesTaxAmount: false,
  });

  const handleColumnModel = () => {
    setColumnVisibilityModel((prevModel) => ({
      id: !prevModel.id,
      externalOrderId: isInAppOperation ? !prevModel.externalOrderId : false,
      tierCode: isInAppOperation ? !prevModel.tierCode : false,
      taxJarOrderId: !isInAppOperation ? !prevModel.taxJarOrderId : false,
      currency: !prevModel.currency,
      salesTaxAmount: !isInAppOperation ? !prevModel.salesTaxAmount : false,
    }));
  };

  const columns = useMemo<GridColDef[]>(
    () => [
      {
        field: 'hidden',
        flex: 0.1,
        headerName: '',
        sortable: false,
        renderCell: (params) => (
          <Typography variant="body2" color="text.secondary">
            {apiRef.current.getAllRowIds().indexOf(params.id) + 1}
          </Typography>
        ),
      },
      {
        field: 'psTransactionId',
        headerName: 'Transaction ID',
        flex: 0.8,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => <RenderTransactionIDCell props={params} />,
      },
      {
        field: 'provider',
        headerName: 'Provider / type',
        flex: 0.6,
        minWidth: 130,
        sortable: false,
        renderCell: (params) => <RenderProviderCell props={params} />,
      },
      {
        field: 'id',
        headerName: 'Internal transaction ID',
        flex: 0.8,
        minWidth: 180,
        sortable: false,
        renderCell: (params) => <RenderCellWithCopy isWrappedText props={params} />,
      },
      {
        field: 'externalOrderId',
        headerName: 'External Order ID',
        minWidth: 180,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => <RenderCellWithCopy props={params} />,
      },
      {
        field: 'tierCode',
        headerName: 'Tier code',
        minWidth: 160,
        flex: 0.8,
        sortable: false,
        renderCell: (params) => <RenderCellWithCopy props={params} />,
        valueGetter: (params) =>
          (params.row.type === TransactionTypes.GOOGLE_IAP || params.row.type === TransactionTypes.APPLE_IAP) &&
          rowData.tierCode,
      },
      {
        field: 'payerId',
        headerName: 'Payer profile ID',
        flex: 0.8,
        minWidth: 150,
        sortable: false,
        renderCell: (params) => <RenderPayerCell props={params} rowData={rowData} />,
      },
      {
        field: 'recipientProfileId',
        headerName: 'Recipient profile ID',
        flex: 0.8,
        minWidth: 160,
        sortable: false,
        renderCell: (params) => <RenderRecipientCell props={params} rowData={rowData} />,
      },
      {
        field: 'status',
        headerName: 'Status',
        flex: 0.4,
        minWidth: 115,
        sortable: false,
        renderCell: (params) => <RenderStatusCell props={params} />,
      },
      {
        field: 'amount',
        headerName: 'Amount',
        flex: 0.4,
        minWidth: 110,
        sortable: false,
        type: 'number',
        renderCell: (params) => {
          return <RenderAmountCell props={params} rowData={rowData} />;
        },
      },
      {
        field: 'fee',
        headerName: 'Fee',
        flex: 0.4,
        minWidth: 110,
        sortable: false,
        type: 'number',
        renderCell: (params) => <RenderTransactionFeeCell props={params} rowData={rowData} />,
      },
      {
        field: 'taxJarOrderId',
        headerName: 'Tax transaction ID',
        flex: 0.7,
        minWidth: 160,
        sortable: false,
        renderCell: (params) => <RenderCellWithCopy props={params} />,
        valueGetter: (params) => params.row.type === TransactionTypes.CARD_PAYMENTS && rowData.taxJarOrderId,
      },
      {
        field: 'salesTaxAmount',
        headerName: 'Sales tax amount',
        flex: 0.7,
        minWidth: 150,
        sortable: false,
        type: 'number',
        renderCell: (params) => {
          return <RenderSalesTaxAmountCell props={params} rowData={rowData} />;
        },
      },
      {
        field: 'total',
        headerName: 'Total',
        flex: 0.4,
        minWidth: 100,
        sortable: false,
        type: 'number',
        renderCell: (params) => <RenderTransactionTotalCell props={params} rowData={rowData} />,
      },
      {
        field: 'currency',
        headerName: 'Currency',
        flex: 0.4,
        minWidth: 90,
        sortable: false,
        headerAlign: 'right',
        align: 'right',
        valueFormatter: (params) => {
          return params?.value ? params?.value || rowData.currency : 'USD';
        },
      },
      {
        field: 'info',
        headerName: '',
        flex: 0.3,
        minWidth: 70,
        sortable: false,
        headerAlign: 'center',
        align: 'center',
        renderHeader: () => (
          <Button sx={{ minWidth: 'unset', p: '4px 5px' }} variant="text" size="small" onClick={handleColumnModel}>
            {columnVisibilityModel.id ? 'Less' : 'More'}
          </Button>
        ),
        renderCell: (params) => <RenderInfoCell props={params} />,
      },
    ],
    [columnVisibilityModel],
  );

  return (
    <div id="transactionsTableContainer">
      {data?.transactions && !isFailed && (
        <Grid
          apiRef={apiRef}
          className="transactions-table"
          autoHeight
          columns={columns}
          columnVisibilityModel={columnVisibilityModel}
          loading={isLoading}
          getRowHeight={() => 'auto'}
          rows={data.transactions}
          getRowId={(row) => row.id}
          disableColumnMenu
          disableColumnFilter
          disableColumnSelector
          disableColumnReorder
          disableColumnResize
          disableSelectionOnClick
          rowThreshold={0}
          hideFooter
        />
      )}
    </div>
  );
};

export default TransactionsTable;
