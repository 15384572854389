import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { fetchPermissions, selectIsAuth } from 'store/authSlice';

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const navigate = useNavigate();
  const isAuth = useAppSelector(selectIsAuth);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!isAuth) {
      navigate('/ui/login');
    }

    dispatch(fetchPermissions());
  }, [isAuth]);

  return children;
};
