import { useEffect, useState } from 'react';
import { IMediaItem } from 'types/commonTypes';
import CircularProgress from 'components/shared/CircularProgress/CircularProgress';
import ErrorIcon from 'assets/img/ErrorIcon';
import colors from 'theme/constants/colors';
import './index.scss';

interface IMediaThumbNail {
  error: boolean;
  mediaItem: IMediaItem;
  currentMainEntity: IMediaItem;
  onThumbnailClick: (maniEntity: IMediaItem) => void;
  isActive: boolean;
}

const MediaThumbnail: React.FC<IMediaThumbNail> = ({
  error,
  mediaItem,
  currentMainEntity,
  onThumbnailClick,
  isActive,
}) => {
  const { mediaType, mediaUrl, mediaId } = mediaItem;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  const loadingClass = isLoading ? '--show' : '';
  const errorClass = error && mediaId === currentMainEntity.mediaId && !isLoading ? '--show' : '';

  return (
    <div className="multimedia-preview-item" onClick={() => onThumbnailClick(mediaItem)}>
      <div>
        <div className={`multimedia-preview-loading${loadingClass}`}>
          <CircularProgress />
        </div>
        <div className={`multimedia-preview-error${errorClass}`}>
          <ErrorIcon sx={{ color: colors.lightError }} />
        </div>
        {mediaType === 'VIDEO' ? (
          <video onLoadedData={() => setIsLoading(false)} src={mediaUrl} className={isActive ? 'active' : ''} />
        ) : (
          <img onLoad={() => setIsLoading(false)} className={isActive ? 'active' : ''} src={mediaUrl} alt="Thumbnail" />
        )}
      </div>
    </div>
  );
};

export default MediaThumbnail;
