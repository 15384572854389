import { FC, useState } from 'react';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { InputBaseProps } from '@mui/material';
import { BaseTextFieldProps } from '@mui/material/TextField/TextField';
import colors from 'theme/constants/colors';
import CloseIcon from 'assets/img/CloseIcon';
import SearchIcon from 'assets/img/SearchIcon';
import IconButton from '../IconButton/IconButton';
import InputAdornment from '../InputAdornment/InputAdornment';
import TextField from '../TextField/TextField';
import './index.scss';

/**
 * Props for a search field component.
 *
 * @interface
 * @extends BaseTextFieldProps - Inherited properties from a base text field component.
 * @property {string} searchValue - The current value of the search field.
 * @property {string} label - The label for the search field.
 * @property {string} [placeholder] - The placeholder text for the search field.
 * @property {string} [customWidth] - Custom width for SearchField.
 * @property {number} [maxlength] - The maximum number of characters allowed in the search field (optional).
 * @property {boolean} [disabled] - If true, the field is disabled.
 * @property {StandardInputProps['onChange']} onChange - A function to handle changes in the search field input.
 * @property {() => void} [searchEvent] - An optional callback function to trigger a search event.
 * @property {() => void} [clearEvent] - An optional callback function to trigger a clear event.
 * @property {Partial<InputBaseProps['inputProps']>} [inputProps] - Additional properties to be passed down to the input element.
 * @property {(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void} [onBlur] - A function to handle the blur event.
 * @property {(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void} [onFocus] - A function to handle the focus event.
 * @property {(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void} [onClick] - A function to handle the click event.
 */
interface ISearchFieldProps extends BaseTextFieldProps {
  searchValue: string;
  label: string;
  placeholder?: string;
  customWidth?: string;
  maxlength?: number;
  disabled?: boolean;
  onChange: StandardInputProps['onChange'];
  searchEvent?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement, Element>) => void;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  clearEvent?: () => void;
  inputProps?: Partial<InputBaseProps['inputProps']>;
}

const SearchField: FC<ISearchFieldProps> = (props) => {
  const {
    maxlength,
    label,
    customWidth,
    placeholder,
    disabled,
    onChange,
    onBlur,
    onFocus,
    onClick,
    searchValue,
    searchEvent,
    clearEvent,
    inputProps,
  } = props;

  const [shrink, setShrink] = useState(false);

  const startAdornment = (
    <InputAdornment position="start">
      <SearchIcon color="disabled" />
    </InputAdornment>
  );

  const endAdornment = (
    <InputAdornment position="end">
      {searchValue.length > 0 ? (
        <IconButton size="small" color="secondary" onClick={() => clearEvent && clearEvent()}>
          <CloseIcon />
        </IconButton>
      ) : (
        <></>
      )}
    </InputAdornment>
  );

  return (
    <div className="searchField">
      <TextField
        id="search-field"
        size="small"
        name="searchField"
        label={label || 'Search'}
        placeholder={placeholder}
        value={searchValue}
        disabled={disabled}
        onChange={(e) => onChange && onChange(e)}
        onKeyDown={(e) => {
          (e.key === 'Enter' || e.code === '13') && searchEvent && searchEvent();
        }}
        onFocus={(e) => {
          setShrink(true);
          onFocus && onFocus(e);
        }}
        onBlur={(e) => {
          !e.target.value && setShrink(false);
          onBlur && onBlur(e);
        }}
        onClick={(e) => onClick && onClick(e)}
        InputProps={{
          startAdornment: startAdornment,
          endAdornment: endAdornment,
        }}
        inputProps={{ maxLength: maxlength, ...inputProps }}
        InputLabelProps={{
          shrink: shrink,
        }}
        sx={{
          '& .MuiInputLabel-root:not(.MuiInputLabel-shrink)': {
            color: colors.outlinedBorder,
            transform: 'translate(44px, 9px)',
          },
          width: customWidth,
        }}
      />
    </div>
  );
};

export default SearchField;
