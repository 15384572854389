import { Fragment, HTMLAttributes, Key, ReactNode, useEffect, useState } from 'react';
import uniqueId from 'lodash/uniqueId';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import {
  Autocomplete as AutocompleteBase,
  AutocompleteRenderGroupParams,
  Collapse,
  InputLabelProps,
  useTheme,
} from '@mui/material';
import { AutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import startCase from 'lodash/startCase';
import ChevronUpIcon from 'assets/img/ChevronUpIcon';
import ChevronDownIcon from 'assets/img/ChevronDownIcon';
import Box from 'components/shared/Box/Box';
import Checkbox from 'components/shared/Checkbox/Checkbox';
import Divider from 'components/shared/Divider/Divider';
import IconButton from 'components/shared/IconButton/IconButton';
import TextField from 'components/shared/TextField/TextField';
import Typography from 'components/shared/Typography/Typography';
import './index.scss';

interface IAutocompleteProps extends AutocompleteProps<any, boolean, boolean, boolean> {
  options: any[];
  label?: string;
  helperText?: string;
  inputlabelprops?: Partial<InputLabelProps>;
  inputprops?: Partial<StandardInputProps>;
}

/**
 * Render highlighted options for an Autocomplete component.
 *
 * @param {HTMLAttributes<HTMLLIElement>} props - HTML attributes for the list item.
 * @param {string} option - The option to be displayed, which may contain the inputValue.
 * @param {string} inputValue - The current input value for highlighting.
 * @param {boolean} [selected] - Indicates whether the option is selected (for multiple selection).
 * @param {boolean} [multiple] - Indicates whether the Autocomplete allows multiple selection.
 * @param {boolean} [allSelected] - Indicates whether all options are selected (for "Select All" functionality).
 * @returns {ReactNode} React element representing the highlighted option.
 */
export const renderOptions = (
  props: HTMLAttributes<HTMLLIElement>,
  option: string,
  inputValue: string,
  selected?: boolean,
  multiple?: boolean,
  allSelected?: boolean,
): ReactNode => {
  const matches = match(option, inputValue, { insideWords: true });
  const parts = parse(option, matches);
  const selectAllProps = option.toLowerCase().includes('all') ? { checked: allSelected } : {};

  return (
    <Fragment key={uniqueId()}>
      <li {...props}>
        <>
          {multiple && <Checkbox style={{ marginRight: 8 }} checked={selected} {...selectAllProps} />}
          <Typography variant="body1">
            {parts.map((part, index: Key) => (
              <span style={{ fontWeight: part.highlight ? 500 : 400 }} key={index}>
                {part.text}
              </span>
            ))}
          </Typography>
        </>
      </li>
      {multiple && option.toLowerCase().includes('all') && <Divider flexItem orientation="horizontal" />}
    </Fragment>
  );
};

export const CollapseGroup = ({
  params,
  inputValue,
}: {
  params: AutocompleteRenderGroupParams;
  inputValue?: string;
}) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (inputValue) {
      setOpen(true);
    }
  }, [inputValue]);

  return (
    <Box p="4px 0">
      <IconButton disableRipple aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
        {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
      </IconButton>
      <Typography sx={{ cursor: 'pointer' }} variant="body2Bold" display="inline-block" onClick={() => setOpen(!open)}>
        {startCase(params.group.toLowerCase())}
      </Typography>
      <Collapse in={open} timeout="auto">
        {params.children}
      </Collapse>
    </Box>
  );
};

const Autocomplete = (props: Omit<IAutocompleteProps, 'renderInput'>) => {
  const theme = useTheme();

  return (
    <AutocompleteBase
      {...props}
      renderInput={(params) => (
        <TextField
          {...params}
          onKeyDown={(event) => {
            if (event.key === 'Backspace') {
              event.stopPropagation();
            }
          }}
          variant="outlined"
          label={props.label}
          error={props.inputprops?.error}
          helperText={props.helperText}
          InputLabelProps={{
            ...params.InputLabelProps,
            ...props.inputlabelprops,
          }}
          InputProps={{
            ...params.InputProps,
            ...props.inputprops,
          }}
          sx={{
            '& .MuiAutocomplete-clearIndicator': {
              display: props.clearIcon === null ? 'none' : 'inline-flex',
            },
            '& .MuiInputBase-root': {
              color: theme.palette.common.black,
            },
            ...props.sx,
          }}
        />
      )}
    />
  );
};

export default Autocomplete;
