import React from "react";
import Button from "components/shared/Button/Button";
import { fetchUnpublishCollection } from "store/worldManagementSlice";
import { enqueueSnackbar, VariantType } from "notistack";
import { useAppDispatch } from "store/hooks";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Collection } from "store/collectionDetailsSlice";

import './index.scss';

dayjs.extend(utc);

interface IProps {
  item: Collection;
  onClose(): void;
}

// todo проверить, когда будут коллекции в статусе Live
const UnpublishCollectionModal = ({ item, onClose }: IProps) => {
  const dispatch = useAppDispatch();

  const handleUnpublush = async () => {
    try {
      const result = await dispatch(fetchUnpublishCollection({
        compilationId: item.id,
        deactivatedDateTime: dayjs().utc(true).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      }));
      onClose();
      result.meta.requestStatus === 'fulfilled' && enqueueSnackbar(`"${item.title}" collection has been unpublished`,
        { variant: 'success' as VariantType });
      result.meta.requestStatus === 'rejected' && enqueueSnackbar(`Unpublishing error. Try later`,
        { variant: 'error' as VariantType });
    } catch (e) {
      enqueueSnackbar(`Unpublishing error. Try later`, { variant: 'error' as VariantType });
    }
  }

  return (
    <div className='unpublishCollectionsModal'>
      <div className="unpublishCollectionsModal-header">You are going to unpublish {item.title} collection</div>
      <div className="unpublishCollectionsModal-footer">
        <div className="unpublishCollectionsModal-footer-buttons">
          <Button onClick={onClose} variant="text" color="primary">Cancel</Button>
          <Button variant="contained" color="warning" onClick={handleUnpublush}>Unpublish collection</Button>
        </div>
      </div>
    </div>
  )
};

export default UnpublishCollectionModal;
