import ZoomInIcon from 'assets/img/ZoomInIcon';
import Carousel from 'react-material-ui-carousel';

const LifestyleCard = (props: any) => {
  const widthEntity = 200;
  const heightEntity = 300;
  return props.data.orderedMediaList.length > 1 ? (
    <Carousel
      height={300}
      animation="slide"
      autoPlay={false}
      swipe={false}
      className="lifestyleCard--Carousel"
      navButtonsAlwaysInvisible={true}
      indicatorIconButtonProps={{ className: 'carouselIcon', style: { color: '#BFC6E2' } }}
      activeIndicatorIconButtonProps={{ style: { color: '#4E79E7' } }}
      indicatorContainerProps={{ style: { display: 'flex', justifyContent: 'center', gap: '4px' } }}
    >
      {props.data.orderedMediaList.map((el: any) => (
        <div className="carouselEntity">
          <div
            className="overlay"
            style={{ width: widthEntity, height: heightEntity }}
            onClick={() => {
              props.showMedia(
                el.mediaUrl ? el.mediaUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
                el,
                el.mediaType === 'VIDEO' ? 'video' : 'Image',
                props.data.orderedMediaList ?? [],
              );
            }}
          >
            <ZoomInIcon />
            VIEW MEDIA
          </div>
          <img
            src={!!el.mediaPreviewUrl ? el.mediaPreviewUrl : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'}
            height={heightEntity}
            width={widthEntity}
          />
        </div>
      ))}
    </Carousel>
  ) : (
    <div className="carouselEntity">
      <div
        className="overlay"
        style={{ width: widthEntity, height: heightEntity }}
        onClick={() => {
          const mediaItem = props.data.orderedMediaList[0] || {
            mediaUrl: 'https://www.svgrepo.com/show/33921/sea-turtle.svg',
            mediaType: 'Image',
          };

          props.showMedia(
            mediaItem.mediaUrl,
            mediaItem,
            mediaItem.mediaType === 'VIDEO' ? 'video' : 'Image',
            props.data.orderedMediaList,
          );
        }}
      >
        <ZoomInIcon />
        VIEW MEDIA
      </div>
      <img
        src={
          !!props.data.orderedMediaList[0].mediaPreviewUrl
            ? props.data.orderedMediaList[0].mediaPreviewUrl
            : 'https://www.svgrepo.com/show/33921/sea-turtle.svg'
        }
      />
    </div>
  );
};

export default LifestyleCard;