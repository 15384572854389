import { useState, useEffect, MutableRefObject, useCallback, useMemo } from 'react';
import { LocalStorageKeys } from 'types/commonTypes';

const useVideoPlayer = (videoElement: MutableRefObject<HTMLVideoElement | null>) => {
  const playerSettings = useMemo(() => {
    return {
      speed: localStorage.getItem(LocalStorageKeys.SPEED),
      volume: localStorage.getItem(LocalStorageKeys.VOLUME),
      mutedVolume: localStorage.getItem(LocalStorageKeys.MUTED_VOLUME),
    };
  }, []);

  const [playerState, setPlayerState] = useState({
    isPlaying: false,
    progress: 0,
    duration: 0,
    currentTime: 0,
    mutedVolume: playerSettings.volume ? Number(playerSettings.mutedVolume) : 0,
    volume: playerSettings.volume ? Number(playerSettings.volume) : 50,
    speed: playerSettings.speed ? Number(playerSettings.speed) : 1,
    isMuted: playerSettings.volume ? Number(playerSettings.volume) === 0 : false,
  });

  const togglePlay = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.muted = playerState.isMuted;
      playerState.isPlaying ? videoElement.current?.pause() : videoElement.current?.play();
      setPlayerState({
        ...playerState,
        isPlaying: !playerState.isPlaying,
      });
    }
  }, [videoElement.current, playerState.isPlaying, playerState]);

  const toggleMute = useCallback(() => {
    if (videoElement.current) {
      const volume = !playerState.isMuted ? 0 : Number(playerState.mutedVolume);
      let newPlayerSettings: any = {
        volume: volume,
        isMuted: !playerState.isMuted,
      };
      if (!playerState.isMuted) {
        newPlayerSettings = {
          ...newPlayerSettings,
          mutedVolume: Number(playerState.volume),
        };
      }
      videoElement.current.muted = !playerState.isMuted;
      videoElement.current.volume = volume / 100;
      setPlayerState((prevState) => {
        return {
          ...prevState,
          ...newPlayerSettings,
        };
      });
    }
  }, [videoElement.current, playerState.isMuted, playerState]);

  const handleOnTimeUpdate = useCallback(() => {
    if (videoElement.current) {
      const progress = (videoElement.current.currentTime / videoElement.current.duration) * 100;
      setPlayerState({
        ...playerState,
        progress,
      });
    }
  }, [videoElement.current, playerState, playerState.currentTime, playerState.duration]);

  const handleVideoProgress = useCallback(
    (event: any, value: any) => {
      if (videoElement.current) {
        const manualChange = Number(value);
        videoElement.current.currentTime = manualChange;
        setPlayerState({
          ...playerState,
          progress: manualChange,
          isPlaying: false,
        });
      }
    },
    [videoElement.current, playerState],
  );

  const handleVideoSpeed = useCallback(
    (event: any, value: any) => {
      if (videoElement.current) {
        const speed = Number(value);
        videoElement.current.playbackRate = speed;
        setPlayerState({
          ...playerState,
          speed,
        });
      }
    },
    [videoElement.current, playerState],
  );

  const handleVideoVolume = useCallback(
    (event: any, value: any) => {
      if (videoElement.current) {
        const manualChange = Number(value);
        videoElement.current.volume = manualChange / 100;
        if (manualChange === 0) {
          videoElement.current.muted = true;
          setPlayerState({
            ...playerState,
            isMuted: true,
            volume: manualChange,
          });
        } else {
          videoElement.current.muted = false;
          setPlayerState({
            ...playerState,
            isMuted: false,
            volume: manualChange,
          });
        }
      }
    },
    [videoElement.current, videoElement.current?.volume, playerState],
  );

  const handleReloadVideo = useCallback(() => {
    if (videoElement.current) {
      videoElement.current.load();
    }
  }, [videoElement.current]);

  useEffect(() => {
    if (videoElement.current) {
      setPlayerState({
        ...playerState,
        duration: videoElement.current?.duration || 0,
        currentTime: videoElement.current?.currentTime || 0,
      });
    }
  }, [
    playerState.duration,
    playerState.currentTime,
    videoElement.current?.duration,
    videoElement.current?.currentTime,
  ]);

  useEffect(() => {
    if (videoElement.current) {
      videoElement.current.playbackRate = playerState.speed;
    }
  }, [videoElement, playerState.speed]);

  useEffect(() => {
    playerState.isPlaying ? videoElement.current?.play() : videoElement.current?.pause();
  }, [playerState.isPlaying, videoElement]);

  useEffect(() => {
    return () => {
      localStorage.setItem(LocalStorageKeys.SPEED, playerState.speed.toString());
      localStorage.setItem(LocalStorageKeys.VOLUME, playerState.volume.toString());
      localStorage.setItem(LocalStorageKeys.MUTED_VOLUME, playerState.mutedVolume.toString());
    };
  });

  return {
    playerState,
    setPlayerState,
    togglePlay,
    handleOnTimeUpdate,
    handleVideoProgress,
    handleVideoSpeed,
    toggleMute,
    handleVideoVolume,
    handleReloadVideo,
  };
};

export default useVideoPlayer;
